import React, { useState } from 'react';

import { useForm }                  from 'react-hook-form';
import { useDispatch,  } from 'react-redux';
import classes                      from './IncludeIncorporation.module.css';
import Button                       from '../elements/Button';
import Dialog                       from '../elements/Dialog';

import { decimalFormat, integerFormat, }            from '../utils/constants';
import { handleError, rules }                       from '../utils/rules';
import Grid                                         from '@mui/material/Grid';
import { searchPaper }                              from '../../store/slices/paper';
import FormDate                                     from '../elements/FormDate';
import CakeIcon                                     from '@mui/icons-material/Cake';
import FormAutoComplete                             from '../elements/FormAutoComplete';
import FormMask                                     from '../elements/FormMask';
import AttachMoneyIcon                              from '@mui/icons-material/AttachMoney';
import MultilineChartIcon                           from '@mui/icons-material/MultilineChart';
import { computeAmortization, includeAmortization } from '../../store/slices/amortization';
import FormSwitch                                   from '../elements/FormSwitch';

const defaultValues = {
  claimDate : '',
  payDate   : '',
  paper     : null,
  value     : '',
  sequential: '',
  schedule  : false,
};

const IncludeAmortization = () => {
  
  const dispatch = useDispatch();
  
  const [modalOn, setModalOn] = useState(false);
  
  const {control, handleSubmit,  getValues, formState} = useForm({mode: 'all', defaultValues});
  
  const {dirtyFields, isValid, errors} = formState;
  
  let modal;
  
  const openModal = () => {
    setModalOn(true);
  };
  
  const closeModal = () => setModalOn(false);
  
  const onSubmit = (data) => {
    
    const {claimDate, payDate, paper, value, sequential, schedule} = data;
    
    const func = schedule ? includeAmortization : computeAmortization;
    
    dispatch(func({
      papelId      : paper.cdPapel,
      dataDireito  : claimDate,
      dataCredito  : payDate,
      seqlDireito  : sequential,
      vlAmortizacao: value
    }));
    
  };
  
  const disabled  = !isValid;
  const showPaper = (!!dirtyFields['claimDate'] && !Boolean(errors['claimDate']));
  
  modal = <Dialog id="amortization-modal" show={modalOn} handleClose={closeModal} title={'Inclusão Amortização'}>
    
    <form id="amortization" onSubmit={handleSubmit(onSubmit)} className={classes.Form}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} className={classes.Item}>
          <FormDate label="Data Direito" name="claimDate" Icon={CakeIcon} errorMessage={handleError(errors, 'claimDate')}
                    control={control} rules={{required: true}}
          />
        </Grid>
        <Grid item xs={12} sm={6} className={classes.Item}>
          <FormDate label="Data Crédito" name="payDate" Icon={CakeIcon} errorMessage={handleError(errors, 'payDate')}
                    control={control}
          />
        </Grid>
        <Grid item xs={12} sm={6} className={classes.Item}>
          <FormMask label="Valor Amortização" name="value" Icon={AttachMoneyIcon} defaultValue={defaultValues['value']}
                    errorMessage={handleError(errors, 'value')} control={control}
                    rules={rules('value')} formatOptions={decimalFormat}
          />
        </Grid>
        <Grid item xs={12} sm={6} className={classes.Item}>
          <FormMask label="Sequencial" name="sequential" Icon={MultilineChartIcon} defaultValue={defaultValues['sequential']}
                    errorMessage={handleError(errors, 'sequential')} control={control}
                    rules={rules('sequential')} formatOptions={integerFormat}
          />
        </Grid>
        <Grid item xs={12} sm={6} className={classes.Item}>
          {showPaper &&
          <FormAutoComplete label="Papel" name="paper" placeholder="Pesquise o papel" control={control} dataFields={{slice: 'paper', data: 'papersSearched'}}
                            dataFunction={searchPaper} searchField={'busca'} requiredData={{dataPesquisa: getValues('claimDate')}}
                            displayField="sigla" rules={(rules('paper'))} errorMessage={handleError(errors, 'paper')}/>
          }
        </Grid>
        <Grid item xs={12} sm={6} className={classes.Switch}>
          <FormSwitch label={'Agendar'} name={'schedule'} control={control} defaultValue={defaultValues['schedule']}/>
        </Grid>
        <Grid item xs={12} className={classes.Button}>
          <Button color="secondary" disabled={disabled} type="submit">
            Cadastrar
          </Button>
        </Grid>
      </Grid>
    </form>
  </Dialog>;
  
  return (
    <>
      <Button color="secondary" disabled={!!modalOn} onClick={openModal}>
        Incluir
      </Button>
      {modal}
    </>
  );
};

export default IncludeAmortization;
