import React from 'react';

import { useForm }                  from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import classes                      from '../containerActions/IncludeIncorporation.module.css';
import Button                       from '../elements/Button';
import Dialog                       from '../elements/Dialog';
import { handleError }              from '../utils/rules';
import Grid                         from '@mui/material/Grid';
import FormDate                     from '../elements/FormDate';
import CakeIcon                     from '@mui/icons-material/Cake';
import { justToDate }               from '../utils/date';


const defaultValues = {
  date: '',
};

const ProcessJob = ({slice, procFunc}) => {
  
  const dispatch           = useDispatch();
  const {processSituation} = useSelector(state => state[slice]);
  
  const [modalOn, setModalOn] = React.useState(false);
  
  const {control, handleSubmit, formState} = useForm({mode: 'all', defaultValues});
  
  const {isValid, errors} = formState;
  
  let modal;
  
  const openModal = () => {
    setModalOn(true);
  };
  
  const closeModal = () => setModalOn(false);
  
  const onSubmit = (data) => {
    
    const {date,} = data;
    
    dispatch(procFunc({dataProcessamento: justToDate(date)}));
    
  };
  
  const disabled = (!isValid || processSituation === 'pending');
  
  modal = <Dialog id="p-process-modal" show={modalOn} handleClose={closeModal} title={'Disparar Processamento'}>
    
    <form id="p-process" onSubmit={handleSubmit(onSubmit)} className={classes.Form}>
      <Grid container spacing={2}>
        <Grid item xs={12} className={classes.Item}>
          <FormDate label="Data Dados Processar" name="date" Icon={CakeIcon} errorMessage={handleError(errors, 'date')}
                    control={control} rules={{required: true}}
          />
        </Grid>
        <Grid item xs={12} className={classes.Button}>
          <Button color="secondary" disabled={disabled} type="submit">
            {processSituation === 'pending' ? 'Processando' : 'Disparar'}
          </Button>
        </Grid>
      </Grid>
    </form>
  </Dialog>;
  
  return (
    <>
      <Button color="secondary" onClick={openModal}>
        Processar
      </Button>
      {modal}
    </>
  );
};

export default ProcessJob;
