import React, { useEffect, useMemo } from 'react';
import Grid                         from '@mui/material/Grid';
import Table                        from '../../components/elements/Table';
import { useDispatch, useSelector } from 'react-redux';
import { getUnderwritings }           from '../../store/slices/underwriting';
import IncludeUnderwriting            from '../../components/containerActions/IncludeUnderwriting';
import { formatCurrency, formatDate } from '../../components/utils/formatters';


const Underwriting = () => {
  
  const dispatch = useDispatch();
  
  const {underwritings} = useSelector(state => state.underwriting);
  
  useEffect(() => {
    
    dispatch(getUnderwritings());
  }, []);
  
  const columns = useMemo(
    () =>
      [
        {
          accessor: 'papel',
          Header  :  'Papel',
        },
        {
          accessor: 'recibo',
          Header  :  'Recibo',
        },
        {
          accessor: 'tipoSubscricao',
          Header  : 'Tipo Subscrição',
        },
        {
          accessor: 'vlSubscricao',
          Header  : 'Valor Subscrição',
          Cell    : ({value}) => formatCurrency(value),
        },
        {
          accessor: value => formatDate(value.dataDebito) ,
          Header  : 'data Débito',
          sortType: 'alphanumericOriginal',
        },
        {
          accessor: value => formatDate(value.dataConversao) ,
          Header  :'data Conversão',
          sortType: 'alphanumericOriginal',
        },
      ],
    []
  );
  
 
  return (
    <Grid container>
      <Grid item xs={12}>
        <Table columns={columns} rowsData={underwritings} inclusion={<IncludeUnderwriting/>}/>
      </Grid>
    </Grid>
  );
};

export default Underwriting;
