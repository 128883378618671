import React, { useEffect, useState } from 'react';
import { motion }                                 from 'framer-motion';
import Paper                                      from '@mui/material/Paper';
import classes                                    from '../sections/FeatureTiles.module.css';
import Typography                                 from '@mui/material/Typography';
import { useInView }                              from 'react-intersection-observer';

const CardTile = ({variants, delay, title, children, icon}) => {
  
  const [ref, inView]         = useInView({rootMargin: '-50px 0px', triggerOnce: true});
  const [variant, setVariant] = useState('hidden');
  
  useEffect(() => {
    if (inView) {
      setVariant('visible');
    }
  }, [inView]);
  
  const Icon = icon;
  
  return (
    <motion.div ref={ref} variants={variants} initial="hidden" animate={variant} transition={{duration: 0.7, delay: delay}}>
      <Paper elevation={0} className={classes.Card}>
        <span className={classes.Icon}>
          <Icon  style={{fontSize: 40}}/>
        </span>
        <Typography variant="h4" className={classes.MinorTitle}>
          {title}
        </Typography>
        <Typography variant="body2" className={classes.MinorText}>
          {children}
        </Typography>
      </Paper>
    </motion.div>
  );
};


// const CardTile = ({ref, variants, show, delay, title, children, icon}) => {
//
//   const myDelay = delay || 0
//
//
//   return forwardRef = ({variants, show, delay, title, children, icon}, ref) => {
//     return <motion.div ref={ref}  variants={variants} initial="hidden" animate={show} transition={{duration: 0.7, delay: myDelay}}>
//       <Paper elevation={0} className={classes.Card}>
//         <Image
//           src={icon}
//           className={classes.Icon}
//           width={64}
//           height={64}/>
//         <Typography variant="h4" className={classes.MinorTitle}>
//           {title}
//         </Typography>
//         <Typography variant="body2" className={classes.MinorText}>
//           {children}
//         </Typography>
//       </Paper>
//     </motion.div>
//   };
// };

export default CardTile;
