import React from 'react';

import Accordion             from '@mui/material/Accordion';
import AccordionDetails      from '@mui/material/AccordionDetails';
import AccordionSummary      from '@mui/material/AccordionSummary';
import Typography            from '@mui/material/Typography';
import ExpandMoreIcon        from '@mui/icons-material/ExpandMore';
import Grid                  from '@mui/material/Grid';
import classes               from './Faq.module.css';
import { motion }            from 'framer-motion';
import Tooltip               from '@mui/material/Tooltip';
import IconButton            from '@mui/material/IconButton';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { useNavigate }        from 'react-router-dom';


const Faq = () => {
  
  const navigate = useNavigate();
  
  return (
    <Grid container className={classes.Container}>
      <Grid item xs={12}>
        <motion.div
          className={classes.MajorTitleContainer}
          initial={{y: 50, opacity: 0}}
          animate={{y: 0, opacity: 1}}
          transition={{duration: 1}}
        >
          <Typography variant="h1" className={classes.MajorTitle}>
            Perguntas Frequentes
          </Typography>
        </motion.div>
      </Grid>
      <Grid item xs={12}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon/>} aria-controls="general1-content" id="general1-header">
            <Typography className={classes.Title}>Geral</Typography>
            <Typography className={classes.Subtitle}>Existe aplicação móvel?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Atualmente apenas a possibilidade de acessar via navegador com toda a responsividade necessária.
              Estamos trabalhando para lançar um aplicativo para os celulares.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon/>} aria-controls="calc1-content" id="calc1-header">
            <Typography className={classes.Title}>Cálculos</Typography>
            <Typography className={classes.Subtitle}>
              Cálculos Day Trade
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              O cálculo de lucro/prejuízos de Day Trade considera alíquotas semelhantes para classes de ativos semelhantes, ou seja ação com ETF, fundos imobiliários com fundos agro.
              A norma da Receite Federal em relação a esse assunto não é específica, o entendimento é que essa é a interpretação mais adequada.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon/>} aria-controls="calc2-content" id="calc2-header">
            <Typography className={classes.Title}>Cálculos</Typography>
            <Typography className={classes.Subtitle}>
              Consigo incluir minhas opções?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Sim, você consegue incluir suas operações de opções, exercícios, declaração de pó.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon/>} aria-controls="calc3-content" id="calc3-header">
            <Typography className={classes.Title}>Cálculos</Typography>
            <Typography className={classes.Subtitle}>
              Qual data é utilizada para os cálculos? Data do Negócio ou Data da Liquidação?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Nas operações de compra e venda são consideradas a data de negociação. Já no caso de ipo / subscrição, é considerada a menor data entre a data
              de liquidação do cronograma da oferta e a data de início de negociação do papel.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Grid>
      <Grid item xs={12} className={classes.Button}>
        <Tooltip title={'Voltar'} placement={'top'}>
          <IconButton aria-label="back" onClick={() => navigate('/')}>
            <KeyboardBackspaceIcon/>
          </IconButton>
        </Tooltip>
      </Grid>
    </Grid>
  );
};

export default Faq;
