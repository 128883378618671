import React, { useState } from 'react';
import MCard               from '@mui/material/Card';
import CardHeader                                       from '@mui/material/CardHeader';
import CardContent                                      from '@mui/material/CardContent';
import Typography                                       from '@mui/material/Typography';
import classes                                          from './ReportCard.module.css';
import { formatCurrency,} from '../utils/formatters';
import InputBoxNumber                                   from './InputBoxNumber';



const AddonCard = ({title, subtitle, price, pack, selectPackage, className}) => {
  
  
        const [total, setTotal]     = useState(0);
  
        const classCard = [classes.Card, className].join(' ');
        let color       = !!className ? {background: 'white'} : null;
    
  
        const changeValue = (value) => {
          setTotal(value * price);
          selectPackage(pack, value);
    
        };
  
     
        return (
          <MCard className={classCard} variant="outlined">
            <CardHeader title={title} subheader={subtitle} titleTypographyProps={{className: classes.Title}}/>
            <CardContent>
              <div className={classes.Separator} style={{marginTop: '-16px', ...color}}/>
              <div className={classes.SubTitleBox}>
                <InputBoxNumber selectedValue={changeValue} maxValue={10}/>
                <Typography variant="body2" gutterBottom className={classes.Text}>
                  {formatCurrency(total)}
                </Typography>
              </div>
            </CardContent>
          </MCard>
        );
      }
;

export default AddonCard;
