import React, { useEffect, useMemo, useState } from 'react';
import Table                                   from '../../components/elements/Table';
import { useDispatch, useSelector }            from 'react-redux';
import { getMonthTax }                         from '../../store/slices/tax';
import { formatCurrency, mesExtenso }          from '../../components/utils/formatters';
import YearBusinessSelect                      from '../../components/partials/YearBusinessSelect';
import Grid                                    from '@mui/material/Grid';

import EditButton from '../../components/elements/EditButton';
import TaxEdit    from '../../components/containerActions/TaxEdit';
import Box        from '../../components/elements/Box';
import classes    from '../Wallet/Wallet.module.css';
import Typography from '@mui/material/Typography';


const MonthTax = () => {
  
  const dispatch                        = useDispatch();
  const {monthTax}                      = useSelector(state => state.tax);
  const {yearBusiness}                  = useSelector(state => state.business);
  const [yearSelected, setYearSelected] = useState('');
  
  
  useEffect(() => {
    if (yearSelected) dispatch(getMonthTax({ano: yearSelected}));
  }, [yearSelected]);
  
  const [editOn, setEditOn]   = useState(false);
  const [rowData, setRowData] = useState(null);
  
  useEffect(() => {
   if (!!rowData && monthTax.length)           setRowData(monthTax[rowData.index]);
  }, [monthTax]);
  
  const openEdit  = (rowData) => {
    setRowData({...rowData.original, index: rowData.index});
    setEditOn(true);
  };
  const closeEdit = () => {
    setEditOn(false);
    setRowData(null);
  };
  
  
  const handleChange = (event) => {
    setYearSelected(event.target.value);
  };
  
  const initializeValue = (value) => {
    setYearSelected(value);
  };
  
  const columns = useMemo(
    () =>
      [
        {
          accessor: 'mes',
          Header  : 'Mês Base',
          Cell    : ({value}) => mesExtenso(value)
        },
        {
          accessor: 'tipoPapel',
          Header  : 'Tipo',
          
        },
        {
          accessor: 'tipoOperacao',
          Header  : 'Modalidade',
        },
        {
          accessor: 'vlTransacionado',
          Header  : 'Valor Transacionado',
          Cell    : ({value}) => formatCurrency(value)
        },
        {
          accessor: 'vlLucro',
          Header  : 'Valor Lucro',
          Cell    : ({value}) => formatCurrency(value)
        },
        {
          accessor: 'vlPrejuizo',
          Header  : 'Valor Prejuízo',
          Cell    : ({value}) => formatCurrency(value)
        },
        {
          accessor: 'vlImpostoCalculado',
          Header  : 'Valor Imposto Calculado',
          Cell    : ({value}) => formatCurrency(value)
        },
        {
          accessor: 'vlImpostoRecolher',
          Header  : 'Valor Imposto Recolher',
          Cell    : ({value}) => formatCurrency(value)
        },
        {
          accessor: 'vlImpostoRetido',
          Header  : 'Valor Imposto Retido',
          Cell    : ({value}) => formatCurrency(value)
        },
        {
          accessor: 'vlImpostoPago',
          Header  : 'Valor Imposto Pago',
          Cell    : ({value}) => formatCurrency(value)
        },
        {
          Header: '',
          id    : 'edit',
          Cell  : EditButton('informar impostos', openEdit)
        },
      ],
    []
  );
  
  return (
    <Grid container>
      <Grid item xs={12}>
        <Table columns={columns} rowsData={monthTax}
               inclusion={
                 <YearBusinessSelect onChange={handleChange} value={yearSelected} onInit={initializeValue}/>}/>
        <TaxEdit data={{ano: yearSelected, ...rowData}} modalOn={editOn} closeModal={closeEdit}/>
      </Grid>
      {!yearBusiness.length &&
      <Box>
        <Grid item xs={12} className={classes.NegativeBox}>
          <Typography variant={'h6'} className={classes.NegativeText}>
            Você ainda não possui realizações
          </Typography>
        </Grid>
      </Box>
      }
    </Grid>
  
  );
};


export default MonthTax;
