import React, { useEffect, useMemo }                 from 'react';
import { formatCurrency, formatDate, formatInteger } from '../../components/utils/formatters';
import excludePopover                                from '../../components/partials/ExcludePopover';
import Grid                         from '@mui/material/Grid';
import Table                        from '../../components/elements/Table';
import { useDispatch, useSelector } from 'react-redux';
import { excludeTransfer, getTransfers } from '../../store/slices/transference';
import IncludeTransfer                   from '../../components/containerActions/IncludeTransfer';



const Transference = () => {
  
  const dispatch = useDispatch();
  
  const {transfers} = useSelector(state => state.transference);
  
  const confirmExclusion = (rowData) => {
    const {papelId, dataTransferencia, corretoraIdOrigem, corretoraIdDestino} = rowData.original;
    dispatch(excludeTransfer({
      data: {papelId, dataTransferencia, corretoraIdOrigem, corretoraIdDestino}, internalData: {index: rowData.index}
    }));
  };
  
  useEffect(() => {
    
    dispatch(getTransfers());
  }, []);
  
  const columns = useMemo(
    () =>
      [
        {
          accessor: 'papel',
          Header  :  'Papel',
        },
        {
          accessor: 'quantidade',
          Header  : 'Quantidade',
        },
        {
          accessor: 'corretoraOrigem',
          Header  :'Corretora Origem',
        },
        {
          accessor: 'corretoraDestino',
          Header  :'Corretora Destino',
        },
        {
          accessor: 'dataTransferencia',
          Header  :'Data Transferência',
          Cell    : ({value}) => formatDate(value)
        },
        {
          Header: '',
          id: 'exclusion',
          Cell    : excludePopover(confirmExclusion, 'transferência')
        },
      ],
    []
  );
  // console.log("business", business)
  
  return (
    <Grid container>
      <Grid item xs={12}>
        {columns.length && <Table columns={columns} rowsData={transfers} inclusion={<IncludeTransfer/>}/>}
      </Grid>
    </Grid>
  );
};

export default Transference;
