import React, { useEffect, useState } from 'react';
import Grid                           from '@mui/material/Grid';
import classes                        from '../sections/FeatureSplit.module.css';
import Typography                     from '@mui/material/Typography';
import Image                          from './Image';

import { useInView }                  from 'react-intersection-observer';
import { motion }                     from 'framer-motion';

const variants1 = {
  hidden : {
    opacity: 0,
    x      : 50,
  },
  visible: {
    opacity: 1,
    x      : 0,
  }
};
const variants2 = {
  hidden : {
    opacity: 0,
    x      : -50,
  },
  visible: {
    opacity: 1,
    x      : 0,
  }
};
const variants3 = {
  hidden : {
    opacity: 0,
    y      : 50,
  },
  visible: {
    opacity: 1,
    y      : 0,
  }
};

const VisualFeature = ({title, subtitle, children, direction, image, delay}) => {
  
  const [ref, inView]         = useInView({rootMargin: '-50px 0px', triggerOnce: true});
  const [variant, setVariant] = useState('hidden');
  
  
  useEffect(() => {
    if (inView) {
      setVariant('visible');
    }
    
  }, [inView]);
  
  let variants = variants1;
  if (direction === 'row-reverse') variants = variants2
  
  return (
    <Grid justifyContent="center" alignItems="center" container className={classes.VisualRoot} spacing={2}  direction={direction}>
      <Grid item xs={12} md={6}>
        <motion.div ref={ref} variants={variants3} initial="hidden" animate={variant} transition={{duration: 0.7, delay: delay}}>
        <Grid justifyContent="center" container className={classes.InternalBox}>
          <Grid item xs={12}>
            <Typography variant="body1" className={classes.PreTitle}>
              {title}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h3" className={classes.MinorTitle}>
              {subtitle}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" className={classes.Text}>
              {children}
            </Typography>
          </Grid>
        </Grid>
        </motion.div>
      </Grid>
      <Grid item xs={12} md={6}>
        <motion.div ref={ref} variants={variants} initial="hidden" animate={variant} transition={{duration: 0.7, delay: delay}}>
        <Image
          src={image}
          width={528}
          height={396}/>
        </motion.div>
      </Grid>
    </Grid>
  )
  
}

export default VisualFeature
