import { createSlice } from '@reduxjs/toolkit';
import { thunkAPI }    from '../../components/utils/api';

export const computeAmortization   = thunkAPI('amortization/compute', '/v1/amortizacao/processar/computar', 'post',
  {successMessage: 'Amortização processada com sucesso'});
export const includeAmortization   = thunkAPI('amortization/include', '/v1/amortizacao/processar', 'post',
  {successMessage: 'Amortização incluída com sucesso'});
export const getMyAmortizations    = thunkAPI('amortization/get', 'v1/posse/amortizacao', 'get',);
export const getPaperAmortizations = thunkAPI('paperAmortization/get', 'v1/amortizacao/busca/20', 'get',);
export const processAmortization   = thunkAPI('amortization/process', '/v1/amortizacao/processar/data', 'post',
  {successMessage: 'Amortização processada com sucesso'});

const formatMyAmortizations = (amortizations) => {
  
  const formattedAmortizations = [];
  // console.log('amortizations', amortizations)
  let i                        = 0;
  for (const data of amortizations) {
    
    formattedAmortizations.push({
      // id             : `${data.Papel.sigla}-${data.dtDireito}-${data.seqlDireito}`,
      id             : i,
      papel          : data.Papel.sigla,
      dataDireito    : data.dtDireito,
      sequencial     : data.seqlDireito,
      dataAmortizacao: data.dtCredito,
      quantidade     : data.qtPapel,
      vlUnitario     : data.vlAmortizacao,
      vlTotal        : data.vlAmortizacao * data.qtPapel,
      
    });
    i++;
    
  }
  
  return formattedAmortizations;
  
};
const formatAmortizations   = (amortizations) => {
  
  const formattedAmortizations = [];
  // console.log('amortizations', amortizations);
  let i                        = 0;
  for (const data of amortizations) {
    
    formattedAmortizations.push({
      id              : i,
      dataDireito     : data.dtDireito,
      valorAmortizacao: data.vlAmortizacao,
      sequencial      : data.seqlDireito,
      dataCredito     : data.dtCredito,
      vigente         : data.vigente ? 'Sim' : 'Não',
      
    });
    i++;
  }
  
  return formattedAmortizations;
  
};

const amortizationSlice = createSlice({
  name         : 'amortization',
  initialState : {
    paperAmortizations: [],
    myAmortizations   : [],
    includeSituation  : 'idle',
    searchSituation   : 'idle',
    processSituation  : 'idle',
  },
  reducers     : {},
  extraReducers: {
    [getMyAmortizations.fulfilled]   : (state, action) => {
      state.myAmortizations = formatMyAmortizations(action.payload);
    },
    [getPaperAmortizations.fulfilled]: (state, action) => {
      state.paperAmortizations = formatAmortizations(action.payload);
      state.searchSituation    = 'idle';
    },
    [getPaperAmortizations.pending]  : (state) => {
      state.searchSituation = 'pending';
    },
    [getPaperAmortizations.rejected] : (state) => {
      state.searchSituation = 'rejected';
    },
    [processAmortization.fulfilled]  : (state) => {
      state.processSituation = 'idle';
    },
    [processAmortization.pending]    : (state) => {
      state.processSituation = 'pending';
    },
    [processAmortization.rejected]   : (state) => {
      state.processSituation = 'rejected';
    },
  }
});

const {reducer} = amortizationSlice;


export default reducer;
