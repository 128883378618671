import React, { useMemo }                   from 'react';
import Grid                                 from '@mui/material/Grid';
import Table                                from '../../components/elements/Table';
import { useSelector }                      from 'react-redux';
import IncludeEarning                       from '../../components/containerActions/IncludeEarning';
import classes                              from './Earning.module.css';
import { getPaperEarnings, processEarning } from '../../store/slices/earning';
import { formatCurrency, formatDate }       from '../../components/utils/formatters';
import RedeemEarning                        from '../../components/containerActions/RedeemEarning';
import ProcessJob                           from '../../components/partials/ProcessJob';
import ParameterHeader                      from '../../components/sections/ParameterHeader';

const Earning = () => {
  
  const {paperEarnings} = useSelector(state => state.earning);
  
  const columns = useMemo(
    () =>
      [
        {
          accessor: 'tipoProvento',
          Header  : 'Tipo de Provento',
          
        },
        {
          accessor: 'dataDireito',
          Header  : 'Data do Direito',
          Cell    : ({value}) => formatDate(value)
        },
        {
          accessor: 'dataPagamento',
          Header  : 'Data do Pagamento',
          Cell    : ({value}) => formatDate(value)
        },
        {
          accessor: 'valorProventoBruto',
          Header  : 'Valor Bruto do Provento',
          Cell    : ({value}) => formatCurrency(value)
        },
      ],
    []
  );
  
  return (
    <Grid container>
      <Grid item xs={12} className={classes.Header}>
        <ParameterHeader Redeem={RedeemEarning} Process={ProcessJob} getFunc={getPaperEarnings} procFunc={processEarning} slice={'earning'}/>
      </Grid>
      <Grid item xs={12}>
        <Table columns={columns} rowsData={paperEarnings} inclusion={<IncludeEarning/>}/>
      </Grid>
    </Grid>
  );
};

export default Earning;
