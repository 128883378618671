import React, { useEffect, useMemo }                  from 'react';
import Table                                         from '../../components/elements/Table';
import { useDispatch, useSelector }                  from 'react-redux';
import { getMyAmortizations }                        from '../../store/slices/amortization';
import { formatCurrency, formatDate, formatInteger } from '../../components/utils/formatters';
import Grid                                          from '@mui/material/Grid';
import Box                                           from '../../components/elements/Box';
import classes                                       from '../Wallet/Wallet.module.css';
import Typography                                    from '@mui/material/Typography';

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'

const MyAmortization = () => {
  
  const dispatch          = useDispatch();
  const {myAmortizations} = useSelector(state => state.amortization);
  
  useEffect(() => {
    dispatch(getMyAmortizations());
  }, []);
  
  const columns = useMemo(
    () =>
      [
        {
          accessor: 'papel',
          Header  : 'Papel',
        },
        {
          accessor: 'dataDireito',
          Header  : 'Data Direito',
          Cell    : ({value}) => formatDate(value)
        },
        {
          accessor: 'sequencial',
          Header  : 'Sequencial do Direito',
          Cell    : ({value}) => formatInteger(value)
        },
        {
          accessor: 'dataAmortizacao',
          Header  : 'Data Amortização',
          Cell    : ({value}) => formatDate(value)
        },
        {
          accessor: 'quantidade',
          Header  : 'Quantidade',
        },
        {
          accessor: 'vlUnitario',
          Header  : 'Valor Unitário Amortizado',
          Cell    : ({value}) => formatCurrency(value)
        },
        {
          accessor: 'vlTotal',
          Header  : 'Valor Total Amortizado',
          Cell    : ({value}) => formatCurrency(value)
        },
      ],
    []
  );
  
  const table = useReactTable({
    data: myAmortizations,
    columns,
    getCoreRowModel: getCoreRowModel(),
  })
  
  return (
    <Grid container>
       {!!myAmortizations.length &&
      <Grid item xs={12}>
        {/* <Table columns={columns} rowsData={myAmortizations}/> */}
        <table>
        <thead>
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map(header => (
                <th key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        {/* <tbody>
          {table.getRowModel().rows.map(row => (
            <tr key={row.id}>
              {row.getVisibleCells().map(cell => (
                <td key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
        <tfoot>
          {table.getFooterGroups().map(footerGroup => (
            <tr key={footerGroup.id}>
              {footerGroup.headers.map(header => (
                <th key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.footer,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </tfoot> */}
      </table>
      </Grid>
      }  
      {!myAmortizations.length &&
      <Box>
        <Grid item xs={12} className={classes.NegativeBox}>
          <Typography variant={'h6'} className={classes.NegativeText}>
            Você não possui amortizações
          </Typography>
        </Grid>
      </Box>
      }
    </Grid>
  
  );
};


export default MyAmortization;
