import React from 'react';

import classes          from './Input.module.css';
import FormHelperText   from '@mui/material/FormHelperText';
import FormControl      from '@mui/material/FormControl';
import TextField        from '@mui/material/TextField';
import { Controller }   from 'react-hook-form';
import {DatePicker}       from '@mui/x-date-pickers/DatePicker';

const FormDate = ({label, name, rules, errorMessage, control}) => {
  
 
  return (
    <FormControl aria-label={name} fullWidth className={classes.Field}>
      <Controller
        render={({field}) =>
          <DatePicker
            disableFuture
            clearable
            {...field}
            label={label}
            renderInput={(params) => <TextField {...params}  error={!!errorMessage}  />}
          />
        }
        name={name}
        control={control}
        rules={rules}
      />
      {!!errorMessage && <FormHelperText error margin="dense">{errorMessage}</FormHelperText>}
    </FormControl>
  );
};


export default FormDate;
