import React, { useEffect, useState } from 'react';

import { useFieldArray, useForm, useWatch } from 'react-hook-form';
import { useDispatch, useSelector }         from 'react-redux';
import classes                              from './IncludeBusiness.module.css';
import Button                               from '../elements/Button';
import FormSelect                           from '../elements/FormSelect4';
import FormDate                             from '../elements/FormDate';
import Dialog                               from '../elements/Dialog';
import { getExchanges }                     from '../../store/slices/exchange';
import { handleError, rules }               from '../utils/rules';
import Grid                                 from '@mui/material/Grid';
import TodayIcon                            from '@mui/icons-material/Today';
import HelpTransference                     from '../helpers/HelpTransference';
import IconButton                           from '../elements/IconButton';
import AddCircleIcon                        from '@mui/icons-material/AddCircle';
import FieldsFormTransference               from '../sections/FieldsFormTransference';
import { includeLotTransfer }               from '../../store/slices/transference';


const newTransfer = {
  paper   : null,
  quantity: '',
};

const defaultValues = {
  date       : null,
  inExchange : null,
  outExchange: null,
  items      : [{...newTransfer}],
};


const IncludeTransfer = () => {
  
  const dispatch = useDispatch();
  
  const {includeSituation} = useSelector(state => state.transference);
  const {exchanges}        = useSelector(state => state.exchange);
  
  const [modalOn, setModalOn] = React.useState(false);
  const [helpOn, setHelpOn]   = useState(false);
  
  useEffect(() => {
    dispatch(getExchanges());
  }, []);
  
  
  const {control, handleSubmit, reset, formState} = useForm({mode: 'all', defaultValues});
  
  const {fields, prepend, remove} = useFieldArray({
    control,
    name: 'items'
  });
  
  const {isSubmitted, isValid, errors} = formState;
  
  useEffect(() => {
    if (includeSituation === 'idle' && isSubmitted) reset(defaultValues);
  }, [includeSituation]);
  
  let modal;
  
  const openModal = () => {
    setModalOn(true);
  };
  
  const handleHelp = () => {
    setHelpOn((prevOpen) => !prevOpen);
  };
  
  const closeModal = () => setModalOn(false);
  
  const onSubmit = (data) => {
    
    const {date, inExchange, outExchange, items} = data;
    const transferencias                         = [];
    const transferenciasSaida                    = [];
    
    for (const item of items) {
      const {paper, quantity,} = item;
      
      let papelId = paper.cdPapel;
      let papel   = paper.sigla;
      
      transferencias.push({
        papelId,
        dataTransferencia : date,
        corretoraIdOrigem : outExchange.corretoraId,
        corretoraIdDestino: inExchange.corretoraId,
        quantidadePapel   : quantity,
      });
      transferenciasSaida.push({
        dataTransferencia: date,
        quantidade       : quantity,
        corretoraOrigem  : {
          nome: outExchange.abreviatura,
          id  : outExchange.corretoraId
        },
        corretoraDestino : {
          nome: inExchange.abreviatura,
          id  : inExchange.corretoraId
        },
        papel            : {
          nome: papel,
          id  : papelId
        },
      });
    }
    
    dispatch(includeLotTransfer({data: {transferencias}, internalData: {transferenciasSaida}}));
    
  };
  
  const disabled = (!isValid || includeSituation === 'pending');
  // const disabled = false;
  
  const transferDate = useWatch({name: 'date', control});
  
  
  modal = <Dialog id="transference-modal" show={modalOn} handleClose={closeModal} handleHelp={handleHelp} title={'Inclusão Transferências'}>
    <form id="transference" onSubmit={handleSubmit(onSubmit)} className={classes.Form}>
      <Grid container spacing={2}>
        <Grid item xs={12} >
          <Grid container spacing={2} className={classes.FixedFields}>
            <Grid item xs={12} sm={4}  className={classes.Item}>
              <FormDate label="Data da Transferência" name="date" Icon={TodayIcon} errorMessage={handleError(errors, 'date')}
                        control={control} rules={rules('businessDate')}
              />
            </Grid>
            <Grid item xs={12} sm={3} className={classes.Item}>
              {/*<FormSelect label="Corretora Origem" name="outExchange" errorMessage={handleError(errors, 'outExchange')}*/}
              {/*            displayField={'abreviatura'} valueField={'corretoraId'}*/}
              {/*            rules={(rules('exchange', null, 'Corretora'))} data={exchanges} control={control}*/}
              {/*/>*/}
              <FormSelect label="Corretora Origem" name="outExchange" errorMessage={handleError(errors, 'outExchange')}
                          displayField={'abreviatura'} dataFields={{slice: 'exchange', data: 'exchanges'}} valueField={'corretoraId'}
                          rules={(rules('exchange', null, 'Corretora'))} control={control}
              />
            </Grid>
            <Grid item xs={12} sm={3}  className={classes.Item}>
              {/*<FormSelect label="Corretora Destino" name="inExchange" errorMessage={handleError(errors, 'inExchange')}*/}
              {/*            displayField={'abreviatura'} valueField={'corretoraId'}*/}
              {/*            rules={(rules('exchange', null, 'Corretora'))} data={exchanges} control={control}*/}
              {/*/>*/}
              <FormSelect label="Corretora Destino" name="inExchange" errorMessage={handleError(errors, 'inExchange')}
                          displayField={'abreviatura'} dataFields={{slice: 'exchange', data: 'exchanges'}} valueField={'corretoraId'}
                          rules={(rules('exchange', null, 'Corretora'))} control={control}
              />
            </Grid>
            <Grid item xs={12} sm={2}  className={classes.IncOpButton}>
              <IconButton aria-label="add" tooltip="incluir outra operação" color="primary" icon={AddCircleIcon} onClick={() => prepend(newTransfer)}/>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.BusinessBox}>
          <Grid container spacing={2}>
            {!!exchanges.length &&
            fields.map((item, index) => {
              return (
                <Grid item xs={12} className={classes.OperationBox} key={index}>
                  <FieldsFormTransference errors={errors} control={control} date={transferDate}
                                          key={item.id} id={item.id} index={index} removeAction={remove}/>
                </Grid>
              );
            })
            }
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.Button}>
          <Button color="secondary" disabled={disabled} type="submit">
            {includeSituation === 'pending' ? 'Cadastrando' : 'Cadastrar'}
          </Button>
        </Grid>
      </Grid>
    </form>
  </Dialog>;
  
  
  return (
    <>
      <Button color="secondary" disabled={!!modalOn} onClick={openModal}>
        Incluir
      </Button>
      {modal}
      <HelpTransference modalOn={helpOn} closeModal={handleHelp}/>
    </>
)

};

export default IncludeTransfer;
