import React, { useEffect }         from 'react';
import Grid                         from '@mui/material/Grid';
import classes                      from './DateBar.module.css';
import FormDate                     from '../elements/FormDate';
import DateRangeIcon                from '@mui/icons-material/DateRange';
import { handleError }              from '../utils/rules';
import { useForm }                  from 'react-hook-form';
import Button                       from '../elements/Button';



const defaultValues = {
  referenceDate: '',
};

const DateBar = ({getDates}) => {
  
  const {handleSubmit, control, getValues, formState, } = useForm({mode: 'all', defaultValues});
  
  const {errors, isValid} = formState;
  
  useEffect(() => {
    getDates(getValues());
  }, []);
  
  const disabled = (!isValid);
  
  const onSubmit = (data) => {
    // console.log("data", data)
    getDates(data);
  };
  
  return (
    <Grid container spacing={3} justifyContent="center" className={classes.Container}>
      <Grid item xs={12} md={3}>
        <FormDate label="Data Consulta" name="referenceDate" Icon={DateRangeIcon} errorMessage={handleError(errors, 'referenceDate')}
                  control={control} rules={{required: true}}
        />
      </Grid>
      <Grid item xs={12} md={3} className={classes.Button}>
        <Button color="secondary" disabled={disabled} onClick={handleSubmit(onSubmit)}>
          Consultar
        </Button>
      </Grid>
    </Grid>
  );
};

export default DateBar;
