import React, { useEffect }           from 'react';
import classes                        from './PaperEdit.module.css';
import Dialog                         from '../elements/Dialog';
import Grid                           from '@mui/material/Grid';
import { useDispatch, useSelector }   from 'react-redux';
import { editPaper }                  from '../../store/slices/paper';
import InputText                      from '../elements/FormText';
import ReceiptIcon                    from '@mui/icons-material/Receipt';
import { handleError, rules }         from '../utils/rules';
import Button                         from '../elements/Button';
import { useForm }                    from 'react-hook-form';
import FormSwitch                     from '../elements/FormSwitch';
import FormDate                       from '../elements/FormDate';
import CakeIcon                       from '@mui/icons-material/Cake';
import FormSelect                     from '../elements/FormSelect';
import { paperPart, paperVisibility } from '../utils/constants';
import { justToDate }         from '../utils/date';

const defaultValues = {
  initials     : '',
  visibility   : '',
  part         : '',
  current      : false,
  endValidity  : '',
  startValidity: '',
  
};

const PaperEdit = ({data, modalOn, closeModal}) => {
  
  
  const dispatch = useDispatch();
  
  const {includeSituation}                               = useSelector(state => state.paper);
  const {handleSubmit, watch, formState, control, reset} = useForm({mode: 'all', defaultValues});
  
  const {isValid, errors} = formState;
  const watchCurrent      = watch('current');
  
  
  useEffect(() => {
    
    if (data) {
      defaultValues.initials   = data.sigla;
      defaultValues.current    = data.vigente === 'sim';
      defaultValues.visibility = data.visibilidade === 'pública' ? 1 : 2;
      defaultValues.part       = data.lote;
    }
    reset(defaultValues);
  }, [data]);
  
  
  const onSubmit = (dataChanged) => {
    const {initials, part, visibility, current, endValidity, startValidity} = dataChanged;
    
    // console.log("dataChanged", dataChanged)
    dispatch(editPaper(
      {
        urlId       : data.id,
        data        : {sigla: initials, lotePadrao: part, utilizacao: visibility, vigente: current, dataInicio: startValidity, dataFim: endValidity},
        internalData: {
          id          : data.id,
          sigla       : initials,
          lote        : part,
          visibilidade: visibility,
          vigente     : current,
          dataInicio  : justToDate(startValidity),
          dataFim     : justToDate(endValidity),
          index       : data.index,
          tipo        : data.tipo
        }
      })
    );
    
  };
  
  
  const disabled = (!isValid || includeSituation === 'pending');
  
  return (
    <Dialog id="paper-edit-modal" show={modalOn} handleClose={closeModal} title={'Edição de Papel'}>
      <form id="paper" onSubmit={handleSubmit(onSubmit)} className={classes.Form}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} className={classes.Item}>
            <InputText label="Sigla Papel" name="initials" Icon={ReceiptIcon} errorMessage={handleError(errors, 'initials')}
                       control={control} rules={rules('initials', null, 'Sigla')}
            />
          </Grid>
          <Grid item xs={12} sm={6} className={classes.Select}>
            <FormSelect label="Visibilidade" name="visibility" errorMessage={handleError(errors, 'visibility')}
                        displayField={'name'} valueField={'value'}
                        rules={(rules('visibility'))} data={paperVisibility} control={control}
            />
          </Grid>
          <Grid item xs={12} sm={6} className={classes.Select}>
            <FormSelect label="Lote Padrão" name="part" errorMessage={handleError(errors, 'part')}
                        displayField={'name'} valueField={'value'}
                        rules={(rules('part', null, 'Lote Padrão'))} data={paperPart} control={control}
            />
          </Grid>
          <Grid item xs={12} sm={6} className={classes.Item}>
            {watchCurrent &&
            <FormDate label="Nova Vigência" name="startValidity" Icon={CakeIcon} errorMessage={handleError(errors, 'startValidity')}
                      control={control} rules={{required: true}}
            />
            }
            {!watchCurrent && <FormDate label="Fim Vigência" name="endValidity" Icon={CakeIcon} errorMessage={handleError(errors, 'endValidity')}
                                        control={control} rules={{required: true}}
            />
            }
          </Grid>
          
          <Grid item xs={12} sm={6} className={classes.Switch}>
            <FormSwitch label={'Vigente'} name={'current'} control={control} defaultValue={defaultValues['current']}/>
          </Grid>
          <Grid item xs={12} className={classes.Button}>
            <Button color="secondary" disabled={disabled} type="submit">
              {includeSituation === 'pending' ? 'Cadastrando' : 'Cadastrar'}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Dialog>
  );
  
};


export default PaperEdit;
