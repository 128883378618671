import React          from 'react';
import classes        from './ExcludePopover.module.css';
import MConfirmPopover from '../elements/ConfirmPopover';
import RowingIcon from '@mui/icons-material/Rowing';

const ConfirmPopover = (actionFunc, type) => {
  
  const artigo = type.slice(-1);
  
  return ({row}) => {
    
    
    return (
      <div className={classes.ActionsButtons}>
        <MConfirmPopover title="Confirmação de Ação" message={`Confirma execução d${artigo} ${type}?`} tooltip={`execução ${type}`}
                        iconButton={RowingIcon}
                        onConfirm={() => actionFunc(row)}
        />
      </div>
    );
    
  };
}

export default ConfirmPopover;
