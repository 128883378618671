import React, { useEffect,   }           from 'react';
import classes                                  from '../containerActions/IncludeBusiness.module.css';
import Grid                                     from '@mui/material/Grid';
import FormDate                                 from '../elements/FormDate';
import TodayIcon                                from '@mui/icons-material/Today';
import { handleError, rules }                   from '../utils/rules';
import FormSelect                               from '../elements/FormSelect4';
import Button                                   from '../elements/Button';
import { getBusinessTypes, includeLotBusiness } from '../../store/slices/business';
import { useFieldArray, useForm, useWatch }     from 'react-hook-form';
import { useDispatch, useSelector }             from 'react-redux';
import { getExchanges }                         from '../../store/slices/exchange';
import FieldsFormBusiness                       from './FieldsFormBusiness';
import IconButton                               from '../elements/IconButton';
import AddCircleIcon                      from '@mui/icons-material/AddCircle';
import { justToDate, stringDateWithTime } from '../utils/date';


const newBusiness = {
  businessType : null,
  paper        : null,
  exercisePaper: null,
  quantity     : '',
  value        : '',
  cost         : '',
  subCost      : 0,
  underwriting : '',
  exercise     : false,
};

const defaultValues = {
  date    : null,
  exchange: null,
  items   : [{...newBusiness}],
};


const FormBusiness = ({modalOn, initialData, blockChange, closeModal}) => {
  
  const dispatch                          = useDispatch();
  const {businessTypes, includeSituation} = useSelector(state => state.business);
  const {exchanges}                       = useSelector(state => state.exchange);
  
  useEffect(() => {
    if (modalOn && !businessTypes.length) {
      dispatch(getBusinessTypes());
      dispatch(getExchanges());
    }
  }, [modalOn]);
  
  useEffect(() => {
    if (initialData) {
      // console.log("initialData", initialData)
      // const bt = businessTypes.find(bt => bt.id === initialData.operationId);
      // const businessType = {value: {...bt}, label: bt.nome};
  
      defaultValues.date = stringDateWithTime(initialData.date);
      // defaultValues.exchange              = JSON.stringify(exchanges.find(ex => ex.abreviatura === initialData.corretora));
      defaultValues.exchange              = exchanges.find(ex => ex.abreviatura === initialData.corretora);
      defaultValues.items[0].quantity     = initialData.quantidade;
      defaultValues.items[0].businessType = businessTypes.find(bt => bt.id === initialData.operationId);
      defaultValues.items[0].paper        = {cdPapel: initialData.papelId, sigla: initialData.papel, cdTipoPapel: initialData.cdTipoPapel};
      // console.log("defaultValues.exchange", defaultValues.exchange)
    reset(defaultValues);
    }
    
    
  }, [initialData]);
  
  
  const {control, handleSubmit, reset, formState} =          useForm({mode: 'all', defaultValues});
  
  const {fields, prepend, remove} = useFieldArray({
    control,
    name: 'items'
  });
  
  const {isSubmitted, isValid, errors} = formState;
  
  useEffect(() => {
    // console.log("isSubmitted", isSubmitted, includeSituation)
    if (includeSituation === 'idle' && isSubmitted) {
      if (!!initialData) closeModal();
      reset({
        date    : null,
        exchange: null,
        items   : [
          {
            businessType : null,
            paper        : null,
            exercisePaper: null,
            quantity     : '',
            value        : '',
            cost         : '',
            subCost      : 0,
            underwriting : '',
            exercise     : false,
          }
        ]
      });
    }
    
  }, [includeSituation]);
  
  
  const onSubmit = (data) => {
   
    let {date, exchange, items} = data;
    const negocios                = [];
    const negociosSaida           = [];
    // console.log('passou aqui',data);
    // exchange = JSON.parse(exchange)
    date = justToDate(date)
    for (const item of items) {
      let {cost, exercisePaper, paper, quantity, businessType, subCost, value, underwriting, exercise} = item;

      if (!!underwriting) underwriting = JSON.parse(underwriting)

      let myDate  = !!underwriting ? underwriting.date : date;
      const myValue = !!underwriting ? underwriting.value : value;

      let papelId = paper.cdPapel;
      let papel   = paper.sigla;
      let papelIdExercicio;

      if (exercise) {
        papelId          = exercisePaper.cdPapel;
        papel            = exercisePaper.sigla;
        papelIdExercicio = paper.cdPapel;
      }

      if (exercise) {
        papelId          = exercisePaper.cdPapel;
        papel            = exercisePaper.sigla;
        papelIdExercicio = paper.cdPapel;
      }

      negocios.push({
        papelId,
        papelIdExercicio,
        quantidadePapel: quantity,
        tipoNegocio    : businessType.id,
        valorPapel     : value,
        custo          : cost,
        dataNegocio    : myDate,
        corretoraId    : exchange.corretoraId,
        custoSubscricao: subCost,
      });
      negociosSaida.push({
        custo      : cost,
        dataNegocio: myDate,
        quantidade : quantity,
        papel,
        valorPapel : myValue,
        corretora  : exchange.abreviatura,
        tipoNegocio: businessType.nome,
        exercido   : exercise,
      });
    }
  
    dispatch(includeLotBusiness({data: {negocios}, internalData: {negociosSaida}}));
    
  };
  
  const disabled = (!isValid || includeSituation === 'pending');
  // const disabled = false;
  
  let businessDate = useWatch({name: 'date', control});
  
  
  if(!!businessDate && typeof businessDate  === 'object' && !!businessDate.getFullYear())  businessDate = justToDate(businessDate)
  
  const watches = useWatch({
    name: "items",
    control
  });
  
  return (
    <form id="business" onSubmit={handleSubmit(onSubmit)} className={classes.Form}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={2} className={classes.FixedFields}>
            <Grid item xs={12} sm={4} className={classes.Item}>
              <FormDate label="Data do Negócio" name="date" Icon={TodayIcon} errorMessage={handleError(errors, 'date')}
                        control={control} rules={rules('businessDate')}
              />
            </Grid>
            <Grid item xs={12} sm={4} className={classes.Item}>
              <FormSelect label="Corretora" name="exchange" errorMessage={handleError(errors, 'exchange')}
                          displayField={'abreviatura'} dataFields={{slice: 'exchange', data: 'exchanges'}} valueField={'corretoraId'}
                          rules={(rules('exchange', null, 'Corretora'))} control={control}
              />
            </Grid>
            {!blockChange &&
            <Grid item xs={12} sm={4} className={classes.IncOpButton}>
              <IconButton aria-label="add" tooltip="incluir outra operação" color="primary" icon={AddCircleIcon} onClick={() => prepend(newBusiness)}/>
            </Grid>
            }
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.BusinessBox}>
          <Grid container spacing={2} >
            {!!businessTypes.length &&
            fields.map((item, index) => {
              return (
                <Grid item xs={12} className={classes.OperationBox} key={index}>
                  <FieldsFormBusiness errors={errors} control={control} date={businessDate} watches={watches[index]}
                                       key={item.id} id={item.id} index={index} removeAction={remove} blockChange={blockChange}/>
                </Grid>
              );
            })
            }
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.Button}>
          <Button color="secondary" disabled={disabled} type="submit">
            {includeSituation === 'pending' ? 'Cadastrando' : 'Cadastrar'}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default FormBusiness;
