import { combineReducers } from 'redux';

import achievementReducer   from '../slices/achievement';
import amortizationReducer  from '../slices/amortization';
import apiReducer           from '../slices/api';
import appReducer           from '../slices/app';
import authReducer          from '../slices/auth';
import businessReducer      from '../slices/business';
import companyReducer       from '../slices/company';
import contactReducer       from '../slices/contact';
import earningReducer       from '../slices/earning';
import earningEventReducer  from '../slices/earningEvent';
import exchangeReducer      from '../slices/exchange';
import incorporationReducer from '../slices/incorporation';
import marketValueReducer   from '../slices/marketValue';
import othersReducer        from '../slices/others';
import paperReducer         from '../slices/paper';
import rearrangementReducer from '../slices/rearrangement';
import receiptReducer       from '../slices/receipt';
import repurchaseReducer    from '../slices/repurchase';
import scissionReducer      from '../slices/scission';
import taxReducer           from '../slices/tax';
import transferenceReducer  from '../slices/transference';
import underwritingReducer  from '../slices/underwriting';
import userReducer          from '../slices/user';
import walletReducer        from '../slices/wallet';
import { setHeader }        from '../../components/utils/api';

const reducers = combineReducers({
  achievement  : achievementReducer,
  amortization : amortizationReducer,
  api          : apiReducer,
  app          : appReducer,
  auth         : authReducer,
  business     : businessReducer,
  company      : companyReducer,
  contact      : contactReducer,
  earning      : earningReducer,
  earningEvent : earningEventReducer,
  exchange     : exchangeReducer,
  incorporation: incorporationReducer,
  marketValue  : marketValueReducer,
  others       : othersReducer,
  paper        : paperReducer,
  rearrangement: rearrangementReducer,
  receipt      : receiptReducer,
  repurchase   : repurchaseReducer,
  scission     : scissionReducer,
  tax          : taxReducer,
  transference : transferenceReducer,
  underwriting : underwritingReducer,
  user         : userReducer,
  wallet       : walletReducer,
});

const rootReducer = (state, action) => {
  
  if (action.type === 'auth/logout' || action.type === 'api/expired') {
    // console.log('entrou aqui');
    setHeader(null);
    localStorage.removeItem('token');
    state = undefined;
  }
  
  return reducers(state, action);
};

export default rootReducer;
