import { createSlice } from '@reduxjs/toolkit';
import { thunkAPI }    from '../../components/utils/api';

export const getMarketValues     = thunkAPI('marketValue/get', '/v1/cotacoes/atual', 'get',);
export const captureMarketValues = thunkAPI('marketValue/include', '/v1/cotacoes/atual/atualizar', 'post',
  {successMessage: 'Cotação capturada com sucesso'});


const formatMarketValues = (marketValues) => {
  
  // console.log('marketValues', marketValues);
  const formattedMarketValues = [];
  
  for (const market of marketValues) {
    formattedMarketValues.push({
      id          : market.cdPapel,
      papel       : market.Papel.sigla,
      dataCotacao : market.dtCotacao,
      valorCotacao: market.vlPapel,
    });
    
  }
  
  return formattedMarketValues;
  
};

const slice = createSlice({
  name         : 'marketValue',
  initialState : {
    marketValues    : [],
    includeSituation: 'idle',
  },
  reducers     : {},
  extraReducers: {
    [getMarketValues.fulfilled]    : (state, action) => {
      state.marketValues = formatMarketValues(action.payload);
    },
    [captureMarketValues.fulfilled]: (state) => {
      state.includeSituation = 'idle';
    },
    [captureMarketValues.pending]  : (state) => {
      state.processSituation = 'pending';
    },
    [captureMarketValues.rejected] : (state) => {
      state.processSituation = 'rejected';
    },
  }
});

const {reducer} = slice;

export default reducer;
