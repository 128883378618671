import React, { useEffect }         from 'react';
import { useNavigate, useParams }    from 'react-router-dom';
import Grid                         from '@mui/material/Grid';
import classes                      from './Login.module.css';
import { validation }               from '../../store/slices/auth';
import { useDispatch, useSelector } from 'react-redux';
import Typography                   from '@mui/material/Typography';

const RegisterValidation = () => {
  
  const dispatch = useDispatch();
  const {token}  = useParams();
  const navigate  = useNavigate();
  
  const {validationSituation} = useSelector(state => state.auth);
  
  if (!token) navigate('/');
  
  useEffect(() => {
    
    dispatch(validation({urlId: token}));
    
  }, [token]);
  
  let message;
  switch (validationSituation) {
    case 'success':
      message = 'Cadastro Validado com sucesso, você já pode realizar login!';
      break;
    case 'rejected':
      message = 'Erro na validação dos dados, entre em contato com o suporte';
      break;
    default:
      message = 'Validação em andamento, aguarde...';
      break;
  }
  
  return (
    <Grid container justifyContent={'center'}>
      
      <Grid item xs={12} className={classes.MessageBox}>
        <Typography variant="h1" className={classes.TitleBox}>
          {message}
        </Typography>
      </Grid>
    </Grid>
  
  );
  
};
export default RegisterValidation;
