import React from 'react';

import Logo         from './partials/Logo';
import FooterNav    from './partials/FooterNav';
import FooterSocial from './partials/FooterSocial';
import classes      from './Footer.module.css';
import Container    from '@mui/material/Container';
import Grid         from '@mui/material/Grid';
import Typography   from '@mui/material/Typography';
import { Link }     from 'react-router-dom';


const Footer = ({className, ...props}) => {
  
  const classBox = [classes.Box, className].join(' ');
  
  return (
    <footer       {...props}    >
      <Container className={classBox}>
        <Grid container justifyContent="space-between" className={classes.Root} >
          <Grid item xs={12} md={6}>
            <Logo/>
          </Grid>
          <Grid item xs={12} md={6}>
            <FooterSocial/>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="body1" className={classes.Text}>
              <Link to="/" className={classes.Link}>
                Kiila
              </Link>
             . Todos os direitos reservados
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <FooterNav/>
          </Grid>
        </Grid>
      </Container>
    </footer>
  );
};


export default Footer;
