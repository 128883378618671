import React from 'react';
import classes              from './Modal.module.css';

import MModal        from '@mui/material/Modal';
import Backdrop      from '@mui/material/Backdrop';
import Fade          from '@mui/material/Fade';


const Modal = ({className, children, show, handleClose, closeHidden, video, videoTag, ...props}) => {
  
  
  const myClasses = [classes.Container, className].join(' ');
  
  
  return (
    <div>
    
      <MModal className={classes.Modal} open={show} onClose={handleClose} scroll={'paper'}
              closeAfterTransition BackdropComponent={Backdrop} BackdropProps={{timeout: 500,}}
              aria-labelledby="transition-modal-title" aria-describedby="transition-modal-description">
        <Fade in={show}>
          <div  className={myClasses}>
          {children}
          </div >
        </Fade>
      </MModal>
    </div>
  );

  
}



export default Modal;
