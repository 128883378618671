import React, { useEffect } from 'react';
import classes              from './Contact.module.css';
import Grid                 from '@mui/material/Grid';

import { handleError, rules }       from '../../components/utils/rules';
import { useForm }                  from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Container                    from '@mui/material/Container';
import { motion }                   from 'framer-motion';
import Typography                   from '@mui/material/Typography';
import Tooltip                      from '@mui/material/Tooltip';
import IconButton                   from '@mui/material/IconButton';
import KeyboardBackspaceIcon        from '@mui/icons-material/KeyboardBackspace';
import { useNavigate }               from 'react-router-dom';
import Button                       from '../../components/elements/Button';
import InputText                    from '../../components/elements/FormText';
import MailIcon                     from '@mui/icons-material/Email';
import FormTextMultiline            from '../../components/elements/FormTextMultiline';
import {
  includeManifestation,
  includeUserManifestation
}                                   from '../../store/slices/contact';


const defaultValues = {
  email  : '',
  subject: '',
};

const Contact = ({}) => {
  
  const dispatch = useDispatch();
  const navigate  = useNavigate();
  
  const {includeSituation} = useSelector(state => state.contact);
  const {isAuthenticated}  = useSelector(state => state.auth);
  
  useEffect(() => {
    
    if (includeSituation === 'idle') reset(defaultValues);
    
  }, [includeSituation]);
  
  
  const {control, handleSubmit, reset, formState} = useForm({mode: 'all', defaultValues});
  
  
  const {isValid, errors} = formState;
  
  const onSubmit = (data) => {
    // console.log('passou aqui',);
    const {email, subject} = data;
    console.log(data); // handle the data here
    if (isAuthenticated) dispatch(includeUserManifestation({email, manifestacao: subject}));
    else dispatch(includeManifestation({email, manifestacao: subject}));
  };
  
  const disabled = (!isValid || includeSituation === 'pending');
 
  
  return (
    <Container>
      <Grid container justifyContent="center" spacing={2}>
        <Grid item xs={12}>
          <motion.div
            className={classes.TitleContainer}
            initial={{y: 50, opacity: 0}}
            animate={{y: 0, opacity: 1}}
            transition={{duration: 1}}
          >
            <Typography variant="h1" className={classes.Title}>
              Contato
            </Typography>
          </motion.div>
        </Grid>
        <Grid item xs={12} sm={8}>
          <motion.div initial={{y: 50, opacity: 0.2}}
                      animate={{y: 0, opacity: 1}} transition={{duration: 0.7, delay: 0.2}}>
            <Typography variant="h6" className={classes.Text}>
              Tem alguma duvida? Quer fazer alguma consideração? Está no espaço certo!
            </Typography>
          </motion.div>
        </Grid>
        <Grid item xs={12}>
          <form id="contact" onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2} justifyContent={'center'}>
              <Grid item xs={12} sm={8}>
                <InputText label="Email" name="email" Icon={MailIcon} errorMessage={handleError(errors, 'email')}
                           control={control} rules={rules('email',)}
                />
              </Grid>
              <Grid item xs={12} sm={8}>
                <FormTextMultiline label="Manifestação" name="subject" Icon={MailIcon} errorMessage={handleError(errors, 'subject')}
                                   rows={8} control={control} rules={rules('subject', null, 'Assunto')}
                />
              </Grid>
              <Grid item xs={12} className={classes.Button}>
                <Button color="secondary" disabled={disabled} type="submit">
                  {includeSituation === 'pending' ? 'Enviando' : 'Enviar'}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
        <Grid item xs={12} className={classes.Button}>
          <Tooltip title={'Voltar'} placement={'top'}>
            <IconButton aria-label="back" onClick={() => navigate('/')}>
              <KeyboardBackspaceIcon/>
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    </Container>
  
  );
};

export default Contact;
