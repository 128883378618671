export const checkRole             = (routes, element, requiredRoles, user) => {
  
  
  if (!user || !Object.keys(user).length || !isAllowed(user.role, requiredRoles))    return ;
  
  return routes.push(element);
  
};


const isAllowed             = (userRole, requiredRoles) =>
  requiredRoles.some(role => userRole === role);
