import React from 'react';


import classes from '../containerActions/IncludeBusiness.module.css';
import Dialog  from '../elements/Dialog';
import Grid    from '@mui/material/Grid';
import Typography                            from '@mui/material/Typography';

const HelpTax = ({modalOn, closeModal}) => {
  
  
  let modal;
  
  modal = <Dialog id="tax-help" show={modalOn} handleClose={closeModal} title={'Ajuda Inclusão Impostos Pagos / Retidos'}>
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} className={classes.Item}>
        <Typography variant="h6">
          Valor Retido
        </Typography>
        <Typography variant="body2" gutterBottom>
          Refere se ao imposto debitado na fonte, ou seja pela sua corretora, seja na nota de corretagem ou direto na conta
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} className={classes.Item}>
        <Typography variant="h6">
          Valor Pago
        </Typography>
        <Typography variant="body2" gutterBottom>
          Valor efetivamente pago na DARF, para futura referência na declaração anual de IR
        </Typography>
      </Grid>
    </Grid>
  </Dialog>;
  
  return (
    <>
      {modal}
    </>
  );
};

export default HelpTax;
