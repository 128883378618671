import React, { useEffect, useState, useMemo } from 'react';
import Grid                                    from '@mui/material/Grid';
import Table                                   from '../../components/elements/Table';
import { useDispatch, useSelector }            from 'react-redux';
import { getFuturePapers }                     from '../../store/slices/paper';
import IncludePaper                            from '../../components/containerActions/IncludePaper';
import PaperHistory                            from '../../components/partials/PaperHistory';
import HistoryButton                           from '../../components/elements/HistoryButton';
import EditButton                              from '../../components/elements/EditButton';
import PaperEdit                               from '../../components/containerActions/PaperEdit';
import classes                                 from '../Amortization/Amortization.module.css';
import ParameterHeader                         from '../../components/sections/ParameterHeader';
import ProcessJob                              from '../../components/partials/ProcessJob';
import { processFuturePaper }                  from '../../store/slices/paper';


const FuturePaper = () => {
  
  const dispatch = useDispatch();
  
  const {futurePapers} = useSelector(state => state.paper);
  
  
  useEffect(() => {
    dispatch(getFuturePapers());
  }, []);
  
  const [rowData, setRowData] = useState(null);
  
  const [editOn, setEditOn]       = useState(false);
  const [historyOn, setHistoryOn] = useState(false);
  
  const openEdit     = (rowData) => {
    
    setRowData({...rowData.original, index: rowData.index});
    setEditOn(true);
  };
  const closeEdit    = () => {
    setEditOn(false);
    setRowData(null);
  };
  const openHistory  = (rowData) => {
    setRowData({...rowData.original, index: rowData.index});
    setHistoryOn(true);
  };
  const closeHistory = () => {
    setHistoryOn(false);
    setRowData(null);
  };
  
  const columns = useMemo(
    () => [
      {
        Header  : 'Papel',
        accessor: 'sigla',
      },
      {
        Header  : 'Empresa',
        accessor: 'nome',
      },
      {
        accessor: 'tipo',
        Header  : 'Tipo',
      },
      {
        accessor: 'lote',
        Header  : 'Lote',
      },
      {
        accessor: 'visibilidade',
        Header  : 'Visibilidade',
      },
      {
        accessor: 'vigente',
        Header  : 'Vigente',
      },
      {
        Header: 'Histórico',
        id    : 'History',
        Cell  : HistoryButton(openHistory)
      },
      {
        Header: 'Ações',
        id    : 'Actions',
        Cell  : EditButton('editar', openEdit)
      },
    ],
    []
  );
  
  return (
    <Grid container>
      <Grid item xs={12} className={classes.Header}>
        <ParameterHeader Process={ProcessJob} slice={'paper'} procFunc={processFuturePaper}/>
      </Grid>
      <Grid item xs={12}>
        {columns.length && <Table columns={columns} rowsData={futurePapers} inclusion={<IncludePaper/>}/>}
        <PaperHistory data={rowData} modalOn={historyOn} closeModal={closeHistory}/>
        <PaperEdit data={rowData} modalOn={editOn} closeModal={closeEdit}/>
      </Grid>
    </Grid>
  );
};

export default FuturePaper;
