import React, { useEffect, useMemo } from 'react';
import Grid                         from '@mui/material/Grid';
import Table                        from '../../components/elements/Table';
import { useDispatch, useSelector } from 'react-redux';

import { getEarningEvent, processEarningEvent }      from '../../store/slices/earningEvent';
import { formatDate } from '../../components/utils/formatters';
import ProcessJob                                    from '../../components/partials/ProcessJob';


const EarningEvent = () => {
  
  const dispatch = useDispatch();
  
  
  const {earnings} = useSelector(state => state.earningEvent);
  
  useEffect(() => {
    
    dispatch(getEarningEvent());
  }, []);
  
  const columns = useMemo(
    () =>
      [
        {
          accessor: 'nrConta',
          Header  :  'Conta',
        },
        {
          accessor: 'papel',
          Header  :  'Papel',
        },
        {
          accessor: 'dtInicioEvento',
          Header  :'Data do Início do Evento',
          Cell: ({value}) => formatDate(value)
        },
        {
          accessor: 'dtFimEvento',
          Header  :'Data Fim do Evento',
          Cell: ({value}) => formatDate(value)
        },
        {
          accessor: 'criacao',
          Header  :'Data Criação',
          Cell: ({value}) => formatDate(value)
        },
        
      ],
    []
  );
  
  return (
    <Grid container>
      <Grid item xs={12}>
        <Table columns={columns} rowsData={earnings} inclusion={<ProcessJob slice={'earningEvent'} procFunc={processEarningEvent}/>}/>
      </Grid>
    </Grid>
  );
};

export default EarningEvent;
