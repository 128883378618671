import React                                from 'react';
import classes                                                        from '../containerActions/IncludeBusiness.module.css';
import Grid                                                           from '@mui/material/Grid';
import { handleError, rules }                                         from '../utils/rules';
import FormMask                                                       from '../elements/FormMask';
import ShoppingCartIcon                                               from '@mui/icons-material/ShoppingCart';
import { integerFormat,   } from '../utils/constants';
import FormAutoComplete                                               from '../elements/FormAutoComplete';
import { searchAdmPaper }                                                from '../../store/slices/paper';
import {   useSelector }                                   from 'react-redux';
import IconButton                                                     from '../elements/IconButton';
import RemoveCircleIcon                                               from '@mui/icons-material/RemoveCircle';



const FieldsFormTransference = ({errors, control, date, index, id, removeAction,  }) => {
  
  const {papersSearched}   = useSelector(state => state.paper);
  
  const showPaper = (date && !Boolean(errors['date']));
  
  
  return (
    <Grid container spacing={2} key={id} className={classes.InternalContainer}
          style={{backgroundColor: index % 2 === 0 ? 'rgb(238, 238, 238)' : '#D0E4F5'}}>
      {showPaper &&
      <Grid item xs={12} sm={3} className={classes.Item}>
        <FormAutoComplete label="Papel" name={`items[${index}].paper`} placeholder="Pesquise o papel" control={control} data={papersSearched}
                          dataFunction={searchAdmPaper} searchField={'busca'} requiredData={{dataPesquisa: date}}
                          dataFields={{slice: 'paper', data: 'papersSearched'}}
                          displayField="sigla" rules={(rules('paper', null, 'Papel'))} errorMessage={handleError(errors, 'paper')}/>
      </Grid>
      }
      <Grid item xs={12} sm={3} className={classes.Item}>
        <FormMask label="Quantidade" name={`items[${index}].quantity`} Icon={ShoppingCartIcon}
                  errorMessage={handleError(errors, 'quantity')} control={control}
                  rules={rules('quantity', null, 'Quantidade')} formatOptions={integerFormat}
        />
      </Grid>
        <Grid item xs={12} sm={3} className={classes.RemoveOpButton}>
          <IconButton aria-label="remove" tooltip="remover essa operação" color="secondary" icon={RemoveCircleIcon} onClick={() => removeAction(index)}/>
        </Grid>
    </Grid>
  
  );
  
};

export default FieldsFormTransference;
