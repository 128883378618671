import React, { useEffect, useState } from 'react';
import Grid                           from '@mui/material/Grid';
import HighchartsReact                from 'highcharts-react-official';
import Highcharts                     from 'highcharts';
import { useDispatch, useSelector } from 'react-redux';
import { getCompiledEarnings }      from '../../store/slices/earning';
import { formatCurrency }           from '../utils/formatters';
import Box                          from '../elements/Box';
import classes                      from './EarningSegmentation.module.css'
import Typography                   from '@mui/material/Typography';

const commonChart  = {
  chart  : {
    plotBackgroundColor: null,
    plotBorderWidth    : null,
    backgroundColor    : '#fafafa',
    plotShadow         : false,
    type               : 'pie',
  },
  tooltip: {
    formatter: function () {
      return `
      ${this.point.name} <br/>
      ${this.series.name} <b>${formatCurrency(this.y)}</b>
      `;
    }
  },
  // tooltip      : {pointFormat: '{series.name}: <b>R${point.y}</b>'},
  plotOptions  : {
    pie: {
      allowPointSelect: true,
      cursor          : 'pointer',
      dataLabels      : {
        enabled  : true,
        formatter: function () {
          return `
                ${this.point.name} <br/>
                ${formatCurrency(this.y)} - (${this.point.percentage.toFixed(2)}%)
      `;
        },
        // format : '<b>{point.name}</b>: {point.percentage:.1f} %'
      },
      showInLegend    : true
    }
  },
  credits      : {
    enabled: false
  },
  accessibility: {
    point: {
      valueSuffix: '%'
    }
  },
};
const commonChart2 = {
  chart      : {
    backgroundColor: '#fafafa',
    type           : 'column',
  },
  yAxis      : {
    min        : 0,
    title      : {
      text: 'Lucro em R$'
    },
    stackLabels: {
      enabled  : false,
      style    : {
        fontWeight: 'bold',
        color     : 'gray'
      },
      formatter: function () {
        return `${formatCurrency(this.total)}`;
      }
    }
  },
  tooltip    : {
    formatter: function () {
      return `
      <b>${this.x} <br/></b>
      ${this.series.name}: <b>${formatCurrency(this.y)}</b><br/>
      Total: <b>${formatCurrency(this.point.stackTotal)}</b>
      `;
    }
  },
  plotOptions: {
    column: {
      stacking  : 'normal',
      dataLabels: {
        enabled: false,
        // rotation: -90,
        // formatter: function () {
        //   return `${formatCurrency(this.y)}`;
        // }
      },
    }
  },
  credits    : {
    enabled: false
  },
};

const title1 = {text: 'Proventos por tipo'};
const title2 = {text: 'Proventos por tipo x ano'};

const EarningSegmentation = () => {
  
  const [chart1Options, setChart1Options] = useState(null);
  const [chart2Options, setChart2Options] = useState(null);
  const dispatch                          = useDispatch();
  const {compiledEarnings, yearsEarnings} = useSelector(state => state.earning);
  
  useEffect(() => {
    
    setChart1Options({
      ...commonChart,
      title : title1,
      series: [{
        name        : 'Valor Líquido',
        colorByPoint: true,
        data        : compiledEarnings
      }
      ]
    });
    
  }, [compiledEarnings]);
  useEffect(() => {
    
    setChart2Options({
      ...commonChart2,
      title : title2,
      xAxis : {
        categories: yearsEarnings.years,
        labels    : {
          rotation: -45,
        }
      },
      series: [{
        name: 'Ação',
        data: yearsEarnings.acao,
      }, {
        name: 'FII',
        data: yearsEarnings.fii,
      },]
    });
    
  }, [yearsEarnings]);
  useEffect(() => {
    dispatch(getCompiledEarnings());
  }, []);
  
  
  let dataScreen;
  
  
  
  if (!!compiledEarnings.length && (compiledEarnings[0][1] > 0 || compiledEarnings[1][1] > 0)) {
    dataScreen = <>
      <Grid item xs={12} md={6}>
        <HighchartsReact highcharts={Highcharts} options={chart1Options} immutable = { true }/>
      </Grid>
      <Grid item xs={12} md={6}>
        <HighchartsReact highcharts={Highcharts} options={chart2Options} immutable = { true }/>
      </Grid>
    </>;
  } else {
    dataScreen =  <Grid item xs={12} className={classes.NegativeBox}>
      <Typography variant={'h6'} className={classes.NegativeText}>
      Você ainda não possui proventos
      </Typography>
    </Grid>
  }
  
  
  return (
    
    <Box>
      <Grid container>
        {dataScreen}
      </Grid>
    </Box>
  
  );
};

export default EarningSegmentation;
