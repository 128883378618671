import React   from 'react';
import classes from './HistoryButton.module.css';

import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import IconButton from '@mui/material/IconButton';
import Tooltip    from '@mui/material/Tooltip';

const ClosureButton = (tooltip, onClick) => ({row}) => {
  
  return (
    <div className={classes.ActionsButtons}>
      <Tooltip title={tooltip}>
        <IconButton aria-label="encerrar" tooltip="encerrar" color="primary" onClick={() => onClick(row)}>
          <AssignmentTurnedInIcon/>
        </IconButton>
      </Tooltip>
    </div>
  
  );
  
};


export default ClosureButton;
