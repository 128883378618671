import React, { useEffect } from 'react';
import classes              from './Dialog.module.css';

import MDialog       from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle   from '@mui/material/DialogTitle';
import Typography    from '@mui/material/Typography';
import IconButton    from '@mui/material/IconButton';
import CloseIcon     from '@mui/icons-material/Close';
import HelpIcon      from '@mui/icons-material/Help';
import Tooltip       from '@mui/material/Tooltip';

const Dialog = ({className, id, children, show, handleClose, handleHelp, title}) => {
  
  const myClasses = [classes.Container, className].join(' ');
  
  const descriptionElementRef = React.useRef(null);
  useEffect(() => {
    if (show) {
      const {current: descriptionElement} = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [show]);
  
  return (
    <MDialog className={classes.Modal} open={show} maxWidth={'md'} onClose={handleClose} scroll={'paper'}
             aria-labelledby="transition-modal-title" aria-describedby="transition-modal-description">
          <DialogTitle id={`title-${id}`}>
            <div><Typography variant="h6" component="h6">{title}</Typography></div>
            {!!handleHelp &&
            <Tooltip title={'Ajuda'} placement={'bottom'}>
              <IconButton aria-label="help" className={classes.helpButton} onClick={handleHelp}>
                <HelpIcon/>
              </IconButton>
            </Tooltip>
            }
            <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
              <CloseIcon/>
            </IconButton>
          </DialogTitle>
          <DialogContent dividers className={myClasses}>
            {children}
          </DialogContent>
    </MDialog>
  );
  
  
};


export default Dialog;
