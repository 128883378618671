import React, { useMemo }         from 'react';
import Grid                       from '@mui/material/Grid';
import Table                      from '../../components/elements/Table';
import { useSelector }            from 'react-redux';
import IncludeIncorporation       from '../../components/containerActions/IncludeIncorporation';
import classes                    from './Incorporation.module.css';
import { processIncorporation }   from '../../store/slices/incorporation';
import { formatDate }             from '../../components/utils/formatters';
import RedeemEarning              from '../../components/containerActions/RedeemEarning';
import { getPaperIncorporations } from '../../store/slices/incorporation';
import ProcessJob                 from '../../components/partials/ProcessJob';
import ParameterHeader            from '../../components/sections/ParameterHeader';

const Incorporation = () => {
  
  
  const {paperIncorporations} = useSelector(state => state.incorporation);
  
  const columns = useMemo(
    () =>
      [
        {
          accessor: 'papelDestino',
          Header  : 'Papel Destino',
        },
        {
          accessor: 'multiplicador',
          Header  : 'Multiplicador',
        },
        {
          accessor: 'dataIncorporacao',
          Header  : 'Data Incorporação',
          Cell    : ({value}) => formatDate(value),
        },
        {
          accessor: 'indicadorCompulsoria',
          Header  : 'Indicador Compulsória',
        },
      
      ],
    []
  );
  
  return (
    <Grid container>
      <Grid item xs={12} className={classes.Header}>
        <ParameterHeader Redeem={RedeemEarning} Process={ProcessJob} slice={'incorporation'} procFunc={processIncorporation}
                         getFunc={getPaperIncorporations}/>
      </Grid>
      <Grid item xs={12}>
        <Table columns={columns} rowsData={paperIncorporations} inclusion={<IncludeIncorporation/>}/>
      </Grid>
    </Grid>
  );
};

export default Incorporation;
