import React, { useMemo }                from 'react';
import Table                             from '../elements/Table.js';
import classes                           from './AchievementDetail.module.css';
import { formatCurrency, formatInteger } from '../utils/formatters';



const WalletDetail = ({row}) => {
  
  const {stocks} = row;
  
  const columns = useMemo(
    () =>
      [
        {
          accessor: 'papel',
          Header  : 'Papel',
        },
        {
          accessor: 'tipoPapel',
          Header  : 'Tipo',
        },
        {
          accessor: 'quantidade',
          Header  : 'Quantidade',
          Cell    : ({value}) => formatInteger(value)
        },
        {
          accessor: 'valor',
          Header  : 'Valor Compra',
          Cell    : ({value}) => formatCurrency(value)
        },
        {
          accessor: 'custo',
          Header  : 'Custo Compra',
          Cell    : ({value}) => formatCurrency(value)
        },
        {
          accessor: 'corretora',
          Header  : 'Corretora',
        },
        {
          accessor: 'valorAtual',
          Header  : 'Valor Atual*',
          Cell    : ({value}) => formatCurrency(value)
        },
        {
          accessor: 'variacao',
          Header  : 'Variação',
          Cell    : ({value}) => formatCurrency(value)
        },
        {
          accessor: 'valorizacao',
          Header  : 'Valorização',
          Cell    : ({value}) => `${(value * 100).toFixed(2)}%`
        },
        {
          accessor: 'capitalInicial',
          Header  : 'Capital Inicial',
          Cell    : ({value}) => formatCurrency(value)
        },
        {
          accessor: 'capitalAtual',
          Header  : 'Capital Atual',
          Cell    : ({value}) => formatCurrency(value)
        },
        {
          accessor: 'participacao',
          Header  : 'Part. %',
          Cell    : ({value}) => `${(value * 100).toFixed(2)}%`
        },
        {
          accessor: 'valorProvento',
          Header  : 'Valor Proventos',
          Cell    : ({value}) => formatCurrency(value)
        },
        {
          accessor: 'valorizacaoTotal',
          Header  : 'Valorização c/ Proventos',
          Cell    : ({value}) => `${(value * 100).toFixed(2)}%`
        },
       
      ],
    []
  );
  
  return (
    <div className={classes.Container}>
      <Table columns={columns} rowsData={stocks} hideFilter={true}/>
    </div>
  );
  
  
};

export default WalletDetail;
