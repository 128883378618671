const patterns = {
  email: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  name : /^[a-zA-Z\u00C0-\u00FF]+(?: [a-zA-Z\u00C0-\u00FF]+)+$/
};
// /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
// pattern:

const today       = new Date();
const baseDate    = new Date('2006-01-01');
const actualMonth = today.getMonth() + 1;
const actualYear  = today.getFullYear().toString().substr(2, 2);

export const rules = (type, data, label = '') => {
  
  let rule = {};
  
  switch (type) {
    case 'birthday':
      rule.required = 'Data de Nascimento não informada';
      rule.validate = (value) => today >= new Date(value) || 'Data inválida';
      break;
    case 'businessDate':
      rule.required = 'Data do Negócio não informada';
      rule.validate = {
        maior: value => today > new Date(value) || 'Data Maior que atual',
        menor: value => baseDate < new Date(value) || 'Data Menor que 01/01/2006',
      };
      break;
    case 'cardNumber':
      rule.required  = 'Número do Cartão não informado';
      rule.minLength = {value: 15, message: 'Número do Cartão incompleto'};
      break;
    case 'company':
      rule.required  = 'Nome não informado';
      rule.minLength = {value: 10, message: 'Nome muito curto'};
      rule.maxLength = {value: 100, message: 'Nome maior que o permitido'};
      rule.pattern   = {value: patterns[type], message: 'Nome inválido'};
      break;
    case 'cvc':
      rule.required  = 'CVC não informado';
      rule.minLength = {value: 3, message: 'CVC incompleto'};
      break;
    case 'docID':
      rule.required  = 'CPF/CNPJ não informado';
      rule.validate = value => {
          const stringValue = value.toString();
          if (stringValue.length !== 11 && stringValue.length !== 14) return 'CPF/CNPJ inválido'
          return true
      }
      break;
    case 'name':
      rule.required  = 'Nome não informado';
      rule.minLength = {value: 10, message: 'Nome muito curto'};
      rule.maxLength = {value: 50, message: 'Nome maior que o permitido'};
      rule.pattern   = {value: patterns[type], message: 'Nome inválido'};
      break;
    case 'email':
      rule.required = 'Email não informado';
      rule.pattern  = {value: patterns[type], message: 'Email inválido'};
      break;
    case 'emailConfirm':
      rule.required = 'Email não informado';
      rule.validate = value => value === data || 'Email não confere';
      break;
    case 'expiry':
      rule.required  = 'Vencimento não informado';
      rule.minLength = {value: 4, message: 'Vencimento inválido'};
      rule.validate  = {
        menor: value => {
          const mes = value.substr(0, 2);
          const ano = value.substr(2, 2);
          if (ano > actualYear) return true;
          if (ano === actualYear) {
            if (actualMonth < mes) return true;
          }
          return 'Cartão Vencido'
        }
      };
      break;
    case 'password':
      rule.required  = 'Senha não informada';
      rule.minLength = {value: 6, message: 'Senha menor que tamanho mínimo'};
      break;
    case 'passwordConfirm':
      rule.required = 'Senha não informada';
      rule.validate = value => value === data || 'Senha não confere';
      break;
    case 'subject':
      rule.required = 'Assunto não informado';
      rule.minLength = {value: 10, message: 'Detalhe mais sua solicitação'};
      break;
    case 'therms':
      rule.required = 'Você deve ler e aceitar os termos';
      break;
    case 'value':
      rule.required = 'Valor não informado';
      break;
    default:
      const last    = label.slice(-1);
      const gender  = last === 'a' || last === 'e' ? 'a' : 'o';
      rule.required = `${label} não informad${gender}`;
  }
  
  return rule;
  
};


export const handleError = (errors, name) => {
  
  let error = '';
  
  // console.log('errors', name, errors[name]);
  if (errors[name]) error = errors[name].message;
  
  return error;
};
