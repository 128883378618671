import React from 'react';


import OutlinedInput  from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel     from '@mui/material/InputLabel';
import FormControl    from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import IconButton     from '@mui/material/IconButton';
import Visibility     from '@mui/icons-material/Visibility';
import VisibilityOff  from '@mui/icons-material/VisibilityOff';

import classes        from './Input.module.css';
import { Controller } from 'react-hook-form';

const FormPassword = ({className, label, name, control, rules, disabled, errorMessage, ...props}) => {
  
  const [iconOn, setIconOn] = React.useState(false);
  
  const handleIcon = () => {
    setIconOn(!iconOn);
  };
  
  return (
    <FormControl aria-label={name} fullWidth variant="outlined" className={classes.Field}>
      <InputLabel error={!!errorMessage} htmlFor={name}>{label}</InputLabel>
      <Controller
        render={({field}) =>
          <OutlinedInput {...field}
                         {...props}
                         id={name}
                         label={label}
                         type={iconOn ? 'text' : 'password'}
                         aria-invalid={errorMessage}
                         aria-describedby={label}
                         error={!!errorMessage}
                         endAdornment={
                           <InputAdornment position="end">
                             <IconButton aria-label="Visualizar/Esconder senha" onClick={handleIcon} className={classes.IconButton}>
                               {iconOn ? <VisibilityOff/> : <Visibility/>}
                             </IconButton>
            
                           </InputAdornment>
                         }
          />
        }
        name={name}
        control={control}
        rules={rules}
      />
      {!!errorMessage && <FormHelperText error margin="dense">{errorMessage}</FormHelperText>}
    
    </FormControl>
  
  
  );
};


export default FormPassword;
