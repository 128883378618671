import React, { useEffect, useMemo, useState } from 'react';
import Grid                         from '@mui/material/Grid';
import Table                        from '../../components/elements/Table';
import { useDispatch, useSelector } from 'react-redux';
import { getReceipt } from '../../store/slices/receipt';
import IncludeReceipt from '../../components/containerActions/IncludeReceipt';
import AddButton      from '../../components/elements/AddButton';
import EditButton         from '../../components/elements/EditButton';
import ReceiptEdit        from '../../components/containerActions/ReceiptEdit';
import ReceiptAdd         from '../../components/containerActions/ReceiptAdd';
import {  objectToArray } from '../../components/utils/formatters';



const Receipt = () => {
  
  const dispatch = useDispatch();
  
  const {receipts}            = useSelector(state => state.receipt);
  const [rowData, setRowData] = useState(null);
  
  useEffect(() => {
    
    dispatch(getReceipt());
  }, []);
  
  
  const [editOn, setEditOn] = useState(false);
  const [addOn, setAddOn]   = useState(false);
  
  
  const openEdit = (rowData) => {
    setRowData({...rowData.original, index: rowData.index});
    setEditOn(true);
  };
  
  const openAdd = (rowData) => {
    setRowData({...rowData.original, index: rowData.index});
    setAddOn(true);
  };
  
  const closeAdd = () => {
    setAddOn(false);
    setRowData(null);
  };
  
  const closeEdit = () => {
    setEditOn(false);
    setRowData(null);
  };
  
 
  const columns = useMemo(
    () =>
      [
        {
          Header  :  'Papel',
          accessor: 'sigla',
        },
        {
          id: 'recibosSigla',
          Header  :'Recibo',
          accessor: line =>  objectToArray(line.recibos, 'sigla'),
          
        },
        {
          Header:  'Edição',
          Cell    : EditButton('editar', openEdit)
        },
        {
          Header:  'Inclusão',
          Cell    : AddButton(openAdd)
        },
      ],
    []
  );
  
  
  return (
    <Grid container>
      <Grid item xs={12}>
        <Table columns={columns} rowsData={receipts} inclusion={<IncludeReceipt/>}/>
        <ReceiptEdit data={rowData} modalOn={editOn} closeModal={closeEdit}/>
        <ReceiptAdd data={rowData} modalOn={addOn} closeModal={closeAdd}/>
      </Grid>
    </Grid>
  );
};

export default Receipt;
