import { createSlice }                            from '@reduxjs/toolkit';
import { thunkAPI }                               from '../../components/utils/api';



export const editReceipt    = thunkAPI('receipt/edit', 'v1/recibos', 'post',
  {successMessage: 'Recibo alterado com sucesso'});
export const getReceipt     = thunkAPI('receipt/get', 'v1/recibos', 'get',);
export const includeReceipt = thunkAPI('receipt/include', 'v1/recibos', 'post',
  {successMessage: 'Recibo incluído com sucesso'});

const formatReceipts       = (receipts) => {
  
  const formattedReceipts = [];
  // console.log('receipts', receipts);
  
  let receipt = {};
  for (const data of receipts) {
    
    if (data.papel_id === data.recibo_id) {
      receipt         = {};
      receipt.id      = data.papel_id;
      receipt.papelId = data.papel_id;
      receipt.sigla   = data.sigla;
      receipt.recibos = [];
      formattedReceipts.push(receipt);
    } else {
      receipt.recibos.push({
        sigla  : data.sigla,
        papelId: data.recibo_id
      });
    }
    
  }
  // console.log('formattedReceipts', formattedReceipts);
  return formattedReceipts;
  
};
const formatReceiptsEdited = (receipts, data) => {
  
  const {index, papel, recibos} = data;
  
  const editedReceipts = [];
  
  if (!papel) {
    const receipt = receipts[index].recibos;
    
    for (const rec of receipt) {
      if (recibos.includes(rec.papelId)) editedReceipts.push(rec);
    }
    
    receipts[index].recibos = editedReceipts;
  } else {
    receipts[index].recibos.push({
      sigla  : papel.sigla,
      papelId: papel.cdPapel
    });
  }
  
  
  return receipts;
  
};

const slice = createSlice({
  name         : 'receipt',
  initialState : {
    receipts        : [],
    includeSituation: 'idle'
  },
  reducers     : {},
  extraReducers: {
    [editReceipt.fulfilled]   : (state, action) => {
      state.receipts = formatReceiptsEdited(state.receipts, action.payload);
    },
    [getReceipt.fulfilled]    : (state, action) => {
      state.receipts = formatReceipts(action.payload);
    },
    [includeReceipt.pending]  : (state) => {
      state.includeSituation = 'pending';
    },
    [includeReceipt.fulfilled]: (state, action) => {
      let receipts = [...state.receipts];
      
      const {id, papelId, sigla, recibos} = action.payload;
      
      receipts.unshift({
        id,
        papelId,
        sigla,
        recibos,
      });
      
      state.receipts         = receipts;
      state.includeSituation = 'idle';
    },
    [includeReceipt.rejected] : (state) => {
      state.includeSituation = 'rejected';
    },
  }
});

const {reducer} = slice;


export default reducer;
