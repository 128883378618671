import { createSlice } from '@reduxjs/toolkit';
import { thunkAPI }    from '../../components/utils/api';



export const getExchanges      = thunkAPI('exchange/get', 'v1/corretoras/listar',
  {verb: 'get', getCache: ['exchange', 'exchanges']});


const slice = createSlice({
  name         : 'exchange',
  initialState : {
    exchanges     : [],
  },
  reducers     : {},
  extraReducers: {
    [getExchanges.fulfilled]: (state, action) => {
      state.exchanges = action.payload
    },
  }
});

const {reducer} = slice;


export default reducer;
