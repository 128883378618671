import React, { useMemo }                               from 'react';
import Grid                                             from '@mui/material/Grid';
import Table                                            from '../../components/elements/Table';
import { useDispatch, useSelector }                     from 'react-redux';
import classes                                          from './Rearrangement.module.css';
import { formatDate }                                   from '../../components/utils/formatters';
import RedeemEarning                                    from '../../components/containerActions/RedeemEarning';
import { getPaperRearrangements, processRearrangement } from '../../store/slices/rearrangement';
import ParameterHeader                                  from '../../components/sections/ParameterHeader';
import ProcessJob                                       from '../../components/partials/ProcessJob';
import IncludeRearrangement                             from '../../components/containerActions/IncludeRearrangement';
import excludePopover                                   from '../../components/partials/ExcludePopover';
import { excludeRearrangement }                         from '../../store/slices/rearrangement';


const Rearrangement = () => {
  
  const dispatch              = useDispatch();
  const {paperRearrangements} = useSelector(state => state.rearrangement);
  
  const confirmExclusion = (rowData) => dispatch(excludeRearrangement({
    papelId      : rowData.original.papelId,
    dataRearranjo: rowData.original.dataRearranjo,
    tipoRearranjo: rowData.original.tipoId
  }));
  
  
  const columns = useMemo(
    () =>
      [
        {
          accessor: 'tipoRearranjo',
          Header  : 'Tipo Rearranjo',
        },
        {
          accessor: 'multiplicador',
          Header  : 'Multiplicador',
        },
        {
          accessor: 'dataRearranjo',
          Header  : 'Data Rearranjo',
          Cell    : ({value}) => formatDate(value)
        },
        {
          accessor: 'dataCredito',
          Header  : 'Data Crédito',
          Cell    : ({value}) => formatDate(value)
        },
        {
          Header: '',
          id    : 'exclusion',
          Cell  : excludePopover(confirmExclusion, 'rearranjo')
        },
      ],
    []
  );
  
  return (
    <Grid container>
      <Grid item xs={12} className={classes.Header}>
        <Grid item xs={12} className={classes.Header}>
          <ParameterHeader Redeem={RedeemEarning} Process={ProcessJob} slice={'rearrangement'} procFunc={processRearrangement}
                           getFunc={getPaperRearrangements}/>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Table columns={columns} rowsData={paperRearrangements} inclusion={<IncludeRearrangement/>}/>
      </Grid>
    </Grid>
  );
};

export default Rearrangement;
