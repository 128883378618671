import React from 'react';

import Grid                 from '@mui/material/Grid';
import classes              from './Plans.module.css';

import Typography           from '@mui/material/Typography';
import { motion }           from 'framer-motion';

import PlanCards            from '../partials/PlanCards';

const Plans = () => {
  
  return (
    <Grid container justifyContent="center" className={classes.Root} spacing={2}>
      <Grid item xs={12}>
        <motion.div initial={{y: 5, opacity: 0.2}}
                    animate={{y: 0, opacity: 1}} transition={{duration: 0.7, delay: 0.2}}>
          <Typography variant="h5" className={classes.Text}>
            Um único plano para tornar mais simples a sua escolha, com a possibilidade de inclusão de recursos adicionais
          </Typography>
        </motion.div>
      </Grid>
      <Grid item xs={12}>
          <PlanCards/>
      </Grid>
      <Grid item xs={12} className={classes.Packages}>
        <Typography variant="h6">
          Inclusões / Exclusões Adicionais:
        </Typography>
        <br/>
        <Typography variant="body2">
          Pacote com 100 R$ 3,00
        </Typography>
        <Typography variant="body2">
          Pacote com 1000 R$ 20,00
        </Typography>
      </Grid>
    </Grid>
  );
};


export default Plans;
