import React, { useEffect } from 'react';

import { useForm }                  from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import classes                      from './IncludeEarning.module.css';
import Button                       from '../elements/Button';
import Dialog                       from '../elements/Dialog';

import { decimalFormat, }                  from '../utils/constants';
import { handleError, rules }              from '../utils/rules';
import Grid                                from '@mui/material/Grid';
import { searchAdmPaper }                  from '../../store/slices/paper';
import FormDate                            from '../elements/FormDate';
import CakeIcon                            from '@mui/icons-material/Cake';
import FormAutoComplete from '../elements/FormAutoComplete';
import FormSelect       from '../elements/FormSelect';
import FormMask         from '../elements/FormMask';
import AttachMoneyIcon                     from '@mui/icons-material/AttachMoney';
import { getEarningTypes, includeEarning } from '../../store/slices/earning';

const defaultValues = {
  claimDate : '',
  incomeType: '',
  paper     : null,
  payDate   : '',
  value     : '',
};

const IncludeEarning = () => {
  
  const dispatch = useDispatch();
  
  const {includeSituation, incomesTypes} = useSelector(state => state.earning);
  
  
  const [modalOn, setModalOn] = React.useState(false);
  
  
  useEffect(() => {
    
    dispatch(getEarningTypes());
  }, []);
  
  useEffect(() => {
    
    if (includeSituation === 'idle') reset(defaultValues);
    
  }, [includeSituation]);
  
  const {control, handleSubmit, reset, getValues, formState} = useForm({mode: 'all', defaultValues});
  
  const {dirtyFields, isValid, errors} = formState;
  
  let modal;
  
  const openModal = () => {
    setModalOn(true);
  };
  
  const closeModal = () => setModalOn(false);
  
  const onSubmit = (data) => {
    
    const {claimDate, incomeType, paper, payDate, value} = data;
    
    dispatch(includeEarning({
        papelId           : paper.cdPapel,
        dataDireito       : claimDate,
        dataPagamento     : payDate,
        tipoProvento      : incomeType.cdTipoProvento,
        valorProventoBruto: value,
      }
    ));
    
  };
  
  const disabled  = (!isValid || includeSituation === 'pending');
  const showPaper = (!!dirtyFields['claimDate'] && !Boolean(errors['claimDate']));
  
  modal = <Dialog id="earning-modal" show={modalOn} handleClose={closeModal} title={'Inclusão Provento'}>
    
    <form id="earning" onSubmit={handleSubmit(onSubmit)} className={classes.Form}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} className={classes.Item}>
          <FormDate label="Data Direito" name="claimDate" Icon={CakeIcon} errorMessage={handleError(errors, 'claimDate')}
                    control={control} rules={{required: true}}
          />
        </Grid>
        <Grid item xs={12} sm={6} className={classes.Item}>
          <FormDate label="Data Pagamento" name="payDate" Icon={CakeIcon} errorMessage={handleError(errors, 'payDate')}
                    control={control}
          />
        </Grid>
        <Grid item xs={12} sm={6} className={classes.Item}>
          <FormSelect label="Tipo de Provento" name="incomeType" errorMessage={handleError(errors, 'incomeType')}
                      displayField={'descricao'}
                      rules={(rules('incomeType'))} data={incomesTypes} control={control} defaultValue={defaultValues.incomeType}
          />
        </Grid>
        <Grid item xs={12} sm={6} className={classes.Item}>
          <FormMask label="Valor Bruto Provento Papel" name="value" Icon={AttachMoneyIcon} defaultValue={defaultValues['value']}
                    errorMessage={handleError(errors, 'value')} control={control}
                    rules={rules('value')} formatOptions={decimalFormat}
          />
        </Grid>
        <Grid item xs={12} sm={6} className={classes.Item}>
          {showPaper &&
          <FormAutoComplete label="Papel" name="paper" placeholder="Pesquise o papel" control={control}  requiredData={{dataPesquisa: getValues('claimDate')}}
                            dataFields={{slice: 'paper', data: 'papersSearched'}} dataFunction={searchAdmPaper} searchField={'busca'}
                            displayField="sigla" rules={(rules('paper'))} errorMessage={handleError(errors, 'paper')}/>
          }
        </Grid>
        <Grid item xs={12} className={classes.Button}>
          <Button color="secondary" disabled={disabled} type="submit">
            {includeSituation === 'pending' ? 'Cadastrando' : 'Cadastrar'}
          </Button>
        </Grid>
      </Grid>
    </form>
  </Dialog>;
  
  return (
    <>
      <Button color="secondary" disabled={!!modalOn} onClick={openModal}>
        Incluir
      </Button>
      {modal}
    </>
  );
};

export default IncludeEarning;
