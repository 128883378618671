import React, { useRef, useState } from 'react';
import AppBar                      from '@mui/material/AppBar';
import Toolbar                     from '@mui/material/Toolbar';

import ExitToAppIcon               from '@mui/icons-material/ExitToApp';
import AccountBoxIcon              from '@mui/icons-material/AccountBox';

import InvertedLogo    from './partials/InvertedLogo';
import classes from './InternalHeader.module.css';

import IconButton                  from '@mui/material/IconButton';
import MenuIcon                    from '@mui/icons-material/Menu';
import NotificationsIcon           from '@mui/icons-material/Notifications';
import Badge                       from '@mui/material/Badge';
import { logout }                  from '../../store/slices/auth';
import { useDispatch }             from 'react-redux';
import ProfileMenu                 from '../../containers/Profile/ProfileMenu';
import Tooltip                     from '@mui/material/Tooltip';
import { useNavigate,   } from 'react-router-dom';
import Grid                        from '@mui/material/Grid';

const InternalHeader = ({open, handleOpenMenu}) => {
  
  const dispatch                      = useDispatch();
  const navigate                       = useNavigate();
  const [openProfile, setOpenProfile] = useState(false);
  
  const appBarClass     = open ? classes.AppBarShift : classes.AppBar;
  const menuButtonClass = open ? classes.MenuButtonHidden : classes.MenuButton;
  
  const anchorRef = useRef(null);
  
  const handleLogout = () => {
    dispatch(logout());
  };
  
  const handleProfileMenu = () => {
    setOpenProfile((prevOpen) => !prevOpen);
  };
  
  
  const goToMain = () => {
    navigate('/');
  };
  
  
  return (
    
    <AppBar position="fixed" className={appBarClass} color="inherit" classes={{colorInherit: classes.AppBarColor}}>
      <Toolbar className={classes.Toolbar}>
        <IconButton edge="start" color="inherit" aria-label="open drawer"
                    onClick={handleOpenMenu} className={menuButtonClass}>
          <MenuIcon/>
        </IconButton>
        <Grid container >
          <Grid item xs={2} className={classes.Logo}>
            <InvertedLogo/>
          </Grid>
          <Grid item xs={10} className={classes.Container}>
            <IconButton color="inherit">
              <Badge badgeContent={4} color="secondary">
                <NotificationsIcon/>
              </Badge>
            </IconButton>
            <Tooltip title={'Perfil'} placement="bottom" arrow>
              <IconButton color="inherit" onClick={handleProfileMenu} ref={anchorRef}>
                <AccountBoxIcon/>
              </IconButton>
            </Tooltip>
            <Tooltip title={'Sair'} placement="bottom" arrow>
              <IconButton color="inherit" onClick={handleLogout}>
                <ExitToAppIcon/>
              </IconButton>
            </Tooltip>
            <ProfileMenu handleToggle={handleProfileMenu} open={openProfile} anchorRef={anchorRef}/>
          </Grid>
        </Grid>
        {/*<Typography component="h1" variant="h6" color="inherit" noWrap className={classes.Title} onClick={goToMain}>*/}
        {/*  Kiila2*/}
        {/*</Typography>*/}
      
      </Toolbar>
    </AppBar>
  
  );
  
};


export default InternalHeader;
