import { createSlice }         from '@reduxjs/toolkit';
import { thunkAPI }            from '../../components/utils/api';
 


export const getMyScissions    = thunkAPI('scission/get', 'v1/posse/cisao', 'get',);
export const getPaperScissions = thunkAPI('paperScission/get', 'v1/cisao/busca/20', 'get',);
export const computeScission   = thunkAPI('scission/compute', '/v1/cisao/processar/computar', 'post',
  {successMessage: 'Cisão processada com sucesso'});
export const includeScission   = thunkAPI('scission/include', '/v1/cisao/processar', 'post',
  {successMessage: 'Cisão incluída com sucesso'});
export const processScission   = thunkAPI('scission/process', '/v1/cisao/processar/data', 'post',
  {successMessage: 'Cisão processada com sucesso'});


const formatMyScissions = (scissions) => {
  
  const formattedScissions = [];
  // console.log('scissions', scissions);
  let i                    = 0;
  for (const data of scissions) {
    
    formattedScissions.push({
      id           : i,
      papelOrigem  : data.PapelOrigem.sigla,
      papelDestino : data.PapelDestino.sigla,
      multiplicador: data.multiplicador,
      dataCisao    : data.dtCisao,
      qtBase       : data.qtPapelOrigem,
      qtDestino    : data.qtPapelDestino
    });
    i++;
  }
  
  return formattedScissions;
  
};
const formatScissions   = (scissions) => {
  
  const formattedScissions = [];
  
  let i = 0;
  for (const data of scissions) {
  
    formattedScissions.push({
      id                  : i,
      papelDestino        : data.PapelDestino.sigla,
      dataCisao    : data.dtCisao,
      multiplicador       : data.multiplicador,
      vigente             : data.vigente ? 'Sim' : 'Não',
    });
    i++;
  }
  
  return formattedScissions;
  
};

const slice = createSlice({
  name         : 'scission',
  initialState : {
    myScissions     : [],
    paperScissions  : [],
    includeSituation: 'idle',
    searchSituation : 'idle',
    processSituation: 'idle',
  },
  reducers     : {},
  extraReducers: {
    [getMyScissions.fulfilled]   : (state, action) => {
      state.myScissions = formatMyScissions(action.payload);
    },
    [getPaperScissions.fulfilled]: (state, action) => {
      state.paperScissions  = formatScissions(action.payload);
      state.searchSituation = 'idle';
    },
    [getPaperScissions.pending]  : (state) => {
      state.searchSituation = 'pending';
    },
    [getPaperScissions.rejected] : (state) => {
      state.searchSituation = 'rejected';
    },
    [processScission.fulfilled]  : (state) => {
      state.processSituation = 'idle';
    },
    [processScission.pending]    : (state) => {
      state.processSituation = 'pending';
    },
    [processScission.rejected]   : (state) => {
      state.processSituation = 'rejected';
    },
    
  }
});

const {reducer} = slice;


export default reducer;
