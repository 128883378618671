import React   from 'react';
import classes from './HistoryButton.module.css';

import EditIcon   from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import Tooltip    from '@mui/material/Tooltip';

const EditButton = (tooltip, onClick) => ({row}) => {
  

  return (
    <div className={classes.ActionsButtons}>
      <Tooltip title={tooltip}>
        <IconButton aria-label="edit" tooltip="edit" color="primary" onClick={() => onClick(row)}>
          <EditIcon/>
        </IconButton>
      </Tooltip>
    </div>
  
  );
  
};


export default EditButton;
