import React from 'react';

import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';

import classes        from './Input.module.css';
import TextField      from '@mui/material/TextField';
import { Controller } from 'react-hook-form';

const FormTextMultiline = ({name, defaultValue, label, control, rules, errorMessage , rows, }) => {
  
  
  return (
    <FormControl aria-label={name} variant="outlined" fullWidth className={classes.Field}>
      <Controller name={name} control={control} rules={rules} defaultValue={defaultValue}
                  render={({field}) => (
                    <TextField
                    name={name}
                    multiline
                    x-id={name}
                    rows={rows}
                    error={!!errorMessage}
                    label={label}
                    {...field}
                    variant="outlined"
                  />
                  )}
      />
    
      {!!errorMessage && <FormHelperText error margin="dense">{errorMessage}</FormHelperText>}
      
    </FormControl>
    
  
  );
};


export default FormTextMultiline;
