import React from 'react';

import KeyboardArrowDown    from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUp      from '@mui/icons-material/KeyboardArrowUp';

const DetailTableButton = (row) => {
  
  return (
    <span {...row.getToggleRowExpandedProps()}>
            {row.isExpanded ? <KeyboardArrowUp fontSize="small"/> : <KeyboardArrowDown fontSize="small"/>}
          </span>
  );
};


export default DetailTableButton;
