import { createSlice }              from '@reduxjs/toolkit';
import { thunkAPI }                 from '../../components/utils/api';
import { daysBetweenDates, toDate } from '../../components/utils/date';
 
export const getUserPlan        = thunkAPI('userPlan/get', 'v1/plano', 'get',);
export const getUserConsume     = thunkAPI('userConsume/get', 'v1/usuario/consumo', 'get',);
export const editUserPlan       = thunkAPI('userPlan/edit', 'v1/plano', 'post',);
export const includeUserConsume = thunkAPI('userConsume/include', 'v1/usuario/consumo', 'post',);

const hoje = toDate();

const formatUserPlan    = (plan) => {
  
  const formattedPlan = {
    descricao: 'Voce não possui plano ativo',
    tempo    : 0
  };
  
  const tempo = daysBetweenDates(hoje, plan.dtValidadePlano);
  if (tempo > 0) {
    formattedPlan.descricao = plan.Plano.descricao;
    formattedPlan.validade  = plan.dtValidadePlano;
    formattedPlan.tempo     = tempo;
    formattedPlan.periodo   = plan.periodicidade === 1 ? 'Mensal' : 'Anual';
  }
  
  return formattedPlan;
};
const formatUserConsume = (consume) => {
  
  const formattedConsume = {
    adcText         : 'Voce não possui pacote adicional',
    planText        : 'Voce não plano vigente',
    qtContratadaAdc : 0,
    qtContratadaPlan: 0,
    qtUtilizadaPlan : 0,
    qtUtilizadaAdc  : 0,
  };
  
  for (const data of consume) {
    
    
    if (data.tipoConsumo === 1) {
      if (daysBetweenDates(hoje, data.dtVencimento) > 0) {
        formattedConsume.qtContratadaPlan = data.quantidadeContratada;
        formattedConsume.qtUtilizadaPlan  = data.quantidadeUtilizada;
        formattedConsume.planText         = ` Utilizadas ${data.quantidadeUtilizada} de ${data.quantidadeContratada} inclusões/exclusões no mês.`;
      }
    }
    if (data.tipoConsumo === 2) {
      if (!data.dtVencimento) {
        formattedConsume.qtContratadaAdc = data.quantidadeContratada;
        formattedConsume.qtUtilizadaAdc  = data.quantidadeUtilizada;
        formattedConsume.adcText         = ` Utilizadas ${data.quantidadeUtilizada} de ${data.quantidadeContratada} inclusões/exclusões.`;
      }
    }
  }
  
  // console.log("formattedConsume", formattedConsume)
  
  return formattedConsume;
};

const slice = createSlice({
  name         : 'user',
  initialState : {
    userPlan      : {},
    userConsume   : {},
    planSituation : 'idle',
    addOnSituation: 'idle',
    planMessage   : {},
    addOnMessage  : {}
  },
  reducers     : {
    eraseAddOnMessage: (state) => {
      state.addOnMessage = {};
    },
    erasePlanMessage : (state) => {
      state.planMessage = {};
    }
  },
  extraReducers: {
    [getUserConsume.fulfilled]    : (state, action) => {
      state.userConsume = formatUserConsume(action.payload);
    },
    [getUserPlan.fulfilled]       : (state, action) => {
      state.userPlan = formatUserPlan(action.payload);
    },
    [editUserPlan.fulfilled]      : (state, action) => {
      const {payError, msg} = action.payload;
      
      if (payError) {
        state.planSituation    = 'rejected';
        state.planMessage.text = msg;
        state.planMessage.type = 'error';
      } else {
        state.planMessage.text = msg;
        state.planMessage.type = 'success';
        state.planSituation    = 'idle';
      }
    },
    [editUserPlan.pending]        : (state) => {
      state.planSituation = 'pending';
      state.planMessage   = {};
    },
    [editUserPlan.rejected]       : (state) => {
      state.planSituation = 'rejected';
    },
    [includeUserConsume.fulfilled]: (state, action) => {
      const {payError, msg} = action.payload;
      
      if (payError) {
        state.addOnSituation    = 'rejected';
        state.addOnMessage.text = msg;
        state.addOnMessage.type = 'error';
      } else {
        state.addOnMessage.text = msg;
        state.addOnMessage.type = 'success';
        state.addOnSituation    = 'idle';
      }
    },
    [includeUserConsume.pending]  : (state) => {
      state.addOnSituation = 'pending';
      state.addOnMessage   = {};
    },
    [includeUserConsume.rejected] : (state) => {
      state.addOnSituation = 'rejected';
    },
  }
});

const {reducer, actions} = slice;

export const {eraseAddOnMessage, erasePlanMessage} = actions;

export default reducer;
