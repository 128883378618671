import React   from 'react';
import classes from '../containerActions/IncludeBusiness.module.css';
import Dialog  from '../elements/Dialog';
import Grid    from '@mui/material/Grid';
import Typography                            from '@mui/material/Typography';

const HelpTransference = ({modalOn, closeModal}) => {
  
  
  let modal;
  
  modal = <Dialog id="transference-help" show={modalOn} handleClose={closeModal} title={'Ajuda Inclusão Transferência'}>
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} className={classes.Item}>
        <Typography variant="h6">
          Data da Transferência
        </Typography>
        <Typography variant="body2" gutterBottom>
          Data em que foi concluída a transferência, ou seja em que o papel aparece na corretora Destino
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} className={classes.Item}>
        <Typography variant="h6">
          Quantidade
        </Typography>
        <Typography variant="body2" gutterBottom>
          Quantidade transferida
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} className={classes.Item}>
        <Typography variant="h6">
          Corretora Origem
        </Typography>
        <Typography variant="body2" gutterBottom>
          Corretora em que o papel estava
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} className={classes.Item}>
        <Typography variant="h6">
          Corretora Destino
        </Typography>
        <Typography variant="body2" gutterBottom>
          Corretora para onde o papel foi
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} className={classes.Item}>
        <Typography variant="h6">
          Papel
        </Typography>
        <Typography variant="body2" gutterBottom>
          O papel que foi transferido, esse campo só aparece após informar a data da transferência
        </Typography>
      </Grid>
    </Grid>
  </Dialog>;
  
  return (
    <>
      {modal}
    </>
  );
};

export default HelpTransference;
