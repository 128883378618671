import React, { useEffect, useMemo, useState } from 'react';
import Dialog                                  from '../elements/Dialog';

import { useDispatch, useSelector } from 'react-redux';

import FormBusiness                     from '../sections/FormBusiness';
import { getBusinessTypes }             from '../../store/slices/business';
import { getExchanges }                 from '../../store/slices/exchange';
import { getBuyWallet, getSellWallet }  from '../../store/slices/wallet';
import { businessList, paperTypesList } from '../utils/constants';
import ChooseClosureAction              from '../partials/ChooseClosureAction';
import { toDate }                       from '../utils/date';
import CloseBusiness                    from '../sections/CloseBusiness';

const date = toDate();

const BusinessClosure = ({data, closureOperation, modalOn, closeModal}) => {
  
  const dispatch = useDispatch();
  
  
  const [action, setAction] = useState(0);
  
  const {businessTypes, includeSituation} = useSelector(state => state.business);
  const {exchanges}                       = useSelector(state => state.exchange);
  const [lastAction, setLastAction]       = useState();
  
  const handleAction = (type) => {
    setAction(type);
  };
  
  useEffect(() => {
    
    if (modalOn && !businessTypes.length) {
      dispatch(getBusinessTypes());
      dispatch(getExchanges());
    }
    
  }, [modalOn]);
  
  
  useEffect(() => {
    
    if (lastAction === 'pending') dispatch(closureOperation === businessList.COMPRA ? getSellWallet() : getBuyWallet());
    setLastAction(includeSituation);
    
  }, [includeSituation]);
  
  
  const myData = useMemo(
    () => ({...data, date, operationId: closureOperation}),
    [data]
  );
  
  useEffect(() => {
    
    // console.log('entrou aqui', myData);
    if (myData.cdTipoPapel === paperTypesList.OPCAO) {
      setAction(1);
    } else {
      setAction(0);
    }
    
  }, [myData]);
  
  // console.log("myData", myData)
  // console.log("action", action)
  return (
    <Dialog id="business-closure-modal" show={modalOn} handleClose={closeModal} title={'Encerramento de Posição'}>
      {action === 0 && modalOn && !!businessTypes.length && !!exchanges.length &&
      <FormBusiness modalOn={modalOn} initialData={myData} closeModal={closeModal} blockChange={true}/>}
      {action === 1 &&
      <ChooseClosureAction handleAction={handleAction}/>}
      {action === 2 && modalOn && !!businessTypes.length && !!exchanges.length &&
      <CloseBusiness modalOn={modalOn} initialData={myData} closeModal={closeModal}/>}
    </Dialog>
  );
  
};


export default BusinessClosure;
