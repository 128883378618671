import React, { useState } from 'react';

import Button from '../elements/Button';
import Dialog from '../elements/Dialog';

import HelpIncludeBusiness from '../helpers/HelpIncludeBusiness';
import HelpFormBusiness    from '../helpers/HelpFormBusiness';
import HelpFileBusiness    from '../helpers/HelpFileBusiness';
import FormBusiness        from '../sections/FormBusiness';
import ChooseBusiness      from '../partials/ChooseBusiness';
import FileBusiness        from '../sections/FileBusiness';

const IncludeBusiness = () => {
  
  const [modalOn, setModalOn] = useState(false);
  const [helpOn, setHelpOn]   = useState(false);
  const [action, setAction]   = useState(0);
  
  let modal;
  
  const openModal = () => {
    setAction(0);
    setModalOn(true);
  };
  
  const handleHelp = () => {
    setHelpOn((prevOpen) => !prevOpen);
  };
  
  const handleAction = (type) => {
    setAction(type);
  };
  
  const closeModal = () => {
    setModalOn(false);
  };
  
  
  modal = <Dialog id="business-modal" show={modalOn} handleClose={closeModal} handleHelp={handleHelp} title={'Inclusão Negócios'}>
    {action === 0 &&
    <ChooseBusiness handleAction={handleAction}/>
    }
    {action === 1 &&
    <FormBusiness modalOn={modalOn}/>
    }
    {action === 2 &&
    <FileBusiness modalOn={modalOn}/>
    }
  </Dialog>;
  
  return (
    <>
      <Button color="secondary" disabled={!!modalOn} onClick={openModal}>
        Incluir
      </Button>
      {modal}
      {action === 0 && <HelpIncludeBusiness modalOn={helpOn} closeModal={handleHelp}/>}
      {action === 1 && <HelpFormBusiness modalOn={helpOn} closeModal={handleHelp}/>}
      {action === 2 && <HelpFileBusiness modalOn={helpOn} closeModal={handleHelp}/>}
    </>
  );
};

export default IncludeBusiness;
