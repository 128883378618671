import React, { useMemo }             from 'react';
import Grid                           from '@mui/material/Grid';
import Table                          from '../../components/elements/Table';
import { useDispatch, useSelector }   from 'react-redux';
import classes                        from './Repurchase.module.css';
import { formatCurrency, formatDate } from '../../components/utils/formatters';

import ParameterHeader                            from '../../components/sections/ParameterHeader';
import ProcessJob                                 from '../../components/partials/ProcessJob';
import IncludeRepurchase                          from '../../components/containerActions/IncludeRepurchase';
import excludePopover                             from '../../components/partials/ExcludePopover';
import { getPaperRepurchases, processRepurchase } from '../../store/slices/repurchase';


const Repurchase = () => {
  
  const dispatch           = useDispatch();
  const {paperRepurchases} = useSelector(state => state.repurchase);
  
  // const confirmExclusion = (rowData) => dispatch(excludeRepurchase({
  //   papelId      : rowData.original.papelId,
  //   dataRearranjo: rowData.original.dataRearranjo,
  //   tipoRearranjo: rowData.original.tipoId
  // }));
  
  
  const columns = useMemo(
    () =>
      [
        {
          accessor: 'dataRecompra',
          Header  : 'Data Recompra',
          Cell    : ({value}) => formatDate(value)
        },
        {
          accessor: 'dataCredito',
          Header  : 'Data Crédito',
          Cell    : ({value}) => formatDate(value)
        },
        {
          accessor: 'valorRecompra',
          Header  : 'Valor Recompra',
          Cell    : ({value}) => formatCurrency(value)
        },
        // {
        //   Header: '',
        //   id    : 'exclusion',
        //   Cell  : excludePopover(confirmExclusion, 'recompra')
        // },
      ],
    []
  );
  
  return (
    <Grid container>
      <Grid item xs={12} className={classes.Header}>
        <Grid item xs={12} className={classes.Header}>
          <ParameterHeader Process={ProcessJob} slice={'repurchase'} procFunc={processRepurchase}
                           getFunc={getPaperRepurchases}/>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Table columns={columns} rowsData={paperRepurchases} inclusion={<IncludeRepurchase/>}/>
      </Grid>
    </Grid>
  );
};

export default Repurchase;
