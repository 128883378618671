import React, { useState } from 'react';


import classes from './Input.module.css';

import Button      from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';

const InputBoxNumber = ({selectedValue, maxValue}) => {
  
  const [counter, setCounter] = useState(0);
  
  const handleIncrement = () => {
    
    const myCounter = counter + 1;
    setCounter(myCounter);
    selectedValue(myCounter);
  };
  
  const handleDecrement = () => {
    const myCounter = counter - 1;
    setCounter(myCounter);
    selectedValue(myCounter);
  };
  
  return (
    <ButtonGroup size="small" aria-label="small outlined button group">
      <Button onClick={handleDecrement} disabled={counter === 0}>-</Button>
      <Button style={{color:'black'}} disabled>{counter}</Button>
      <Button onClick={handleIncrement} disabled={!!maxValue && counter === maxValue}>+</Button>
    </ButtonGroup>
  
  );
};


export default InputBoxNumber;
