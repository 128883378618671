import React from 'react';



import FormControlLabel from '@mui/material/FormControlLabel';
import MSwitch          from '@mui/material/Switch';

const InputSwitch = ({label, name, onChange, checked}) => {
  
  return (
    <FormControlLabel control={
      <MSwitch color="primary" name={name} onChange={onChange} checked={checked}/>
    } label={label} labelPlacement="top"/>
  );
};


export default InputSwitch;
