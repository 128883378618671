import { format as dateFormat } from 'date-fns';
import { toDate }               from './date';

const CpfFormatter          = (id) => {
  
  // id = id.replace( /\D/g , '');               //Remove tudo o que não é dígito
  id = id.replace(/(\d{3})(\d)/, '$1.$2');  //Coloca um ponto entre o terceiro e o quarto dígitos
  id = id.replace(/(\d{3})(\d)/, '$1.$2');  //Coloca um ponto entre o terceiro e o quarto dígitos
  //de novo (para o segundo bloco de números)
  id = id.replace(/(\d{3})(\d{1,2})$/, '$1-$2'); //Coloca um hífen entre o terceiro e o quarto dígitos
  
  return id;
  
};
const CnpjFormatter         = (id) => {
  
  id = id.replace(/^(\d{2})(\d)/, '$1.$2'); //Coloca ponto entre o segundo e o terceiro dígitos
  id = id.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3'); //Coloca ponto entre o quinto e o sexto dígitos
  id = id.replace(/\.(\d{3})(\d)/, '.$1/$2'); //Coloca uma barra entre o oitavo e o nono dígitos
  id = id.replace(/(\d{4})(\d)/, '$1-$2'); //Coloca um hífen depois do bloco de quatro dígitos
  
  return id;
  
};
export const formatCpfCnpj  = (value) => {
  
  
  if (!value) return '';
  
  if (typeof value !==  'string') value = JSON.stringify(value)
  
  value = value.replace(/\D/g, ''); //Remove tudo o que não é dígito
  value = value.substring(0, 14);
  
  if (value.length < 12) {
    return CpfFormatter(value);
  }
  
  return CnpjFormatter(value);
  
};
export const formatCurrency = (number) => {
  
  const internalNumber = number || 0;
  
  return `R$ ${Intl.NumberFormat('pt-BR', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(internalNumber)}`;
  
};
export const formatDecimal = (number) => {
  
  const internalNumber = number || 0;
  
  return `${Intl.NumberFormat('pt-BR', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 10
  }).format(internalNumber)}`;
  
};
export const formatInteger = (number) => {
  
  const internalNumber = number || 0;
  
  return `${Intl.NumberFormat('pt-BR', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  }).format(internalNumber)}`;
  
};
export const formatPercentage = (number, digits) => {
  
  const numberDigits  = digits || 2;
  const numberPercent = (number || 0) * 100;
  
  return `${Intl.NumberFormat('pt-BR', {
    minimumFractionDigits: numberDigits,
    maximumFractionDigits: numberDigits
  }).format(numberPercent)}%`;
  
};
export const mesExtenso = (mes) => {
  
  const mesNumerico = Number(mes);
  let descricao;
  
  switch (mesNumerico) {
    case 1:
      descricao = 'Janeiro';
      break;
    case 2:
      descricao = 'Fevereiro';
      break;
    case 3:
      descricao = 'Março';
      break;
    case 4:
      descricao = 'Abril';
      break;
    case 5:
      descricao = 'Maio';
      break;
    case 6:
      descricao = 'Junho';
      break;
    case 7:
      descricao = 'Julho';
      break;
    case 8:
      descricao = 'Agosto';
      break;
    case 9:
      descricao = 'Setembro';
      break;
    case 10:
      descricao = 'Outubro';
      break;
    case 11:
      descricao = 'Novembro';
      break;
    case 12:
      descricao = 'Dezembro';
      break;
    default:
      throw new Error('Mês não encontrado');
  }
  
  
  return descricao;
};
export const formatDate = (date, format) => {
  
  if (!date) return ' ';
  
  // console.log("date", date)
  format = format || 'dd/MM/yyyy';
  
  return dateFormat(toDate(date), format);
  
};
export const objectToArray = (object, field) =>  object.reduce((acc, el) => acc += `${el[field]} `, '')

