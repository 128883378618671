import React, { useEffect, useState } from 'react';
import { getAchievementsReport }      from '../../store/slices/achievement';
import { useDispatch, useSelector }   from 'react-redux';
import { monthsBetweenDates }         from '../../components/utils/date';
import Grid                           from '@mui/material/Grid';
import DateBar                        from '../../components/partials/DateBar';
import AchievementAnalytic            from '../../components/graphics/AchievementAnalytic';
import AllIncomesAnalytic             from '../../components/graphics/AllIncomesAnalytic';

const Income = () => {
  
  const dispatch                      = useDispatch();
 
  const [searchDates, setSearchDates] = useState({});
  
  useEffect(() => {
    
    if (searchDates.initialDate) dispatch(getAchievementsReport({
      data        : {dataInicio: searchDates.initialDate, dataFim: searchDates.finalDate},
      internalData: {time: monthsBetweenDates(searchDates.initialDate, searchDates.finalDate)}
    }));
    
  }, [searchDates]);
  
  const handleChangeDate = (dates) => {
    // console.log("dates", dates)
    setSearchDates(dates);
  };
  
  return (
    <Grid container>
      <Grid item xs={12}>
        <DateBar getDates={handleChangeDate}/>
      </Grid>
      <Grid item xs={12}>
        <AllIncomesAnalytic/>
      </Grid>
    </Grid>
  );
};

export default Income;
