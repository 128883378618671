import React, { useState } from 'react';
import Popover             from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Button     from './Button';
import classes    from './Confirmepopover.module.css';
import IconButton from '../elements/IconButton';

const ConfirmPopover = ({title, tooltip, message, iconButton, colorButton, onConfirm}) => {
  
  const [anchorEl, setAnchorEl]                     = useState(null);
  
  
  const confirm = () => {
    
    handleClose()
    onConfirm();
    
  };
  
  const handleClose = () => {
    setAnchorEl(null)
    
  };
  
  const handleToggleVisible = event => {
    setAnchorEl(event.currentTarget)
    
  };
  
  const open = Boolean(anchorEl);
  
  return (
    <div>
      <IconButton icon={iconButton} onClick={handleToggleVisible}
                  cor={colorButton} tooltip={tooltip}/>
      <Popover
        id="simple-popper"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical  : 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical  : 'top',
          horizontal: 'center',
        }}
      >
        <div className={classes.Content}>
          <div className={classes.Header}>
            <Typography variant="subtitle1">
              {title}
            </Typography>
          </div>
          <div className={classes.Body}>
            <Typography variant="body2">
              {message}
            </Typography>
          </div>
          <div className={classes.Footer}>
            <Button color="primary" onClick={confirm}>Sim</Button>
            <Button color="secondary" onClick={handleClose}>Não</Button>
          </div>
        
        </div>
      </Popover>
    </div>
  );
};


export default ConfirmPopover;
