import React          from 'react';
import classes        from './FeatureSplit.module.css';
import Container      from '@mui/material/Container';
import MainFeature    from '../elements/MainFeature';
import VisualFeature  from '../elements/VisualFeature';

const image1 = require('./../../assets/images/vantagem1.jpg');
const image2 = require('./../../assets/images/vantagem2.jpg');
const image3 = require('./../../assets/images/vantagem3.jpg');
const image4 = require('./../../assets/images/vantagem4.jpg');

const variants = {
  hidden : {
    opacity: 0,
    y      : 50,
  },
  visible: {
    opacity: 1,
    y      : 0,
  }
};

const FeaturesSplit = ({className}) => {
  
  const classBox = [classes.Box, className].join(' ');
  
  return (
    <Container className={classBox}>
      <MainFeature variants={variants} delay={0} title={'Funcionalidades que realmente fazem sentido'} separator>
        Conjunto de ações para atender a sua necessidade de gestão, controle e contabilidade
      </MainFeature>
      <VisualFeature title={'Posição Atual'} subtitle={'Gestão da carteira'} direction={'row'} image={image1} delay={0}>
        Incluindo as negociações de forma manual, ou através da importação das notas de corretagem, nós calculamos preço médio, custos, variação percentual e
        valorização.
        Ainda de forma automática são consideradas amortizações, bonificações, desdobramentos e grupamentos.
      </VisualFeature>
      <VisualFeature title={'Histórico de Negócios'} subtitle={'Controle das realizações'} direction={'row-reverse'} image={image2} delay={0.2}>
        Ao fechar um ciclo de negócios, além dos dados mencionados acima, são calculados impostos, e valorização total considerando os proventos do período.
      </VisualFeature>
      <VisualFeature title={'Obrigações do Período'} subtitle={'Impostos e prejuízos'} direction={'row'} image={image3} delay={0.3}>
        Existe uma sumarização dos impostos e valores transacionados no mês agrupados por tipo de papel e modalidade da operação.
      </VisualFeature>
      <VisualFeature title={'Patrimônio Anual'} subtitle={'Carteira Detalhada'} direction={'row-reverse'} image={image4} delay={0.4}>
        Ainda relacionado a impostos, a posição de cada ano para utilização na declaração anual.
      </VisualFeature>
    </Container>
  );
};


export default FeaturesSplit;
