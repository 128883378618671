import React                  from 'react';
import classes   from './Login.module.css';
import InputText from '../../components/elements/FormText';
import MailIcon  from '@mui/icons-material/Email';
import Grid                   from '@mui/material/Grid';
import Button                 from '../../components/elements/Button';
import { useForm }            from 'react-hook-form';
import { handleError, rules } from '../../components/utils/rules';
import Typography             from '@mui/material/Typography';
import { useDispatch }        from 'react-redux';
import { recoveryPassword }   from '../../store/slices/auth';


const defaultValues = {
  email: '',
};

const Forgot = ({}) => {
  
  const dispatch                                    = useDispatch();
  const {control, handleSubmit, formState} = useForm({mode: 'all', defaultValues});
  
  const {isValid, errors} = formState;
  
  
  const disabled = !isValid;
  
  const onSubmit = (data, event) => {
    // console.log('passou aqui');
    // console.log(data); // handle the data here
    dispatch(recoveryPassword(data));
    event.target.reset(); // reset the form after submission
  };
  
  // console.log("isValid", isValid);
  
  return (
    
    <form id="forgot" onSubmit={handleSubmit(onSubmit)}>
      <Grid item xs={12}>
        <Typography variant="body1" className={classes.Title}>
          Recuperação de senha
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <InputText label="Email" name="email" Icon={MailIcon} errorMessage={handleError(errors, 'email')}
                   control={control} rules={rules('email', )}
        />
      </Grid>
      <Grid item xs={12} className={classes.Button}>
        <Button color="secondary" disabled={disabled} type="submit">Recuperar</Button>
      </Grid>
    </form>
  
  );
};


export default Forgot;
