import React      from 'react';
import Grid       from '@mui/material/Grid';

import classes        from './NoPlan.module.css';
import { motion }     from 'framer-motion';
import Typography     from '@mui/material/Typography';
import Button         from '../../components/elements/Button';
import { useNavigate } from 'react-router-dom';


const NoPlan = () => {
  
  const navigate  = useNavigate();
  
  return (
    <Grid container justifyContent={'center'} alignItems={'center'} alignContent={'center'}>
          <Grid item xs={12} >
            <motion.div
              className={classes.Container}
              initial={{y: 50, opacity: 0}}
              animate={{y: 0, opacity: 1}}
              transition={{duration: 1}}
            >
              <Typography variant="h3" className={classes.Title}>
                No Momento você não possui um plano ativo
              </Typography>
            </motion.div>
          </Grid>
          <Grid item xs={12}   className={classes.Container}>
            <motion.div initial={{y: 50, opacity: 0.2}}
                        animate={{y: 0, opacity: 1}} transition={{duration: 0.7, delay: 0.2}}>
              <Typography variant="h6" className={classes.Text}>
                Acesse aqui para renovar seu plano e continuar aproveitando de todas as facilidades que oferecemos
              </Typography>
            </motion.div>
            
          </Grid>
  
      <Grid item xs={12} className={classes.Button}>
        <motion.div initial={{y: 50, opacity: 0.2}}
                    animate={{y: 0, opacity: 1}} transition={{duration: 0.7, delay: 0.2}}>
          <Button color="primary"  onClick={() => navigate('/plano')}>
            Renovar
          </Button>
        </motion.div>
  
      </Grid>
      
    </Grid>
  );
};


export default NoPlan;
