import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import classes                      from './YearBusinessSelect.module.css';
import { getYearsBusiness }         from '../../store/slices/business';
import FormControl                  from '@mui/material/FormControl';
import InputLabel                   from '@mui/material/InputLabel';
import Select                       from '@mui/material/Select';
import MenuItem                     from '@mui/material/MenuItem';

const YearBusinessSelect = ({value, onChange, onInit, defaultPosition}) => {
  
  const dispatch = useDispatch();
  
  const {yearBusiness} = useSelector(state => state.business);
  
  
  useEffect(() => {
    
    dispatch(getYearsBusiness());
  }, []);
  
  useEffect(() => {
    
    if (yearBusiness.length) onInit(yearBusiness[defaultPosition || 0].ano)
    
  }, [yearBusiness]);
  
  const items = [];
  
  // console.log("yearBusiness", yearBusiness)
  for (const value of yearBusiness) {
    items.push(
      <MenuItem value={value.ano} key={value.ano}>{value.ano}</MenuItem>
    );
  }
  
  return (
    <div>
    
    <FormControl aria-label={'Selecione ano de negócio'} variant="outlined" className={classes.Field}>
      <InputLabel htmlFor={'yearBusiness'}>Ano Operação</InputLabel>
      <Select labelId={`yearBusiness-label`} id="yearBusiness" label="Ano Operação" value={value} onChange={onChange}>
        {items}
      </Select>
    </FormControl>
    </div>
  );
};

export default YearBusinessSelect;
