import React, { useEffect, useState }  from 'react';
import Grid                            from '@mui/material/Grid';
import Typography                      from '@mui/material/Typography';
import classes                         from './MyPlan.module.css';
import Box                             from '../../components/elements/Box';
import PlanChange                      from '../../components/sections/PlanChange';
import PlanAddon                       from '../../components/sections/PlanAddon';
import { useDispatch, useSelector }    from 'react-redux';
import { getUserConsume, getUserPlan } from '../../store/slices/user';
import { formatDate } from '../../components/utils/formatters';
import MyPlanDetail   from '../../components/detailComponents/MyPlanDetail';
import MyAddonDetail  from '../../components/detailComponents/MyAddonDetail';


const MyPlan = () => {
  
  const dispatch                = useDispatch();
  const {userPlan, userConsume} = useSelector(state => state.user);
  
  
  useEffect(() => {
    
    dispatch(getUserConsume());
    dispatch(getUserPlan());
    
  }, []);
  
  // console.log('userPlan', userPlan);
  // console.log('userConsume', userConsume);
  
  return (
    <Grid container>
      <Grid item xs={12} sm={8} className={classes.Plan}>
        <MyPlanDetail userPlan={userPlan}/>
      </Grid>
      <Grid item xs={12} className={classes.Plan}>
        <Typography variant="h5" className={classes.Text}>
          Consumo Plano
        </Typography>
      </Grid>
      <Grid item xs={12} md={8}>
        <Box>
          <Grid container justifyContent={'space-between'} alignItems={'center'}>
            <Grid item xs={12} md={9} className={classes.Plan} style={{minHeight: '40px'}}>
              <Typography variant="body1" className={classes.Text}>
                {userConsume.planText}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={12} sm={8} className={classes.Plan}>
        <MyAddonDetail userConsume={userConsume}/>
      </Grid>
    </Grid>
  
  );
};


export default MyPlan;
