import React        from 'react';
import { AnimatePresence } from 'framer-motion'
import { useLocation } from 'react-router-dom';
import { Routes } from 'react-router-dom';

const RoutesTransition = ({exitBeforeEnter, initial, children}) => {
  
  const location = useLocation()
  return (
    // <AnimatePresence exitBeforeEnter={exitBeforeEnter} initial={initial}>
      <Routes location={location} key={location.pathname}>
        {children}
      </Routes>
    // </AnimatePresence>
  )
}


export default RoutesTransition
