import React, { useEffect, useState } from 'react';
import Grid                           from '@mui/material/Grid';
import HighchartsReact                from 'highcharts-react-official';
import Highcharts                     from 'highcharts';
import { useSelector }    from 'react-redux';
import { formatCurrency } from '../utils/formatters';
import Box                from '../elements/Box';


const commonChart = {
  chart      : {
    backgroundColor: '#fafafa',
    type           : 'column',
  },
  yAxis      : {
    min        : 0,
    title      : {
      text: 'Lucro em R$'
    },
    stackLabels: {
      enabled  : false,
      style    : {
        fontWeight: 'bold',
        color     : 'gray'
      },
      formatter: function () {
        return `${formatCurrency(this.total)}`;
      }
    }
  },
  tooltip    : {
    formatter: function () {
      return `
      <b>${this.x} <br/></b>
      ${this.series.name}: <b>${formatCurrency(this.y)}</b><br/>
      Total: <b>${formatCurrency(this.point.stackTotal)}</b>
      `;
    }
  },
  plotOptions: {
    column: {
      stacking  : 'normal',
      dataLabels: {
        enabled: false,
        // rotation: -90,
        // formatter: function () {
        //   return `${formatCurrency(this.y)}`;
        // }
      },
    }
  },
  credits    : {
    enabled: false
  },
};

const title2 = {text: 'Proventos por período'};

const EarningsAnalytic = () => {
  
  const [chart2Options, setChart2Options] = useState(null);
  const {periodEarnings}              = useSelector(state => state.earning);
  
  useEffect(() => {
    
    setChart2Options({
      ...commonChart,
      title : title2,
      xAxis : {
        categories: periodEarnings.xAxis,
        labels    : {
          rotation: -45,
        }
      },
      series: [
        {
          name: 'Ação',
          data: periodEarnings.yAxis.acao,
        },
        {
          name: 'FII',
          data: periodEarnings.yAxis.fii,
        }
      ]
    });
    
    
  }, [periodEarnings]);
  
  return (
    <>
      {!!periodEarnings.xAxis.length &&
      <Box>
        <Grid container>
          <Grid item xs={12}>
            <HighchartsReact highcharts={Highcharts} options={chart2Options} immutable = { true }/>
          </Grid>
        </Grid>
      </Box>
      }
    </>
  );
};

export default EarningsAnalytic;
