import { createSlice }  from '@reduxjs/toolkit';
import { thunkAPI }     from '../../components/utils/api';
import { subtractDays } from '../../components/utils/date';


export const editCompany       = thunkAPI('company/edit', '/v1/empresas', 'post', {successMessage: 'Empresa alterada com sucesso'});
export const getCompanyHistory = thunkAPI('companyHistory/get', '/v1/empresa/historico',
  {verb: 'get', getCache: ['company', 'historyCompanies', 'empresaId']},);
export const getCompanies      = thunkAPI('company/get', 'v1/empresas', 'get',);
export const includeCompany    = thunkAPI('company/include', 'v1/empresas', 'post', {successMessage: 'Empresa Incluída com sucesso'});
export const searchCompany     = thunkAPI('searchCompany/get', 'v1/empresa/busca', 'get',);

const includeHistoryCompany = (company, history) => {
  
  const {dataInicio, dataFim, id, nome} = company;
  
  let endDate;
  const register = {
    cdEmpresa: id,
    nome     : nome,
  };
  
  if (dataInicio) {
    register.tsIncVgc = dataInicio;
    endDate           = subtractDays(dataInicio, 1).toJSON().substr(0, 10);
  }
  if (dataFim) endDate = dataFim;
  
  if (endDate && history[id].length > 0 && !history[id][0].tsFimVgc) history[id][0].tsFimVgc = endDate;
  if (dataInicio) history[id].unshift(register);
  
  return history;
  
};
const formatCompanies       = (companies) => {
  
  const formattedCompanies = [];
  
  for (const data of companies) {
    
    formattedCompanies.push({
      id     : data.cdEmpresa,
      nome   : data.nome,
      cnpj   : data.cnpj,
      vigente: data.vigente ? 'sim' : 'não'
    });
  }
  
  return formattedCompanies;
  
};
const formatCompanyHistory  = (history) => {
  
  const formattedHistory = [];
  
  let id = 0;
  for (const data of history) {
    
    formattedHistory.push({
      id,
      nome      : data.nome,
      dataInicio: data.tsIncVgc,
      dataFim   : data.tsFimVgc,
    });
    id++;
  }
  
  return formattedHistory;
  
};

const slice = createSlice({
  name         : 'company',
  initialState : {
    companiesSearched: [],
    companies        : [],
    companyHistory   : [],
    historyCompanies : {},
    includeSituation : 'idle'
  },
  reducers     : {},
  extraReducers: {
    [editCompany.fulfilled]      : (state, action) => {
      const {id, index, nome, vigente} = action.payload;
      const companies                  = [...state.companies];
      
      if (state.historyCompanies[id]) {
        state.historyCompanies = includeHistoryCompany(action.payload, {...state.historyCompanies});
      }
      
      companies[index].nome    = nome;
      companies[index].vigente = vigente ? 'sim' : 'não';
      state.companies          = companies;
    },
    [getCompanies.fulfilled]     : (state, action) => {
      state.companies = formatCompanies(action.payload);
    },
    [getCompanyHistory.pending]  : (state) => {
      state.companyHistory = [];
    },
    [getCompanyHistory.fulfilled]: (state, action) => {
      
      if (action.payload.length) {
        const id                   = action.payload[0].cdEmpresa;
        state.historyCompanies[id] = action.payload;
        
      }
      
      state.companyHistory = formatCompanyHistory(action.payload);
    },
    [includeCompany.pending]     : (state) => {
      state.includeSituation = 'pending';
    },
    [includeCompany.fulfilled]   : (state, action) => {
      let companies = [...state.companies];
      
      const {id, nome, cnpj, vigente} = action.payload;
      
      const newCompany = {
        id,
        nome,
        cnpj,
        vigente: vigente ? 'sim' : 'não'
      };
      
      companies.unshift(newCompany);
      
      state.companies        = companies;
      state.includeSituation = 'idle';
    },
    [includeCompany.rejected]    : (state) => {
      state.includeSituation = 'rejected';
    },
    [searchCompany.fulfilled]    : (state, action) => {
      state.companiesSearched = action.payload;
    },
  }
});

const {reducer} = slice;

export default reducer;
