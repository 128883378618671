import React, { useEffect }         from 'react';
import classes                      from './ReceiptEdit.module.css';
import Dialog                       from '../elements/Dialog';
import Grid                         from '@mui/material/Grid';
import { useDispatch, useSelector } from 'react-redux';

import { handleError, rules } from '../utils/rules';
import Button                 from '../elements/Button';
import { useForm }            from 'react-hook-form';

import { editReceipt }    from '../../store/slices/receipt';
import FormAutoComplete   from '../elements/FormAutoComplete';
import { searchAdmPaper } from '../../store/slices/paper';
import FormDate           from '../elements/FormDate';
import CakeIcon           from '@mui/icons-material/Cake';


const defaultValues = {
  date : '',
  paper: null,
};

let title = '';


const ReceiptAdd = ({data, modalOn, closeModal}) => {
  
  const dispatch = useDispatch();
  
  const {includeSituation} = useSelector(state => state.receipt);
  
  const {control, handleSubmit, reset, getValues, formState} = useForm({mode: 'all', defaultValues});
  
  const {dirtyFields, isValid, errors} = formState;
  
  
  if (data) title = data.sigla;
  
  
  useEffect(() => {
    
    if (includeSituation === 'idle') reset(defaultValues);
    
  }, [includeSituation]);
  
  const onSubmit = (dataChanged) => {
    const {paper}   = dataChanged;
    const {recibos} = data;
    
    
    dispatch(editReceipt(
      {
        urlId       : data.id,
        data        : {recibos: [data.papelId, ...recibos.map(rec => rec.papelId), paper.cdPapel], papelId: data.papelId},
        internalData: {papel: paper, index: data.index}
      })
    );
    
  };
  
  const disabled  = (!isValid || includeSituation === 'pending');
  const showPaper = (!!dirtyFields['date'] && !Boolean(errors['date']));
  
  return (
    <Dialog id="receipt-add-modal" show={modalOn} handleClose={closeModal} title={`Inclusão de Recibo ${title}`}>
      <form id="receipt" onSubmit={handleSubmit(onSubmit)} className={classes.Form}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} className={classes.Item}>
            <FormDate label="Data Vigência" name="date" Icon={CakeIcon} errorMessage={handleError(errors, 'date')}
                      control={control} rules={{required: true}}
            />
          </Grid>
          <Grid item xs={12} sm={6} className={classes.Select}>
            {showPaper &&
            <FormAutoComplete label="Papel" name="paper" placeholder="Pesquise o papel" control={control} errorMessage={handleError(errors, 'paper')}
                              dataFunction={searchAdmPaper} searchField={'busca'} requiredData={{dataPesquisa: getValues('date')}}
                              displayField="sigla" rules={(rules('paper'))} dataFields={{slice: 'paper', data: 'papersSearched'}}/>
            }
          </Grid>
          
          <Grid item xs={12} className={classes.Button}>
            <Button color="secondary" disabled={disabled} type="submit">
              {includeSituation === 'pending' ? 'Incluindo' : 'Incluir'}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Dialog>
  );
  
};


export default ReceiptAdd;
