import React, { useEffect, useMemo, useState } from 'react';

import classes                      from './Input.module.css';
import FormHelperText               from '@mui/material/FormHelperText';
import FormControl                  from '@mui/material/FormControl';
import InputLabel                   from '@mui/material/InputLabel';
import Select                       from '@mui/material/Select';
import MenuItem                     from '@mui/material/MenuItem';
import { Controller }               from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import TextField                    from '@mui/material/TextField';
import Autocomplete                 from '@mui/material/Autocomplete';


const FormSelect = ({label, name, dataFunction, dataFields, control, displayField, requiredData, valueField, rules, errorMessage, ...others}) => {
  
  const dispatch = useDispatch();
  
  const [internalList, setInternalList] = useState([]);
  
  const data = useSelector(state => state[dataFields.slice]);
  
  useEffect(() => {
    if (dataFunction && requiredData) dispatch(dataFunction(requiredData));
  }, [requiredData]);
  
  
  // console.log(data)
  // console.log(internalList)
  
  useEffect(() => {
    
    let myList = data[dataFields.data];
    if (dataFields.field1) myList = myList[dataFields.field1] || [];
    if (dataFields.field2 && data[dataFields.data][dataFields.field1]) myList = data[dataFields.data][dataFields.field1][dataFields.field2] || [];
    setInternalList(myList);
  }, [data]);
  
  return (
    
    <FormControl aria-label={name} variant="outlined" fullWidth className={classes.Field}>
      <Controller name={name} control={control} rules={rules}
                  render={({field}) => (
                    <Autocomplete
                      {...field}
                      isOptionEqualToValue={(option, value) => option[valueField] === value[valueField]}
                      getOptionLabel={option => option[displayField]}
                      options={internalList}
                      onChange={(_, data) => field.onChange(data)}
                      renderInput={(params) => <TextField {...params} label={label} error={!!errorMessage}/>}
                    />)}
      
      />
      {!!errorMessage && <FormHelperText error margin="dense">{errorMessage}</FormHelperText>}
    
    </FormControl>
  );
};


export default FormSelect;
