import React                                            from 'react';
import MCard                                            from '@mui/material/Card';
import CardHeader                                       from '@mui/material/CardHeader';
import CardContent                                      from '@mui/material/CardContent';
import Typography                                       from '@mui/material/Typography';
import classes                                          from './ReportCard.module.css';
import { formatCurrency, formatDate, formatPercentage } from '../utils/formatters';


const reportCard = ({
                      appreciation, averageAppreciation, monthAppreciation, title, subtitle, rightSubtitle, value, paper, period, initialDate,
                      finalDate, principal, averageTicket, className
                    }) => {
  
  
        let field1, field2;
  
        switch (principal) {
          case 'appreciation':
            field1 = formatPercentage(appreciation);
            field2 = formatCurrency(value);
            break;
          case 'value':
            field1 = formatCurrency(value);
            field2 = !!appreciation ? formatPercentage(appreciation) : null;
        }
  
        let mySubtitle = subtitle;
  
        if (rightSubtitle) {
          mySubtitle = <div className={classes.SubTitleBox}>
            <span>{subtitle}</span>
            <span>{rightSubtitle}</span>
          </div>;
        }
  
  
        const classCard = [classes.Card, className].join(' ');
        let color       = !!className ? {background: 'white'} : null;
  
        return (
          <MCard className={classCard} variant="outlined">
            <CardHeader title={title} subheader={mySubtitle} titleTypographyProps={{className: classes.Title}}/>
            <CardContent>
              <div className={classes.Separator} style={{marginTop: '-16px', ...color}}/>
              <Typography variant="body1" gutterBottom className={classes.Paper}>
                {paper}
              </Typography>
        
              <Typography variant="body1" gutterBottom className={classes.MainText}>
                {field1}
              </Typography>
              {!!initialDate &&
                <>
                  <div className={classes.Separator} style={color}/>
                  <Typography variant="body2" className={classes.Text}>
                    {period} dias
                  </Typography>
                  <div className={classes.Dates}>
                    <Typography variant="body1" gutterBottom className={classes.Text}>
                      <span style={{fontWeight: 'bold'}}>Compra</span> {formatDate(initialDate)}
                    </Typography>
                    <Typography variant="body1" gutterBottom className={classes.Text}>
                      <span style={{fontWeight: 'bold'}}>Venda</span> {formatDate(finalDate)}
                    </Typography>
                  </div>
                </>
              }
              {!!field2 &&
              <>
                <div className={classes.Separator} style={color}/>
                <Typography variant="body1" className={classes.SubTitle}>
                  Valorização
                </Typography>
                <div className={classes.Dates}>
                  <Typography variant="body1" gutterBottom className={classes.Text}>
                    Total: {field2}
                  </Typography>
                  <Typography variant="body1" gutterBottom className={classes.Text}>
                    Mensal: {formatPercentage(monthAppreciation)}
                  </Typography>
                </div>
              </>
              }
              {!!averageAppreciation &&
              <>
                <div className={classes.Separator} style={color}/>
                <Typography variant="body1" className={classes.SubTitle}>
                  Média
                </Typography>
                <div className={classes.Dates}>
                  <Typography variant="body1" gutterBottom className={classes.Text}>
                    Ticket:
                    {formatCurrency(averageTicket)}
                  </Typography>
                  <Typography variant="body1" gutterBottom className={classes.Text}>
                    Ganho:
                    {formatPercentage(averageAppreciation)}
                  </Typography>
                </div>
              </>
              }
      
            </CardContent>
          </MCard>
        );
      }
;

export default reportCard;
