import React from 'react';


import classes          from './Input.module.css';
import FormControlLabel from '@mui/material/FormControlLabel';
import MSwitch          from '@mui/material/Switch';
import { Controller }   from 'react-hook-form';


const FormSwitch = ({label, name, control,}) => {
  
  return (
    <FormControlLabel className={classes.Field} name={name} label={label}
                      control={
                        <Controller
                          render={({field}) => (
                            <MSwitch  checked={field.value} onChange={(_, data) => field.onChange(data)}   />
                            // <MSwitch    {...field} />
                          )}
                          type="checkbox"
                          name={name}
                          control={control}
                          className={classes.Field}
                        />
                      }
    />
  
  );
};


export default FormSwitch;
