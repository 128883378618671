import React, { useEffect, useMemo, useState } from 'react';
import Table                                   from '../../components/elements/Table';
import { useDispatch, useSelector }            from 'react-redux';
import { getEarnings }                         from '../../store/slices/earning';
import { formatCurrency, formatDate, }         from '../../components/utils/formatters';
import Grid                                    from '@mui/material/Grid';
import { monthsBetweenDates }                  from '../../components/utils/date';
import DateBar          from '../../components/partials/DateBar';
import EarningsAnalytic from '../../components/graphics/EarningsAnalytic';
import classes          from '../Wallet/Wallet.module.css';
import Typography                              from '@mui/material/Typography';
import Box                                     from '../../components/elements/Box';


const MyEarning = () => {
  
  const dispatch                      = useDispatch();
  const {earnings}                    = useSelector(state => state.earning);
  const [searchDates, setSearchDates] = useState({});
  
  useEffect(() => {
    
    if (searchDates.initialDate) dispatch(getEarnings({
      data        : {dataInicio: searchDates.initialDate, dataFim: searchDates.finalDate},
      internalData: {time: monthsBetweenDates(searchDates.initialDate, searchDates.finalDate)}
    }));
    
  }, [searchDates]);
  
  const handleChangeDate = (dates) => {
    // console.log("dates", dates)
    setSearchDates(dates);
  };
  
  const columns = useMemo(
    () =>
      [
        {
          accessor: 'papel',
          Header  : 'Papel',
        },
        {
          accessor: 'corretora',
          Header  : 'Corretora',
        },
        {
          accessor: 'tipoProvento',
          Header  : 'Tipo de Provento',
        },
        {
          accessor: 'quantidade',
          Header  : 'Quantidade',
        },
        {
          accessor: 'vlPagamento',
          Header  : 'Valor Provento',
          Cell    : ({value}) => formatCurrency(value)
        },
        {
          Header  : 'Data Direito (com)',
          id      : 'dataDireito',
          accessor: value => formatDate(value.dataDireito),
          sortType: 'alphanumericOriginal',
        },
        {
          Header  : 'Data Pagamento',
          id      : 'dataPagamento',
          accessor: value => formatDate(value.dataPagamento),
          sortType: 'alphanumericOriginal',
        },
      ],
    []
  );
  
  return (
    <Grid container>
      <Grid item xs={12}>
        <DateBar getDates={handleChangeDate}/>
      </Grid>
      <Grid item xs={12}>
        <EarningsAnalytic/>
      </Grid>
      {!!earnings.length &&
      <Grid item xs={12}>
        <Table rowsData={earnings} columns={columns}/>
      </Grid>
      }
      {!earnings.length &&
      <Box>
        <Grid item xs={12} className={classes.NegativeBox}>
          <Typography variant={'h6'} className={classes.NegativeText}>
            Você não possui proventos para o período
          </Typography>
        </Grid>
      </Box>
      }
      <Grid item xs={12} className={classes.Text}>
        <Typography variant="body2" gutterBottom>
          *Os negócios incluídos no dia, terão o processamento noturno dos proventos
        </Typography>
      </Grid>
    </Grid>
  
  );
};


export default MyEarning;
