import React   from 'react';
import classes from './HistoryButton.module.css';

import NoteAddIcon from '@mui/icons-material/NoteAdd';
import IconButton  from '@mui/material/IconButton';

const AddButton = (onClick) => ({row}) => {
  
 
  return (
    <div className={classes.ActionsButtons}>
      <IconButton aria-label="add" tooltip="incluir" color="primary" onClick={() => onClick(row)}>
        <NoteAddIcon />
      </IconButton>
    </div>
  
  );
  
};


export default AddButton;
