import React   from 'react';

import Grid    from '@mui/material/Grid';

import WalletSegmentation      from '../../components/sections/WalletSegmentation';
import AchievementSegmentation from '../../components/graphics/AchievementSegmentation';
import EarningSegmentation     from '../../components/graphics/EarningSegmentation';

const Home = () => {
  
  return (
    <Grid container>
      <Grid item xs={12}>
        <WalletSegmentation/>
      </Grid>
      <Grid item xs={12}>
        <AchievementSegmentation/>
      </Grid>
      <Grid item xs={12}>
        <EarningSegmentation/>
      </Grid>
    </Grid>
  );
};


export default Home;
