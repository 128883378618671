import React                                from 'react';
import MiniCard                             from '../elements/MiniCard';
import Grid                                 from '@mui/material/Grid';
import classes                              from './WalletSummary.module.css';
import { formatCurrency, formatPercentage } from '../utils/formatters';
import AccountBalanceIcon                   from '@mui/icons-material/AccountBalance';
import SpeedIcon                            from '@mui/icons-material/Speed';
import MonetizationOnIcon                   from '@mui/icons-material/MonetizationOn';

const walletSummary = ({summary}) => {
  
  return (
    <Grid container spacing={3} justifyContent="center" className={classes.Container}>
      <Grid item xs={12} md={4} className={classes.Card}>
        <MiniCard title={'Patrimônio Inicial'} info={formatCurrency(summary.capitalInicialTotal)}
                  icon={AccountBalanceIcon}/>
      </Grid>
      <Grid item xs={12} md={4} className={classes.Card}>
        <MiniCard title={'Patrimônio Atual*'} info={formatCurrency(summary.capitalAtualTotal)}
                  icon={MonetizationOnIcon}/>
      </Grid>
      <Grid item xs={12} md={4} className={classes.Card}>
        <MiniCard title={'Valorização'} info={formatPercentage((summary.capitalAtualTotal / summary.capitalInicialTotal) - 1)}
                  icon={SpeedIcon}/>
      </Grid>
    </Grid>
  );
};

export default walletSummary;
