import React                        from 'react';
import Grid                         from '@mui/material/Grid';
import classes                      from '../../containers/Incorporation/Incorporation.module.css';
import FormDate                     from '../elements/FormDate';
import TodayIcon                    from '@mui/icons-material/Today';
import { handleError, rules }       from '../utils/rules';
import FormAutoComplete             from '../elements/FormAutoComplete';
import { searchPaper }              from '../../store/slices/paper';
import Button                       from '../elements/Button';
import { toDate }                   from '../utils/date';
import { useDispatch, useSelector } from 'react-redux';
import { useForm }                  from 'react-hook-form';


const defaultValues = {
  date : toDate().toISOString().substring(0, 10),
  paper: null,
};


const ParameterHeader = ({slice, Redeem, Process, getFunc, procFunc}) => {
  
  const dispatch = useDispatch();
  
  const onSearch = (data) => {
    const {paper} = data;
    dispatch(getFunc({papelId: paper.cdPapel,},));
  };
  
  
  const {control, handleSubmit, getValues, formState} = useForm({mode: 'all', defaultValues});
  
  const {isValid, errors} = formState;
  const {searchSituation} = useSelector(state => state[slice]);
  
  const showPaper = (!Boolean(errors['date']));
  
  const disabled = (!isValid || searchSituation === 'pending');
  
  return (
    <Grid container spacing={2} justifyContent={'space-between'} className={classes.Search}>
      <Grid item xs={12} sm={6}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <FormDate label="Data Vigência Papel" name="date" Icon={TodayIcon} errorMessage={handleError(errors, 'date')}
                      control={control} rules={rules('businessDate')}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            {showPaper &&
            <FormAutoComplete label="Papel" name="paper" placeholder="Pesquise o papel" control={control} dataFunction={searchPaper}
                              dataFields={{slice: 'paper', data: 'papersSearched'}} searchField={'busca'} requiredData={{dataPesquisa: getValues('date')}}
                              displayField="sigla" rules={(rules('paper'))} errorMessage={handleError(errors, 'paper')}/>
            }
          </Grid>
          <Grid item xs={12} sm={4} className={classes.Button}>
            <Button color="secondary" disabled={disabled} onClick={handleSubmit(onSearch)}>
              {searchSituation === 'pending' ? 'Pesquisando' : 'Pesquisar'}
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Grid container justifyContent={'flex-end'}>
          { !!Redeem &&
            <Grid item xs={12} sm={4} className={classes.Button}>
              <Redeem/>
            </Grid>
          }
         
          <Grid item xs={12} sm={4} className={classes.Button}>
            <Process slice={slice} procFunc={procFunc}/>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
  
};


export default ParameterHeader;
