import React, { useMemo }                               from 'react';
import Table                                            from '../elements/Table.js';
import classes                                          from './AchievementDetail.module.css';
import { formatCpfCnpj, formatCurrency, formatInteger } from '../utils/formatters';



const YearPositionDetail = ({row}) => {
  
  
  const {corretoras} = row;
  
  const columns = useMemo(
    () =>
      [
        {
          accessor: 'corretora',
          Header  :  'Corretora',
        },
        {
          accessor: 'quantidade',
          Header  :  'Quantidade',
          Cell    : ({value}) => formatInteger(value)
        },
        {
          accessor: 'valorTotal',
          Header  :  'Valor Total',
          Cell    : ({value}) => formatCurrency(value)
        },
      ],
    []
  );
  
  return (
    <div className={classes.Container}>
      <Table columns={columns} rowsData={corretoras} hideFilter={true}/>
    </div>
  );
  
  
};

export default YearPositionDetail;
