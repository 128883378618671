import React         from 'react';
import Popover       from '@mui/material/Popover';
import classes       from './Login.module.css';
import Paper         from '@mui/material/Paper';

import Grid          from '@mui/material/Grid';


import Link   from '@mui/material/Link';
import Signin from './Signin';
import Forgot from './Forgot';



const Login = ({className, id, open, onClose, anchorEl, ...props}) => {
  

  
  const [otherForm, setOtherForm] = React.useState(false)
  
  
  const changeForm = () => {
    setOtherForm(true);
  };
  
  
  const closePopover = () => {
    onClose()
    setOtherForm(false);
  }
  
  return (
    
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={closePopover}
      anchorOrigin={{
        vertical  : 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical  : 'top',
        horizontal: 'center',
      }}
    >
      <Paper elevation={0} className={classes.Card}>
        {!otherForm && <Signin/>}
        {!!otherForm && <Forgot/>}
        {!otherForm && <Grid item xs={12} className={classes.Button}>
          <Link onClick={changeForm} variant="body2"> Esqueci a senha </Link>
        </Grid>}
      </Paper>
    </Popover>
  
  );
};


export default Login;
