import React       from 'react';
import MCard       from '@mui/material/Card';
import Avatar      from '@mui/material/Avatar';
import CardHeader  from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Typography  from '@mui/material/Typography';
import classes     from './Card.module.css';


const card = ({title, children,}) => {
  

  
  return (
    <MCard className={classes.Card} variant="outlined">
      <CardHeader  title={title}   titleTypographyProps={{className: classes.Title}}/>
      <CardContent style={{padding: 0}}>
        
          {children}
      
      </CardContent>
    </MCard>
  );
};

export default card;
