import React, { useEffect, useState } from 'react';

import Grid                 from '@mui/material/Grid';
import classes              from '../sections/Plans.module.css';
import Card                 from '../elements/Card';
import Typography           from '@mui/material/Typography';
import { ListItem, Switch } from '@mui/material';
import List                 from '@mui/material/List';
import { formatCurrency }   from '../utils/formatters';


const monthPrice  = 15;
const annualPrice = 120;

let preco      = monthPrice;
let priceColor = classes.PriceMonth;
let tempo      = 'mês';

const PlanCards = ({selectedPlan, initialPlan, size = 4}) => {
  
  const [period, setPeriod] = useState(false);
  
  useEffect(() => {
    
    if (initialPlan === 'Anual') {
      preco      = annualPrice;
      priceColor = classes.PriceAnnual;
      tempo      = 'ano';
      setPeriod(true);
    }
    
  }, [initialPlan]);
  
  const handleChange = (event) => {
    setPeriod(event.target.checked);
    
    let periodicidade;
    if (event.target.checked) {
      preco         = annualPrice;
      priceColor    = classes.PriceAnnual;
      tempo         = 'ano';
      periodicidade = 'Anual';
    } else {
      preco         = monthPrice;
      priceColor    = classes.PriceMonth;
      tempo         = 'mês';
      periodicidade = 'Mensal';
    }
    
    if (selectedPlan) selectedPlan(preco, periodicidade);
    
  };
  
  
  const classPrice = [classes.Price, priceColor].join(' ');
  
  
  return (
    <Grid container justifyContent="center">
      <Grid item xs={12}>
        <Typography component="div">
          <Grid component="label" container alignItems="center" justifyContent="center" spacing={1}>
            <Grid item>Mensal</Grid>
            <Grid item>
              <Switch checked={period} onChange={handleChange} color="primary"
                classes={{
               track       : classes.SwitchOff,
               thumb       : classes.SwitchButton,
               colorPrimary: classes.SwitchOn,
               }}
              />
            </Grid>
            <Grid item>Anual</Grid>
          </Grid>
        </Typography>
      </Grid>
      <Grid item xs={12} sm={size}>
        <Card>
          {period &&
          <div className={classes.Ribbon}>
            <span>33% desconto</span>
          </div>
          }
          <Grid container className={classPrice}>
            <Grid item xs={12}>
              <Typography variant="h4">
                {formatCurrency(preco)}
              </Typography>
              <Typography variant="body1">
                por {tempo}
              </Typography>
            </Grid>
          </Grid>
          <List component="nav" className={classes.List}>
            <ListItem className={classes.ListItem}>
              calculo de preço médio
            </ListItem>
            <ListItem className={classes.ListItem}>
              cálculo de IR
            </ListItem>
            <ListItem className={classes.ListItem}>
              carteira atual
            </ListItem>
            <ListItem className={classes.ListItem}>
              histórico de negócios
            </ListItem>
            <ListItem className={classes.ListItem}>
              contabilização de proventos
            </ListItem>
            <ListItem className={classes.ListItem}>
              posição anual
            </ListItem>
            <ListItem className={classes.ListItem}>
              100 inclusões / exclusões por mês
            </ListItem>
            <ListItem className={classes.ListItem}>
              acesso a relatórios e estatísticas
            </ListItem>
          </List>
        </Card>
      </Grid>
    </Grid>
  );
};


export default PlanCards;
