import React   from 'react';
import classes from '../../containers/Report/Statistic.module.css';
import Grid    from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { formatCurrency, formatInteger } from '../utils/formatters';

const ReportDetail = ({modalData}) => {
  
  // console.log('   {modalData}', modalData);
  
  const custoTotal         = (Number(modalData.custoCompra) + Number(modalData.custoVenda)) * modalData.qtPapel;
  const custoTotalOriginal = (Number(modalData.custoCompraOriginal) + Number(modalData.custoVendaOriginal)) * modalData.qtPapel;
  
  return (
    <Grid container spacing={2}>
      <Grid item xs={8}>
        <Typography variant="h6" gutterBottom>
          Papel
        </Typography>
      </Grid>
      <Grid item xs={4} >
        <Typography variant="body1"  gutterBottom>
          {modalData.sigla}
        </Typography>
      </Grid>
      <Grid item xs={8} >
        <Typography variant="h6" gutterBottom>
          Quantidade Transacionada
        </Typography>
      </Grid>
      <Grid item xs={4} >
        <Typography variant="body1"  gutterBottom>
          {formatInteger(modalData.qtPapel)}
        </Typography>
      </Grid>
      <Grid item xs={8}>
        <Typography variant="h6" gutterBottom>
          Valor de Compra médio
        </Typography>
      </Grid>
      <Grid item xs={4} >
        <Typography variant="body1" gutterBottom>
          {formatCurrency(modalData.vlCompra)}
        </Typography>
      </Grid>
      
      <Grid item xs={8} >
        <Typography variant="h6"  gutterBottom>
          Valor de Venda médio
        </Typography>
      </Grid>
      <Grid item xs={4} >
        <Typography variant="body1" gutterBottom>
          {formatCurrency(modalData.vlVenda)}
        </Typography>
      </Grid>
      <Grid item xs={8} >
        <Typography variant="h6"  gutterBottom>
          Custos médios totais
        </Typography>
      </Grid>
      <Grid item xs={4} >
        <Typography variant="body1"  gutterBottom>
          {formatCurrency(custoTotal)}
        </Typography>
      </Grid>
      <Grid item xs={8} >
        <Typography variant="h6" gutterBottom>
          Valor de Compra Original
        </Typography>
      </Grid>
      <Grid item xs={4} >
        <Typography variant="body1" gutterBottom>
          {formatCurrency(modalData.vlCompraOriginal)}
        </Typography>
      </Grid>
      <Grid item xs={8} >
        <Typography variant="h6" gutterBottom>
          Valor de Venda Original
        </Typography>
      </Grid>
      <Grid item xs={4} >
        <Typography variant="body1"  gutterBottom>
          {formatCurrency(modalData.vlVendaOriginal)}
        </Typography>
      </Grid>
      <Grid item xs={8} >
        <Typography variant="h6"  gutterBottom>
          Custos originais totais
        </Typography>
      </Grid>
      <Grid item xs={4} >
        <Typography variant="body1" gutterBottom>
          {formatCurrency(custoTotalOriginal)}
        </Typography>
      </Grid>
      <Grid item xs={8} >
        <Typography variant="h6"gutterBottom>
          Proventos recebidos no período
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="body1" gutterBottom>
          {formatCurrency(modalData.valorProvento)}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ReportDetail;
