import { createSlice }                            from '@reduxjs/toolkit';
import { thunkAPI }                               from '../../components/utils/api';
import { subtractDays }                   from '../../components/utils/date';
import { definePaperType, definePaperVisibility } from '../../components/utils/constants';

export const searchPaper        = thunkAPI('searchPaper/get', 'v1/papel/busca', 'get',);
export const searchAdmPaper     = thunkAPI('searchPaper/get', 'v1/papel/busca/adm', 'get',);
export const getPaperHistory    = thunkAPI('paperHistory/get', '/v1/papel/historico',
  {verb: 'get', getCache: ['paper', 'historyPapers', 'papelId']},);
export const editPaper          = thunkAPI('paper/edit', '/v1/papeis', 'post', {successMessage: 'Papel alterado com sucesso'});
export const getSpotPapers      = thunkAPI('paper-spot/get', 'v1/papeis/vista', 'get',);
export const getFuturePapers    = thunkAPI('paper-future/get', 'v1/papeis/futuro', 'get',);
export const includePaper       = thunkAPI('paper/include', 'v1/papeis', 'post', {successMessage: 'Papel incluído com sucesso'});
export const processFuturePaper = thunkAPI('paper-future/process', '/v1/papeis/opcoes/atualizar', 'post',
  {successMessage: 'Papeis processados com sucesso'});


const includeHistoryPaper = (paper, history) => {
  
  const {lote, sigla, visibilidade, dataInicio, dataFim, id} = paper;
  
  let endDate;
  const register = {
    lotePadrao: lote,
    sigla,
    utilizacao: visibilidade,
  };
  
  if (dataInicio) {
    register.tsIncVgc = dataInicio;
    endDate           = subtractDays(dataInicio, 1).toJSON().substr(0, 10);
  }
  
  if (dataFim) endDate = dataFim;
  
  if (endDate && history[id].length > 0 && !history[id][0].tsFimVgc) history[id][0].tsFimVgc = endDate;
  if (dataInicio) history[id].unshift(register);
  
  return history;
  
};
const formatPapers        = (papers) => {
  
  const formattedPapers = [];
  
  for (const data of papers) {
    
    formattedPapers.push({
      id          : data.cdPapel,
      nome        : data.Empresa.nome,
      sigla       : data.sigla,
      lote        : data.lotePadrao,
      tipo        : data.TipoPapel.descricao,
      visibilidade: definePaperVisibility(data.utilizacao),
      vigente     : data.vigente ? 'sim' : 'não'
    });
  }
  
  return formattedPapers;
  
};
const formatPaperHistory  = (history) => {
  
  const formattedHistory = [];
  // console.log('history', history);
  let id                 = 0;
  for (const data of history) {
    
    formattedHistory.push({
      id,
      sigla       : data.sigla,
      lote        : data.lotePadrao,
      visibilidade: definePaperVisibility(data.utilizacao),
      dataInicio  : data.tsIncVgc,
      dataFim     : data.tsFimVgc,
    });
    id++;
  }
  
  return formattedHistory;
  
};

const slice = createSlice({
  name         : 'paper',
  initialState : {
    papersSearched  : [],
    spotPapers      : [],
    futurePapers    : [],
    paperHistory    : [],
    historyPapers   : {},
    includeSituation: 'idle'
  },
  reducers     : {},
  extraReducers: {
    [editPaper.fulfilled]      : (state, action) => {
      const {id, sigla, lote, tipo, visibilidade, vigente, index} = action.payload;
      let papers;
      
      if (tipo === 'Opção') papers = [...state.futurePapers];
      else papers = [...state.spotPapers];
      
      if (state.historyPapers[id]) {
        state.historyPapers = includeHistoryPaper(action.payload, {...state.historyPapers});
      }
      
      papers[index].sigla        = sigla;
      papers[index].vigente      = vigente ? 'sim' : 'não';
      papers[index].lote         = lote;
      papers[index].visibilidade = definePaperVisibility(visibilidade);
      
      if (tipo === 'Opção') state.futurePapers = papers;
      else state.spotPapers = papers;
      
    },
    [getSpotPapers.fulfilled]  : (state, action) => {
      state.spotPapers = formatPapers(action.payload);
    },
    [getFuturePapers.fulfilled]: (state, action) => {
      state.futurePapers = formatPapers(action.payload);
    },
    [getPaperHistory.pending]  : (state) => {
      state.paperHistory = [];
    },
    [getPaperHistory.fulfilled]: (state, action) => {
      
      if (action.payload.length) {
        const id                = action.payload[0].cdPapel;
        state.historyPapers[id] = action.payload;
      }
      
      state.paperHistory = formatPaperHistory(action.payload);
    },
    [includePaper.pending]     : (state) => {
      state.includeSituation = 'pending';
    },
    [includePaper.fulfilled]   : (state, action) => {
      
      const {id, nome, sigla, lotePadrao, tipoPapel, utilizacao, vigente} = action.payload;
      
      let papers = [...state.spotPapers];
      
      const newPaper = {
        id,
        nome,
        sigla,
        lote        : lotePadrao,
        tipo        : definePaperType(tipoPapel),
        visibilidade: definePaperVisibility(utilizacao),
        vigente     : vigente ? 'sim' : 'não',
      };
      
      papers.unshift(newPaper);
      
      state.spotPapers       = papers;
      state.includeSituation = 'idle';
    },
    [includePaper.rejected]    : (state) => {
      state.includeSituation = 'rejected';
    },
    [searchPaper.fulfilled]    : (state, action) => {
      state.papersSearched = action.payload;
    },
    [searchAdmPaper.fulfilled] : (state, action) => {
      state.papersSearched = action.payload;
    },
  }
});

const {reducer} = slice;


export default reducer;
