import { createSlice }        from '@reduxjs/toolkit';
import { thunkAPI }           from '../../components/utils/api';



export const excludeTransfer    = thunkAPI('transference/exclude', 'v1/posse/transferencia', 'delete',
  {successMessage: 'Transferência excluída com sucesso'});
export const getTransfers       = thunkAPI('transference/get', 'v1/posse/transferencia', 'get',);
export const includeAdmTransfer = thunkAPI('transference-adm/include', 'v1/posse/transferencia/adm', 'post',
  {successMessage: 'Transferência processada com sucesso'});
export const includeTransfer    = thunkAPI('transference/include', 'v1/posse/transferencia', 'post',
  {successMessage: 'Transferência Incluída com sucesso'});
export const includeLotTransfer    = thunkAPI('transference-lot/include', 'v1/posse/transferencia/lote', 'post',
  {successMessage: 'Transferência(s) Incluída(s) com sucesso'});


const formatTransfers = (transfers) => {
  
  const formattedTransfers = [];
  
  let i = 0;
  for (const data of transfers) {
    formattedTransfers.push({
      id                : i,
      papel             : data.Papel.sigla,
      papelId           : data.Papel.cdPapel,
      quantidade        : data.qtPapel,
      corretoraIdDestino: data.CorretoraDestino.corretoraId,
      corretoraDestino  : data.CorretoraDestino.abreviatura,
      corretoraIdOrigem : data.CorretoraOrigem.corretoraId,
      corretoraOrigem   : data.CorretoraOrigem.abreviatura,
      dataTransferencia : data.dtTransferencia,
      
    });
    i++;
  }
  
  return formattedTransfers;
  
};


const transferenceSlice = createSlice({
  name         : 'transference',
  initialState : {
    transfers       : [],
    includeSituation: 'idle'
  },
  reducers     : {},
  extraReducers: {
    [getTransfers.fulfilled]   : (state, action) => {
      state.transfers = formatTransfers(action.payload);
    },
    [excludeTransfer.fulfilled]: (state, action) => {
      let transfers = [...state.transfers];
      transfers.splice(action.payload.index, 1);
      state.transfers = transfers;
    },
    [includeTransfer.fulfilled]: (state, action) => {
      let transfers = [...state.transfers];
      
      const {quantidade, papel, corretoraDestino, corretoraOrigem, dataTransferencia} = action.payload;
      
      const id = transfers.length;
      
      const newTransfer = {
        id                : id,
        papel             : papel.nome,
        papelId           : papel.id,
        quantidade        : quantidade,
        corretoraIdDestino: corretoraDestino.id,
        corretoraDestino  : corretoraDestino.nome,
        corretoraIdOrigem : corretoraOrigem.id,
        corretoraOrigem   : corretoraOrigem.nome,
        dataTransferencia : dataTransferencia,
      };
      
      transfers.unshift(newTransfer);
      
      state.transfers        = transfers;
      state.includeSituation = 'idle';
    },
    [includeLotTransfer.fulfilled]: (state, action) => {
      let transfers      = [...state.transfers];
    
      const { transferenciasSaida} = action.payload;
  
      let id = transfers.length;
    
      for (let index = 0; index < transferenciasSaida.length; index++) {
      
        const transferencia = transferenciasSaida[index];
      
        const newTransfer = {
          id           ,
          papel             : transferencia.papel.nome,
          papelId           : transferencia.papel.id,
          quantidade        : transferencia.quantidade,
          corretoraIdDestino: transferencia.corretoraDestino.id,
          corretoraDestino  : transferencia.corretoraDestino.nome,
          corretoraIdOrigem : transferencia.corretoraOrigem.id,
          corretoraOrigem   : transferencia.corretoraOrigem.nome,
          dataTransferencia : transferencia.dataTransferencia,
        };
        id++;
        transfers.unshift(newTransfer);
      }
    
      state.transfers         = transfers;
      state.includeSituation = 'idle';
    },
    [includeLotTransfer.pending]  : (state) => {
      state.includeSituation = 'pending';
    },
    [includeLotTransfer.rejected] : (state) => {
      state.includeSituation = 'rejected';
    },
    [includeTransfer.pending]  : (state) => {
      state.includeSituation = 'pending';
    },
    [includeTransfer.rejected] : (state) => {
      state.includeSituation = 'rejected';
    }
  }
});

const {reducer} = transferenceSlice;


export default reducer;
