import React, { useEffect, useState } from 'react';


import Cards   from 'react-credit-cards';
import myCss   from 'react-credit-cards/es/styles-compiled.css';
import Grid    from '@mui/material/Grid';
import classes from '../containerActions/IncludeBusiness.module.css';

import { handleError, rules } from '../utils/rules';

import FormMaskText from './FormMaskText';

import { creditCardAmexCVCFormat, creditCardCVCFormat, creditCardDateFormat, creditCardFormat } from '../utils/constants';

import { useForm } from 'react-hook-form';
import InputText   from './FormText';
import PersonIcon  from '@mui/icons-material/Person';
import Button              from './Button';


const defaultValues = {
  cvc   : '',
  expiry: '',
  focus : '',
  name  : '',
  number: '',
};

const InputCreditCard = ({paySituation, action}) => {
  
  const [focus, setFocus] = useState('');
  
  const {control, handleSubmit, watch, reset, formState} =
          useForm({mode: 'all', defaultValues});
  
  const {isValid, errors} = formState;
  
  const disabled = (!isValid || paySituation === 'pending');
  
  const handleInputFocus = (e) => {
    const myFocus = e.target.name;
    setFocus(myFocus);
  };
  
  const onSubmit = (data) => {
    action(data)
  };
  
  useEffect(() => {
    
    if (paySituation === 'idle') reset(defaultValues);
    
  }, [paySituation]);
  
  const watchCardNumber = watch('number');
  const watchCardName   = watch('name');
  const watchCardCvc    = watch('cvc');
  const watchCardExpiry = watch('expiry');
  
  let cvcFormat = creditCardCVCFormat
  
  const brand = watchCardNumber.substr(0,2)
  if (brand === '34' || brand === '37') {
    cvcFormat = creditCardAmexCVCFormat
  }
 
  return (
    
    <div id="PaymentForm" className={myCss}>
      <Grid container>
        <Grid item xs={12}>
          <Cards cvc={watchCardCvc} expiry={watchCardExpiry} name={watchCardName} number={watchCardNumber}
                   locale={{valid: 'vencimento'}} focused={focus}  placeholders={{name: 'SEU NOME'}}
                  />
        </Grid>
        <form id="creditCard" onSubmit={handleSubmit(onSubmit)} className={classes.Form}>
          <Grid container spacing={2}>
            <Grid item xs={12} className={classes.Item}>
              <FormMaskText label="Número Cartão" name="number" defaultValue={defaultValues['number']}
                            errorMessage={handleError(errors, 'number')} control={control} onFocus={handleInputFocus}
                            rules={rules('cardNumber', )} formatOptions={creditCardFormat}
              />
            </Grid>
            <Grid item xs={12} className={classes.Item}>
              <InputText label="Nome no Cartão" name="name" Icon={PersonIcon} errorMessage={handleError(errors, 'name')}
                         control={control} rules={rules('name', )} onFocus={handleInputFocus}
              />
            </Grid>
            <Grid item xs={6} className={classes.Item}>
              <FormMaskText label="Vencimento" name="expiry" defaultValue={defaultValues['expiry']} onFocus={handleInputFocus}
                            errorMessage={handleError(errors, 'expiry')} control={control}
                            rules={rules('expiry', )} formatOptions={creditCardDateFormat}
              />
            </Grid>
            <Grid item xs={6} className={classes.Item}>
              <FormMaskText label="CVC" name="cvc" defaultValue={defaultValues['cvc']}
                            errorMessage={handleError(errors, 'cvc')} control={control} onFocus={handleInputFocus}
                            rules={rules('cvc', )} formatOptions={cvcFormat}
              />
            </Grid>
            <Grid item xs={12} className={classes.Button}>
              <Button color="secondary" type="submit" disabled={disabled}>
                {paySituation === 'pending' ? 'Pagando' : 'Pagar'}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </div>
  
  );
};


export default InputCreditCard;
