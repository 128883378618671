import React from 'react';


import { useDispatch, useSelector } from 'react-redux';

import Button                       from '../elements/Button';
import { captureMarketValues }             from '../../store/slices/marketValue';


const CaptureMarketValue = () => {
  
  const dispatch = useDispatch();
  
  const {includeSituation} = useSelector(state => state.marketValue);
  
  const submit = () => dispatch(captureMarketValues());
  
  const disabled = includeSituation === 'pending';
  
  return (
    <Button color="secondary" disabled={disabled} onClick={submit}>
      Capturar
    </Button>
  );
};

export default CaptureMarketValue;
