import React, { useState } from 'react';

import Typography from '@mui/material/Typography';
import Button     from '../elements/Button';

import Logo         from './partials/Logo';
import classes      from './ExternalHeader.module.css';
import Login        from '../../containers/Auth/Login';
import Signup       from '../../containers/Auth/Signup';
import HeaderBar    from './partials/HeaderBar';
import Grid         from '@mui/material/Grid';
import InvertedLogo from './partials/InvertedLogo';


const ExternalHeader = ({className, ...props}) => {
  
  const [anchorEl, setAnchorEl]         = React.useState(null);
  const [signupActive, setSignupActive] = useState(false);
  
  const handleClick  = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose  = () => {
    setAnchorEl(null);
  };
  const handleSignup = (e) => {
    e.preventDefault();
    setSignupActive(true);
  };
  const closeSignup  = (e) => {
    e.preventDefault();
    setSignupActive(false);
  };
  
  const open = Boolean(anchorEl);
  const id   = open ? 'simple-popover' : undefined;
  
  return (
    <HeaderBar {...props}>
      <div className={classes.Container}>
      <div  className={classes.ContainerLogo}>
        <Logo className={classes.Logo}/>
      </div>
        <div className={classes.Teste1}>
          <Typography variant="h6" className={classes.Title}>
            Kiila
          </Typography>
        </div>
      </div>
      
      <Button color="secondary" onClick={handleClick}>Entrar</Button>
      <Button color="primary" onClick={handleSignup}>
        Registrar
      </Button>
      <Login id={id} open={open} anchorEl={anchorEl} onClose={handleClose}/>
      <Signup open={signupActive} onClose={closeSignup}/>
    </HeaderBar>
  );
  
};


export default ExternalHeader;
