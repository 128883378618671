import { formatCpfCnpj } from './formatters';
import env               from '../../environment/index';

export const profiles = {
  ADMIN: 'admin',
  USER : 'user'
};

export const paperVisibility  = [
  {name: 'pública', value: 1},
  {name: 'sistema', value: 2},
];
export const paperType        = [
  {name: 'Ação', value: 1},
  {name: 'Fundo Imobiliário', value: 2},
  {name: 'ETF', value: 3},
  {name: 'Opção', value: 4},
  {name: 'Fundo Agro', value: 5},
  {name: 'Fundo Infra', value: 6},
];
export const paperPart        = [
  {name: '1', value: 1},
  {name: '100', value: 100},
];
export const rearrangeType    = [
  {name: 'Grupamento', value: 1},
  {name: 'Desdobramento', value: 2},
  {name: 'Bonificação', value: 3},
];
export const underwritingType = [
  {name: 'Subscrição', value: 1},
  {name: 'IPO', value: 2},
];
export const cnpjFormat       = {
  numericOnly: true,
  delimiters : ['.', '.', '-', '/'],
  blocks     : [2, 3, 3, 4, 2],
};
export const cpfFormat        = {
  numericOnly: true,
  blocks     : [3, 3, 3, 2],
  delimiters : ['.', '.', '-']
};
// export const integerFormat    = {
//   numeral            : true,
//   numeralDecimalMark : ',',
//   delimiter          : '.',
//   numeralDecimalScale: 0,
//   numeralIntegerScale: 10,
//   numeralPositiveOnly: true
// };
// export const decimalFormat    = {
//   numeral            : true,
//   numeralDecimalMark : ',',
//   delimiter          : '.',
//   numeralDecimalScale: 12,
//   numeralIntegerScale: 10,
//   numeralPositiveOnly: true
// };


const maxInteger = ({value}) => value < 10000000000;

export const integerFormat = {
  validator: maxInteger,
  scale    : 0
};
export const decimalFormat = {
  validator: maxInteger,
  scale    : 12
};

export const currencyFormat = {
  validator: maxInteger,
  scale    : 2
};

export const creditCardFormat = {
  formatter: '#### #### #### ####'
};

export const creditCardDateFormat = {
  formatter: '##/##'
};

export const creditCardCVCFormat     = {
  formatter: '###'
};
export const creditCardAmexCVCFormat = {
  formatter: '####'
};


export const businessList = {
  COMPRA: 1,
  VENDA : 2,
  IPO   : 3,
  OPA   : 4,
  SUB   : 7,
};

export const paperTypesList = {
  ACAO : 1,
  FII  : 2,
  ETF  : 3,
  OPCAO: 4,
  AGRO : 5,
  INFRA: 6,
};

export const docIdRule = {
  scale    : 0,
  formatter: formatCpfCnpj
};

export const definePaperVisibility = (value) => {
  
  switch (value) {
    case 1:
      return 'pública';
    default:
      return 'sistema';
  }
};
export const defineOperationType   = (value, daytrade) => {
  
  switch (value) {
    case 1:
      if (daytrade) return 'Ação/DT';
      return 'Ação/ST';
    case 2:
      if (daytrade) return 'FII/DT';
      return 'FII/ST';
    case 3:
      if (daytrade) return 'ETF/DT';
      return 'ETF/ST';
    case 4:
      if (daytrade) return 'Opção/DT';
      return 'Opção/ST';
    case 5:
      if (daytrade) return 'Agro/DT';
      return 'Agro/ST';
    case 6:
      if (daytrade) return 'Infra/DT';
      return 'Infra/ST';
    default:
      return 'Desconhecido';
  }
};
export const definePaperType       = (value) => {
  
  switch (value) {
    case 1:
      return 'Ação';
    case 2:
      return 'Fundo Imobiliário';
    case 3:
      return 'ETF';
    case 4:
      return 'Opção';
    case 5:
      return 'Fundo Agro';
    case 6:
      return 'Fundo Infra';
    default:
      return 'Desconhecido';
  }
};

export const defineBusinessType     = (value) => {
  
  switch (value) {
    case 1:
      return 'Compra';
    default:
      return 'Venda';
  }
};
export const defineUnderwritingType = (value) => {
  
  switch (value) {
    case 1:
      return 'Subscrição';
    default:
      return 'IPO';
  }
};
export const defineRearrangeType    = (value) => {
  
  switch (value) {
    case 1:
      return 'Grupamento';
    case 2:
      return 'Desdobramento';
    default:
      return 'Bonificação';
  }
};

