import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';
import { ReactComponent as Icon } from './calculate.svg'
const calculate = ({className, ...props}) => {
  
  
  return (
    <SvgIcon {...props}>
      <Icon/>
    </SvgIcon>
  );
};


export default calculate;
