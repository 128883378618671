import { createSlice } from '@reduxjs/toolkit';
import { thunkAPI }    from '../../components/utils/api';


export const getEarningEvent = thunkAPI('earningEvent/get', '/v1/evento/provento/processar', 'get');

export const processEarningEvent = thunkAPI('earningEvent/process', '/v1/evento/provento/processar', 'post',
  {successMessage: 'Eventos processados com sucesso'});


const formatEarningEvents = (events) => {
  
  const formattedEvents = [];
 
  for (const event of events) {
    
    formattedEvents.push({
      id            : event.seqlEventoProcessar,
      papel         : event.Papel.sigla,
      nrConta       : event.nrConta,
      dtInicioEvento: event.dtInicioEvento,
      dtFimEvento   : event.dtFimEvento,
      criacao       : event.criacao,
    });
    
  }
  
  return formattedEvents;
  
};

const slice = createSlice({
  name         : 'earningEvent',
  initialState : {
    earnings        : [],
    processSituation: 'idle',
  },
  reducers     : {},
  extraReducers: {
    [getEarningEvent.fulfilled]    : (state, action) => {
      state.earnings = formatEarningEvents(action.payload);
    },
    [processEarningEvent.fulfilled]: (state) => {
      state.processSituation = 'idle';
    },
    [processEarningEvent.pending]  : (state) => {
      state.processSituation = 'pending';
    },
    [processEarningEvent.rejected] : (state) => {
      state.processSituation = 'rejected';
    },
  }
});

const {reducer} = slice;

export default reducer;
