import React, {forwardRef} from 'react';


import OutlinedInput  from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel     from '@mui/material/InputLabel';
import FormControl    from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';

import classes           from './Input.module.css';
import { Controller }    from 'react-hook-form';
import NumberFormat      from 'react-number-format';

const MaskedTextField = forwardRef((props, ref) => {
  
  let {onChange, formatOptions, ...other} = props;
  
  // console.log("formatOptions", formatOptions)
  
  return <NumberFormat {...other} getInputRef={ref} 
                       decimalSeparator={','} thousandSeparator={'.'} allowNegative={false} onValueChange={({floatValue}) => onChange(floatValue)}
                       decimalScale={formatOptions.scale} isAllowed={formatOptions.validator} format={formatOptions.formatter}  
  />;
  
});

const FormMask = ({name, label, Icon, control, rules, formatOptions, errorMessage, ...props}) => {
  
  return (
    <FormControl aria-label={name} variant="outlined" fullWidth className={classes.Field}>
      <InputLabel error={!!errorMessage} htmlFor={name}>{label}</InputLabel>
      <Controller name={name} control={control} rules={rules}
                  render={({field}) => (
                    <OutlinedInput id={name} label={label} error={!!errorMessage} {...props}
                                   inputProps={{formatOptions, ...field}}
                                   inputComponent={MaskedTextField}
                                   endAdornment={
                                     <InputAdornment position="end">
                                       <Icon/>
                                     </InputAdornment>
                                   }
                    />
                  )}
      />
      {!!errorMessage && <FormHelperText error margin="dense">{errorMessage}</FormHelperText>}
    </FormControl>
  
  );
};


export default FormMask;
