import React         from 'react';

import classes   from './Login.module.css';
import InputText from '../../components/elements/FormText';
import MailIcon  from '@mui/icons-material/Email';
import Grid          from '@mui/material/Grid';
import Button        from '../../components/elements/Button';
import { useForm }  from 'react-hook-form';
import FormPassword from '../../components/elements/FormPassword';
import Typography   from '@mui/material/Typography';
import {useDispatch} from 'react-redux'
import {login}       from '../../store/slices/auth'
import { handleError, rules } from '../../components/utils/rules';


const defaultValues = {
  email          : '',
  password: ''
};

const Signin = () => {
  
  const dispatch = useDispatch()
  
  const {control, handleSubmit, formState} = useForm({mode: 'all', defaultValues});
  
  const {isValid, errors} = formState;
  
  
  // const disabled = !values.email || !values.password;
  const disabled = !isValid;
  
  const onSubmit = (data) => {
    // console.log('passou aqui');
    // event.target.reset(); // reset the form after submission
    // console.log(data); // handle the data here
    dispatch(login(data))
  };
  
  return (
    
        <form id="login" onSubmit={handleSubmit(onSubmit)}>
          <Grid item xs={12}>
            <Typography variant="body1" className={classes.Title}>
              Informe os dados de Login
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <InputText label="Email" name="email" Icon={MailIcon}  errorMessage={handleError(errors, 'email')}
                       control={control} rules={rules('email', )}
            />
          </Grid>
          <Grid item xs={12}>
            <FormPassword label="Senha" name="password" errorMessage={handleError(errors, 'password')}
                          control={control} rules={rules('password', )}
            />
          </Grid>
          <Grid item xs={12} className={classes.Button}>
            <Button color="secondary" disabled={disabled} type="submit">Entrar</Button>
          </Grid>
        </form>
  
  );
};


export default Signin;
