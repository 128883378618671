import { createSlice }                                        from '@reduxjs/toolkit';


const slice = createSlice({
  name         : 'api',
  initialState : {
    havePlan           : true,
  },
  reducers     : {
    changePlanSituation: (state, action) => {
      // console.log("serverApi ", serverApi )
      // localStorage.removeItem('token');
      // user                  = {};
      // state.user            = user;
      state.havePlan = action.payload;
    },
    expired: (state) => {}
  },

});


const {reducer, actions} = slice;

export const {changePlanSituation, expired} = actions;

export default reducer;
