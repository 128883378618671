import React   from 'react';
import classes from './Image.module.css';

const Image = ({className, src, width, height, alt, ...props}) => {
  
  
  const classImage = [classes.Image, className].join(' ');
  
  return (
    
    <img className={classImage} {...props} src={src} width={width}
         height={height} alt={alt}/>
  
  );
};


export default Image;
