import React                 from 'react';
import Container             from '@mui/material/Container';
import Grid                  from '@mui/material/Grid';
import classes               from './AboutUs.module.css';
import { motion }            from 'framer-motion';
import Typography            from '@mui/material/Typography';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import IconButton            from '@mui/material/IconButton';
import Tooltip               from '@mui/material/Tooltip';
import { useNavigate }        from 'react-router-dom';


const AboutUs = () => {
  
  const navigate  = useNavigate();
  
  return (
    <Container className={classes.Box}>
      <Grid container justifyContent="center" className={classes.Root} spacing={2}>
        <Grid item xs={12}>
          <motion.div
            className={classes.TitleContainer}
            initial={{y: 50, opacity: 0}}
            animate={{y: 0, opacity: 1}}
            transition={{duration: 1}}
          >
            <Typography variant="h1" className={classes.Title}>
              Nossa <span className={classes.PrimaryColor}>História</span>
            </Typography>
          </motion.div>
        </Grid>
        <Grid item xs={12}>
          <motion.div initial={{y: 50, opacity: 0.2}}
                      animate={{y: 0, opacity: 1}} transition={{duration: 0.7, delay: 0.2}}>
            <Typography variant="h6" className={classes.Text}>
              Nascemos de uma necessidade pessoal de controle dos investimentos, a partir de uma planilha Excel. Em 2016 buscando uma maior automação,
              o Kiila começou a ser desenvolvido. Hoje com mais de 300 testes automatizados, temos a capacidade de incluir um negócio em qualquer ordem
              cronológica, recalculando as informações que tiverem que ser alteradas devido a essa ordem invertida.
            </Typography>
          </motion.div>
        </Grid>
        <Grid item xs={12} className={classes.Button}>
          <Tooltip title={'Voltar'} placement={'top'}>
            <IconButton aria-label="back" onClick={() => navigate('/')}>
              <KeyboardBackspaceIcon/>
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    </Container>
  );
};

export default AboutUs;
