import React, { useEffect, useMemo, useState } from 'react';

import Grid            from '@mui/material/Grid';
import Table           from '../../components/elements/Table';
import { useDispatch } from 'react-redux';
import confirmPopover           from '../../components/partials/ConfirmPopover';
import { resetAllUsersConsume } from '../../store/slices/others';



const AdminActions = () => {
  
  const dispatch        = useDispatch();
  const [rows, setRows] = useState([]);
  
  const confirmAction = (rowData) => dispatch(rowData.original.acao());
  
  useEffect(() => {
    
    setRows([
      {id: 1, nome: 'Reiniciar consumo do mês - TODOS usuários', acao: resetAllUsersConsume}
    ]);
    
    
  }, []);
  
  const columns = useMemo(
    () =>
      [
        {
          accessor: 'nome',
          Header  :   'Descrição',
        },
        {
          accessor: 'acao',
          Header  :   'Executar',
          Cell: confirmPopover(confirmAction, 'ação')
        },
        
      ],
    []
  );
  
  return (
    <Grid container>
      <Grid item xs={12}>
        {!!columns.length && <Table columns={columns} rowsData={rows} hideFilter={true}/>}
      </Grid>
    </Grid>
  );
};

export default AdminActions;
