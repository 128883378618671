import React, { useMemo }  from 'react';
import Grid                from '@mui/material/Grid';
import Table               from '../../components/elements/Table';
import { useSelector }     from 'react-redux';
import IncludeAmortization from '../../components/containerActions/IncludeAmortization';
import classes             from './Amortization.module.css';

import { formatCurrency, formatDate, formatInteger }  from '../../components/utils/formatters';
import RedeemEarning                                  from '../../components/containerActions/RedeemEarning';
import { getPaperAmortizations, processAmortization } from '../../store/slices/amortization';
import ParameterHeader                                from '../../components/sections/ParameterHeader';
import ProcessJob                                     from '../../components/partials/ProcessJob';

const Amortization = () => {
  
  const {paperAmortizations} = useSelector(state => state.amortization);
  
  const columns = useMemo(
    () =>
      [
        {
          accessor: 'dataDireito',
          Header  : 'Data do Direito',
          Cell    : ({value}) => formatDate(value)
        }, {
        accessor: 'sequencial',
        Header  : 'Sequencial do Direito',
        Cell    : ({value}) => formatInteger(value)
      },
        {
          accessor: 'dataCredito',
          Header  : 'Data da Amortização',
          Cell    : ({value}) => formatDate(value)
        },
        {
          accessor: 'valorAmortizacao',
          Header  : 'Valor da Amortização',
          Cell    : ({value}) => formatCurrency(value)
        },
        {
          accessor: 'vigente',
          Header  : 'Vigente',
        },
      ],
    []
  );
  
  return (
    <Grid container>
      <Grid item xs={12} className={classes.Header}>
        <ParameterHeader Redeem={RedeemEarning} Process={ProcessJob} slice={'amortization'} procFunc={processAmortization}
                         getFunc={getPaperAmortizations}/>
      </Grid>
      <Grid item xs={12}>
        <Table columns={columns} rowsData={paperAmortizations} inclusion={<IncludeAmortization/>}/>
      </Grid>
    </Grid>
  );
};

export default Amortization;
