import { createSlice }      from '@reduxjs/toolkit';
import { thunkAPI }         from '../../components/utils/api';


export const includeManifestation = thunkAPI('contact/include', '/v1/contato/visitante', 'post', {successMessage: 'Mensagem enviada com sucesso'});
export const includeUserManifestation = thunkAPI('contact/include', '/v1/contato/usuario', 'post', {successMessage: 'Mensagem enviada com sucesso'});


const slice = createSlice({
  name         : 'contact',
  initialState : {
    includeSituation: 'idle',
  },
  reducers     : {},
  extraReducers: {
    [includeManifestation.fulfilled]: (state) => {
      state.includeSituation = 'idle';
    },
    [includeManifestation.pending]  : (state) => {
      state.includeSituation = 'pending';
    },
    [includeManifestation.rejected] : (state) => {
      state.includeSituation = 'rejected';
    },
    [includeUserManifestation.fulfilled]: (state) => {
      state.includeSituation = 'idle';
    },
    [includeUserManifestation.pending]  : (state) => {
      state.includeSituation = 'pending';
    },
    [includeUserManifestation.rejected] : (state) => {
      state.includeSituation = 'rejected';
    },
  }
});

const {reducer} = slice;

export default reducer;
