import React from 'react';


import FormHelperText from '@mui/material/FormHelperText';

import classes          from './Input.module.css';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox        from '@mui/material/Checkbox';
import { Controller }   from 'react-hook-form';

const FormCheckbox = ({control, label, name, errorMessage, rules, ...others}) => {
  
  
  return (
    <>
      <FormControlLabel className={classes.Field} label={label}
        control={
          <Controller
            name={name}
            control={control}
            rules={rules}
            render={({field}) => (
              <Checkbox  color="primary" {...others}
                onChange={(e) => field.onChange(e.target.checked)}
                checked={field.value}
              />
            )}
          />
        }
        
      />
      {!!errorMessage && <FormHelperText error margin="dense">{errorMessage}</FormHelperText>}
    </>
  );
};


export default FormCheckbox;
