import React, { useEffect, useState } from 'react';
import Dialog       from '../../components/elements/Dialog';
import ReportDetail from '../../components/detailComponents/ReportDetail';
import Grid         from '@mui/material/Grid';
import Box                          from '../../components/elements/Box';
import classes                      from './Statistic.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { getAllAchievements }         from '../../store/slices/achievement';
import ReportCard                     from '../../components/elements/ReportCard';
import Typography                     from '@mui/material/Typography';



const titles = [
  'Estatística',
  'Dados Gerais',
  'Dados Gerais com proventos',
  'Últimos 12 meses',
  'Últimos 12 meses com proventos'
];

const template = (report, name, i, action) =>
  <Grid item xs={12} md={4} className={classes.Card} key={name} onClick={() => action(report.realizacao)}>
    <ReportCard title={report.title} subtitle={report.subtitle} rightSubtitle={report.rightSubtitle}
                value={report.valor} principal={report.principal} className={i % 2 === 1 ? classes.CardColor : null}
                paper={report.papel} period={report.periodo}
                appreciation={report.valorizacao}
                averageAppreciation={report.valorizacaoMedia}
                averageTicket={report.ticketMedio}
                monthAppreciation={report.valorizacaoMensal}
                initialDate={report.realizacao.dtCompra}
                finalDate={report.realizacao.dtVenda}/>
  </Grid>;


const Statistic = () => {
  
  const [modalOn, setModalOn]     = useState(false);
  const [modalData, setModalData] = useState({});
  const dispatch                  = useDispatch();
  const {achievementsReport}      = useSelector(state => state.achievement);
  let boxes    = [];
  
  useEffect(() => {
    dispatch(getAllAchievements());
  }, []);
  
  
  let modal;
  let j = 0;
  
  const openModal = (modalData) => {
    setModalOn(true);
    setModalData(modalData);
  };
  
  
  const closeModal = () => {
    setModalOn(false);
  };
  
  if (!modal)
    modal = <Dialog id="report-modal" show={modalOn} handleClose={closeModal} title={'Detalhe do Card'}>
      <ReportDetail modalData={modalData}/>
    </Dialog>;
  
  
  if (Object.entries(achievementsReport).length > 0 && !boxes.length) {
    
    
    let i = 0;
    for (const data of Object.values(achievementsReport)) {
      const reports = [];
      for (const [name, report] of Object.entries(data)) {
        if (report.realizacao) {
          reports.push(template(report, name, i, openModal));
          i++;
        }
        
      }
      if (!!data && !!data.absolutoMedio.valor)
        boxes.push(<Box className={classes.Box} title={titles[j]} titleProps={classes.BoxTitle} key={`box${j}`}>
          <Grid item xs={12}>
            <Grid container spacing={2} justifyContent={'center'}>
              {reports}
            </Grid>
          </Grid>
        </Box>);
      i = 0;
      j++;
    }
    
  }
  
  
  return (
    <>
      {modal}
      <Grid container>
        {boxes}
        {!boxes.length &&
        <Box>
          <Grid item xs={12} className={classes.NegativeBox}>
            <Typography variant={'h6'} className={classes.NegativeText}>
              Você ainda não possui estatísticas
            </Typography>
          </Grid>
        </Box>
        }
      </Grid>
    </>
  );
};

export default Statistic;
