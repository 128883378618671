import React, {useMemo, useState } from 'react';
import { useDropzone }             from 'react-dropzone';
import classes from './FileDrop.module.css'

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out'
};
const activeStyle = {
  borderColor: '#2196f3'
};
const acceptStyle = {
  borderColor: '#00e676'
};
const rejectStyle = {
  borderColor: '#ff1744'
};


const convertMessage = (code) => {
  
  switch (code) {
    case "file-too-large":
      return 'Tamanho do arquivo maior do que o permitido'
    case "file-too-small":
      return 'Tamanho do arquivo menor do que o mínimo'
    case "too-many-files":
      return 'Número máximo de arquivos atingido'
    case "file-invalid-type":
      return 'Tipo de arquivo não permitido'
    default:
      return 'Erro desconhecido'
  }
  
}

const FileDrop = ({files, setFiles, preview}) => {
  

  const {fileRejections, getRootProps, getInputProps,  isDragActive,
          isDragAccept,
          isDragReject} = useDropzone({
    accept: 'application/pdf',
    maxFiles:1,
    onDrop: acceptedFiles => {
      setFiles(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      })));
    }
  });
  
  const style = useMemo(() => ({
    ...baseStyle,
    ...(isDragActive ? activeStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isDragActive,
    isDragReject,
    isDragAccept
  ]);
  
  const thumbs = files.map(file => (
    <div className={classes.Visualizer} key={file.name}>
      <div className={classes.Internal}>
        <embed src={file.preview} width="500" height="500"
               type="application/pdf"/>
      </div>
    </div>
  ));
  
  const fileRejectionItems = fileRejections.map(({ file, errors  }) => {
    console.log("errors", errors)
    return (
      <li key={file.path}>
        {file.path} - {file.size} bytes
        <ul>
          {errors.map(e => <li key={e.code}>{convertMessage(e.code)}</li>)}
        </ul>
      
      </li>
    )
  });
  

  
  return (
    <>
      <div {...getRootProps({style})}>
        <input {...getInputProps()} />
        <p>Arraste e solte o arquivo, ou clique aqui para Selecionar</p>
      </div>
      <aside className={classes.Container}>
        {preview && thumbs}
        {!!fileRejectionItems.length &&
          <div>
          <h4>Arquivos Recusados</h4>
          <ul>{fileRejectionItems}</ul>
        </div>
        }
      </aside>
      </>
  )
}

export default FileDrop
