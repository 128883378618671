import { createSlice }     from '@reduxjs/toolkit';
import { thunkAPI }        from '../../components/utils/api';


export const getMonthTax     = thunkAPI('monthTax/get', 'v1/imposto/ano', 'get',);
export const getYearPosition = thunkAPI('yearPosition/get', 'v1/posse/ano', 'get',);
export const includePaidTax  = thunkAPI('paidTax/include', 'v1/imposto/recolhido', 'post',);


const formatMonthTax     = (monthTax) => {
  
  const formattedYearMovement = [];
  
  
  let i = 0;
  for (const data of monthTax) {
    
    
    let tipoPapel        = data.TipoPapel.descricao;
    const tipoOperacao   = data.TipoOperacao.descricao;
    const isento         = data.inImpostoIsento;
    const vlPrejuizo     = data.vlPrejuizo;
    const tipoOperacaoId = data.cdTipoOperacao;
    const tipoPapelId    = data.cdTipoPapel;
    
    
    let vlImpostoRetido = 0;
    let vlImpostoPago   = 0;
    
    if (data.ImpostoRecolhido) {
      vlImpostoRetido = data.ImpostoRecolhido.vlImpostoRetido;
      vlImpostoPago   = data.ImpostoRecolhido.vlImpostoRecolhido;
    }
    
    
    formattedYearMovement.push({
      id                : i,
      mes               : data.mesNegocio,
      tipoOperacao,
      tipoOperacaoId,
      vlImpostoCalculado: data.vlImpostoCalculado,
      vlImpostoRecolher : data.vlImpostoRecolher,
      vlImpostoRetido,
      vlImpostoPago,
      vlLucro           : data.vlLucro,
      vlTransacionado   : data.vlTransacionado,
      isento,
      tipoPapel,
      tipoPapelId,
      vlPrejuizo,
    });
    i++;
    
  }
  
  return formattedYearMovement;
  
};
const formatYearPosition = (yearPosition) => {
  
  const formattedYearPosition = [];
  
  // console.log('yearPosition', yearPosition);
  
  let i = -1;
  for (const data of yearPosition) {
    
    const valorUnitario  = Number(data.vlNegocio) + Number(data.custoNegocio);
    const valorTotal     = valorUnitario * data.qtPapel;
    const siglaCorretora = data.Corretora.abreviatura;
    const corretora      = {
      id        : `${i + 1}c${siglaCorretora}`,
      corretora : siglaCorretora,
      quantidade: data.qtPapel,
      valorTotal
    };
    if (i > -1 && data.Papel.sigla === formattedYearPosition[i].papel) {
      formattedYearPosition[i].quantidade += data.qtPapel;
      formattedYearPosition[i].valorTotal += valorTotal;
      formattedYearPosition[i].corretoras.push(corretora);
    } else {
      i++;
      
      formattedYearPosition.push({
        id           : i,
        papel        : data.Papel.sigla,
        cnpj         : data.Papel.Empresa.cnpj,
        nome         : data.Papel.Empresa.nome,
        corretoras   : [corretora],
        quantidade   : data.qtPapel,
        valorUnitario: valorUnitario,
        valorTotal
      });
      
    }
    
  }
  
  return formattedYearPosition;
  
};

const taxSlice = createSlice({
  name         : 'tax',
  initialState : {
    monthTax        : [],
    yearPosition    : [],
    includeSituation: 'idle'
  },
  reducers     : {},
  extraReducers: {
    [includePaidTax.fulfilled] : (state, action) => {
      
      const {index, vlImpostoRetido, vlImpostoPago} = action.payload;
      const monthTax                                = [...state.monthTax];
      
      monthTax[index].vlImpostoRetido = vlImpostoRetido;
      monthTax[index].vlImpostoPago   = vlImpostoPago;
      
      state.monthTax = monthTax;
      
      state.includeSituation = 'idle';
    },
    [includePaidTax.pending]   : (state) => {
      state.includeSituation = 'pending';
    },
    [includePaidTax.rejected]  : (state) => {
      state.includeSituation = 'rejected';
    },
    [getMonthTax.fulfilled]    : (state, action) => {
      state.monthTax = formatMonthTax(action.payload);
    },
    [getYearPosition.fulfilled]: (state, action) => {
      state.yearPosition = formatYearPosition(action.payload);
    },
    
  }
});

const {reducer} = taxSlice;


export default reducer;
