import React, { useEffect, useMemo }                from 'react';
import Table                                        from '../../components/elements/Table';
import { useDispatch, useSelector }                 from 'react-redux';
import { getMyScissions }                           from '../../store/slices/scission';
import { formatDate, formatDecimal,  } from '../../components/utils/formatters';
import Grid                                         from '@mui/material/Grid';
import Box                                          from '../../components/elements/Box';
import classes                                      from '../Wallet/Wallet.module.css';
import Typography                                   from '@mui/material/Typography';


const MyScission = () => {
  
  
  const dispatch      = useDispatch();
  const {myScissions} = useSelector(state => state.scission);
  
  useEffect(() => {
    dispatch(getMyScissions());
  }, []);
  
  const columns = useMemo(
    () =>
      [
        {
          accessor: 'papelOrigem',
          Header  : 'Papel Origem',
        },
        {
          accessor: 'papelDestino',
          Header  : 'Papel Destino',
        },
        {
          accessor: 'qtBase',
          Header  : 'Quantidade Original',
        },
        {
          accessor: 'multiplicador',
          Header  : 'Multiplicador',
          Cell    : ({value}) => formatDecimal(value)
        },
        {
          accessor: 'qtDestino',
          Header  : 'Quantidade Destino',
        },
        {
          accessor: 'dataCisao',
          Header  : 'Data Cisão',
          Cell    : ({value}) => formatDate(value)
        },
      ],
    []
  );
  
  return (
    <Grid container>
      {!!myScissions.length &&
      <Grid item xs={12}>
        <Table columns={columns} rowsData={myScissions}/>
      </Grid>
      }
      {!myScissions.length &&
      <Box>
        <Grid item xs={12} className={classes.NegativeBox}>
          <Typography variant={'h6'} className={classes.NegativeText}>
            Você não possui cisões
          </Typography>
        </Grid>
      </Box>
      }
    </Grid>
  
  );
};


export default MyScission;
