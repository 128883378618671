import { createSlice }         from '@reduxjs/toolkit';
import { thunkAPI }            from '../../components/utils/api';

export const getMyIncorporations    = thunkAPI('incorporation/get', 'v1/posse/incorporacao', 'get',);
export const getPaperIncorporations = thunkAPI('paperIncorporation/get', 'v1/incorporacao/busca/20', 'get',);
export const computeIncorporation   = thunkAPI('incorporation/compute', '/v1/incorporacao/processar/computar', 'post',
  {successMessage: 'Incorporação processada com sucesso'});
export const includeIncorporation   = thunkAPI('incorporation/include', '/v1/incorporacao/processar', 'post',
  {successMessage: 'Incorporação incluída com sucesso'});
export const processIncorporation   = thunkAPI('incorporation/process', '/v1/incorporacao/processar/data', 'post',
  {successMessage: 'Incorporação processada com sucesso'});


const formatMyIncorporations = (incorporations) => {
  
  const formattedIncorporations = [];
  // console.log('incorporations', incorporations);
  let i                         = 0;
  for (const data of incorporations) {
    
    formattedIncorporations.push({
      id              : i,
      papelOrigem     : data.PapelOrigem.sigla,
      papelDestino    : data.PapelDestino.sigla,
      multiplicador   : data.multiplicador,
      dataIncorporacao: data.dtIncorporacao,
      qtBase          : data.qtPapelOrigem,
      qtDestino       : data.qtPapelDestino
    });
    i++;
  }
  
  return formattedIncorporations;
  
};
const formatIncorporations   = (incorporations) => {
  
  const formattedIncorporations = [];
  
  let i = 0;
  for (const data of incorporations) {
    
    formattedIncorporations.push({
      id                  : i,
      papelDestino        : data.PapelDestino.sigla,
      dataIncorporacao    : data.dtIncorporacao,
      multiplicador       : data.multiplicador,
      indicadorCompulsoria: data.inCompulsoria ? 'Sim' : 'Não',
      vigente             : data.vigente ? 'Sim' : 'Não',
    });
    i++;
  }
  
  return formattedIncorporations;
  
};

const incorporationSlice = createSlice({
  name         : 'incorporation',
  initialState : {
    myIncorporations   : [],
    paperIncorporations: [],
    includeSituation   : 'idle',
    searchSituation    : 'idle',
    processSituation   : 'idle',
  },
  reducers     : {},
  extraReducers: {
    [getMyIncorporations.fulfilled]   : (state, action) => {
      state.myIncorporations = formatMyIncorporations(action.payload);
    },
    [getPaperIncorporations.fulfilled]: (state, action) => {
      state.paperIncorporations = formatIncorporations(action.payload);
      state.searchSituation     = 'idle';
    },
    [getPaperIncorporations.pending]  : (state) => {
      state.searchSituation = 'pending';
    },
    [getPaperIncorporations.rejected] : (state) => {
      state.searchSituation = 'rejected';
    },
    [processIncorporation.fulfilled]  : (state) => {
      state.processSituation = 'idle';
    },
    [processIncorporation.pending]    : (state) => {
      state.processSituation = 'pending';
    },
    [processIncorporation.rejected]   : (state) => {
      state.processSituation = 'rejected';
    },
    
  }
});

const {reducer} = incorporationSlice;


export default reducer;
