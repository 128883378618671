import React, { useEffect } from 'react';
import classes              from '../../layouts/InternalLayout.module.css';
import IconButton           from '@mui/material/IconButton';
import ChevronLeftIcon      from '@mui/icons-material/ChevronLeft';
import MDrawer              from '@mui/material/Drawer';
import NavigationMenu       from './partials/NavigationMenu';
import Typography           from '@mui/material/Typography';
import { useNavigate }       from 'react-router-dom';

const Drawer = ({open, handleCloseMenu }) => {
  
  const navigate  = useNavigate();
  
  const [type, setType]   = React.useState(window.innerWidth > 479 ? 'permanent' : 'temporary');
  const drawerClass     = open ? classes.DrawerPaper : classes.DrawerPaperClose;
  let paddingSize = open ? 240 : 72;
  
  
  useEffect(() => {
    // initiate the event handler
    window.addEventListener('resize', handleWindowResize);
    
    // this will clean up the event every time the component is re-rendered
    return function cleanup() {
      window.removeEventListener('resize', handleWindowResize);
    };
  });
  
  const handleWindowResize = () => {
    
    if (window.innerWidth < 480) setType('temporary')
    else setType('permanent')
    // console.log('entrou aqui', hugeScreen)
    // const mobile         = window.innerWidth < 480;
    // console.log("oi", window.innerWidth < 480)
    
  };
  
  const goToMain = () => {
    navigate('/');
  }
 
 
  return (
    <MDrawer variant={type} open={open} classes={ {paper: drawerClass}}  className={classes.Drawer} style={{paddingRight: paddingSize}}>
      <div className={classes.ToolbarIcon}>
        <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.Title} onClick={goToMain} >
          Kiila
        </Typography>
        <IconButton onClick={handleCloseMenu}>
          <ChevronLeftIcon/>
        </IconButton>
      </div>
     <NavigationMenu closeDrawer={type=== 'temporary' ? handleCloseMenu : null}/>
    </MDrawer>
  )

}

export default Drawer
