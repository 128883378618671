import React, { useEffect, useState } from 'react';

import { useForm }                  from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import classes                      from './IncludePaper.module.css';
import Button                       from '../elements/Button';
import Dialog                       from '../elements/Dialog';

import { paperPart, paperType, paperVisibility } from '../utils/constants';
import { handleError, rules }                    from '../utils/rules';
import Grid                                      from '@mui/material/Grid';
import InputText                                 from '../elements/FormText';
import PersonIcon                                from '@mui/icons-material/Person';
import { includePaper }                          from '../../store/slices/paper';
import { searchCompany }                         from '../../store/slices/company';
import FormDate                                  from '../elements/FormDate';
import CakeIcon                                  from '@mui/icons-material/Cake';
import FormAutoComplete                          from '../elements/FormAutoComplete';
import FormSelect                                from '../elements/FormSelect';
import FormSwitch                                from '../elements/FormSwitch';

const defaultValues = {
  company      : null,
  current      : false,
  initials     : '',
  paperType    : '',
  part         : '',
  startValidity: null,
  visibility   : '',
};

const IncludePaper = () => {
  
  const dispatch = useDispatch();
  
  const {includeSituation}  = useSelector(state => state.paper);
 
  
  const [modalOn, setModalOn] = useState(false);
  
  
  useEffect(() => {
    
    if (includeSituation === 'idle') reset(defaultValues);
    
  }, [includeSituation]);
  
  const {control, handleSubmit, reset, getValues, formState} = useForm({mode: 'all', defaultValues});
  
  const {dirtyFields, isValid, errors} = formState;
  
  let modal;
  
  const openModal = () => {
    setModalOn(true);
  };
  
  const closeModal = () => setModalOn(false);
  
  const onSubmit = (data) => {
    
    const {company, current, initials, paperType, part, startValidity, visibility} = data;
    
    
    dispatch(includePaper({
      data           : {
        sigla     : initials,
        empresaId : company.cdEmpresa,
        lotePadrao: part,
        tipoPapel : paperType,
        utilizacao: visibility,
        vigente   : current,
        dataInicio: startValidity,
      }, internalData: {
        nome      : company.nome,
        sigla     : initials.toUpperCase(),
        lotePadrao: part,
        tipoPapel : paperType,
        utilizacao: visibility,
        vigente   : current,
      }
    }));
    
  };
  
  const disabled    = (!isValid || includeSituation === 'pending');
  const showCompany = (!!dirtyFields['startValidity'] && !Boolean(errors['startValidity']));
  
  
  modal = <Dialog id="paper-modal" show={modalOn} handleClose={closeModal} title={'Inclusão Papel'}>
    
    <form id="paper" onSubmit={handleSubmit(onSubmit)} className={classes.Form}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} className={classes.Item}>
          <FormDate label="Início Vigência" name="startValidity" Icon={CakeIcon} errorMessage={handleError(errors, 'startValidity')}
                    control={control} rules={{required: true}}
          />
        </Grid>
        <Grid item xs={12} sm={6} className={classes.Select}>
          <FormSelect label="Tipo Papel" name="paperType" errorMessage={handleError(errors, 'paperType')}
                      displayField={'name'} valueField={'value'} rules={(rules('paperType', null, 'Tipo de Papel'))}
                      data={paperType} control={control} defaultValue={defaultValues.paperType}
          />
        </Grid>
        <Grid item xs={12} sm={6} className={classes.Item}>
          {showCompany &&
          <FormAutoComplete label="Empresa" name="company" placeholder="Pesquise a empresa" control={control}
                            dataFunction={searchCompany} searchField={'busca'} requiredData={{dataPesquisa: getValues('startValidity')}}
                              displayField="nome" dataFields={{slice: 'company', data: 'companiesSearched'}}
                            rules={(rules('company', null, 'Empresa'))} errorMessage={handleError(errors, 'company')}/>
          }
        </Grid>
        <Grid item xs={12} sm={6} className={classes.Item}>
          <InputText label="Sigla Papel" name="initials" Icon={PersonIcon} errorMessage={handleError(errors, 'initials')}
                     control={control} rules={rules('initials', null, 'Sigla')}
          />
        </Grid>
        <Grid item xs={12} sm={6} className={classes.Select}>
          <FormSelect label="Visibilidade" name="visibility" errorMessage={handleError(errors, 'visibility')}
                      displayField={'name'} valueField={'value'}
                      rules={(rules('visibility'))} data={paperVisibility} control={control} defaultValue={defaultValues.visibility}
          />
        </Grid>
        <Grid item xs={12} sm={6} className={classes.Select}>
          <FormSelect label="Lote Padrão" name="part" errorMessage={handleError(errors, 'part')}
                      displayField={'name'} valueField={'value'}
                      rules={(rules('part', null, 'Lote Padrão'))} data={paperPart} control={control} defaultValue={defaultValues.part}
          />
        </Grid>
        <Grid item xs={12} sm={6} className={classes.Switch}>
          <FormSwitch label={'Vigente'} name={'current'} control={control} defaultValue={defaultValues['current']}/>
        </Grid>
        <Grid item xs={12} className={classes.Button}>
          <Button color="secondary" disabled={disabled} type="submit">
            {includeSituation === 'pending' ? 'Cadastrando' : 'Cadastrar'}
          </Button>
        </Grid>
      </Grid>
    </form>
  </Dialog>;
  
  return (
    <>
      <Button color="secondary" disabled={!!modalOn} onClick={openModal}>
        Incluir
      </Button>
      {modal}
    </>
  );
};

export default IncludePaper;
