import React from 'react';

import {  Link } from 'react-router-dom';
import Grid           from '@mui/material/Grid';
import classes        from './FooterNav.module.css';

const FooterNav = ({className, ...props}) => {
  
  return (<nav {...props}>
      <Grid container direction="row" className={classes.Root} spacing={2}>
        <Grid item xs={12} className={classes.GroupItems}>
          <Link className={classes.Itens} to="/termos">Termos</Link>
          <Link className={classes.Itens} to="/contato">Contato</Link>
          <Link className={classes.Itens} to="/sobre">Sobre Nós</Link>
          <Link className={classes.Itens} to="/faq">FAQ's</Link>
          <Link className={classes.Itens} to="/planos">Planos</Link>
        </Grid>
      </Grid>
    </nav>
  );
};

export default FooterNav;
