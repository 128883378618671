import React, { useEffect, useMemo, useState }       from 'react';
import Grid                                          from '@mui/material/Grid';
import Table                                         from '../../components/elements/Table';
import { formatCurrency, formatInteger, mesExtenso } from '../../components/utils/formatters';
import { useDispatch, useSelector }                  from 'react-redux';
import { getAchievements }                           from '../../store/slices/achievement';
import AchievementDetail                             from '../../components/detailComponents/AchievementDetail';
import DateBar                from '../../components/partials/DateBar';
import AchievementAnalytic    from '../../components/graphics/AchievementAnalytic';
import { monthsBetweenDates } from '../../components/utils/date';
import classes                from '../../components/graphics/EarningSegmentation.module.css';
import Typography             from '@mui/material/Typography';
import Box                                           from '../../components/elements/Box';


const Achievement = () => {
  
  const dispatch                      = useDispatch();
  const {achievements}                = useSelector(state => state.achievement);
  const [searchDates, setSearchDates] = useState({});
  
  useEffect(() => {
    
    if (searchDates.initialDate) dispatch(getAchievements({
      data        : {dataInicio: searchDates.initialDate, dataFim: searchDates.finalDate},
      internalData: {time: monthsBetweenDates(searchDates.initialDate, searchDates.finalDate)}
    }));
    
  }, [searchDates]);
  
  const columns = useMemo(
    () =>
      [
        {
          accessor: 'anoBase',
          Header  : 'Ano Realização',
        },
        {
          accessor: 'mesBase',
          Header  : 'Mês Realização',
          Cell    : ({value}) => mesExtenso(value)
        },
        {
          accessor: 'papel',
          Header  : 'Papel',
        },
        {
          accessor: 'quantidade',
          Header  : 'Quantidade',
          Cell    : ({value}) => formatInteger(value)
        },
        {
          accessor: 'valorCompra',
          Header  : 'Valor Unitário Compra',
          Cell    : ({value}) => formatCurrency(value)
        },
        {
          accessor: 'valorVenda',
          Header  : 'Valor Unitário Venda',
          Cell    : ({value}) => formatCurrency(value)
        },
        {
          accessor: 'custoTotalCompra',
          Header  : 'Custo Total Compra',
          Cell    : ({value}) => formatCurrency(value)
        },
        {
          accessor: 'custoTotalVenda',
          Header  : 'Custo Total Venda',
          Cell    : ({value}) => formatCurrency(value)
        },
        {
          accessor: 'custoTotal',
          Header  : 'Custo Total',
          Cell    : ({value}) => formatCurrency(value)
        },
        {
          accessor: 'balanco',
          Header  : 'Balanço',
          Cell    : ({value}) => formatCurrency(value)
        },
        {
          accessor: 'valorizacao',
          Header  : 'Valorização',
          Cell    : ({value}) => `${(value * 100).toFixed(2)}%`
        },
        {
          accessor: 'valorProvento',
          Header  : 'Proventos',
          Cell    : ({value}) => formatCurrency(value)
        },
        {
          accessor: 'valorizacaoProventos',
          Header  : 'Valorização c/ Proventos',
          Cell    : ({value}) => `${(value * 100).toFixed(2)}%`
        },
        {
          Header: 'Detalhar',
          id    : 'expander'
        },
      ],
    []
  );
  
  const handleChangeDate = (dates) => {
    // console.log("dates", dates)
    setSearchDates(dates);
  };
  // console.log("searchDates", searchDates)
  return (
    <Grid container>
      <Grid item xs={12}>
        <DateBar getDates={handleChangeDate}/>
      </Grid>
      <Grid item xs={12}>
        <AchievementAnalytic/>
      </Grid>
      {!!achievements.length &&
      <Grid item xs={12}>
        <Table columns={columns} rowsData={achievements} detailComponent={AchievementDetail}/>
      </Grid>
      }
      {!achievements.length &&
      <Box>
        <Grid item xs={12} className={classes.NegativeBox}>
          <Typography variant={'h6'} className={classes.NegativeText}>
            Você não possui realizações para o período
          </Typography>
        </Grid>
      </Box>
      }
    </Grid>
  );
};


export default Achievement;
