import React                    from 'react';
import { useSelector }          from 'react-redux';
import NavigationGroup          from './NavigationGroup';
import NavigationItem           from './NavigationItem';
import Divider                  from '@mui/material/Divider';
import List                     from '@mui/material/List';
import DashboardIcon            from '@mui/icons-material/Dashboard';
import ShoppingCartIcon         from '@mui/icons-material/ShoppingCart';
import BarChartIcon             from '@mui/icons-material/BarChart';
import AssignmentIcon           from '@mui/icons-material/Assignment';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import SupervisorAccountIcon    from '@mui/icons-material/SupervisorAccount';
import EventIcon                from '@mui/icons-material/Event';
import BusinessCenterIcon       from '@mui/icons-material/BusinessCenter';
import MonetizationOnIcon       from '@mui/icons-material/MonetizationOn';
import AccountBalanceIcon       from '@mui/icons-material/AccountBalance';
import SwapHorizIcon            from '@mui/icons-material/SwapHoriz';
import ExtensionIcon            from '@mui/icons-material/Extension';
import ThumbsUpDownIcon         from '@mui/icons-material/ThumbsUpDown';
import MoneyOffIcon             from '@mui/icons-material/MoneyOff';
import AspectRatioIcon          from '@mui/icons-material/AspectRatio';
import AssignmentLateIcon       from '@mui/icons-material/AssignmentLate';
import LocationCityIcon         from '@mui/icons-material/LocationCity';
import ShowChartIcon            from '@mui/icons-material/ShowChart';
import SubscriptionsIcon        from '@mui/icons-material/Subscriptions';
import ReceiptIcon              from '@mui/icons-material/Receipt';
import BluetoothAudioIcon       from '@mui/icons-material/BluetoothAudio';
import MovieFilterIcon          from '@mui/icons-material/MovieFilter';
import BorderClearIcon          from '@mui/icons-material/BorderClear';
import CloudUploadIcon          from '@mui/icons-material/CloudUpload';
import CloudDownloadIcon        from '@mui/icons-material/CloudDownload';
import DirectionsWalkIcon       from '@mui/icons-material/DirectionsWalk';
import DirectionsRunIcon        from '@mui/icons-material/DirectionsRun';
import AccountTreeIcon          from '@mui/icons-material/AccountTree';
import PollIcon                 from '@mui/icons-material/Poll';
import CardGiftcardIcon         from '@mui/icons-material/CardGiftcard';
import RemoveShoppingCartIcon   from '@mui/icons-material/RemoveShoppingCart';
import AccessTimeIcon           from '@mui/icons-material/AccessTime';

const NavigationMenu = ({closeDrawer}) => {
  
  const {user} = useSelector(state => state.auth);
  
  let adm;
  if (user && user.role === 'admin') adm = <>
    <Divider/>
    <NavigationGroup grouper={'Administração'} icon={SupervisorAccountIcon}>
      <NavigationItem text={'Empresas'} link={'/administracao/empresa'} icon={LocationCityIcon} clickAction={closeDrawer}/>
      <NavigationGroup grouper={'Papéis'} icon={ShowChartIcon}>
        <NavigationItem text={'Vista'} link={'/administracao/papel/vista'} icon={DirectionsWalkIcon} clickAction={closeDrawer}/>
        <NavigationItem text={'Futuro'} link={'/administracao/papel/futuro'} icon={DirectionsRunIcon} clickAction={closeDrawer}/>
      </NavigationGroup>
      <NavigationItem text={'Ações'} link={'/administracao/acoes'} icon={BluetoothAudioIcon} clickAction={closeDrawer}/>
      <NavigationItem text={'Amortizações'} link={'/administracao/amortizacao'} icon={MoneyOffIcon} clickAction={closeDrawer}/>
      <NavigationItem text={'Cisões'} link={'/administracao/cisao'} icon={AccountTreeIcon} clickAction={closeDrawer}/>
      <NavigationItem text={'Eventos Provento'} link={'/administracao/evento/provento'} icon={MonetizationOnIcon} clickAction={closeDrawer}/>
      <NavigationItem text={'Incorporações'} link={'/administracao/incorporacao'} icon={ThumbsUpDownIcon} clickAction={closeDrawer}/>
      <NavigationItem text={'Negócios'} link={'/administracao/negocios'} icon={BluetoothAudioIcon} clickAction={closeDrawer}/>
      <NavigationItem text={'Proventos'} link={'/administracao/provento'} icon={MonetizationOnIcon} clickAction={closeDrawer}/>
      <NavigationItem text={'Rearranjos'} link={'/administracao/rearranjo'} icon={ExtensionIcon} clickAction={closeDrawer}/>
      <NavigationItem text={'Recompras'} link={'/administracao/recompra'} icon={RemoveShoppingCartIcon} clickAction={closeDrawer}/>
      <NavigationItem text={'Recibos'} link={'/administracao/recibo'} icon={ReceiptIcon} clickAction={closeDrawer}/>
      <NavigationItem text={'Subscrições'} link={'/administracao/subscricao'} icon={SubscriptionsIcon} clickAction={closeDrawer}/>
      <NavigationItem text={'Cotações'} link={'/administracao/cotacao'} icon={AccountBalanceWalletIcon} clickAction={closeDrawer}/>
    </NavigationGroup>
  </>;
  
  return (
    <>
      <Divider/>
      <List>
        <NavigationItem text={'Principal'} link={'/'} icon={DashboardIcon} clickAction={closeDrawer}/>
      </List>
      <Divider/>
      <List>
        <NavigationGroup grouper={'Carteira'} icon={AccountBalanceWalletIcon}>
          <NavigationItem text={'Posição Comprada'} link={'/carteira/comprada'} icon={CloudUploadIcon} clickAction={closeDrawer}/>
          <NavigationItem text={'Posição Vendida'} link={'/carteira/vendida'} icon={CloudDownloadIcon} clickAction={closeDrawer}/>
          <NavigationItem text={'Posição Temporal'} link={'/carteira/temporal'} icon={AccessTimeIcon} clickAction={closeDrawer}/>
        </NavigationGroup>
        <NavigationItem text={'Realizações'} link={'/realizacao'} icon={AccountBalanceIcon} clickAction={closeDrawer}/>
        <NavigationItem text={'Proventos'} link={'/provento'} icon={MonetizationOnIcon} clickAction={closeDrawer}/>
      </List>
      <Divider/>
      <List>
        <NavigationGroup grouper={'Negócios'} icon={BusinessCenterIcon}>
          <NavigationItem text={'Compra/Venda'} link={'/negocio'} icon={ShoppingCartIcon} clickAction={closeDrawer}/>
          <NavigationItem text={'Transferência'} link={'/transferencia'} icon={SwapHorizIcon} clickAction={closeDrawer}/>
        </NavigationGroup>
      </List>
      <Divider/>
      <List>
        <NavigationGroup grouper={'Relatórios'} icon={PollIcon}>
          <NavigationItem text={'Estatísticas'} link={'/estatistica'} icon={BarChartIcon} clickAction={closeDrawer}/>
          <NavigationItem text={'Lucros/Prejuízos'} link={'/lucro'} icon={CardGiftcardIcon} clickAction={closeDrawer}/>
        </NavigationGroup>
        <NavigationGroup grouper={'Eventos'} icon={EventIcon}>
          <NavigationItem text={'Amortização'} link={'/amortizacao'} icon={MoneyOffIcon} clickAction={closeDrawer}/>
          <NavigationItem text={'Cisão'} link={'/cisao'} icon={AccountTreeIcon} clickAction={closeDrawer}/>
          <NavigationItem text={'Incorporação'} link={'/incorporacao'} icon={ThumbsUpDownIcon} clickAction={closeDrawer}/>
          <NavigationItem text={'Rearranjo'} link={'/rearranjo'} icon={ExtensionIcon} clickAction={closeDrawer}/>
          <NavigationItem text={'OPA Automática'} link={'/recompra'} icon={RemoveShoppingCartIcon} clickAction={closeDrawer}/>
        </NavigationGroup>
        <NavigationGroup grouper={'Imposto'} icon={AssignmentIcon}>
          <NavigationItem text={'Obrigações Mensais'} link={'/imposto/movimentacao'} icon={AssignmentLateIcon} clickAction={closeDrawer}/>
          <NavigationItem text={'Posição Anual'} link={'/imposto/posicao'} icon={AspectRatioIcon} clickAction={closeDrawer}/>
          <NavigationItem text={'Provento Anual'} link={'/imposto/provento'} icon={MovieFilterIcon} clickAction={closeDrawer}/>
          <NavigationItem text={'Provento Posterior'} link={'/imposto/provento/posterior'} icon={BorderClearIcon} clickAction={closeDrawer}/>
        </NavigationGroup>
        {adm}
      </List>
    </>
  );
  
};

export default NavigationMenu;
