import React, { useEffect, useMemo, useState } from 'react';

import classes                      from './Input.module.css';
import FormHelperText               from '@mui/material/FormHelperText';
import FormControl                  from '@mui/material/FormControl';
import InputLabel                   from '@mui/material/InputLabel';
import Select                       from '@mui/material/Select';
import MenuItem                     from '@mui/material/MenuItem';
import { Controller }               from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

const FormSelect = ({label, name, dataFunction, dataFields, control, displayField, requiredData, valueField, rules, errorMessage, ...others}) => {
  
  const dispatch = useDispatch();
  
  const [internalList, setInternalList] = useState([]);
  
  const data = useSelector(state => state[dataFields.slice]);
  
  useEffect(() => {
    if (dataFunction && requiredData) dispatch(dataFunction(requiredData));
  }, [requiredData]);
  
  
  // console.log(data)
  
  useEffect(() => {
    
    let myList = data[dataFields.data];
    if (dataFields.field1) myList = myList[dataFields.field1] || []
    if (dataFields.field2 && data[dataFields.data][dataFields.field1]) myList = data[dataFields.data][dataFields.field1][dataFields.field2] || []
    setInternalList(myList);
  }, [data]);
  
  
  const mountItems = useMemo(
    () => {
      const items = [];
      for (const value of internalList) {
        const myValue = !!valueField ? value[valueField] : value
        items.push(
          <MenuItem value={JSON.stringify(myValue)} key={`${value[displayField]}`}>{value[displayField]}</MenuItem>
        );
      }
      return items;
    },
    [internalList]
  );

  
  return (
    
    <FormControl aria-label={name} variant="outlined" fullWidth className={classes.Field}>
      <InputLabel error={!!errorMessage} htmlFor={name}>{label}</InputLabel>
      <Controller name={name} control={control} rules={rules}
                  render={({field}) => (
                    <Select {...field} labelId={`${name}-label`} id={name} error={!!errorMessage} {...others}
                            label={label}>
                      <MenuItem value="" disabled>
                        <em>Selecione</em>
                      </MenuItem>
                      {mountItems}
                    </Select>
                  )}
                  />
      {!!errorMessage && <FormHelperText error margin="dense">{errorMessage}</FormHelperText>}
    
    </FormControl>
  );
};


export default FormSelect;
