import React                    from 'react';
import Container                from '@mui/material/Container';
import classes                  from './FeatureTiles.module.css';
import Grid                     from '@mui/material/Grid';
import MainFeature              from '../elements/MainFeature';
import CardTile                 from '../elements/CardTile';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import CalculateIcon            from './../../assets/icons/calculate';
import BorderColorIcon          from '@mui/icons-material/BorderColor';
import MonetizationOnIcon       from '@mui/icons-material/MonetizationOn';
import AssignmentIcon           from '@mui/icons-material/Assignment';
import FlightLandIcon           from '@mui/icons-material/FlightLand';

const variants = {
  hidden : {
    opacity: 0,
    y      : 50,
  },
  visible: {
    opacity: 1,
    y      : 0,
  }
};

const FeaturesTiles = ({className, ...props}) => {
  
  
  const classBox              = [classes.Box, className].join(' ');
 
  // console.log('inView', inView);
  
  return (
    
    <Container className={classBox}>
      <MainFeature variants={variants} delay={0} title={'Desburocratização dos investimentos'}>
        Foque no que só você pode fazer, escolher e gerenciar onde quer colocar o seu dinheiro. Deixe os cálculos com a gente
      </MainFeature>
      
      <Grid container justifyContent="center" className={classes.Root} spacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          <CardTile  variants={variants}  delay={0} title={'Carteira'} icon={AccountBalanceWalletIcon}>
            Controle seus ativos independente da corretora em um só lugar
          </CardTile>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <CardTile  variants={variants} delay={0.2} title={'Preço Médio'} icon={CalculateIcon}>
            Calculo do preço médio automático, levando em conta os custos
          </CardTile>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <CardTile  variants={variants} delay={0.3} title={'DARF'} icon={BorderColorIcon}>
            Geração dos valores em relação aos lucros auferidos no período
          </CardTile>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <CardTile variants={variants} delay={0} title={'IRPF'} icon={AssignmentIcon}>
            Posição da carteira no final do ano, com as informações necessárias para realização da declaração.
          </CardTile>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <CardTile  variants={variants}  delay={0.2} title={'Prejuízos'} icon={FlightLandIcon}>
            Apuração e compensação dos prejuízos realizados
          </CardTile>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <CardTile variants={variants} delay={0.2} title={'Proventos'} icon={MonetizationOnIcon}>
            Contabilização automática dos proventos da sua carteira
          </CardTile>
        </Grid>
      </Grid>
    </Container>
  );
  
};

export default FeaturesTiles;
