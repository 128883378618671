import React, { useEffect } from 'react';

import { useForm }                  from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import classes                      from './IncludeUnderwriting.module.css';
import Button                       from '../elements/Button';
import Dialog                       from '../elements/Dialog';

import { handleError, rules }              from '../utils/rules';
import Grid                                from '@mui/material/Grid';
import { searchAdmPaper }                  from '../../store/slices/paper';
import { includeUnderwriting }             from '../../store/slices/underwriting';
import FormDate                            from '../elements/FormDate';
import CakeIcon                            from '@mui/icons-material/Cake';
import FormAutoComplete                    from '../elements/FormAutoComplete';
import FormSelect                          from '../elements/FormSelect';
import { decimalFormat, underwritingType } from '../utils/constants';
import FormMask                            from '../elements/FormMask';
import AttachMoneyIcon                     from '@mui/icons-material/AttachMoney';
import { justToDate }                      from '../utils/date';


const defaultValues = {
  paper           : null,
  receipt         : null,
  conversionDate  : '',
  debtDate        : '',
  value           : '',
  underwritingType: '',
};

const IncludeUnderwriting = () => {
  
  const dispatch = useDispatch();
  
  
  const {includeSituation} = useSelector(state => state.underwriting);
  
  const [modalOn, setModalOn] = React.useState(false);
  
  
  useEffect(() => {
    
    if (includeSituation === 'idle') reset(defaultValues);
    
  }, [includeSituation]);
  
  const {control, handleSubmit, reset, getValues, formState} = useForm({mode: 'all', defaultValues});
  
  const {dirtyFields, isValid, errors} = formState;
  
  let modal;
  
  const openModal = () => {
    setModalOn(true);
  };
  
  const closeModal = () => setModalOn(false);
  
  const onSubmit = (data) => {
    
    let {paper, receipt, conversionDate, debtDate, value, underwritingType} = data;
    if (!conversionDate) conversionDate = null;
    else conversionDate   = justToDate(conversionDate);
    
    underwritingType = JSON.parse(underwritingType);
    
    debtDate         = justToDate(debtDate);
    
    dispatch(includeUnderwriting({
      data           : {
        papelId       : paper.cdPapel,
        reciboId      : receipt.cdPapel,
        dataConversao : conversionDate,
        dataDebito    : debtDate,
        vlSubscricao  : value,
        tipoSubscricao: underwritingType.value,
      }, internalData: {
        papel         : paper.sigla,
        recibo        : receipt.sigla,
        dataDebito    : debtDate,
        dataConversao : conversionDate,
        vlSubscricao  : value,
        tipoSubscricao: underwritingType.name
      }
    }));
    
  };
  
  const disabled  = (!isValid || includeSituation === 'pending');
  const showPaper = (!!dirtyFields['debtDate'] && !Boolean(errors['debtDate']));
  
  modal = <Dialog id="underwriting-modal" show={modalOn} handleClose={closeModal} title={'Inclusão Subscrição'}>
    
    <form id="underwriting" onSubmit={handleSubmit(onSubmit)} className={classes.Form}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} className={classes.Item}>
          <FormDate label="Data Débito" name="debtDate" Icon={CakeIcon} errorMessage={handleError(errors, 'debtDate')}
                    control={control} rules={{required: true}}
          />
        </Grid>
        <Grid item xs={12} sm={6} className={classes.Item}>
          <FormDate label="Data Conversão" name="conversionDate" Icon={CakeIcon} errorMessage={handleError(errors, 'conversionDate')}
                    control={control}
          />
        </Grid>
        <Grid item xs={12} sm={6} className={classes.Item}>
          <FormSelect label="Tipo Subscrição" name="underwritingType" errorMessage={handleError(errors, 'underwritingType')}
                      displayField={'name'} rules={(rules('underwritingType'))} data={underwritingType} control={control}
          />
        </Grid>
        <Grid item xs={12} sm={6} className={classes.Item}>
          <FormMask label="Valor Unitário Subscrição" name="value" Icon={AttachMoneyIcon} defaultValue={defaultValues['value']}
                    errorMessage={handleError(errors, 'value')} control={control}
                    rules={rules('value')} formatOptions={decimalFormat}
          />
        </Grid>
        <Grid item xs={12} sm={6} className={classes.Item}>
          {showPaper &&
          <FormAutoComplete label="Papel" name="paper" placeholder="Pesquise o papel" control={control} errorMessage={handleError(errors, 'paper')}
                            dataFunction={searchAdmPaper} searchField={'busca'} requiredData={{dataPesquisa: getValues('debtDate')}}
                            displayField="sigla" rules={(rules('paper'))} dataFields={{slice: 'paper', data: 'papersSearched'}}/>
          }
        </Grid>
        <Grid item xs={12} sm={6} className={classes.Item}>
          {showPaper &&
          <FormAutoComplete label="recibo" name="receipt" placeholder="Pesquise o recibo" control={control} errorMessage={handleError(errors, 'receipt')}
                            dataFunction={searchAdmPaper} searchField={'busca'} requiredData={{dataPesquisa: getValues('debtDate')}}
                            displayField="sigla" rules={(rules('paper'))} dataFields={{slice: 'paper', data: 'papersSearched'}}/>
          }
        </Grid>
        <Grid item xs={12} className={classes.Button}>
          <Button color="secondary" disabled={disabled} type="submit">
            {includeSituation === 'pending' ? 'Cadastrando' : 'Cadastrar'}
          </Button>
        </Grid>
      </Grid>
    </form>
  </Dialog>;
  
  return (
    <>
      <Button color="secondary" disabled={!!modalOn} onClick={openModal}>
        Incluir
      </Button>
      {modal}
    </>
  );
};

export default IncludeUnderwriting;
