import React, { useEffect, useState }           from 'react';
import { useDispatch, useSelector }             from 'react-redux';
import { getBusinessTypes, includeLotBusiness } from '../../store/slices/business';
import Grid                                     from '@mui/material/Grid';
import Button                               from '../elements/Button';
import { useFieldArray, useForm, useWatch } from 'react-hook-form';
import FieldsFormBusiness                   from './FieldsFormBusiness';
import classes                              from '../containerActions/IncludeBusiness.module.css';
import TodayIcon                            from '@mui/icons-material/Today';
import FormDate                             from '../elements/FormDate';
import { handleError, rules }               from '../utils/rules';
import FormSelect                           from '../elements/FormSelect2';
import { getExchanges }                     from '../../store/slices/exchange';
import IconButton                           from '../elements/IconButton';
import AddCircleIcon                        from '@mui/icons-material/AddCircle';
import { stringDateWithTime }                       from '../utils/date';



const newBusiness = {
  businessType : null,
  paper        : null,
  exercisePaper: null,
  quantity     : '',
  value        : '',
  cost         : '',
  subCost      : 0,
  underwriting : '',
  exercise     : false,
};

let defaultValues = {
  date    : '',
  exchange: '',
  items   : [],
};

const businessArray = (data, date, businessTypes) => {
   defaultValues.date = stringDateWithTime(date);
  
  let items = [];
  
  for (const line of data) {
    const {custoTotal, valor, type, papel, quantity, exercise} = line;
    let bt;
    
    if (type === 1)
      bt = businessTypes[0];
    else bt = businessTypes[1];
    
    items.push({
      cost         : Number(custoTotal),
      value        : Number(valor),
      businessType : bt,
      paper        : papel || null,
      quantity,
      exercise,
      exercisePaper: null,
    });
    
    // errors, control, date, index, id, watchs, removeAction
  }
  
  defaultValues.items = items;
};

const ConfirmationFileBusiness = ({data}) => {
  
  const dispatch = useDispatch();
  
  
  // console.log('adff', data);
  
  useEffect(() => {
    if (!businessTypes.length) dispatch(getBusinessTypes());
    if (!exchanges.length) dispatch(getExchanges());
  }, []);
  
  
  const {businessTypes, includeSituation} = useSelector(state => state.business);
  const {exchanges}                       = useSelector(state => state.exchange);
  
  
  const {control, handleSubmit, formState, reset} = useForm({mode: 'all', defaultValues});
  
  const {fields, prepend, remove} = useFieldArray({
    control,
    name: 'items'
  });
  
  const {isValid, errors} = formState;
  
  
  useEffect(() => {
    if (businessTypes.length && exchanges.length) {
      if (data.exchangeId) {
        defaultValues.exchange = JSON.stringify(exchanges.find(ex => ex.corretoraId === data.exchangeId));
      }
      businessArray(data.business, businessDate, businessTypes);
      reset(defaultValues)
    }
    
  }, [businessTypes, exchanges]);
  
  
  const onSubmit = (data) => {
    // console.log('passou aqui',);
    // console.log(data); // handle the data here
    let {exchange, items} = data;
  
    exchange = JSON.parse(exchange);
    const negocios      = [];
    const negociosSaida = [];
    
    for (const item of items) {
      
      let papelIdExercicio;
      let papelId = item.paper.cdPapel;
      let papel   = item.paper.sigla;
      
      if (item.exercise) {
        papelId          = item.exercisePaper.cdPapel;
        papel            = item.exercisePaper.sigla;
        papelIdExercicio = item.paper.cdPapel;
      }
      
      negocios.push({
        papelId,
        papelIdExercicio,
        quantidadePapel: item.quantity,
        tipoNegocio    : item.businessType.id,
        valorPapel     : item.value,
        custo          : item.cost,
        dataNegocio    : businessDate,
        corretoraId    : exchange.corretoraId
      });
      negociosSaida.push({
        custo      : item.cost,
        dataNegocio: businessDate,
        quantidade : item.quantity,
        papel,
        valorPapel : item.value,
        corretora  : exchange.abreviatura,
        tipoNegocio: item.businessType.nome,
        exercido   : item.exercise,
      });
    }
    // console.log(negocios);
    dispatch(includeLotBusiness({data: {negocios}, internalData: {negociosSaida}}));
    
  };
  
  
  const businessDate = data.date;
  
  const watches = useWatch({
    name: 'items',
    control
  });
  
  const disabled = (!isValid || includeSituation === 'pending');
  // const disabled = false;
  
  return (
    <form id="business" onSubmit={handleSubmit(onSubmit)} className={classes.Form}>
      
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={2} className={classes.FixedFields}>
            <Grid item xs={12} sm={4} className={classes.Item}>
              <FormDate label="Data do Negócio" name="date" Icon={TodayIcon} errorMessage={handleError(errors, 'date')}
                        control={control} rules={rules('businessDate')}
              />
            </Grid>
            <Grid item xs={12} sm={4} className={classes.Item}>
              <FormSelect label="Corretora" name="exchange" errorMessage={handleError(errors, 'exchange')}
                           displayField={'abreviatura'} dataFields={{slice: 'exchange', data: 'exchanges'}}
                           rules={(rules('exchange', null, 'Corretora'))} control={control}
              />
            </Grid>
            <Grid item xs={12} sm={4} className={classes.IncOpButton}>
              <IconButton aria-label="add" tooltip="incluir outra operação" color="primary" icon={AddCircleIcon} onClick={() => prepend(newBusiness)}/>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.BusinessBox}>
          <Grid container spacing={2}>
            {!!businessTypes.length && exchanges.length &&
            fields.map((item, index) => {
              return (
                <Grid item xs={12} className={classes.OperationBox} key={index}>
                  <FieldsFormBusiness errors={errors} control={control} date={businessDate} watches={watches[index]}
                                      key={item.id} id={item.id} index={index} removeAction={remove}/>
                </Grid>
              );
            })
            }
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.Button}>
          <Button color="secondary" disabled={disabled} type="submit">
            {includeSituation === 'pending' ? 'Cadastrando' : 'Cadastrar'}
          </Button>
        </Grid>
      </Grid>
    
    </form>
  );
  
};

export default ConfirmationFileBusiness;
