import React, { useState } from 'react';

import Image               from '../elements/Image';
import Modal               from '../elements/Modal';
import Container           from '@mui/material/Container';
import classes             from './Hero.module.css';
import Grid                from '@mui/material/Grid';
import Typography          from '@mui/material/Typography';
import { motion }          from 'framer-motion';
import videoPlay           from '../../assets/images/video-placeholder.jpg';

const Hero = ({className, ...props}) => {
  
  const [videoModalActive, setVideoModalActive] = useState(false);
  
  const openModal = (e) => {
    e.preventDefault();
    setVideoModalActive(true);
  };
  
  const closeModal = (e) => {
    e.preventDefault();
    setVideoModalActive(false);
  };
  
  const classBox = [classes.Box, className].join(' ');
  return (
    <Container className={classBox}>
      <Grid container justifyContent="center" className={classes.Root} spacing={2}>
        <Grid item xs={12} >
          <motion.div
            className={classes.TitleContainer}
            initial={{y: 50, opacity: 0}}
            animate={{y: 0, opacity: 1}}
            transition={{duration: 1}}
          >
            <Typography variant="h1" className={classes.Title}>
              Descomplique seus <span className={classes.PrimaryColor}>investimentos</span>
            </Typography>
          </motion.div>
        </Grid>
        <Grid item xs={6}>
          <motion.div initial={{y: 50, opacity: 0.2}}
                      animate={{y: 0, opacity: 1}} transition={{duration: 0.7, delay: 0.2}}>
            <Typography variant="h6" className={classes.Text}>
              Gerencie sua carteira de uma forma simples e automatizada, sabendo a rentabilização e calculando os impostos de forma integrada
            </Typography>
          </motion.div>
          {/*<motion.div className={classes.Actions} initial={{y: 60, opacity: 0}}*/}
          {/*            animate={{y: 0, opacity: 1}}                      transition={{delay: 0.4, duration: 0.7}}>*/}
          {/*  <ButtonGroup>*/}
          {/*    <Button color="primary" href="https://cruip.com/">*/}
          {/*      Get started*/}
          {/*    </Button>*/}
          {/*    <Button color="secondary" href="https://github.com/cruip/open-react-template/">*/}
          {/*      View on Github*/}
          {/*    </Button>*/}
          {/*  </ButtonGroup>*/}
          {/*</motion.div>*/}
        </Grid>
        <Grid item xs={12} className={classes.Video}>
          <motion.div initial={{y: 50, opacity: 0}}
                      animate={{y: 0, opacity: 1}} transition={{duration: 0.7, delay: 0.4}}>
            <a data-video="https://player.vimeo.com/video/174002812" href="#0" aria-controls="video-modal" onClick={openModal}>
              <Image src={videoPlay} alt="Hero" width={896} height={504}/>
            </a>
          </motion.div>
          <Modal
            id="video-modal"
            show={videoModalActive}
            handleClose={closeModal}
            video="https://player.vimeo.com/video/174002812"
            videoTag="iframe">
            <div className={classes.Modal}>
              <h2 id="transition-modal-title">Video </h2>
              <p id="transition-modal-description">Aqui terá um video sobre o site</p>
            </div>
          </Modal>
        </Grid>
      </Grid>
    </Container>
  );
};


export default Hero;
