import React, { useEffect, useState } from 'react';


import Grid    from '@mui/material/Grid';
import classes from './Plans.module.css';

import { useDispatch, useSelector }   from 'react-redux';
import PlanCards                      from '../partials/PlanCards';
import Box                            from '../elements/Box';
import Typography                     from '@mui/material/Typography';
import { formatCurrency, formatDate } from '../utils/formatters';
import { addAny, addDays }            from '../utils/date';

import Pay                                from './Pay';
import { editUserPlan, erasePlanMessage } from '../../store/slices/user';
import { changePlanSituation }            from '../../store/slices/api';


const monthDate     = addAny(new Date(), 'months', 1);
const yearDate      = addAny(new Date(), 'years', 1);
const monthDateText = formatDate(monthDate);
const yearDateText  = formatDate(yearDate);

let monthDatePlan, yearDatePlan;

const PlanChange = ({userPlan}) => {
  
  
  const [userChoice, setUserChoice]  = useState({});
  const {planSituation, planMessage} = useSelector(state => state.user);
  const dispatch                     = useDispatch();
  
  useEffect(() => {
    
    // console.log('userPlan', userPlan);
    if (userPlan.tempo) {
      monthDatePlan = formatDate(addDays(monthDate, userPlan.tempo));
      yearDatePlan  = formatDate(addDays(yearDate, userPlan.tempo));
    }
    
    let price  = 15;
    let myDate = monthDateText;
    let period = 'Mensal';
    
    if (userPlan.periodo) {
      period = userPlan.periodo;
      if (userPlan.periodo === 'Mensal') {
        price  = 15;
        myDate = monthDatePlan;
      } else {
        price  = 120;
        myDate = yearDatePlan;
      }
    }
    
    setUserChoice({price, period, date: myDate});
    
  }, [userPlan]);
  
  
  useEffect(() => {
     
      if (planMessage.type === 'success') {
        dispatch(changePlanSituation(true));
      }
  }, [planMessage]);
  
  const chosenPlan = (price, period) => {
    
    let date = monthDateText;
    
    if (period === 'Anual') {
      if (userPlan.tempo) date = yearDatePlan;
      else date = yearDateText;
    } else if (userPlan.tempo) date = monthDatePlan;
    
    
    setUserChoice({price, period, date});
  };
  
  const eraseMessage = () => {
    dispatch(erasePlanMessage());
  };
  
  return (
    <Grid container spacing={2} justifyContent={'center'}>
      <Grid item xs={12}>
        <PlanCards selectedPlan={chosenPlan} initialPlan={userPlan.periodo} size={6}/>
      </Grid>
      <Grid item xs={12} md={8}>
        <Box>
          <Grid container justifyContent={'space-between'} alignItems={'center'}>
            <Grid item xs={6} md={4} className={classes.Plan}>
              <Typography variant="body1" className={classes.Text}>
                Plano Selecionado
              </Typography>
            </Grid>
            <Grid item xs={4} md={3}>
              <Typography variant="body1" className={classes.MyText}>
                Padrão {userChoice.period}
              </Typography>
            </Grid>
            <Grid item xs={12} md={3} className={classes.Plan}>
              <Typography variant="body1" className={classes.Text}>
                Vencerá em <span className={classes.MyText}>{userChoice.date}</span>
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={12} md={8}>
        <Box>
          <Grid container justifyContent={'space-between'} alignItems={'center'}>
            <Grid item xs={9} className={classes.Plan}>
              <Typography variant="body1" className={classes.Text}>
                Valor a Pagar
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="body1" className={classes.MyText}>
                {formatCurrency(userChoice.price)}
              </Typography>
            </Grid>
            <Grid item xs={12} style={{paddingTop: '4px'}}>
              <br/>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={12} className={classes.Button}>
        <Pay description={'Compra de Plano'} price={userChoice.price} action={editUserPlan} message={planMessage}
             contractData={{periodicidade: userChoice.period}} paySituation={planSituation} closeAction={eraseMessage}/>
      </Grid>
    </Grid>
  );
  
};


export default PlanChange;
