import React  from 'react';

import { useDispatch } from 'react-redux';

import Button from '../elements/Button';

import { redeemEarnings } from '../../store/slices/earning';

const RedeemEarning = () => {
  
  const dispatch = useDispatch();
  
  const handleCapture = () => {
    dispatch(redeemEarnings())
  }

  
  return (
    <>
      <Button color="secondary" onClick={handleCapture}>
        Capturar
      </Button>
    </>
  );
};

export default RedeemEarning;
