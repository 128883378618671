import React       from 'react';
import MCard       from '@mui/material/Card';
import Avatar      from '@mui/material/Avatar';
import CardHeader  from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Typography  from '@mui/material/Typography';
import classes     from './Card.module.css';


const miniCard = ({title, info, icon}) => {
  
  const Icon = icon;
  
  return (
    <MCard className={classes.Card} variant="outlined">
      <CardHeader  title={title}   titleTypographyProps={{className: classes.Title}}
        avatar={
      <Avatar aria-label="recipe" className={classes.Avatar}>
        {!!icon && <Icon fontSize="large"  />}
      </Avatar>
        }
      />
      <CardContent >
        <Typography variant="body1" gutterBottom className={classes.Text}>
          {info}
        </Typography>
      </CardContent>
    </MCard>
  );
};

export default miniCard;
