import { createSlice }                                  from '@reduxjs/toolkit';
import { thunkAPI }                                     from '../../components/utils/api';
import { defineRearrangeType }                          from '../../components/utils/constants';

export const computeRearrangement   = thunkAPI('rearrangement/compute', '/v1/rearranjo/processar/computar', 'post',
  {successMessage: 'Rearranjo processado com sucesso'});
export const excludeRearrangement   = thunkAPI('rearrangement/exclude', 'v1/rearranjo/processar/computar', 'delete',
  {successMessage: 'Rearranjo excluído com sucesso'});
export const includeRearrangement   = thunkAPI('rearrangement/include', '/v1/rearranjo/processar', 'post',
  {successMessage: 'Rearranjo incluído com sucesso'});
export const getMyRearrangements    = thunkAPI('rearrangement/get', 'v1/posse/rearranjo', 'get',);
export const getPaperRearrangements = thunkAPI('paperRearrangement/get', 'v1/rearranjo/busca/20', 'get',);
export const processRearrangement   = thunkAPI('rearrangement/process', '/v1/rearranjo/processar/data', 'post',
  {successMessage: 'Rearranjo processado com sucesso'});

const formatMyRearrangements = (rearrangements) => {
  
  const formattedRearrangements = [];
  // console.log('rearrangements', rearrangements);
  let i                         = 0;
  for (const data of rearrangements) {
    
    formattedRearrangements.push({
      id           : i,
      papel        : data.Papel.sigla,
      multiplicador: data.multiplicador,
      dataRearranjo: data.dtRearranjo,
      tipo         : defineRearrangeType(data.tipoRearranjo),
      qtBase       : data.qtPapelBase,
      qtNova       : data.qtPapelRearranjado
    });
    i++;
  }
  
  return formattedRearrangements;
  
};
const formatRearrangements   = (rearrangements) => {
  
  const formattedRearrangements = [];
  
  
  let i = 0;
  for (const data of rearrangements) {
    
    formattedRearrangements.push({
      id           : i,
      papelId      : data.cdPapel,
      tipoId       : data.tipoRearranjo,
      dataRearranjo: data.dtRearranjo,
      dataCredito  : data.dtCredito,
      multiplicador: data.multiplicador,
      tipoRearranjo: defineRearrangeType(data.tipoRearranjo),
      vigente      : data.vigente ? 'Sim' : 'Não',
    });
    i++;
  }
  
  return formattedRearrangements;
  
};

const rearrangementSlice = createSlice({
  name         : 'rearrangement',
  initialState : {
    myRearrangements   : [],
    paperRearrangements: [],
    includeSituation   : 'idle',
    searchSituation    : 'idle',
    processSituation   : 'idle',
  },
  reducers     : {},
  extraReducers: {
    [getMyRearrangements.fulfilled]   : (state, action) => {
      state.myRearrangements = formatMyRearrangements(action.payload);
    },
    [getPaperRearrangements.fulfilled]: (state, action) => {
      state.paperRearrangements = formatRearrangements(action.payload);
      state.searchSituation     = 'idle';
    },
    [getPaperRearrangements.pending]  : (state) => {
      state.searchSituation = 'pending';
    },
    [getPaperRearrangements.rejected] : (state) => {
      state.searchSituation = 'rejected';
    },
    [processRearrangement.fulfilled]  : (state) => {
      state.processSituation = 'idle';
    },
    [processRearrangement.pending]    : (state) => {
      state.processSituation = 'pending';
    },
    [processRearrangement.rejected]   : (state) => {
      state.processSituation = 'rejected';
    },
  }
});

const {reducer} = rearrangementSlice;


export default reducer;
