import axios                                           from 'axios';
import env                                             from '../../environment';
import { createAsyncThunk }                            from '@reduxjs/toolkit';
import { addMessage, setLoadingTrue, setLoadingFalse } from '../../store/slices/app';
import { expired, changePlanSituation }                from '../../store/slices/api';

const api = (baseUrl) => {
  
  let create = axios.create({
    baseURL        : baseUrl,
    withCredentials: true,
    CancelToken    : axios.CancelToken,
    isCancel       : axios.isCancel
  });
  // create.CancelToken = axios.CancelToken;
  // create.isCancel = axios.isCancel;
  
  // create.instance.interceptors.request.use(
  //   function(config) {
  //     const token = localStorage.getItem("token");
  //     if (token) {
  //       config.headers["Authorization"] = 'Bearer ' + token;
  //     }
  //     return config;
  //   },
  //   function(error) {
  //     return Promise.reject(error);
  //   }
  // );
  
  create.interceptors.request.use(request => request, error => {
    error.msg = 'Erro ao tentar enviar dados.';
    console.error(error.msg, error, error.request);
    return Promise.reject(error);
  });
  
  create.interceptors.response.use(response => response, error => {
    const response = error.response;
    // console.log("response", response)
    error.msg      = response && response.data ? response.data.msg : 'Erro ao tentar receber dados.';
    console.error(error.msg, error, error.response);
    return Promise.reject(error);
  });
  
  return create;
};

export const serverApi = api(env.serverApi);


export const setHeader = (token) => {
  serverApi.defaults.headers.common['x-access-token'] = token;
};
export const thunkAPI  = (typePrefix, url, mode, messages) => createAsyncThunk(typePrefix,
  async (arg, others) => {
    const options                               = {};
    //arg {data ,  internalData }
    const {dispatch, getState, rejectWithValue} = others;
    let {data, internalData, urlId, file}       = arg || {};
    let {verb, getCache}                        = mode || {};
    
    if (!data) data = arg;
    if (!verb) verb = mode;
    // console.log("arg", arg)
    
    if (getCache) {
      const [reducer, object, key] = getCache;
      const response               = getState()[reducer][object];
      
      if (key) {
        if (response[data[key]]) return response[data[key]];
      } else {
        if (Array.isArray(response)) {
          if (response.length) return response;
        } else {
          if (Object.entries(response).length) return response;
        }
      }
    }
    
    
    dispatch(setLoadingTrue());
    
    let object;
    let newUrl = url;
    
    switch (verb) {
      case 'delete':
      case 'get':
        object = {params: data};
        break;
      case 'post':
        object = data;
        break;
      default:
        object = data;
    }
    
    if (urlId) newUrl = `${url}/${urlId}`;
    if (file) options.headers = {'Content-Type': 'multipart/form-data'};
    
    try {
      
      const response = await serverApi[verb](`${newUrl}`, object, options);
      dispatch(setLoadingFalse());
      
      if (messages && messages.successMessage) dispatch(addMessage({text: messages.successMessage, type: 'success'}));
      if (typePrefix === 'auth/login') return response.headers['x-access-token'];
      
      if (response.data && response.data.semPlano) {
        console.log("response.data", response.data)
        dispatch(changePlanSituation(false));
        return rejectWithValue();
      }
      
      if (internalData) {
        let internalResponse;
        
        switch (true) {
          case Array.isArray(response.data):
            internalResponse = {response: response.data};
            break;
          case typeof response.data === 'object' :
            internalResponse = response.data;
            break;
          default:
            internalResponse = {response: response.data};
        }
        
        return {...internalData, ...internalResponse};
      }
      return response.data;
    } catch (e) {
      dispatch(setLoadingFalse());
      // console.log('deu erro', e);
      let msg = 'Erro ao contactar Servidor';
      // let statusCode  = 999;
      // let messageCode = 0;
      if (e.response && e.response.data && e.response.data.message) {
        msg = e.response.data.message;
        // messageCode = e.response.data.messageCode;
      }
      if (e.response.status === 401) {
        msg = 'Sessão expirada';
        dispatch(expired());
      }
      
      dispatch(addMessage({text: msg, type: 'error'}));
      return rejectWithValue();
    }
    
  }
);


export default api;

