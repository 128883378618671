import 'core-js';
import React              from 'react';
import ReactDOM           from 'react-dom/client';
import { BrowserRouter }  from 'react-router-dom';
import { Provider }       from 'react-redux';
import App                from './App';
import store              from './store/store';
import './index.css';
import reportWebVitals    from './reportWebVitals';
 
import env from './environment';

const {basename} = env;

const app =
        <Provider store={store}>
          <BrowserRouter basename={basename}>
            <App/>
          </BrowserRouter>
        </Provider>;


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    {app}
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();