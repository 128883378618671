import React         from 'react';
// import classes       from './Main.module.css';
import Hero          from '../../components/sections/Hero';
import FeaturesTiles from '../../components/sections/FeaturesTiles';
import FeaturesSplit from '../../components/sections/FeaturesSplit';
import Testimonial   from '../../components/sections/Testimonial';
import Cta           from '../../components/sections/Cta';

const main = () => {
  
  return (
    <>
      <Hero/>
      <FeaturesTiles/>
      <FeaturesSplit/>
      {/*<Testimonial/>*/}
      {/*<Cta/>*/}
    </>
  );
};

export default main;
