import { createSlice } from '@reduxjs/toolkit';

import { consumeToken } from '../../components/utils/token';
import { thunkAPI }     from '../../components/utils/api';

let user       = {};
const loadUser = () => {
  user        = {};
  const token = localStorage.getItem('token');
  if (token) user = consumeToken(token);
  return user;
};


export const login            = thunkAPI('auth/login', 'v1/signin', 'post',);
export const changePassword   = thunkAPI('auth/password', 'v1/password', 'post', {successMessage: 'Senha alterada com sucesso'});
export const recoveryPassword = thunkAPI('auth/recovery', 'v1/forgot', 'post', {successMessage: 'Enviado email com instruções de recuperação'});
export const signup           = thunkAPI('auth/signup', 'v1/signup', 'post', {successMessage: 'Cadastro realizado com sucesso'});
export const validation       = thunkAPI('auth/validation', 'v1/signup/validation', 'post');


const authSlice = createSlice({
  name         : 'auth',
  initialState : {
    user               : loadUser(),
    authenticating     : 'idle',
    isAuthenticated    : !!user && user.hasOwnProperty('id'),
    includeSituation   : 'idle',
    passwordSituation  : 'idle',
    validationSituation: 'idle',
  },
  reducers     : {
    logout             : (state) => {
      // console.log("serverApi ", serverApi )
      // localStorage.removeItem('token');
      // user                  = {};
      // state.user            = user;
      state.isAuthenticated = false;
    },
  
  },
  extraReducers: {
    [changePassword.fulfilled]: (state) => {
      state.passwordSituation = 'success';
    },
    [changePassword.pending]  : (state) => {
      state.passwordSituation = 'pending';
    },
    [changePassword.rejected] : (state) => {
      state.passwordSituation = 'rejected';
    },
    [login.fulfilled]         : (state, action) => {

      localStorage.setItem('token', action.payload);
      state.user            = consumeToken(action.payload);
      state.isAuthenticated = true;
      
    },
    [signup.fulfilled]        : (state) => {
      state.includeSituation = 'idle';
    },
    [signup.pending]          : (state) => {
      state.includeSituation = 'pending';
    },
    [signup.rejected]         : (state) => {
      state.includeSituation = 'rejected';
    },
    [validation.fulfilled]    : (state) => {
      state.validationSituation = 'success';
    },
    [validation.pending]      : (state) => {
      state.validationSituation = 'pending';
    },
    [validation.rejected]     : (state) => {
      state.validationSituation = 'rejected';
    },
  }
});


const {reducer, actions} = authSlice;

export const {logout} = actions;

export default reducer;


