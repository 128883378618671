import React, { useEffect, useMemo, }                                 from 'react';
import classes                                                        from '../containerActions/IncludeBusiness.module.css';
import Grid                                                           from '@mui/material/Grid';
import { handleError, rules }                                         from '../utils/rules';
import FormSelect                                                     from '../elements/FormSelect2';
import FormMask                                                       from '../elements/FormMask';
import ShoppingCartIcon                                               from '@mui/icons-material/ShoppingCart';
import { businessList, decimalFormat, integerFormat, paperTypesList } from '../utils/constants';
import AttachMoneyIcon                                                from '@mui/icons-material/AttachMoney';
import LocalAtmIcon                                                   from '@mui/icons-material/LocalAtm';
import FormAutoComplete                                               from '../elements/FormAutoComplete';
import { searchPaper }                                                from '../../store/slices/paper';
import { useDispatch, useSelector }                                   from 'react-redux';
import FormCheckbox                                                   from '../elements/FormCheckbox';
import { getPaperUnderwritingsType }                                  from '../../store/slices/underwriting';
import IconButton                                                     from '../elements/IconButton';
import RemoveCircleIcon                                               from '@mui/icons-material/RemoveCircle';
import FormSelect3                                                    from '../elements/FormSelect3';
import FormSelect4                                                    from '../elements/FormSelect4';


const FieldsFormBusiness = ({errors, control, date, index, id, watches, removeAction, blockChange}) => {
  
  const dispatch = useDispatch();
 
  const {businessType: watchBusinessType, paper: watchPaper, exercise: watchExercise} = watches || {};
  
  const showPaper = (date && !Boolean(errors['date']));
  
  const showCost          = !!watchBusinessType && (watchBusinessType.id === businessList.COMPRA || watchBusinessType.id === businessList.VENDA);
  const showValue         = !!watchBusinessType && watchBusinessType.id !== businessList.IPO && watchBusinessType.id !== businessList.SUB;
  const showSubscription  = !!watchBusinessType && (watchBusinessType.id === businessList.IPO || watchBusinessType.id === businessList.SUB);
  const showSubCost       = !!watchBusinessType && watchBusinessType.id === businessList.SUB;
  const showExercise      = !!watchBusinessType && (watchBusinessType.id === businessList.COMPRA || watchBusinessType.id === businessList.VENDA)
    && (!!watchPaper && watchPaper.cdTipoPapel === paperTypesList.OPCAO);
  const showExercisePaper = !!watchExercise;
  
  
  const subQuery = useMemo(
    () => {
      if (!!watchPaper && !!watchBusinessType && !!date) {
        return {
          data        : {papelId: watchPaper.cdPapel, dataReferencia: date, tipoSubscricao: watchBusinessType.id === businessList.SUB ? 1 : 2},
          internalData: {date, papelId: watchPaper.cdPapel},
        };
      }
      else return null;
    },
    [watchPaper, watchBusinessType, date]
  );
  
  const {papersUnderwritings} = useSelector(state => state.underwriting);
  const {cdPapel}             = watchPaper || {};
  
  
  useEffect(() => {
    
    if (!!watchBusinessType && (watchBusinessType.id === businessList.IPO || watchBusinessType.id === businessList.SUB) && !!watchPaper && date) {
      if (!papersUnderwritings[cdPapel] || !papersUnderwritings[cdPapel][date]) dispatch(getPaperUnderwritingsType(subQuery));
    }
  }, [watchPaper, watchBusinessType, date]);
  
  return (
    <Grid container spacing={2} key={id} className={classes.InternalContainer}
          style={{backgroundColor: index % 2 === 0 ? 'rgb(238, 238, 238)' : '#D0E4F5'}}>
      <Grid item xs={12} sm={3} className={classes.Item}>
        <FormSelect4 label="Tipo de Operação" name={`items[${index}].businessType`} errorMessage={handleError(errors, 'businessType')}
                     displayField={'nome'} rules={(rules('businessType', null, 'Tipo de Negócio'))}
                     dataFields={{slice: 'business', data: 'businessTypes'}} control={control} valueField={'id'}
        />
      </Grid>
      <Grid item xs={12} sm={3} className={classes.Item}>
        <FormMask label="Quantidade" name={`items[${index}].quantity`} Icon={ShoppingCartIcon}
                  errorMessage={handleError(errors, 'quantity')} control={control}
                  rules={rules('quantity', null, 'Quantidade')} formatOptions={integerFormat}
        />
      </Grid>
      {showValue &&
      <Grid item xs={12} sm={3} className={classes.Item}>
        <FormMask label="Valor Unitário Papel" name={`items[${index}].value`} Icon={AttachMoneyIcon}
                  errorMessage={handleError(errors, 'value')} control={control}
                  rules={rules('value')} formatOptions={decimalFormat}
        />
      </Grid>
      }
      {showCost &&
      <Grid item xs={12} sm={3} className={classes.Item}>
        <FormMask label="Custo Total Negócio" name={`items[${index}].cost`} Icon={LocalAtmIcon}
                  errorMessage={handleError(errors, 'cost')} control={control}
                  rules={rules('cost', null, 'Custo')} formatOptions={decimalFormat}
        />
      </Grid>
      }
      {showSubCost &&
      <Grid item xs={12} sm={3} className={classes.Item}>
        <FormMask label="Custo Compra Total Direito" name={`items[${index}].subCost`} Icon={LocalAtmIcon}
                  errorMessage={handleError(errors, 'subCost')} control={control}
                  rules={rules('cost', null, 'Custo')} formatOptions={decimalFormat}
        />
      </Grid>
      }
      {showPaper &&
      <Grid item xs={12} sm={3} className={classes.Item}>
        <FormAutoComplete label="Papel" name={`items[${index}].paper`} placeholder="Pesquise o papel" control={control}
                          dataFunction={searchPaper} searchField={'busca'} requiredData={{dataPesquisa: date}}
                          dataFields={{slice: 'paper', data: 'papersSearched'}}
                          displayField="sigla" rules={(rules('paper', null, 'Papel'))} errorMessage={handleError(errors, 'paper')}/>
      </Grid>
      }
      {showExercise &&
      <Grid item xs={12} sm={3} className={classes.Item}>
        <FormCheckbox label="É exercício" name={`items[${index}].exercise`} control={control}/>
      </Grid>
      }
      {!!watchPaper && showSubscription &&
      <Grid item xs={12} sm={3} className={classes.Item}>
        <FormSelect label="Data Exata Subscrição/ipo" name={`items[${index}].underwriting`} errorMessage={handleError(errors, 'underwriting')}
                    dataFields={{slice: 'underwriting', data: 'papersUnderwritings', field1: cdPapel, field2: date}} displayField={'name'}
                    rules={(rules('underwriting', null, 'Data'))} control={control}
        />
      </Grid>
      }
      {showExercisePaper &&
      <Grid item xs={12} sm={3} className={classes.Item}>
        <FormAutoComplete label="Papel Exercício" name={`items[${index}].exercisePaper`} placeholder="Pesquise o papel" control={control}
                          dataFunction={searchPaper} searchField={'busca'} dataFields={{slice: 'paper', data: 'papersSearched'}}
                          requiredData={{dataPesquisa: date}} displayField="sigla"
                          rules={(rules('paper', null, 'Papel Exercício'))} errorMessage={handleError(errors, 'exercisePaper')}/>
      </Grid>
      }
      {!blockChange &&
        <Grid item xs={12} sm={3} className={classes.RemoveOpButton}>
          <IconButton aria-label="remove" tooltip="remover essa operação" color="secondary" icon={RemoveCircleIcon} onClick={() => removeAction(index)}/>
        </Grid>
      }
    </Grid>
  
  );
  
};

export default FieldsFormBusiness;
