import React, { useEffect }         from 'react';
import Grid                         from '@mui/material/Grid';
import classes       from './DateBar.module.css';
import FormDate      from '../elements/FormDate';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { handleError }              from '../utils/rules';
import { useForm }                  from 'react-hook-form';
import Button                       from '../elements/Button';
import { justToDate, subtractDays } from '../utils/date';


const today = justToDate();
const base  = justToDate(subtractDays(today, 364));

const defaultValues = {
  initialDate: base,
  finalDate  : today,
};

const DateBar = ({getDates}) => {
  
  const {handleSubmit, control, getValues, formState, } = useForm({mode: 'all', defaultValues});
  
  const {errors} = formState;
  
  useEffect(() => {
    getDates(getValues());
  }, []);
  
  
  const onSubmit = (data) => {
    // console.log("data", data)
    getDates(data);
  };
  
  return (
    <Grid container spacing={3} justifyContent="center" className={classes.Container}>
      <Grid item xs={12} md={3}>
        <FormDate label="Data Inicio" name="initialDate" Icon={DateRangeIcon} errorMessage={handleError(errors, 'initialDate')}
                  control={control} rules={{required: true}}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <FormDate label="Data Fim" name="finalDate" Icon={DateRangeIcon} errorMessage={handleError(errors, 'finalDate')}
                  control={control} rules={{required: true}}
        />
      </Grid>
      <Grid item xs={12} md={3} className={classes.Button}>
        <Button color="secondary" onClick={handleSubmit(onSubmit)}>
          Consultar
        </Button>
      </Grid>
    </Grid>
  );
};

export default DateBar;
