import React from 'react';


import classes    from '../containerActions/IncludeBusiness.module.css';
import Dialog     from '../elements/Dialog';
import Grid       from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

const HelpIncludeBusiness = ({modalOn, closeModal}) => {
  
  
  let modal;
  
  modal = <Dialog id="business-help" show={modalOn} handleClose={closeModal} title={'Ajuda Inclusão Negócios'}>
    <Grid container spacing={2}>
      <Grid item xs={12} className={classes.Item}>
        <Typography variant="h5" color={'primary'} gutterBottom>
          Existem duas opções de impostação dos dados
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} className={classes.Item}>
        <Typography variant="h6">
          Manual
        </Typography>
        <Typography variant="body2" gutterBottom>
          O usuário é responsável por informar todos os dados da negociação
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} className={classes.Item}>
        <Typography variant="h6">
          Nota de Corretagem
        </Typography>
        <Typography variant="body2" gutterBottom>
          Através do envio da nota de corretagem em PDF, o sistema interpreta os dados, e mostra em tela para confirmação do usuário
        </Typography>
      </Grid>
    </Grid>
  </Dialog>;
  
  return (
    <>
      {modal}
    </>
  );
};

export default HelpIncludeBusiness;
