import React, { useEffect, useState } from 'react';
import classes                        from './CompanyEdit.module.css';
import Dialog                       from '../elements/Dialog';
import Grid                         from '@mui/material/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { includePaidTax }           from '../../store/slices/tax';
import { handleError, rules }       from '../utils/rules';
import Button                       from '../elements/Button';
import { useForm }                  from 'react-hook-form';
import AttachMoneyIcon              from '@mui/icons-material/AttachMoney';
import { currencyFormat }           from '../utils/constants';
import FormMask                     from '../elements/FormMask';
import HelpTax                      from '../helpers/HelpTax';


const defaultValues = {
  holdingAmount: '',
  paidAmount   : '',
};

const TaxEdit = ({data, modalOn, closeModal}) => {
  
  
  const dispatch = useDispatch();
  
  const {includeSituation}  = useSelector(state => state.tax);
  const [helpOn, setHelpOn] = useState(false);
  
  const {handleSubmit, formState, control, reset} = useForm({mode: 'all', defaultValues});
  
  const {isValid, errors} = formState;
  
  const {ano, mes, tipoPapelId, tipoOperacaoId, vlImpostoRetido, vlImpostoPago} = data || {};
  
  
  useEffect(() => {
    
      defaultValues.holdingAmount = Number(vlImpostoRetido);
      defaultValues.paidAmount    = Number(vlImpostoPago);
      reset(defaultValues);
    
  }, [data]);
  
  
  
  
  const onSubmit = (dataChanged) => {
    const {holdingAmount, paidAmount,} = dataChanged;
    dispatch(includePaidTax(
      {
        data        : {
          tipoPapel         : tipoPapelId,
          tipoOperacao      : tipoOperacaoId,
          anoNegocio        : ano,
          mesNegocio        : mes,
          vlImpostoRetido   : holdingAmount || 0,
          vlImpostoRecolhido: paidAmount || 0
        },
        internalData: {vlImpostoRetido: holdingAmount || 0, vlImpostoPago: paidAmount || 0, index: data.index}
      })
    );
    
  };
  
  const handleHelp = () => {
    setHelpOn((prevOpen) => !prevOpen);
  };
  
  const disabled = (!isValid || includeSituation === 'pending');
  
  return (
    <>
      <Dialog id="tax-edit-modal" show={modalOn} handleClose={closeModal} handleHelp={handleHelp} title={'Edição de Imposto'}>
        <form id="tax-manual" onSubmit={handleSubmit(onSubmit)} className={classes.Form}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} className={classes.Item}>
              <FormMask label="Valor Retido" name="holdingAmount" Icon={AttachMoneyIcon} defaultValue={defaultValues['holdingAmount']}
                        errorMessage={handleError(errors, 'holdingAmount')} control={control}
                        rules={rules('holdingAmount', null, 'Valor Retido')} formatOptions={currencyFormat}
              />
            </Grid>
            <Grid item xs={12} sm={6} className={classes.Item}>
              <FormMask label="Valor Pago" name="paidAmount" Icon={AttachMoneyIcon} defaultValue={defaultValues['paidAmount']}
                        errorMessage={handleError(errors, 'paidAmount')} control={control}
                        rules={rules('paidAmount', null, 'Valor Pago')} formatOptions={currencyFormat}
              />
            </Grid>
            
            <Grid item xs={12} className={classes.Button}>
              <Button color="secondary" disabled={disabled} type="submit">
                {includeSituation === 'pending' ? 'Ajustando' : 'Ajustar'}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Dialog>
      <HelpTax modalOn={helpOn} closeModal={handleHelp}/>
    </>
  );
  
};


export default TaxEdit;
