import React, { useEffect, useMemo, useState } from 'react';
import { formatCpfCnpj }                       from '../../components/utils/formatters';
import Grid                                    from '@mui/material/Grid';
import Table                                   from '../../components/elements/Table';
import { useDispatch, useSelector }            from 'react-redux';
import { getCompanies }                        from '../../store/slices/company';
import IncludeCompany                          from '../../components/containerActions/IncludeCompany';
import CompanyHistory                          from '../../components/partials/CompanyHistory';
import HistoryButton                           from '../../components/elements/HistoryButton';
import EditButton                              from '../../components/elements/EditButton';
import CompanyEdit                             from '../../components/containerActions/CompanyEdit';

const Company = () => {
  
  const dispatch = useDispatch();
  
  const {companies} = useSelector(state => state.company);
  
  
  useEffect(() => {
    dispatch(getCompanies());
  }, []);
  
  const [rowData, setRowData] = useState(null);
  
  const [editOn, setEditOn]       = useState(false);
  const [historyOn, setHistoryOn] = useState(false);
  
  const openEdit     = (rowData) => {
    setRowData({...rowData.original, index: rowData.index});
    setEditOn(true);
  };
  const closeEdit    = () => {
    setEditOn(false);
    setRowData(null);
  };
  const openHistory  = (rowData) => {
    setRowData({...rowData.original, index: rowData.index});
    setHistoryOn(true);
  };
  const closeHistory = () => {
    setHistoryOn(false);
    setRowData(null);
  };
  
  const columns = useMemo(
    () =>
      [
        {
          accessor: 'nome',
          Header  : 'Nome',
        },
        {
          accessor: 'cnpj',
          Header  : 'CNPJ',
          Cell    : ({value}) => formatCpfCnpj(value)
        },
        {
          accessor: 'vigente',
          Header  : 'Vigente',
        },
        {
          Header: 'Histórico',
          id    : 'History',
          Cell  : HistoryButton(openHistory)
        },
        {
          Header: 'Ações',
          id    : 'Actions',
          Cell  : EditButton('editar', openEdit)
        },
      ],
    []
  );
  
  return (
    <Grid container>
      <Grid item xs={12}>
        {columns.length && <Table columns={columns} rowsData={companies} inclusion={<IncludeCompany/>}/>}
        <CompanyHistory data={rowData} modalOn={historyOn} closeModal={closeHistory}/>
        <CompanyEdit data={rowData} modalOn={editOn} closeModal={closeEdit}/>
      </Grid>
    </Grid>
  );
};

export default Company;
