import React            from 'react';
import { makeStyles }   from '@mui/material/styles';
import Accordion        from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography       from '@mui/material/Typography';
import ExpandMoreIcon   from '@mui/icons-material/ExpandMore';
import Grid           from '@mui/material/Grid';
import classes        from './MyPlanDetail.module.css';
import Box            from '../elements/Box';
import { formatDate } from '../utils/formatters';
import PlanChange     from '../sections/PlanChange';


const MyPlanDetail = ({userPlan}) => {
  
  
  return (
    <Grid container>
      <Grid item xs={8} className={classes.Plan}>
        <Typography variant="h5" className={classes.Text} gutterBottom>
          Meu Plano
        </Typography>
      </Grid>
      <Accordion defaultExpanded={true}>
        <AccordionSummary expandIcon={<ExpandMoreIcon/>} aria-controls="panel1a-content" id="panel1a-header">
          <Typography className={classes.Title}>{userPlan.descricao}</Typography>
          <Typography className={classes.SubTitle}> Vigente até {formatDate(userPlan.validade)}</Typography>
        </AccordionSummary>
        <AccordionDetails>
         <PlanChange userPlan={userPlan} />
        </AccordionDetails>
      </Accordion>
    
    
    </Grid>
  );
};


export default MyPlanDetail;
