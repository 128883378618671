import React, { useEffect }         from 'react';
import classes                      from './CompanyEdit.module.css';
import Dialog                       from '../elements/Dialog';
import Grid                         from '@mui/material/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { editCompany }              from '../../store/slices/company';
import InputText                    from '../elements/FormText';
import PersonIcon                   from '@mui/icons-material/Person';
import { handleError, rules }       from '../utils/rules';
import Button                       from '../elements/Button';
import { useForm }                  from 'react-hook-form';
import FormSwitch                   from '../elements/FormSwitch';
import FormDate                     from '../elements/FormDate';
import CakeIcon                     from '@mui/icons-material/Cake';

const defaultValues = {
  name         : '',
  current      : false,
  endValidity  : '',
  startValidity: '',
  
};

const CompanyEdit = ({data, modalOn, closeModal}) => {
  
  const dispatch = useDispatch();
  
  const {includeSituation}                               = useSelector(state => state.company);
  const {handleSubmit, watch, formState, control, reset} = useForm({mode: 'all', defaultValues});
  
  const {isValid, errors} = formState;
  const watchCurrent      = watch('current');
  
  useEffect(() => {
    
    if (data) {
      defaultValues.name    = data.nome;
      defaultValues.current = data.vigente === 'sim';
    }
    reset(defaultValues);
  }, [data]);
  
  
  const onSubmit = (dataChanged) => {
    const {name, current, endValidity, startValidity} = dataChanged;
    
    // console.log("dataChanged", dataChanged)
    dispatch(editCompany(
      {
        urlId       : data.id,
        data        : {nome: name, vigente: current, dataInicio: startValidity, dataFim: endValidity},
        internalData: {id: data.id, nome: name, vigente: current, dataInicio: startValidity, dataFim: endValidity, index: data.index}
      })
    );
    
  };
  
  
  const disabled = (!isValid || includeSituation === 'pending');
  
  return (
    <Dialog id="company-edit-modal" show={modalOn} handleClose={closeModal} title={'Edição da Empresa'}>
      <form id="company" onSubmit={handleSubmit(onSubmit)} className={classes.Form}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} className={classes.Item}>
            <InputText label="Nome Empresa" name="name" Icon={PersonIcon} errorMessage={handleError(errors, 'name')}
                       control={control} rules={rules('name',)}
            />
          </Grid>
          <Grid item xs={12} sm={6} className={classes.Item}>
            {watchCurrent &&
            <FormDate label="Nova Vigência" name="startValidity" Icon={CakeIcon} errorMessage={handleError(errors, 'startValidity')}
                      control={control} rules={{required: true}}
            />
            }
            {!watchCurrent && <FormDate label="Fim Vigência" name="endValidity" Icon={CakeIcon} errorMessage={handleError(errors, 'endValidity')}
                                        control={control} rules={{required: true}}
            />
            }
          </Grid>
          
          <Grid item xs={12} sm={6} className={classes.Switch}>
            <FormSwitch label={'Vigente'} name={'current'} control={control} />
          </Grid>
          <Grid item xs={12} className={classes.Button}>
            <Button color="secondary" disabled={disabled} type="submit">
              {includeSituation === 'pending' ? 'Cadastrando' : 'Cadastrar'}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Dialog>
  );
  
};


export default CompanyEdit;
