import React, { useEffect, useMemo } from 'react';

import classes         from './Input.module.css';
import FormHelperText  from '@mui/material/FormHelperText';
import FormControl     from '@mui/material/FormControl';
import InputLabel      from '@mui/material/InputLabel';
import Select          from '@mui/material/Select';
import MenuItem        from '@mui/material/MenuItem';
import { Controller }  from 'react-hook-form';
import { useDispatch } from 'react-redux';


const FormSelect = ({label, name, dataFunction, data, control, displayField, requiredData, valueField, rules, errorMessage, ...others}) => {
  
  const dispatch = useDispatch();
  
  useEffect(() => {
    if (dataFunction && requiredData) dispatch(dataFunction(requiredData));
    
  }, []);
  
  
  
  const mountItems = useMemo(
    () => {
      const items = [];
      for (const value of data) {
        const myValue = !!valueField ? value[valueField] : value
        items.push(
          <MenuItem value={JSON.stringify(myValue)} key={`${value[displayField]}`}>{value[displayField]}</MenuItem>
        );
      }
      return items;
    },
    [data]
  );
  
  return (
    
    <FormControl aria-label={name} variant="outlined" fullWidth className={classes.Field}>
      <InputLabel error={!!errorMessage} htmlFor={name}>{label}</InputLabel>
      <Controller name={name} control={control} rules={rules}
                  render={({field}) => (
                    <Select {...field} labelId={`${name}-label`} id={name} error={!!errorMessage} {...others}
                            label={label}>
                      <MenuItem value="" disabled>
                        <em>Selecione</em>
                      </MenuItem>
                      {mountItems}
                    </Select>
                  )}/>
      {!!errorMessage && <FormHelperText error margin="dense">{errorMessage}</FormHelperText>}
    
    </FormControl>
  );
};


export default FormSelect;
