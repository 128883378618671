import React, { useCallback, useMemo, useRef, useState } from 'react';

import {
  useTable, useFilters, usePagination, useGlobalFilter,
  useSortBy, useExpanded,
}                        from 'react-table';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import classes           from './Table.module.css';
import ListItemText      from '@mui/material/ListItemText';
import MenuItem          from '@mui/material/MenuItem';
import TextField         from '@mui/material/TextField';
import Grid              from '@mui/material/Grid';
import Pagination        from '@mui/material/Pagination';
import Tooltip           from '@mui/material/Tooltip';
import IconButton        from '@mui/material/IconButton';
import FilterListIcon    from '@mui/icons-material/FilterList';
import Select            from '@mui/material/Select';
import DetailTableButton from './DetailTableButton';
import Checkbox          from '@mui/material/Checkbox';
import * as sortTypes    from '../utils/tableSorts';


const mySearch            = (rows, id, filterValue) => {
  
  if (!filterValue || !filterValue.length) return rows;
  
  return rows.filter(row => {
    return filterValue.includes(row.values[id]);
  });
};
const defaultColumnFilter = ({column: {filterValue, setFilter, id},}) => {
  
  return (
    <TextField className={classes.SearchColumn}
               id={`Fi-${id}`}
      // label="Filtre esse campo"
               placeholder={'Filtre aqui'}
               size="small"
               name={`Nom-${id}`}
               fullWidth
               variant={'outlined'}
      // InputLabelProps={ { shrink: true } }
               value={filterValue || ''}
               onChange={e => setFilter(e.target.value || undefined)}
    />
  
  );
};
const SelectColumnFilter  = ({column: {filterValue, setFilter, preFilteredRows, id},}) => {
  
  const options = useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach(row => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);
  
  return (
    <Select
      className={classes.SearchBoxColumn}
      labelId={`filter-checkbox-${id}`}
      id={`filter-checkbox-${id}`}
      value={filterValue || []}
      multiple
      renderValue={(selected) => selected.join(', ')}
      onChange={e => setFilter(e.target.value || undefined)}
    >
      {options.map((option, i) => (
        <MenuItem key={i} value={option}>
          <Checkbox color="primary" checked={!!filterValue && filterValue.indexOf(option) > -1}/>
          <ListItemText primary={option}/>
        </MenuItem>
      ))}
    </Select>
  );
};
const mountHeader         = (headerGroups, fieldFilters) => {
  const myHeader = [];
  
  for (const headerGroup of headerGroups) {
    
    const internalHeader = [];
    for (const column of headerGroup.headers) {
      let sortable = null;
      if (!!column.canSort) {
        let style = classes.SortNone;
        if (!!column.isSortedDesc) style = classes.SortDesc;
        else if (column.isSortedDesc === false) style = classes.SortAsc;
        sortable = <span {...column.getSortByToggleProps()} className={[classes.Sort, style].join(' ')}/>;
      }
      
      switch (column.newFilter) {
        case 'SelectColumnFilter':
          column.Filter = SelectColumnFilter;
          column.filter = mySearch;
          break;
        default:
      }
      
      internalHeader.push(
        <th {...column.getHeaderProps()}>
          <div style={{display: 'inline'}}>
            {/*<div style={{display: 'flex', justifyContent: 'center'}}>*/}
            <span>{column.render('Header')}</span>
            {sortable}
            {
              column.sortedIndex > -1 && <span style={{
                marginLeft: '0.5em',
                fontSize  : '0.7rem',
                float     : 'right'
              }}> {column.sortedIndex + 1}      </span>
            }
          </div>
          {!!fieldFilters && <div>{column.canFilter ? column.render('Filter') : null}</div>}
        </th>
      );
    }
    myHeader.push(
      <tr {...headerGroup.getHeaderGroupProps()}>
        {internalHeader}
      </tr>
    );
    
  }
  
  return <thead>
  {myHeader}
  </thead>;
};
const mountBody           = (rows, prepareRow, visibleColumns, getTableBodyProps, detailButton, RowDetail) => {
  
  const myBody = [];
  // column.cell.props['mobile-label'] = column.header.label;
  for (const row of rows) {
    prepareRow(row);
    const internalBody = [];
    for (const cell of row.cells) {
      let renderData = cell.render('Cell');
      if (cell.column.id === 'expander') renderData = cell.render(detailButton);
      internalBody.push(
        <td {...cell.getCellProps()} mobile-label={cell.column.Header}>
          {renderData}
        </td>
      );
    }
    const rowProps = row.getRowProps();
    // console.log("row", row)
    myBody.push(
      <React.Fragment key={rowProps.key}>
        <tr {...rowProps}>
          {internalBody}
        </tr>
        {row.isExpanded && !!RowDetail &&
        <tr>
          <td colSpan={visibleColumns.length}>
            <RowDetail row={row.original}/>
          </td>
        </tr>
        }
      </React.Fragment>
    );
    
  }
  
  return <tbody {...getTableBodyProps()}>
  {myBody}
  </tbody>;
};

const Table = ({columns, rowsData, hideFilter, detailComponent, inclusion}) => {
  
  const defaultColumn = React.useMemo(
    () => ({Filter: defaultColumnFilter,}),
    []
  );
  
  
  
  const {
          getTableProps,
          getTableBodyProps,
          headerGroups,
          rows,
          page,
          pageOptions,
          prepareRow,
          visibleColumns,
          gotoPage,
          state: {pageIndex, pageSize, globalFilter},
          setGlobalFilter,
        } = useTable({
    columns, data: rowsData, initialState: {pageSize: 30}, defaultColumn, sortTypes, isMultiSortEvent: () => true,
  }, useGlobalFilter, useFilters, useSortBy, useExpanded, usePagination,);
  
  const anchorRef = useRef(null);
  
  React.useEffect(() => {
    // props.dispatch({ type: actions.resetPage })
    
  }, [globalFilter]);
  
  const detailButton = useCallback(
    ({row}) => DetailTableButton(row),
    []
  );
  
  const changePage = (event, value) => {
    gotoPage(value - 1);
  };
  
  const [fieldFilters, setFieldFilters] = useState(false);
  
  const handleIndividualFilter = () => {
    setFieldFilters(!fieldFilters);
  };
  
  const showPages = rows.length > pageSize;
  
  return (
    
    <Grid container>
      {!hideFilter &&
      <Grid item xs={12} className={classes.FilterContainer}>
        <Grid container alignItems={'center'}>
          <Grid item xs={12} sm={6}>
            <Grid container>
              <Grid item xs={2} sm={1}>
                <Tooltip title={'Filtros por campo'} placement="bottom" arrow>
                  <IconButton color="inherit" onClick={handleIndividualFilter} ref={anchorRef}>
                    <FilterListIcon/>
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item xs={10}>
                <TextField
                  id="outlined-filter-text"
                  label="Filtre aqui qualquer coisa"
                  name="Filtro"
                  fullWidth
                  variant="outlined"
                  value={globalFilter || ''}
                  onChange={e => setGlobalFilter(e.target.value)}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} className={classes.Inclusion}>
            {inclusion}
          </Grid>
        </Grid>
      </Grid>
      }
      <Grid item xs={12} className={classes.ScrollTable}>
        <table className={classes.MyTable} {...getTableProps()}  >
          {mountHeader(headerGroups, fieldFilters)}
          {mountBody(page, prepareRow, visibleColumns, getTableBodyProps, detailButton, detailComponent,)}
        </table>
      </Grid>
      {showPages &&
      <Grid item xs={12} className={classes.FilterContainer}>
        <Pagination count={pageOptions.length} variant="outlined" shape="rounded" page={pageIndex + 1}
                    showFirstButton showLastButton
                    onChange={changePage}/>
      </Grid>
      }
    
    </Grid>
  
  );
  
};


export default Table;
