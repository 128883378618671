import React, { useEffect, useState } from 'react';


import Grid    from '@mui/material/Grid';
import classes from './Plans.module.css';


import { useDispatch, useSelector } from 'react-redux';
import Box                          from '../elements/Box';
import Typography                   from '@mui/material/Typography';
import { formatCurrency }           from '../utils/formatters';

import AddOnCard from '../elements/AddOnCard';

import Pay                                       from './Pay';
import { includeUserConsume, eraseAddOnMessage } from '../../store/slices/user';

const pack1Price = 3;
const pack2Price = 20;


const PlanAddon = () => {
  
  const [packs, setPacks]        = useState({});
  const [packages, setPackages]        = useState({1: 0});
  const {addOnSituation, addOnMessage} = useSelector(state => state.user);
  const dispatch                       = useDispatch();
  

  
  const selectPackage = (pack, value) => {
    
    const myPackages = {...packages};
    const myPacks = {...packs};
    
    myPackages[pack] = value * eval(`pack${pack}Price`);
  
    myPacks[pack] = value;
    
    setPackages(myPackages);
    setPacks(myPacks);
  };
  
  const eraseMessage = () => {
    dispatch(eraseAddOnMessage());
  };
  
  
  const totalPrice = Object.values(packages).reduce((acc, vl) => acc + vl);
  
  return (
    
    <Grid container justifyContent={'center'}>
      <Grid item xs={12}>
        <Typography variant="h6" className={classes.Text} align="justify" gutterBottom>
          Caso tenha necessidade de realizar transações (inclusão/exclusão) no mês numa quantidade maior do que a contida no plano, deverá ser adquirido
          um dos pacotes adicionais abaixo.
        </Typography>
      </Grid>
      <Grid item xs={12} className={classes.CardAlign}>
        <AddOnCard title={'Pacote com 100 transações'} subtitle={'para aqueles meses em que você operou um pouco mais'} price={pack1Price}
                   selectPackage={selectPackage} pack={1}/>
      </Grid>
      <Grid item xs={12} className={classes.CardAlign}>
        <AddOnCard title={'Pacote com 1000 transações'} subtitle={'inclusão de muitos negócios, presentes ou passados'} price={pack2Price}
                   selectPackage={selectPackage} pack={2}/>
      </Grid>
      <Grid item xs={12} md={8} className={classes.Plan}>
        <Typography variant="body1" className={classes.Text} align="justify" gutterBottom>
          Os pacotes não expiram desde que possua um plano ativo.
        </Typography>
      </Grid>
      <Grid item xs={12} md={8}>
        <Box>
          <Grid container justifyContent={'space-between'} alignItems={'center'}>
            <Grid item xs={9} className={classes.Plan}>
              <Typography variant="body1" className={classes.Text}>
                Valor a Pagar
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="body1" className={classes.MyText}>
                {formatCurrency(totalPrice)}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={12} className={classes.Button}>
        <Pay description={'Pacotes Adicionais'} price={totalPrice} closeAction={eraseMessage} message={addOnMessage}
             paySituation={addOnSituation} action={includeUserConsume} contractData={{packs}}/>
      </Grid>
    </Grid>
  );
  
};


export default PlanAddon;
