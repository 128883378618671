import React, { useEffect, useMemo } from 'react';
import Grid                         from '@mui/material/Grid';
import Table                        from '../../components/elements/Table';
import { useDispatch, useSelector } from 'react-redux';
import { formatCurrency, formatDate } from '../../components/utils/formatters';
import { getMarketValues }            from '../../store/slices/marketValue';
import CaptureMarketValue             from '../../components/partials/CaptureMarketValue';



const MarketValue = () => {
  
  const {marketValues} = useSelector(state => state.marketValue);
  const dispatch       = useDispatch();
  
  useEffect(() => {
    dispatch(getMarketValues());
  }, []);
  
  const columns = useMemo(
    () =>
      [
        {
          accessor: 'papel',
          Header  :  'Papel',
        },
        {
          accessor: 'dataCotacao',
          Header  :'Data da Cotação',
          Cell: ({value}) => formatDate(value)
        },
        {
          accessor: 'valorCotacao',
          Header  :  'Valor da Cotação',
          Cell: ({value}) => formatCurrency(value)
          
        },
      ],
    []
  );
  
  return (
    <Grid container>
      <Grid item xs={12}>
        <Table columns={columns} rowsData={marketValues} inclusion={<CaptureMarketValue/>}/>
      </Grid>
    </Grid>
  );
};

export default MarketValue;
