import React, { useEffect } from 'react';
import classes              from './ReceiptEdit.module.css';
import Dialog                       from '../elements/Dialog';
import Grid                         from '@mui/material/Grid';
import { useDispatch, useSelector } from 'react-redux';

import { handleError, rules } from '../utils/rules';
import Button                 from '../elements/Button';
import { useForm }            from 'react-hook-form';


import FormMultipleSelect from '../elements/FormMultipleSelect';
import { editReceipt }    from '../../store/slices/receipt';


const defaultValues = {
  receipts: [],
};

let title   = '';
let recibos = [];


const ReceiptEdit = ({data, modalOn, closeModal}) => {
  
  const dispatch = useDispatch();
  
  const {includeSituation}                        = useSelector(state => state.paper);
  const {handleSubmit, formState, reset, control} = useForm({mode: 'all', defaultValues});
  
  const {isValid, errors} = formState;
  
  useEffect(() => {
    
    if (data) {
      // console.log('data', data, defaultValues);
      defaultValues.receipts = data.recibos.map(rec => rec.papelId);
      recibos                = data.recibos;
      title                  = data.sigla;
    }
    reset(defaultValues);
  }, [data]);
  
  
  const onSubmit = (dataChanged) => {
    const {receipts} = dataChanged;
    
    // console.log('dataChanged', dataChanged);
    dispatch(editReceipt(
      {
        urlId       : data.id,
        data        : {recibos: [data.papelId, ...receipts], papelId: data.papelId},
        internalData: {recibos: receipts, index: data.index}
      })
    );
    
  };
  
  const disabled = (!isValid || includeSituation === 'pending');
  
  return (
    <Dialog id="receipt-edit-modal" show={modalOn} handleClose={closeModal} title={`Edição de Recibo ${title}`}>
      <form id="receipt" onSubmit={handleSubmit(onSubmit)} className={classes.Form}>
        <Grid container spacing={2}>
          
          <Grid item xs={12} className={classes.Select}>
            <FormMultipleSelect label="Recibos" name="receipts" errorMessage={handleError(errors, 'receipts')}
                                displayField={'sigla'} valueField={'papelId'}
                                rules={(rules('part', null, 'Lote Padrão'))} data={recibos} control={control} defaultValue={defaultValues.receipts}
            />
          </Grid>
          
          <Grid item xs={12} className={classes.Button}>
            <Button color="secondary" disabled={disabled} type="submit">
              {includeSituation === 'pending' ? 'Alterando' : 'Alterar'}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Dialog>
  );
  
};


export default ReceiptEdit;
