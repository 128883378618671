import React, {useState} from 'react';

import { useForm }                  from 'react-hook-form';
import { useDispatch,  } from 'react-redux';
import classes                      from './IncludeRearrangement.module.css';
import Button                       from '../elements/Button';
import Dialog                       from '../elements/Dialog';

import { decimalFormat, rearrangeType, }              from '../utils/constants';
import { handleError, rules }                         from '../utils/rules';
import Grid                                           from '@mui/material/Grid';
import { searchAdmPaper }                             from '../../store/slices/paper';
import FormDate                                       from '../elements/FormDate';
import CakeIcon                                       from '@mui/icons-material/Cake';
import FormAutoComplete                               from '../elements/FormAutoComplete';
import FormMask                                       from '../elements/FormMask';
import HighlightOffTwoToneIcon                        from '@mui/icons-material/HighlightOffTwoTone';
import { computeRearrangement, includeRearrangement } from '../../store/slices/rearrangement';
import FormSwitch                                     from '../elements/FormSwitch';
import FormSelect                                     from '../elements/FormSelect';

const defaultValues = {
  creditDate       : '',
  rearrangementDate: '',
  paper            : null,
  multiplier       : '',
  type             : '',
  schedule         : false,
};

const IncludeRearrangement = () => {
  
  const dispatch = useDispatch();
  
  const [modalOn, setModalOn] = useState(false);
  
  const {control, handleSubmit, getValues, formState} = useForm({mode: 'all', defaultValues});
  
  const {dirtyFields, isValid, errors} = formState;
  
  let modal;
  
  const openModal = () => {
    setModalOn(true);
  };
  
  const closeModal = () => setModalOn(false);
  
  const onSubmit = (data) => {
    
    let {rearrangementDate, creditDate, paper, multiplier, type, schedule} = data;
    const func                                                               = schedule ? includeRearrangement : computeRearrangement;
 
    if (creditDate === '') creditDate = null; 
    
    dispatch(func({
      papelId      : paper.cdPapel,
      dataRearranjo: rearrangementDate,
      dataCredito  : creditDate,
      tipoRearranjo: type,
      multiplicador: multiplier
    }));
    
  };
  
  const disabled  = !isValid;
  const showPaper = (!!dirtyFields['rearrangementDate'] && !Boolean(errors['rearrangementDate']));
  
  modal = <Dialog id="rearrangement-modal" show={modalOn} handleClose={closeModal} title={'Inclusão Rearranjo'}>
    
    <form id="rearrangement" onSubmit={handleSubmit(onSubmit)} className={classes.Form}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} className={classes.Item}>
          <FormDate label="Data Direito" name="rearrangementDate" Icon={CakeIcon} errorMessage={handleError(errors, 'rearrangementDate')}
                    control={control} rules={rules('businessDate')}
          />
        </Grid>
        <Grid item xs={12} sm={6} className={classes.Item}>
          <FormDate label="Data Crédito" name="creditDate" Icon={CakeIcon} errorMessage={handleError(errors, 'creditDate')}
                    control={control}
          />
        </Grid>
        <Grid item xs={12} sm={6} className={classes.Item}>
          <FormMask label="Multiplicador" name="multiplier" Icon={HighlightOffTwoToneIcon} defaultValue={defaultValues['multiplier']}
                    errorMessage={handleError(errors, 'multiplier')} control={control}
                    rules={rules('value')} formatOptions={decimalFormat}
          />
        </Grid>
        <Grid item xs={12} sm={6} className={classes.Item}>
          <FormSelect label="Tipo Rearranjo" name="type" errorMessage={handleError(errors, 'type')}
                      displayField={'name'} valueField={'value'}
                      rules={(rules('exchange', null, 'Corretora'))} data={rearrangeType} control={control} defaultValue={defaultValues.type}
          />
        </Grid>
        <Grid item xs={12} sm={6} className={classes.Item}>
          {showPaper &&
          <FormAutoComplete label="Papel" name="paper" placeholder="Pesquise o papel" control={control} dataFields={{slice: 'paper', data: 'papersSearched'}}
                            dataFunction={searchAdmPaper} searchField={'busca'} requiredData={{dataPesquisa: getValues('rearrangementDate')}}
                            displayField="sigla" rules={(rules('paper', null, 'Papel'))} errorMessage={handleError(errors, 'paper')}/>
          }
        </Grid>
        <Grid item xs={12} sm={6} className={classes.Switch}>
          <FormSwitch label={'Agendar'} name={'schedule'} control={control} defaultValue={defaultValues['schedule']}/>
        </Grid>
        <Grid item xs={12} className={classes.Button}>
          <Button color="secondary" disabled={disabled} type="submit">
            Cadastrar
          </Button>
        </Grid>
      </Grid>
    </form>
  </Dialog>;
  
  return (
    <>
      <Button color="secondary" disabled={!!modalOn} onClick={openModal}>
        Incluir
      </Button>
      {modal}
    </>
  );
};

export default IncludeRearrangement;
