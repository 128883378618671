import React  from 'react';

import { useDispatch } from 'react-redux';
import classes                      from './Pay.module.css';
import Button                       from '../elements/Button';
import Dialog                       from '../elements/Dialog';

import Grid from '@mui/material/Grid';

import InputCreditCard    from '../elements/InputCreditCard';
import Box                from '../elements/Box';
import Typography         from '@mui/material/Typography';
import { formatCurrency } from '../utils/formatters';
import { Paper }          from '@material-ui/core';


const Pay = ({description, price, action, contractData, paySituation, message, closeAction}) => {
  
  const dispatch = useDispatch();
  
  
  const [modalOn, setModalOn] = React.useState(false);
  
  
  
  let modal;
  let boxMessage = classes.BoxMessageSuccess;
  
  const openModal  = () => {
    setModalOn(true);
  };
  const closeModal = () => {
    setModalOn(false);
    if (closeAction) closeAction()
  }
  
  const onSubmit = (data) => {
    
    dispatch(action({...contractData, cardData: data}));
    
  };
  
  if (message.type === 'error') {
    boxMessage = classes.BoxMessageError;
  }
  
  
  modal = <Dialog id="pay-modal" show={modalOn} handleClose={closeModal} title={'Pagamento'}>
    
    <Grid container spacing={2}>
      <Box>
        <Grid container justifyContent={'space-between'} alignItems={'center'}>
          <Grid item xs={9} className={classes.Plan}>
            <Typography variant="body1" className={classes.Text}>
              {description}
            </Typography>
          </Grid>
          <Grid item xs={3} className={classes.Plan}>
            <Typography variant="body1" className={classes.Text}>
              {formatCurrency(price)}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      {!!message.text &&
      <Grid item xs={12} className={classes.MessageContainer}>
        <Paper elevation={3} className={boxMessage}>
          {message.text}
        </Paper>
      </Grid>
      }
      <Grid item xs={12}>
        <InputCreditCard action={onSubmit} paySituation={paySituation}/>
      </Grid>
    </Grid>
  </Dialog>;
  
  return (
    <>
      <Button color="secondary" disabled={!!modalOn} onClick={openModal}>
        Pagar
      </Button>
      {modal}
    </>
  );
};

export default Pay;
