import { createSlice }     from '@reduxjs/toolkit';
import { thunkAPI }        from '../../components/utils/api';
import { formatDate }      from '../../components/utils/formatters';
import { definePaperType } from '../../components/utils/constants';

export const getBuyWallet      = thunkAPI('buy-wallet/get', 'v1/realizacao/carteira', 'get',);
export const getSellWallet     = thunkAPI('sell-wallet/get', 'v1/posse/vendida', 'get',);
export const getCompiledWallet = thunkAPI('compiledWallet/get', 'v1/posse/agrupado', 'get',);


const formatStocks         = (stocks) => {
  
  const formattedStocks = [];
  const groupedStocks   = [];
  let i                 = 0;
  
  let summary = {
    capitalInicialTotal: 0,
    capitalAtualTotal  : 0
  };
  
  // console.log('stocks', stocks);
  
  let papelAnterior;
  
  for (const data of stocks) {
    
    let corretora, custoUnitario, valorUnitario, quantidade;
    
    if (data.corretoraCompra) {
      corretora     = data.corretoraCompra;
      custoUnitario = data.custoCompra;
      valorUnitario = data.vlCompra;
      quantidade    = data.qtPapel;
    } else {
      corretora     = data.corretoraVenda;
      custoUnitario = data.custoVenda;
      valorUnitario = data.vlVenda;
      quantidade    = data.qtPapel * -1;
    }
    
    const papelId        = data.cdPapel;
    const papel          = data.sigla;
    const custo          = quantidade * custoUnitario;
    let valorAtual       = 0;
    const capitalInicial = (quantidade * valorUnitario) + custo;
    const valorProvento  = Number(data.valorProvento || 0);
    summary.capitalInicialTotal += capitalInicial;
    let capitalAtual     = 0;
    let variacao         = 0;
    let valorizacao      = 0;
    let valorizacaoTotal = 0;
    
    if (data.vlAtual) {
      valorAtual       = data.vlAtual;
      capitalAtual     = (quantidade * valorAtual) - custo;
      variacao         = capitalAtual - capitalInicial;
      valorizacao      = variacao / capitalInicial;
      summary.capitalAtualTotal += capitalAtual;
      valorizacaoTotal = (variacao + valorProvento) / capitalInicial;
    }
    const tipoPapel   = definePaperType(data.cdTipoPapel);
    const actualStock = {
      id         : i,
      papel,
      papelId,
      tipoPapel,
      cdTipoPapel: data.cdTipoPapel,
      quantidade,
      valor      : valorUnitario,
      corretora,
      custo,
      valorAtual,
      variacao,
      valorizacao,
      capitalInicial,
      capitalAtual,
      valorProvento,
      valorizacaoTotal
    };
    formattedStocks.push(actualStock);
    
    if (papelAnterior === papelId) {
      const index                           = groupedStocks.length - 1;
      groupedStocks[index].capitalAtual += capitalAtual;
      groupedStocks[index].capitalInicial += capitalInicial;
      groupedStocks[index].valorProvento += valorProvento;
      groupedStocks[index].quantidade += quantidade;
      variacao                              = groupedStocks[index].capitalAtual - groupedStocks[index].capitalInicial;
      valorizacao                           = variacao / groupedStocks[index].capitalInicial;
      groupedStocks[index].variacao         = variacao;
      groupedStocks[index].valorizacao      = valorizacao;
      groupedStocks[index].valorizacaoTotal = (variacao + groupedStocks[index].valorProvento) / groupedStocks[index].capitalInicial;
      groupedStocks[index].stocks.push(actualStock);
    } else {
      papelAnterior = papelId;
      groupedStocks.push({
        id         : i,
        papel,
        tipoPapel,
        cdTipoPapel: data.cdTipoPapel,
        quantidade,
        valor      : valorUnitario,
        custo,
        valorAtual,
        variacao,
        valorizacao,
        capitalInicial,
        capitalAtual,
        valorProvento,
        valorizacaoTotal,
        stocks     : [actualStock]
      });
    }
    
    i++;
  }
  
  for (const data of formattedStocks) {
    data.participacao = data.capitalAtual / summary.capitalAtualTotal;
  }
  
  for (const data of groupedStocks) {
    data.participacao = data.capitalAtual / summary.capitalAtualTotal;
  }
  
  if (stocks[0]) {
    summary.dtCotacao = formatDate(stocks[0].dtCotacao);
  }
  return [formattedStocks, groupedStocks, summary];
  
};
const formatSellStocks     = (stocks) => {
  
  const formattedStocks = [];
  const groupedStocks   = [];
  let i                 = 0;
  
  let summary = {
    capitalInicialTotal: 0,
    capitalAtualTotal  : 0
  };
  
  // console.log('stocks', stocks);
  
  let papelAnterior;
  
  for (const data of stocks) {
    
    const {vlPapel} = data.CotacaoAtual || {};
    
    const corretora     = data.Corretora.abreviatura;
    const papelId       = data.cdPapel;
    const custoUnitario = data.custoNegocio;
    const valorUnitario = data.vlNegocio;
    let quantidade      = data.qtPapel * -1;
    const custo         = quantidade * custoUnitario;
    const valorAtual    = vlPapel || 0;
    const cdTipoPapel   = data.Papel.cdTipoPapel;
    const tipoPapel     = definePaperType(cdTipoPapel);
    const papel         = data.Papel.sigla;
    
    const capitalInicial = (quantidade * valorUnitario) - custo;
    
    summary.capitalInicialTotal += capitalInicial;
    let capitalAtual = 0;
    let variacao     = 0;
    let valorizacao  = 0;
    
    capitalAtual = (quantidade * valorAtual) + custo;
    variacao     = capitalInicial - capitalAtual;
    valorizacao  = variacao / capitalInicial;
    summary.capitalAtualTotal += capitalAtual;
    
    
    const actualStock = {
      id   : i,
      papel,
      papelId,
      tipoPapel,
      cdTipoPapel,
      quantidade,
      valor: valorUnitario,
      corretora,
      custo,
      valorAtual,
      variacao,
      valorizacao,
      capitalInicial,
      capitalAtual,
    };
    formattedStocks.push(actualStock);
    
    if (papelAnterior === papelId) {
      const index                      = groupedStocks.length - 1;
      groupedStocks[index].capitalAtual += capitalAtual;
      groupedStocks[index].capitalInicial += capitalInicial;
      groupedStocks[index].quantidade += quantidade;
      variacao                         = groupedStocks[index].capitalInicial - groupedStocks[index].capitalAtual;
      valorizacao                      = variacao / groupedStocks[index].capitalInicial;
      groupedStocks[index].variacao    = variacao;
      groupedStocks[index].valorizacao = valorizacao;
      groupedStocks[index].stocks.push(actualStock);
    } else {
      papelAnterior = papelId;
      groupedStocks.push({
        id    : i,
        papel,
        tipoPapel,
        cdTipoPapel,
        quantidade,
        valor : valorUnitario,
        custo,
        valorAtual,
        variacao,
        valorizacao,
        capitalInicial,
        capitalAtual,
        stocks: [actualStock]
      });
    }
    
    i++;
  }
  
  for (const data of formattedStocks) {
    data.participacao = data.capitalAtual / summary.capitalAtualTotal;
  }
  
  for (const data of groupedStocks) {
    data.participacao = data.capitalAtual / summary.capitalAtualTotal;
  }
  
  if (stocks[0] && stocks[0].CotacaoAtual) {
    summary.dtCotacao = formatDate(stocks[0].CotacaoAtual.dtCotacao);
  }
  return [formattedStocks, groupedStocks, summary];
  
};
const formatCompiledStocks = (stocks) => {
  
  
  const formattedCompiledStocks = {
    inicial: [],
    atual  : []
  };
  
  for (const data of stocks) {
    formattedCompiledStocks.inicial.push([data.descricao, Number(data.vl_compra)]);
    formattedCompiledStocks.atual.push([data.descricao, Number(data.vl_atual || 0)]);
  }
  
  return formattedCompiledStocks;
  
};

const walletSlice = createSlice({
  name         : 'wallet',
  initialState : {
    compiledStocks: {inicial: [], atual: []},
    stocks        : [],
    groupedStocks : [],
    summary       : {},
  },
  reducers     : {},
  extraReducers: {
    [getBuyWallet.fulfilled]     : (state, action) => {
      const res           = formatStocks(action.payload);
      state.stocks        = res[0];
      state.groupedStocks = res[1];
      state.summary       = res[2];
    },
    [getSellWallet.fulfilled]    : (state, action) => {
      const res           = formatSellStocks(action.payload);
      state.stocks        = res[0];
      state.groupedStocks = res[1];
      state.summary       = res[2];
    },
    [getCompiledWallet.fulfilled]: (state, action) => {
      state.compiledStocks = formatCompiledStocks(action.payload);
      
    }
    
  }
});

const {reducer} = walletSlice;


export default reducer;
