import React, { useEffect, useMemo }                 from 'react';
import Table                                         from '../../components/elements/Table';
import { useDispatch, useSelector }                  from 'react-redux';
import { formatCurrency, formatDate, formatInteger } from '../../components/utils/formatters';
import Grid                                          from '@mui/material/Grid';
import Box                                           from '../../components/elements/Box';
import classes                                       from '../Wallet/Wallet.module.css';
import Typography                                    from '@mui/material/Typography';
import { getMyRepurchases }                          from '../../store/slices/repurchase';

const MyRepurchase = () => {
  
  const dispatch          = useDispatch();
  const {myRepurchases} = useSelector(state => state.repurchase);
  
  useEffect(() => {
    dispatch(getMyRepurchases());
  }, []);
  
  const columns = useMemo(
    () =>
      [
        {
          accessor: 'papel',
          Header  : 'Papel',
        },
        {
          accessor: 'dtRecompra',
          Header  : 'Data Recompra',
          Cell    : ({value}) => formatDate(value)
        },
        {
          accessor: 'dtCredito',
          Header  : 'Data Crédito',
          Cell    : ({value}) => formatDate(value)
        },
        {
          accessor: 'quantidade',
          Header  : 'Quantidade',
          Cell    : ({value}) => formatInteger(value)
        },
        {
          accessor: 'vlUnitario',
          Header  : 'Valor Unitário Recompra',
          Cell    : ({value}) => formatCurrency(value)
        },
        {
          accessor: 'vlTotal',
          Header  : 'Valor Total Recompra',
          Cell    : ({value}) => formatCurrency(value)
        },
      ],
    []
  );
  
  return (
    <Grid container>
      {!!myRepurchases.length &&
      <Grid item xs={12}>
        <Table columns={columns} rowsData={myRepurchases}/>
      </Grid>
      }
      {!myRepurchases.length &&
      <Box>
        <Grid item xs={12} className={classes.NegativeBox}>
          <Typography variant={'h6'} className={classes.NegativeText}>
            Você não possui OPAs
          </Typography>
        </Grid>
      </Box>
      }
    </Grid>
  
  );
};


export default MyRepurchase;
