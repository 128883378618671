import React, { useEffect, useMemo, useState } from 'react';
import Table                                   from '../../components/elements/Table';
import { useDispatch, useSelector }            from 'react-redux';
import { getYearPosition }                              from '../../store/slices/tax';
import { formatCpfCnpj, formatCurrency, formatInteger } from '../../components/utils/formatters';
import Grid                                             from '@mui/material/Grid';
import YearBusinessSelect from '../../components/partials/YearBusinessSelect';
import YearPositionDetail from '../../components/detailComponents/YearPositionDetail';
import Box                from '../../components/elements/Box';
import classes                                          from '../Wallet/Wallet.module.css';
import Typography                                       from '@mui/material/Typography';

const YearPosition = () => {
  
  const dispatch                        = useDispatch();
  const {yearPosition}                  = useSelector(state => state.tax);
  const {yearBusiness}                  = useSelector(state => state.business);
  const [yearSelected, setYearSelected] = useState('');
  
  const handleChange = (event) => {
    setYearSelected(event.target.value);
  };
  
  const initializeValue = (value) => {
    setYearSelected(value);
  };
  
  useEffect(() => {
    
    if (yearSelected) dispatch(getYearPosition({ano: yearSelected}));
  }, [yearSelected]);
  
  
  const columns = useMemo(
    () =>
      [
        {
          accessor: 'papel',
          Header  : 'Papel',
          
        },
        {
          accessor: 'nome',
          Header  : 'Nome',
          
        },
        {
          accessor: 'cnpj',
          Header  : 'CNPJ',
          Cell    : ({value}) => formatCpfCnpj(value)
        },
        {
          accessor: 'quantidade',
          Header  : 'Quantidade',
          Cell    : ({value}) => formatInteger(value)
        },
        {
          accessor: 'valorUnitario',
          Header  : 'Valor Unitário',
          Cell    : ({value}) => formatCurrency(value)
        },
        {
          accessor: 'valorTotal',
          Header  : 'Valor Total',
          Cell    : ({value}) => formatCurrency(value)
        },
        {
          Header: 'Detalhar',
          id    : 'expander',
        },
      ],
    []
  );
  
  return (
    <Grid container>
      <Grid item xs={12}>
        <Table columns={columns} rowsData={yearPosition} detailComponent={YearPositionDetail} inclusion={
          <YearBusinessSelect onChange={handleChange} value={yearSelected} onInit={initializeValue}/>}/>
      </Grid>
      {!yearBusiness.length &&
      <Box>
        <Grid item xs={12} className={classes.NegativeBox}>
          <Typography variant={'h6'} className={classes.NegativeText}>
            Você ainda não possui ativos
          </Typography>
        </Grid>
      </Box>
      }
    </Grid>
  
  );
};


export default YearPosition;
