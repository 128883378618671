import React, { useState, useEffect, useCallback } from 'react';
import Autocomplete                                from '@mui/material/Autocomplete';
import TextField                                   from '@mui/material/TextField';
import { useDispatch, useSelector }                from 'react-redux';
import { Controller }                              from 'react-hook-form';
import FormControl                                 from '@mui/material/FormControl';
import FormHelperText                              from '@mui/material/FormHelperText';
import classes                                     from './Input.module.css';

function debounce(func, wait) {
  let timeout;
  return function (...args) {
    const context = this;
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      timeout = null;
      func.apply(context, args);
    }, wait);
  };
}

const FormAutoComplete = ({
                            label, placeholder, name, rules, control, dataFunction, dataFields, requiredData,
                            searchField, displayField, errorMessage, ...others
                          }) => {
  
  const [inputValue, setInputValue]   = useState('');
  const [open, setOpen]               = React.useState(false);
  const [inputSearch, setInputSearch] = useState('');
  
  const dispatch = useDispatch();
  
  
  const debounceOnChange = useCallback(
    debounce(value => {
      setInputSearch(value);
    }, 500),
    []
  );
  
  
  function handleChange(value) {
    setInputValue(value);
    debounceOnChange(value);
  }
  
  const data = useSelector(state => state[dataFields.slice]) || [];
  
  
  useEffect(() => {
    
    if (inputValue) dispatch(dataFunction({[searchField]: inputValue, ...requiredData}));
    
  }, [inputSearch]);
  
  
  const loading = open && data[dataFields.data].length === 0;
  
  
  return (
    <FormControl aria-label={name} variant="outlined" fullWidth className={classes.Field}>
      <Controller control={control} rules={rules} name={name}
                  render={({field}) => (
                    <Autocomplete  {...field} {...others} open={open} freeSolo clearOnBlur fullWidth loading={loading}
                                   onOpen={() => {setOpen(true)}}
                                   onClose={() => {setOpen(false)}}
                                   isOptionEqualToValue={(option, value) => option[displayField] === value[displayField]}
                                   getOptionLabel={option => option[displayField]}
                                   options={data[dataFields.data]}
                                   onChange={(_, data) => field.onChange(data)}
                                   renderInput={params => (
                                     <TextField {...params} label={label} error={!!errorMessage} placeholder={placeholder} variant="outlined"
                                                onChange={event => handleChange(event.target.value)}/>
                                   )}
                                   renderOption={(props, option) => {
                                     return <div {...props}>{option[displayField]}</div>;
                                   }}
                    />)}
      />
      {!!errorMessage && <FormHelperText error margin="dense">{errorMessage}</FormHelperText>}
    </FormControl>
  );
};

export default FormAutoComplete;
