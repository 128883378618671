import React         from 'react';
import Grid          from '@mui/material/Grid';
import classes       from '../sections/Hero.module.css';
import Typography    from '@mui/material/Typography';
import Link from '@mui/material/Link';

const RegisterMessage = ({handleBack}) => {
  
  
  return (

    <Grid container spacing={2}>
      <Grid item xs={12}>
          <Typography variant="body1" className={classes.Text}>
           Verifique seu email para confirmação de cadastro, você deve realizar a confirmação em até 48 horas
          </Typography>
      </Grid>
      <Grid item xs={12} className={classes.GoBack}>
        <Link href="#" underline={"none"} onClick={() => handleBack(true)} className={classes.GoBackText}>
          voltar para cadastro
        </Link>
      </Grid>
    </Grid>
  );
  
};

export default RegisterMessage;
