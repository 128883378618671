import React from 'react';


import classes from '../containerActions/IncludeBusiness.module.css';
import Dialog  from '../elements/Dialog';
import Grid    from '@mui/material/Grid';
import Typography                            from '@mui/material/Typography';

const HelpFormBusiness = ({modalOn, closeModal}) => {
  
  
  let modal;
  
  modal = <Dialog id="business-help" show={modalOn} handleClose={closeModal} title={'Ajuda Inclusão Negócios'}>
    <Grid container spacing={2}>
      <Grid item xs={12} className={classes.Item}>
        <Typography variant="body2" color={'primary'} gutterBottom>
          Alguns campos aparecem de acordo com as opções selecionadas
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} className={classes.Item}>
        <Typography variant="h6">
          Data de Negócios
        </Typography>
        <Typography variant="body2" gutterBottom>
          Data em que foi realizada a transação
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} className={classes.Item}>
        <Typography variant="h6">
          Corretora
        </Typography>
        <Typography variant="body2" gutterBottom>
          Corretora em que foi realizada a transação
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} className={classes.Item}>
        <Typography variant="h6">
          Tipo Operação
        </Typography>
        <Typography variant="body2" gutterBottom>
          Tipo de transação realizada
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} className={classes.Item}>
        <Typography variant="h6">
          Quantidade
        </Typography>
        <Typography variant="body2" gutterBottom>
          Quantidade transacionada
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} className={classes.Item}>
        <Typography variant="h6">
          Valor Unitário
        </Typography>
        <Typography variant="body2" gutterBottom>
          Valor transacionado por cada unidade de papel, em caso de vários negócios como o mesmo papel, fazer a média de preços
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} className={classes.Item}>
        <Typography variant="h6">
          Custo Total do Negócio
        </Typography>
        <Typography variant="body2" gutterBottom>
          Custo total da transação, considerando a quantidade total do papel. Caso tenha mais de um papel na mesma nota,
          dividir o custo pelo valor total da nota e multiplicar pelo valor total daquele papel. Não deve ser considerado custo de IRRF
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} className={classes.Item}>
        <Typography variant="h6">
          Papel
        </Typography>
        <Typography variant="body2" gutterBottom>
          O papel que foi transacionado
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} className={classes.Item}>
        <Typography variant="h6">
          Data Exata Subscrição/ipo
        </Typography>
        <Typography variant="body2" gutterBottom>
          Para que todos os cálculos de proventos sejam realizados com precisão, é importante que a data da ipo/subscrição seja padronizada
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} className={classes.Item}>
        <Typography variant="h6">
          Custo Compra Total Direito
        </Typography>
        <Typography variant="body2" gutterBottom>
          Caso tenha comprado o direito de subscrição, o valor da compra mais os custos devem ser incluídos nesse campo. Não deve ser considerado custo de IRRF
        </Typography>
      </Grid>
    </Grid>
  </Dialog>;
  
  return (
    <>
      {modal}
    </>
  );
};

export default HelpFormBusiness;
