import React, { useEffect, useMemo, useState } from 'react';
import classes                                 from './Wallet.module.css';
import Table                                   from '../../components/elements/Table';
import { useDispatch, useSelector }            from 'react-redux';
import { getBuyWallet }                        from '../../store/slices/wallet';
import { formatCurrency, formatInteger }       from '../../components/utils/formatters';
import Grid                                    from '@mui/material/Grid';
import WalletSummary                           from '../../components/partials/WalletSummary';
import Typography                              from '@mui/material/Typography';
import Box                                     from '../../components/elements/Box';
import InputSwitch                             from '../../components/elements/InputSwitch';
import WalletDetail                            from '../../components/detailComponents/WalletDetail';
import BusinessClosure                         from '../../components/containerActions/BusinessClosure';
import ClosureButton                              from '../../components/elements/ClosureButton';
import { businessList }                        from '../../components/utils/constants';


const BuyWallet = () => {
  
  const dispatch                         = useDispatch();
  const {groupedStocks, summary, stocks} = useSelector(state => state.wallet);
  
  const [groupOn, setGroupOn]     = useState(false);
  const [myStock, setMyStock]     = useState([]);
  const [rowData, setRowData]     = useState(null);
  const [closureOn, setClosureOn] = useState(false);
  
  useEffect(() => {
    dispatch(getBuyWallet());
  }, []);
  
  useEffect(() => {
    setMyStock(stocks);
  }, [stocks]);
  
  
  const openClosure  = (rowData) => {
    setRowData({...rowData.original, index: rowData.index});
    setClosureOn(true);
  };
  const closeClosure = () => {
    setClosureOn(false);
    setRowData(null);
  };
  
  const columns = useMemo(
    () => {
      
      if (groupOn) {
        return [{
          accessor: 'papel',
          Header  : 'Papel',
        },
          {
            accessor : 'tipoPapel',
            Header   : 'Tipo',
            newFilter: 'SelectColumnFilter',
          },
          {
            accessor: 'quantidade',
            Header  : 'Quantidade',
            Cell    : ({value}) => formatInteger(value)
          },
          {
            accessor: 'valor',
            Header  : 'Valor Compra',
            Cell    : ({value}) => formatCurrency(value)
          },
          {
            accessor: 'custo',
            Header  : 'Custo Compra',
            Cell    : ({value}) => formatCurrency(value)
          },
          {
            accessor: 'valorAtual',
            Header  : 'Valor Atual*',
            Cell    : ({value}) => formatCurrency(value)
          },
          {
            accessor: 'variacao',
            Header  : 'Variação',
            Cell    : ({value}) => formatCurrency(value)
          },
          {
            accessor: 'valorizacao',
            Header  : 'Valorização',
            Cell    : ({value}) => `${(value * 100).toFixed(2)}%`
          },
          {
            accessor: 'capitalInicial',
            Header  : 'Capital Inicial',
            Cell    : ({value}) => formatCurrency(value)
          },
          {
            accessor: 'capitalAtual',
            Header  : 'Capital Atual',
            Cell    : ({value}) => formatCurrency(value)
          },
          {
            accessor: 'participacao',
            Header  : 'Part. %',
            Cell    : ({value}) => `${(value * 100).toFixed(2)}%`
          },
          {
            accessor: 'valorProvento',
            Header  : 'Valor Proventos',
            Cell    : ({value}) => formatCurrency(value)
          },
          {
            accessor: 'valorizacaoTotal',
            Header  : 'Valorização c/ Proventos',
            Cell    : ({value}) => `${(value * 100).toFixed(2)}%`
          },
          {
            Header: 'Detalhar',
            id    : 'expander'
          },
        ];
      } else return [
        {
          accessor: 'papel',
          Header  : 'Papel',
        },
        {
          accessor: 'tipoPapel',
          Header  : 'Tipo',
          newFilter: 'SelectColumnFilter',
        },
        {
          accessor: 'quantidade',
          Header  : 'Quantidade',
          Cell    : ({value}) => formatInteger(value)
        },
        {
          accessor: 'valor',
          Header  : 'Valor Compra',
          Cell    : ({value}) => formatCurrency(value)
        },
        {
          accessor: 'custo',
          Header  : 'Custo Compra',
          Cell    : ({value}) => formatCurrency(value)
        },
        {
          accessor: 'corretora',
          Header  : 'Corretora',
          newFilter: 'SelectColumnFilter',
        },
        {
          accessor: 'valorAtual',
          Header  : 'Valor Atual*',
          Cell    : ({value}) => formatCurrency(value)
        },
        {
          accessor: 'variacao',
          Header  : 'Variação',
          Cell    : ({value}) => formatCurrency(value)
        },
        {
          accessor: 'valorizacao',
          Header  : 'Valorização',
          Cell    : ({value}) => `${(value * 100).toFixed(2)}%`
        },
        {
          accessor: 'capitalInicial',
          Header  : 'Capital Inicial',
          Cell    : ({value}) => formatCurrency(value)
        },
        {
          accessor: 'capitalAtual',
          Header  : 'Capital Atual',
          Cell    : ({value}) => formatCurrency(value)
        },
        {
          accessor: 'participacao',
          Header  : 'Part. %',
          Cell    : ({value}) => `${(value * 100).toFixed(2)}%`
        },
        {
          accessor: 'valorProvento',
          Header  : 'Valor Proventos',
          Cell    : ({value}) => formatCurrency(value)
        },
        {
          accessor: 'valorizacaoTotal',
          Header  : 'Valorização c/ Proventos',
          Cell    : ({value}) => `${(value * 100).toFixed(2)}%`
        },
        {
          Header: '',
          id    : 'action',
          Cell  : ClosureButton('encerrar operação', openClosure)
        },
      ];
    },
    [groupOn]
  );
  
  const detailComponent = useMemo(
    () => !!groupOn ? WalletDetail : null,
    [groupOn]
  );
  
  const groupList = (event) => {
    setGroupOn(event.target.checked);
    if (event.target.checked === true) setMyStock(groupedStocks);
    else setMyStock(stocks);
  };
  
  return (
    <Grid container >
      <Grid item xs={12}>
        <WalletSummary summary={summary}/>
      </Grid>
      {!!stocks.length &&
      <Grid item xs={12}>
        <Table columns={columns} rowsData={myStock} inclusion={<InputSwitch label={'agrupar'} name={'paper'} checked={groupOn} onChange={groupList}/>}
               detailComponent={detailComponent}/>
        {closureOn && <BusinessClosure data={rowData} modalOn={closureOn} closeModal={closeClosure} closureOperation={businessList.VENDA}/>}
      </Grid>
      }
      {!stocks.length &&
      <Box>
        <Grid item xs={12} className={classes.NegativeBox}>
          <Typography variant={'h6'} className={classes.NegativeText}>
            Você ainda não possui ativos
          </Typography>
        </Grid>
      </Box>
      }
      <Grid item xs={12} className={classes.Text}>
        {summary.dtCotacao &&
        <Typography variant="body2" gutterBottom>
          *Valor Atual com data de {summary.dtCotacao}
        </Typography>
        }
      </Grid>
    </Grid>
  
  );
};


export default BuyWallet;
