import React, { useEffect } from 'react';

import { useForm }                  from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import classes                      from './IncludeBusiness.module.css';
import Button                       from '../elements/Button';
import Dialog                       from '../elements/Dialog';
import FormMask                     from '../elements/FormMask';
import { docIdRule }                from '../utils/constants';
import { handleError, rules }       from '../utils/rules';
import Grid                         from '@mui/material/Grid';
import InputText                    from '../elements/FormText';
import PersonIcon                   from '@mui/icons-material/Person';
import DocIcon                      from '@mui/icons-material/PictureInPicture';
import { includeCompany }           from '../../store/slices/company';
import FormDate                     from '../elements/FormDate';
import CakeIcon   from '@mui/icons-material/Cake';
import FormSwitch from '../elements/FormSwitch';

const defaultValues = {
  cnpj: '',
  current      : false,
  name: '',
  startValidity: null,
};

const IncludeCompany = () => {
  
  const dispatch = useDispatch();
  
  const {includeSituation} = useSelector(state => state.company);
  
  
  const [modalOn, setModalOn] = React.useState(false);
  
  
  useEffect(() => {
    
    if (includeSituation === 'idle') reset(defaultValues);
    
  }, [includeSituation]);
  
  const {control,  handleSubmit, reset, formState} = useForm({mode: 'all', defaultValues});
  
  const {isValid, errors} = formState;
  
  let modal;
  
  const openModal = () => {
    setModalOn(true);
  };
  
  
  const closeModal = () => setModalOn(false);
  
  const onSubmit = (data) => {

    const {name, cnpj, startValidity, current} = data;
    dispatch(includeCompany({data: {nome: name, cnpj, dataInicio: startValidity, vigente: current}, internalData: {nome: name, cnpj, vigente: current}}));
    
  };
  
  const disabled = (!isValid || includeSituation === 'pending');
  // const disabled = false;
  
  
  modal = <Dialog id="company-modal" show={modalOn} handleClose={closeModal} title={'Inclusão Empresa'}>
    
    <form id="company" onSubmit={handleSubmit(onSubmit)} className={classes.Form}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} className={classes.Item}>
          <InputText label="Nome Empresa" name="name" Icon={PersonIcon} errorMessage={handleError(errors, 'name')}
                     control={control} rules={rules('company', )}
          />
        </Grid>
        <Grid item xs={12} sm={6} className={classes.Item}>
          <FormMask label="CNPJ" name="cnpj" Icon={DocIcon} defaultValue={defaultValues['cnpj']}
                    errorMessage={handleError(errors, 'cnpj')} control={control}
                    rules={rules('docID', null, 'CNPJ')} formatOptions={docIdRule}
          />
        </Grid>
        <Grid item xs={12} sm={6} className={classes.Item}>
          <FormDate label="Início Vigência" name="startValidity" Icon={CakeIcon} errorMessage={handleError(errors, 'startValidity')}
                    control={control} rules={{required: true}}
          />
        </Grid>
        <Grid item xs={12} sm={6} className={classes.Switch}>
          <FormSwitch label={'Vigente'} name={'current'} control={control} defaultValue={defaultValues['current']}/>
        </Grid>
        <Grid item xs={12} className={classes.Button}>
          <Button color="secondary" disabled={disabled} type="submit">
            {includeSituation === 'pending' ? 'Cadastrando' : 'Cadastrar'}
          </Button>
        </Grid>
      </Grid>
    </form>
  </Dialog>;
  
  return (
    <>
      <Button color="secondary" disabled={!!modalOn} onClick={openModal}>
        Incluir
      </Button>
      {modal}
    </>
  );
};

export default IncludeCompany;
