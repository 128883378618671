import { createSlice } from '@reduxjs/toolkit';
import { thunkAPI }    from '../../components/utils/api';

export const getCompiledEarnings = thunkAPI('compiledEarnings/get', 'v1/provento/realizacao/agrupado', 'get',);
export const getEarnings         = thunkAPI('earning/get', 'v1/provento/realizacao/proprio', 'get',);
export const getPaperEarnings    = thunkAPI('paperEarning/get', 'v1/provento/busca/20', 'get',);
export const getEarningTypes     = thunkAPI('earningTypes/get', '/v1/tiposProvento/vigentes',
  {verb: 'get', getCache: ['earning', 'incomesTypes']},);
export const getYearEarnings     = thunkAPI('yearEarning/get', 'v1/provento/realizacao/ano', 'get',);
export const getYearNextEarnings = thunkAPI('yearEarningNext/get', 'v1/provento/realizacao/posterior/ano', 'get',);
export const includeEarning      = thunkAPI('earning/include', '/v1/provento', 'post', {successMessage: 'Provento Incluído com sucesso'});
export const processEarning      = thunkAPI('earning/process', '/v1/provento/processar/data', 'post',
  {successMessage: 'Provento processado com sucesso'});
export const redeemEarnings      = thunkAPI('earning/redeem', '/v1/provento/capturar', 'post', {successMessage: 'Provento Incluído com sucesso'});

const formatCompiledEarnings = (incomes) => {
  
  const formattedCompiledEarnings = [['Ação', 0], ['FII', 0]];
  const formattedYearsEarnings    = {acao: [], fii: [],};
  
  // console.log('incomes', incomes);
  
  const years = [];
  let anoAnterior;
  
  for (const data of incomes) {
    const saldo = Number(data.saldo);
    
    const {ano, daytrade, tipo_papel} = data;
    
    if (anoAnterior !== ano && years.length < 10) {
      years.unshift(ano);
      formattedYearsEarnings.acao.unshift(0);
      formattedYearsEarnings.fii.unshift(0);
    }
    
    
    if (daytrade) {
      if (tipo_papel === 1) {
        formattedCompiledEarnings[0][1] += saldo;
        formattedYearsEarnings.acao[0] = saldo;
      } else {
        formattedCompiledEarnings[1][1] += saldo;
        formattedYearsEarnings.fii[0] = saldo;
      }
    } else {
      if (tipo_papel === 1) {
        formattedYearsEarnings.acao[0] = saldo;
        formattedCompiledEarnings[0][1] += saldo;
      } else {
        formattedYearsEarnings.fii[0] = saldo;
        formattedCompiledEarnings[1][1] += saldo;
      }
    }
    
    
    anoAnterior = ano;
  }
  
  formattedYearsEarnings.years = years;
  
  // console.log('formattedYearsEarnings', formattedYearsEarnings);
  // console.log('formattedCompiledEarnings', formattedCompiledEarnings);
  
  return [formattedCompiledEarnings, formattedYearsEarnings];
  
};
const formatEarnings         = (incomes, time) => {
  
  const formattedIncomes = [];
  const period           = {};
  let i                  = 0;
 
  
  // console.log("incomes", incomes)
  for (const income of incomes) {
    
    const [anoBase, mesBase] = income.dataDireito.split('-');
    const tipoPapel          = income.tipoPapel;
    const vlPagamento        = income.valorPagamento;
    
    formattedIncomes.push({
      id           : i,
      papel        : income.papel,
      corretora    : income.corretora,
      tipoProvento : income.tipoProvento,
      quantidade   : income.qtPapel,
      dataDireito  : income.dataDireito,
      dataPagamento: income.dataPagamento,
      vlPagamento,
    });
    i++;
    
    const mesNumero = Number(mesBase);
    const anoNumero = Number(anoBase);
    
    if (!period[anoNumero]) period[anoNumero] = {data: {}, months: {}};
    if (!period[anoNumero].data[tipoPapel]) period[anoNumero].data[tipoPapel] = 0;
    if (!period[anoNumero].months[mesNumero]) period[anoNumero].months[mesNumero] = {};
    if (!period[anoNumero].months[mesNumero][tipoPapel]) period[anoNumero].months[mesNumero][tipoPapel] = 0;
    
    period[anoNumero].data[tipoPapel] += Number(vlPagamento);
    period[anoNumero].months[mesNumero][tipoPapel] += Number(vlPagamento);
    
  }
  
  
  const xAxis = [];
  const yAxis = {acao: [], fii: []};
  for (const [ano, values] of Object.entries(period)) {
    
    if (time > 12) {
      xAxis.push(ano);
      let typeValues = values.data[1] || {};
      yAxis[`acao`].push(typeValues || 0);
      typeValues = values.data[2] || {};
      yAxis[`fii`].push(typeValues || 0);
    } else {
      for (const [mes, values2] of Object.entries(values.months)) {
        // console.log("mes", mes)
        xAxis.push(`${mes}/${ano}`);
        let typeValues = values2[1] || {};
        yAxis[`acao`].push(typeValues || 0);
        typeValues = values2[2] || {};
        yAxis[`fii`].push(typeValues || 0);
      }
    }
  }
  
  return [formattedIncomes, {xAxis, yAxis}];
  
};
const formatYearEarnings     = (incomes) => {
  
  const formattedIncomes = [];
  
  let i = -1;
  let papelAnterior;
  
  // console.log('incomes year', incomes);
  
  
  for (const income of incomes) {
    
    const vlPagamento    = income.valorProvento;
    const cdTipoProvento = income.cdTipoProvento;
    const sigla          = income.Papel.sigla;
    const id             = income.Papel.cdPapel;
    const cnpj           = income.Papel.Empresa.cnpj;
    
    if (!papelAnterior || papelAnterior !== id) {
      formattedIncomes.push({
        id             : id,
        valorDividendo : 0,
        valorJcp       : 0,
        valorRendimento: 0,
        valorTotal: 0,
        sigla,
        cnpj
      });
      papelAnterior = id;
      i++;
    }
    
    switch (cdTipoProvento) {
      case 1:
        formattedIncomes[i].valorDividendo = vlPagamento;
        break;
      case 2:
        formattedIncomes[i].valorJcp = vlPagamento;
        break;
      case 3:
        formattedIncomes[i].valorRendimento = vlPagamento;
        break;
      default:
        console.log('deu erro');
    }
  
    formattedIncomes[i].valorTotal += Number(vlPagamento);
  }
  
  // console.log('formattedIncomes year', formattedIncomes);
  
  return formattedIncomes;
  
};
const formatPaperEarnings    = (incomes) => {
  
  // console.log('incomes', incomes);
  const formattedIncomes = [];
  
  let i = 0;
  for (const income of incomes) {
    formattedIncomes.push({
      id                : i,
      tipoProvento      : income.TipoProvento.descricao,
      dataDireito       : income.dtDireito,
      dataPagamento     : income.dtPagamento,
      valorProventoBruto: income.vlProventoBruto,
    });
    i++;
  }
  
  return formattedIncomes;
  
};

const slice = createSlice({
  name         : 'earning',
  initialState : {
    paperEarnings   : [],
    periodEarnings  : {yAxis: {acao: [], fii: []}, xAxis: [],},
    earnings        : [],
    yearEarnings    : [],
    yearEarningsNext: [],
    incomesTypes    : [],
    compiledEarnings: [],
    yearsEarnings   : {acao: [], fii: [], years: [],},
    includeSituation: 'idle',
    processSituation: 'idle',
    searchSituation : 'idle',
  },
  reducers     : {},
  extraReducers: {
    [getCompiledEarnings.fulfilled]: (state, action) => {
      [state.compiledEarnings, state.yearsEarnings] = formatCompiledEarnings(action.payload);
    },
    [getEarningTypes.fulfilled]    : (state, action) => {
      state.incomesTypes = action.payload;
    },
    [includeEarning.fulfilled]     : (state, action) => {
      state.includeSituation = 'idle';
    },
    [getEarnings.fulfilled]        : (state, action) => {
      [state.earnings, state.periodEarnings] = formatEarnings(action.payload.response, action.payload.time);
    },
    [getPaperEarnings.fulfilled]   : (state, action) => {
      state.paperEarnings   = formatPaperEarnings(action.payload);
      state.searchSituation = 'idle';
    },
    [getPaperEarnings.pending]     : (state) => {
      state.searchSituation = 'pending';
    },
    [getPaperEarnings.rejected]    : (state) => {
      state.searchSituation = 'rejected';
    },
    [getYearEarnings.fulfilled]    : (state, action) => {
      state.yearEarnings = formatYearEarnings(action.payload);
    },
    
    [getYearNextEarnings.fulfilled]: (state, action) => {
      state.yearEarningsNext = formatYearEarnings(action.payload);
    },
    [processEarning.fulfilled]     : (state) => {
      state.processSituation = 'idle';
    },
    [processEarning.pending]       : (state) => {
      state.processSituation = 'pending';
    },
    [processEarning.rejected]      : (state) => {
      state.processSituation = 'rejected';
    },
  }
});

const {reducer} = slice;

export default reducer;
