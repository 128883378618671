import React, { useCallback } from 'react';

import classes        from './Input.module.css';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl    from '@mui/material/FormControl';
import InputLabel     from '@mui/material/InputLabel';
import Select         from '@mui/material/Select';
import MenuItem       from '@mui/material/MenuItem';
import { Controller } from 'react-hook-form';
import Checkbox       from '@mui/material/Checkbox';
import Chip           from '@mui/material/Chip';
import ListItemText   from '@mui/material/ListItemText';


const FormMultipleSelect = ({label, name, defaultValue, data, control, displayField, valueField, rules, errorMessage,}) => {
  
  
  const mountItems = useCallback(
    ({value}) => {
      
      const items = [];
      for (const reg of data) {
        const regValue = !!valueField ? reg[valueField] : reg;
        items.push(
          <MenuItem value={regValue} key={`${reg[displayField]}`}>
            <Checkbox color="primary" checked={value.indexOf(regValue) > -1}/>
            <ListItemText primary={reg[displayField]}/>
          </MenuItem>
        );
      }
      return items;
    },
    [data]
  );
  
  
  return (
    
    <FormControl aria-label={name} variant="outlined" fullWidth className={classes.Field}>
      <InputLabel error={!!errorMessage} htmlFor={name}>{label}</InputLabel>
      <Controller name={name} control={control} rules={rules} defaultValue={defaultValue}
                  render={({field}) => (
                    <Select {...field} labelId={`${name}-label`} id={name} error={!!errorMessage} multiple label={label}
                            renderValue={(selected) => (
                              <div>
                                {selected.map((value, index) => (
                                    <Chip key={value} label={data[index][displayField]}/>
                                  )
                                )}
                              </div>
                            )}
                    >
                      <MenuItem value="" disabled>
                        <em>Selecione</em>
                      </MenuItem>
                      {mountItems(field)}
                    </Select>
                  )}/>
      {!!errorMessage && <FormHelperText error margin="dense">{errorMessage}</FormHelperText>}
    
    </FormControl>
  );
};


export default FormMultipleSelect;
