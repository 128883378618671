import React                       from 'react';
import ListItem                    from '@mui/material/ListItem';
import ListItemIcon                from '@mui/material/ListItemIcon';
import ListItemText                from '@mui/material/ListItemText';
import { useLocation, useNavigate } from 'react-router-dom';
import classes                     from './NavigationItem.module.css';
import Tooltip                     from '@mui/material/Tooltip';


const NavigationItem = ({icon, text, handleClick, link, element, clickAction}) => {
  
  const navigate  = useNavigate();
  const location = useLocation();
  
  const currentPath = location.pathname;
  const Icon        = icon;
  
  let internalHandleClick = handleClick;
  
  if (!internalHandleClick && !!link) internalHandleClick = () => {
    if (clickAction) clickAction()
    navigate(link);
  };
  
  return (
    <Tooltip title={text} placement="right" arrow>
    <ListItem button selected={currentPath === link} onClick={internalHandleClick}
              classes={{root: classes.ListItem, selected: classes.SelectedItem}} >
      <ListItemIcon>
        <Icon/>
      </ListItemIcon>
      <ListItemText primary={text}/>
      {element}
    </ListItem>
    </Tooltip>
  );
  
};

export default NavigationItem;
