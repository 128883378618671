import React from 'react';

import Grid from '@mui/material/Grid';

import {useDispatch} from 'react-redux';

import classes              from '../../components/containerActions/IncludeBusiness.module.css';
import {handleError, rules} from '../../components/utils/rules';

import Button                   from '../../components/elements/Button';
import {includeAdmBusiness}     from '../../store/slices/business';
import {useForm}                from 'react-hook-form';
import FormMask                 from '../../components/elements/FormMask';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import {decimalFormat}          from '../../components/utils/constants';
import FormSelect               from '../../components/elements/FormSelect';
import {includeAdmTransfer}     from '../../store/slices/transference';

const types = [
  {id: 1, nome: "Negócios"},
  {id: 2, nome: "Transferências"},
]

const defaultValues = {
  account: '',
  type   : types[0].id,
};


const AdmBusiness = () => {

  const dispatch = useDispatch();

  const onSubmit = (data) => {
    console.log('passou aqui', data);
    const request = {account: data.account}

    if (Number(data.type) === 1) dispatch(includeAdmBusiness(request));
    if (Number(data.type) === 2) dispatch(includeAdmTransfer(request));

  };

  const {control, handleSubmit, formState} =
          useForm({mode: 'all', defaultValues});

  const {isValid, errors,} = formState;

  return (
    <Grid container>
      <form id="admBusiness" onSubmit={handleSubmit(onSubmit)} className={classes.Form}>
        <Grid container spacing={2} justifyContent={'center'}>
          <Grid item xs={12} sm={6} className={classes.Item}>
            <FormMask label="Conta Alvo Execução" name="account" Icon={AccountBalanceWalletIcon} defaultValue={defaultValues['account']}
                      errorMessage={handleError(errors, 'account')} control={control}
                      rules={rules('value')} formatOptions={decimalFormat}
            />
          </Grid>
          <Grid item xs={12} sm={6} className={classes.Item}>
            <FormSelect label="Tipo" name="type" errorMessage={handleError(errors, 'type')} displayField={'nome'} valueField={'id'}
                        rules={(rules('type', null, 'Tipo'))} data={types} control={control} defaultValue={defaultValues.type}
            />
          </Grid>
          <Grid item xs={12} className={classes.Button}>
            <Button color="secondary" type="submit" disabled={!isValid}>
              Executar
            </Button>
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
};

export default AdmBusiness;
