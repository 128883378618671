import {
  differenceInCalendarDays, differenceInCalendarMonths,
  startOfToday, sub, add, parseISO, format
} from 'date-fns';

export const daysBetweenDates   = (initialDate, finalDate) => {
  
  const duration = differenceInCalendarDays(new Date(finalDate), new Date(initialDate));
  
  return duration || 1;
  
};
export const justToDate         = (date) => {
  
  let myDate = date;
  
  if (!myDate) myDate = new Date();
  
  return format(myDate, 'yyyy-MM-dd');
  
};

export const toPickerDate         = (date) => {
  
  let myDate = date;
  
  if (!myDate) myDate = new Date();
  // console.log(typeof myDate, myDate)
  // if (typeof myDate === 'string') return myDate.substring(0, 10);
  // myDate = parseISO(myDate);
  
  return format(myDate, 'yyyy-MM-dd 04:00');
  
};

export const monthsBetweenDates = (initialDate, finalDate) => {
  
  const duration = differenceInCalendarMonths(new Date(finalDate), new Date(initialDate));
  
  return duration || 1;
  
};
export const subtractDays       = (date, days) => {
  
  return sub(new Date(date), {days});
};
export const addDays            = (date, days) => {
  
  return add(new Date(date), {days});
};

export const addAny           = (date, type, value) => {
  
  return add(date, {[type]: value});
  
};

export const toDate             = (date) => {
  
  if (typeof date === 'object') return date;
  
  if (!date) return parseISO(startOfToday().toJSON());
  
  return parseISO(date);
};
export const stringDateWithTime       = (date) => {
  
  return `${date} 00:00`;
};




