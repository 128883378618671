import React               from 'react';
import ExternalHeader      from '../components/layout/ExternalHeader';
import Footer              from '../components/layout/Footer';
import classes             from './ExternalLayout.module.css';
import Container           from '@mui/material/Container';
import { AnimatePresence } from 'framer-motion';
import MountTransition     from '../components/layout/partials/MountTransition';

const ExternalLayout = ({children}) => (
  <AnimatePresence mode='wait'>
    <MountTransition  slideUp={30}>
      <Container>
        <ExternalHeader className={classes.Reveal}/>
        <main className={classes.SiteContent}>
          {children}
        </main>
        <Footer/>
      </Container>
    </MountTransition>
  </AnimatePresence>
);

export default ExternalLayout;
