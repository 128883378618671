import React from 'react';


import classes from '../containerActions/IncludeBusiness.module.css';
import Dialog  from '../elements/Dialog';
import Grid    from '@mui/material/Grid';
import Typography                            from '@mui/material/Typography';

const HelpFileBusiness = ({modalOn, closeModal}) => {
  
  
  let modal;
  
  modal = <Dialog id="business-help" show={modalOn} handleClose={closeModal} title={'Ajuda Inclusão Negócios'}>
    <Grid container spacing={2}>
      <Grid item xs={12} className={classes.Item}>
        <Typography variant="body2" className={classes.Alert} gutterBottom>
          Cada Corretora possui um padrão de arquivo, portanto inconsistências podem ocorrer,
          confira os dados antes de confirmar.
        </Typography>
      </Grid>
      <Grid item xs={12} className={classes.Item}>
        <Typography variant="body2" color={'primary'} gutterBottom>
          Algumas corretoras colocam na nota o nome ao invés do Ticker do papel,
          para esses casos você deve selecionar manualmente o papel
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} className={classes.Item}>
        <Typography variant="h6">
          Data de Negócios
        </Typography>
        <Typography variant="body2" gutterBottom>
          Data em que foi realizada a transação
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} className={classes.Item}>
        <Typography variant="h6">
          Corretora
        </Typography>
        <Typography variant="body2" gutterBottom>
          Corretora em que foi realizada a transação
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} className={classes.Item}>
        <Typography variant="h6">
          Tipo Operação
        </Typography>
        <Typography variant="body2" gutterBottom>
          Tipo de transação realizada
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} className={classes.Item}>
        <Typography variant="h6">
          Quantidade
        </Typography>
        <Typography variant="body2" gutterBottom>
          Quantidade transacionada
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} className={classes.Item}>
        <Typography variant="h6">
          Valor Unitário
        </Typography>
        <Typography variant="body2" gutterBottom>
          Valor transacionado por cada unidade de papel, em caso de vários negócios como o mesmo papel, será a média de preços
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} className={classes.Item}>
        <Typography variant="h6">
          Custo Total
        </Typography>
        <Typography variant="body2" gutterBottom>
          Custo total da transação, considerando a quantidade total do papel. Caso tenha mais de um papel na mesma nota,
          o custo será dividido entre todos os negócios da nota. Não é considerado custo de IRRF.
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} className={classes.Item}>
        <Typography variant="h6">
          Papel
        </Typography>
        <Typography variant="body2" gutterBottom>
          O papel que foi transacionado
        </Typography>
      </Grid>
    </Grid>
  </Dialog>;
  
  return (
    <>
      {modal}
    </>
  );
};

export default HelpFileBusiness;
