import Grid    from '@mui/material/Grid';
import classes from '../containerActions/IncludeBusiness.module.css';
import Box     from '../elements/Box';
import KeyboardIcon    from '@mui/icons-material/Keyboard';
import Typography      from '@mui/material/Typography';
import DescriptionIcon from '@mui/icons-material/Description';
import React           from 'react';

const ChooseBusiness = ({handleAction}) => {
  
  return (
    <Grid container className={classes.Main} alignItems={'center'}  >
      <Grid item xs={12} sm={6} className={classes.Grid}>
        <Box className={classes.Item2} onClick={() => handleAction(2)} boxProps={classes.Box} >
          <DescriptionIcon style={{fontSize: 140}}/>
          <Typography variant="h6">
            Nota Corretagem
          </Typography>
          <Typography variant="body1" gutterBottom className={classes.Badge}>
            Experimental
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} sm={6} className={classes.Grid}>
        <Box className={classes.Item2} onClick={() => handleAction(1)}  boxProps={classes.Box}>
          <KeyboardIcon style={{fontSize: 140}}/>
          <Typography variant="h6">
            Digitar Dados
          </Typography>
        </Box>
      </Grid>
    </Grid>
  )
  
}

export default ChooseBusiness
