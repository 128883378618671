import React               from 'react';
import InternalLayout      from './InternalLayout';
import ExternalLayout      from './ExternalLayout';


const Layout = ({children, authenticated}) => {
  
  const ChosenLayout = authenticated ? InternalLayout : ExternalLayout;
  
  return (
        <ChosenLayout>
          {children}
        </ChosenLayout>
  );
};

export default Layout;
