import React, { useEffect, useMemo, useState } from 'react';
import classes                                 from './Wallet.module.css';
import Table                                   from '../../components/elements/Table';
import { useDispatch, useSelector }            from 'react-redux';
import { getTemporalWallet }                   from '../../store/slices/achievement';
import { formatInteger }                       from '../../components/utils/formatters';
import Grid                                    from '@mui/material/Grid';
import OneDateBar                              from '../../components/partials/OneDateBar';
import Typography                              from '@mui/material/Typography';
import Box                                     from '../../components/elements/Box';
import InputSwitch                             from '../../components/elements/InputSwitch';

const TemporalWallet = () => {
  
  const dispatch                         = useDispatch();
  const {dateWallet, groupedWallet} = useSelector(state => state.achievement);
  
  const [searchDates, setSearchDates] = useState({});
  const [groupOn, setGroupOn]     = useState(false);
  const [myWallet, setMyWallet]     = useState([]); 
  
  const columns = useMemo(
    () => {
      
      if (groupOn) {
        return [{
          accessor: 'papel',
          Header  : 'Papel',
        },
        {
          accessor: 'quantidade',
          Header  : 'Quantidade',
          Cell    : ({value}) => formatInteger(value)
        },
        // {
        //   Header: 'Detalhar',
        //   id    : 'expander'
        // },
        ];
      } else return [
        {
          accessor: 'papel',
          Header  : 'Papel',
        },
        {
          accessor : 'corretora',
          Header   : 'Corretora',
        },
        {
          accessor: 'quantidade',
          Header  : 'Quantidade',
          Cell    : ({value}) => formatInteger(value)
        },
      ]
    },
    [groupOn]
  );
  
  useEffect(() => {
    setMyWallet(dateWallet);
  }, [dateWallet]);

  // const detailComponent = useMemo(
  //   () => !!groupOn ? WalletDetail : null,
  //   [groupOn]
  // );
   
  const groupList = (event) => {
    setGroupOn(event.target.checked);
    if (event.target.checked === true) setMyWallet(groupedWallet);
    else setMyWallet(dateWallet);
  };
  
  useEffect(() => { 
    if (searchDates.referenceDate) dispatch(getTemporalWallet({dataReferencia: searchDates.referenceDate}));
  }, [searchDates]);
  
  const handleChangeDate = (dates) => {
    // console.log("dates", dates)
    setSearchDates(dates);
  };

  return (
    <Grid container >
     <Grid item xs={12}>
        <OneDateBar getDates={handleChangeDate}/>
      </Grid>
      {!!dateWallet.length &&
      <Grid item xs={12}>
        <Table columns={columns} rowsData={myWallet}   
        inclusion={<InputSwitch label={'agrupar'} name={'paper'} checked={groupOn} onChange={groupList}/>}/>
      </Grid>
      }
      {!dateWallet.length &&
      <Box>
        <Grid item xs={12} className={classes.NegativeBox}>
          <Typography variant={'h6'} className={classes.NegativeText}>
            Você ainda não possui ativos na data
          </Typography>
        </Grid>
      </Box>
      }
    </Grid>
  
  );
};


export default TemporalWallet;
