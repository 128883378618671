import { createSlice } from '@reduxjs/toolkit';
import { thunkAPI }    from '../../components/utils/api';

export const computeRepurchase   = thunkAPI('repurchase/compute', '/v1/recompra/processar/computar', 'post',
  {successMessage: 'Recompra processada com sucesso'});
export const includeRepurchase   = thunkAPI('repurchase/include', '/v1/recompra/processar', 'post',
  {successMessage: 'Recompra incluída com sucesso'});
export const getMyRepurchases    = thunkAPI('repurchase/get', 'v1/posse/recompra', 'get',);
export const getPaperRepurchases = thunkAPI('paperRepurchase/get', 'v1/recompra/busca/20', 'get',);
export const processRepurchase   = thunkAPI('repurchase/process', '/v1/recompra/processar/data', 'post',
  {successMessage: 'Recompra processada com sucesso'});

const formatMyRepurchases = (repurchases) => {
  
  const formattedRepurchases = [];
  // console.log('repurchases', repurchases)
   
  for (const data of repurchases) {
    
    formattedRepurchases.push({
      papel     : data.Papel.sigla,
      dtRecompra: data.dtRecompra,
      dtCredito : data.dtCredito,
      quantidade: data.qtPapel,
      vlUnitario: data.vlRecompra,
      vlTotal   : data.vlRecompra * data.qtPapel,
      
    });
    
  }
  
  return formattedRepurchases;
  
};
const formatRepurchases   = (repurchases) => {
  
  const formattedRepurchases = [];
  console.log('repurchase', repurchases);
  
  for (const data of repurchases) {
    
    formattedRepurchases.push({
      dataRecompra : data.dtRecompra,
      valorRecompra: data.vlRecompra,
      dataCredito  : data.dtCredito,
      vigente      : data.vigente ? 'Sim' : 'Não',
      
    });
  }
  
  return formattedRepurchases;
  
};

const slice = createSlice({
  name         : 'repurchase',
  initialState : {
    paperRepurchases: [],
    myRepurchases   : [],
    includeSituation: 'idle',
    searchSituation : 'idle',
    processSituation: 'idle',
  },
  reducers     : {},
  extraReducers: {
    [getMyRepurchases.fulfilled]   : (state, action) => {
      state.myRepurchases = formatMyRepurchases(action.payload);
    },
    [getPaperRepurchases.fulfilled]: (state, action) => {
      state.paperRepurchases = formatRepurchases(action.payload);
      state.searchSituation  = 'idle';
    },
    [getPaperRepurchases.pending]  : (state) => {
      state.searchSituation = 'pending';
    },
    [getPaperRepurchases.rejected] : (state) => {
      state.searchSituation = 'rejected';
    },
    [processRepurchase.fulfilled]  : (state) => {
      state.processSituation = 'idle';
    },
    [processRepurchase.pending]    : (state) => {
      state.processSituation = 'pending';
    },
    [processRepurchase.rejected]   : (state) => {
      state.processSituation = 'rejected';
    },
  }
});

const {reducer} = slice;


export default reducer;
