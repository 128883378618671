import React, { useEffect, useMemo } from 'react';
import Table                         from '../../components/elements/Table';
import { useDispatch, useSelector }  from 'react-redux';
import { getMyRearrangements }                                      from '../../store/slices/rearrangement';
import { formatCpfCnpj, formatCurrency, formatDate, formatInteger } from '../../components/utils/formatters';
import Grid                                                         from '@mui/material/Grid';
import Box                          from '../../components/elements/Box';
import classes                      from '../Wallet/Wallet.module.css';
import Typography                   from '@mui/material/Typography';



const MyRearrangement = () => {
  
  
  const dispatch           = useDispatch();
  const {myRearrangements} = useSelector(state => state.rearrangement);
  
  useEffect(() => {
    dispatch(getMyRearrangements());
  }, []);
  
  const columns = useMemo(
    () =>
      [
        {
          accessor: 'papel',
          Header  :   'Papel',
        },
        {
          accessor: 'tipo',
          Header  :  'Tipo de Rearranjo',
        },
        {
          accessor: 'qtBase',
          Header  : 'Quantidade Original',
        },
        {
          accessor: 'multiplicador',
          Header  : 'Multiplicador',
        },
        {
          accessor: 'qtNova',
          Header  :'Nova Quantidade',
        },
        {
          accessor: 'dataRearranjo',
          Header  : 'Data Rearranjo',
          Cell    : ({value}) => formatDate(value)
        },
        
      ],
    []
  );
  
  return (
    <Grid container>
      {!!myRearrangements.length &&
        <Grid item xs={12}>
        <Table columns={columns} rowsData={myRearrangements}/>
      </Grid>
      }
      {!myRearrangements.length &&
      <Box>
        <Grid item xs={12} className={classes.NegativeBox}>
          <Typography variant={'h6'} className={classes.NegativeText}>
            Você não possui rearranjos
          </Typography>
        </Grid>
      </Box>
      }
    </Grid>
  
  );
};


export default MyRearrangement;
