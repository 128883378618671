import React, { useMemo }             from 'react';
import Table                          from '../elements/Table.js';
import classes                        from './AchievementDetail.module.css';
import { formatCurrency, formatDate } from '../utils/formatters';



const AchievementDetail = ({row}) => {
  
  const {realizacoes} = row;
  
  const columns = useMemo(
    () =>
      [
        {
          accessor: 'quantidade',
          Header  :  'Quantidade',
        },
        {
          accessor: 'vlCompraOriginal',
          Header  :  'Valor Compra',
          Cell    : ({value}) => formatCurrency(value)
        },
        {
          accessor: 'vlVendaOriginal',
          Header  :  'Valor Venda',
          Cell    : ({value}) => formatCurrency(value)
        },
        {
          accessor: 'custoTotalOriginal',
          Header  : 'Custo Total',
          Cell    : ({value}) => formatCurrency(value)
        },
        {
          accessor: 'balancoOriginal',
          Header  : 'Balanço',
          Cell    : ({value}) => formatCurrency(value)
        },
        {
          accessor: 'valorizacaoOriginal',
          Header  : 'Valorização',
          Cell    : ({value}) => `${(value * 100).toFixed(2)}%`
        },
        {
          accessor: 'valorizacaoProvOriginal',
          Header  : 'Valorização c/ Proventos',
          Cell    : ({value}) => `${(value * 100).toFixed(2)}%`
        },
        {
          accessor: 'valorizacaoMensal',
          Header  : 'Valorização Mensal',
          Cell    : ({value}) => `${(value * 100).toFixed(2)}%`
        },
        {
          accessor: 'valorizacaoProvMensal',
          Header  : 'Valorização Mensal c/ Proventos',
          Cell    : ({value}) => `${(value * 100).toFixed(2)}%`
        },
        {
          accessor: 'dataCompra',
          Header  :'Data Compra',
          Cell    : ({value}) => formatDate(value)
        },
        {
          accessor: 'dataVenda',
          Header  : 'Data Venda',
          Cell    : ({value}) => formatDate(value)
        },
        {
          accessor: 'corretoraCompra',
          Header  :'Corretora Compra',
        },
        {
          accessor: 'corretoraVenda',
          Header  :'Corretora Venda',
        },
       
      ],
    []
  );
  
  return (
    <div className={classes.Container}>
      <Table columns={columns} rowsData={realizacoes} hideFilter={true}/>
    </div>
  );
  
  
};

export default AchievementDetail;
