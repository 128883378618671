import React, { useEffect, useState }        from 'react';
import Grid                                  from '@mui/material/Grid';
import classes                               from './FileBusiness.module.css';
import Typography                            from '@mui/material/Typography';
import Button                                from '../elements/Button';
import FileDrop                              from '../elements/FileDrop';
import { readPDFBusiness, resetPdfBusiness } from '../../store/slices/business';
import { useDispatch, useSelector }          from 'react-redux';
import ConfirmationFileBusiness              from './ConfirmationFileBusiness';


const FileBusiness = ({}) => {
  
  const [files, setFiles] = useState([]);
  const dispatch          = useDispatch();
  const {pdfBusiness}     = useSelector(state => state.business);
  
  const handleSend = () => {
    const formData = new FormData();
    formData.append('file', files[0]);
    dispatch(readPDFBusiness({data: formData, file: true}));
  };
  
  
  useEffect(() => {
    
    dispatch(resetPdfBusiness());
    
  }, [files]);
  
  useEffect(() => {
    setFiles([]);
    dispatch(resetPdfBusiness());
    
  }, []);
  
  return (
    <Grid container className={classes.Main} alignItems={'center'}>
      <Grid item xs={12} className={classes.Notice}>
        <Typography variant="h5" className={classes.Title} gutterBottom>
          ATENÇÃO
        </Typography>
        <Typography variant="h6" gutterBottom>
          Apenas arquivos PDF são aceitos, um arquivo por vez.
        </Typography>
        <Typography variant="h6">
          Os arquivos devem estar em formato texto, salve o arquivo da sua corretora ao invés de imprimir.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <FileDrop files={files} setFiles={setFiles} preview={!pdfBusiness.date}/>
      </Grid>
      {!!files.length && !pdfBusiness.date &&
      <Grid item xs={12}>
        <Button color="secondary" onClick={handleSend}>
          Enviar
        </Button>
      </Grid>
      }
      <Grid item xs={12}>
        {!!pdfBusiness.date && <ConfirmationFileBusiness data={pdfBusiness}/>}
      </Grid>
    
    </Grid>
  );
  
};

export default FileBusiness;
