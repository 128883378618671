import React, { useEffect }                  from 'react';
import classes                               from '../containerActions/IncludeBusiness.module.css';
import Grid                                  from '@mui/material/Grid';
import FormDate                              from '../elements/FormDate';
import TodayIcon                             from '@mui/icons-material/Today';
import { handleError, rules }                from '../utils/rules';
import FormSelect                            from '../elements/FormSelect';
import FormMask                              from '../elements/FormMask';
import ShoppingCartIcon                      from '@mui/icons-material/ShoppingCart';
import { integerFormat, }                    from '../utils/constants';
import FormAutoComplete                      from '../elements/FormAutoComplete';
import { searchPaper }                       from '../../store/slices/paper';
import Button                                from '../elements/Button';
import { getBusinessTypes, includeBusiness } from '../../store/slices/business';
import { useForm }                           from 'react-hook-form';
import { useDispatch, useSelector }          from 'react-redux';
import { getExchanges }             from '../../store/slices/exchange';
import { justToDate, toPickerDate } from '../utils/date';


const defaultValues = {
  date         : '',
  businessType : '',
  paper        : null,
  exercisePaper: null,
  quantity     : '',
  exchange     : '',
  value        : '',
  cost         : '',
  subCost      : 0,
  underwriting : '',
};

const CloseBusiness = ({modalOn, initialData, closeModal}) => {
  
  const dispatch = useDispatch();
  
  const {businessTypes, includeSituation} = useSelector(state => state.business);
  
  const {exchanges} = useSelector(state => state.exchange);
  
  
  useEffect(() => {
    
    if (modalOn && !businessTypes.length) {
      dispatch(getBusinessTypes());
      dispatch(getExchanges());
    }
    
  }, [modalOn]);
  
  useEffect(() => {
    if (initialData) {
      // console.log("initialData", initialData)
      defaultValues.date         = initialData.date;
      defaultValues.quantity     = initialData.quantidade;
      defaultValues.exchange     = JSON.stringify(exchanges.find(ex => ex.abreviatura === initialData.corretora));
      defaultValues.businessType = JSON.stringify(businessTypes.find(bt => bt.id === initialData.operationId));
      defaultValues.paper        = {cdPapel: initialData.papelId, sigla: initialData.papel, cdTipoPapel: initialData.cdTipoPapel};
    }
    // console.log(defaultValues)
    reset(defaultValues);
  }, [initialData]);
  
  
  const {control, handleSubmit, watch, reset, getValues, formState} =
          useForm({mode: 'all', defaultValues});
  
  const watchDate                      = watch('date');
  const {isSubmitted, isValid, errors} = formState;
  
  useEffect(() => {
    // console.log("isSubmitted", isSubmitted, includeSituation)
    if (includeSituation === 'idle' && isSubmitted) {
      if (!!initialData) closeModal();
      reset({
        date        : '',
        businessType: '',
        paper       : null,
        quantity    : '',
        exchange    : '',
        cost        : '',
      });
    }
    
  }, [includeSituation]);
  
  const onSubmit = (data) => {
    // console.log('passou aqui',);
    let {date, exchange, paper, quantity, businessType} = data;
    // console.log(data); // handle the data here
    // console.log(justToDate(date)); // handle the data here
    date = justToDate(date)
    exchange = JSON.parse(exchange)
    businessType = JSON.parse(businessType)
    
    let papelId = paper.cdPapel;
    let papel   = paper.sigla;
    
    dispatch(includeBusiness(
      {
        data        : {
          papelId,
          dataNegocio    : date,
          quantidadePapel: quantity,
          valorPapel     : 0,
          custo          : 0,
          corretoraId    : exchange.corretoraId,
          tipoNegocio    : businessType.id,
        },
        internalData: {
          custo      : 0,
          dataNegocio: date,
          quantidade : quantity,
          corretora  : exchange.abreviatura,
          papel,
          tipoNegocio: businessType.nome,
          valorPapel : 0,
        }
      }));
    
  };
  
  const disabled  = (!isValid || includeSituation === 'pending');
  const showPaper = (watchDate && !Boolean(errors['date']));
  
  return (
    <form id="business" onSubmit={handleSubmit(onSubmit)} className={classes.Form}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} className={classes.Item}>
          <FormDate label="Data do Negócio" name="date" Icon={TodayIcon} errorMessage={handleError(errors, 'date')}
                    control={control} rules={rules('businessDate')}
          />
        </Grid>
        <Grid item xs={12} sm={6} className={classes.Item}>
          <FormSelect label="Corretora" name="exchange" errorMessage={handleError(errors, 'exchange')}
                      displayField={'abreviatura'} disabled={true}
                      rules={(rules('exchange', null, 'Corretora'))} data={exchanges} control={control}
          />
        </Grid>
        <Grid item xs={12} sm={6} className={classes.Item}>
          <FormSelect label="Tipo de Operação" name="businessType" errorMessage={handleError(errors, 'businessType')}
                      displayField={'nome'} rules={(rules('businessType', null, 'Tipo de Negócio'))}
                      data={businessTypes} control={control} disabled={true}
          />
        </Grid>
        <Grid item xs={12} sm={6} className={classes.Item}>
          <FormMask label="Quantidade" name="quantity" Icon={ShoppingCartIcon}
                    errorMessage={handleError(errors, 'quantity')} control={control}
                    rules={rules('quantity', null, 'Quantidade')} formatOptions={integerFormat}
          />
        </Grid>
        {showPaper &&
        <Grid item xs={12} sm={6} className={classes.Item}>
          <FormAutoComplete label="Papel" name="paper" placeholder="Pesquise o papel" control={control} errorMessage={handleError(errors, 'paper')}
                            dataFunction={searchPaper} searchField={'busca'} requiredData={{dataPesquisa: watchDate}} displayField="sigla" disabled={true}
                            rules={(rules('paper', null, 'Papel'))} dataFields={{slice: 'paper', data: 'papersSearched'}}/>
        </Grid>
        }
        
        <Grid item xs={12} className={classes.Button}>
          <Button color="secondary" disabled={disabled} type="submit">
            {includeSituation === 'pending' ? 'Cadastrando' : 'Cadastrar'}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default CloseBusiness;
