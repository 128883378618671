import React    from 'react';
import classes  from './Logo.module.css';
import myLogo   from "../../../assets/images/inverted-logo.svg";
import { Link } from 'react-router-dom';
import Image    from '../../elements/Image';
import Tooltip  from '@mui/material/Tooltip';

const InvertedLogo = ({className, ...props}) => {
  
  const myClasses = [classes.Marca, className].join(' ');
  
  return (
    <div className={myClasses}  {...props}>
      <h1 className={classes.Destaque}>
        <Tooltip title={'Kiila'}>
          <Link to="/">
            <Image src={myLogo} alt="Open" width={53} height={53}/>
          </Link>
        </Tooltip>
      </h1>
    </div>
  );
};

export default InvertedLogo;
