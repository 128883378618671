import React, { useEffect, useState } from 'react';
import Grid                                       from '@mui/material/Grid';
import classes                         from '../sections/FeatureTiles.module.css';
import Typography                      from '@mui/material/Typography';
import { motion }                      from 'framer-motion';
import { useInView }                   from 'react-intersection-observer';

const MainFeature = ({title, children, separator, variants, delay,}) => {
  
  
  const [ref, inView]         = useInView({rootMargin: '-50px 0px', triggerOnce: true});
  const [variant, setVariant] = useState('hidden');
  
  useEffect(() => {
    if (inView) {
      setVariant('visible');
    }
  }, [inView]);
  
  return (
    <motion.div ref={ref} variants={variants} initial="hidden" animate={variant} transition={{duration: 0.7, delay: delay}}>
      <Grid container justifyContent="center" className={classes.Root}>
        {!!separator && <Grid item xs={12} className={classes.Separator}/>}
        <Grid item xs={6} className={classes.TitleContainer}>
          <Typography variant="h3" className={classes.Title}>
            {title}
          </Typography>
        </Grid>
        <Grid item xs={7}>
          <Typography variant="body1" className={classes.Text}>
            {children}
          </Typography>
        </Grid>
      </Grid>
    </motion.div>
  );
  
}

export default MainFeature;
