import React, { useEffect, useState } from 'react';
import Grid                           from '@mui/material/Grid';
import HighchartsReact                from 'highcharts-react-official';
import Highcharts                     from 'highcharts';
import { useDispatch, useSelector } from 'react-redux';
import { getCompiledAchievements }  from '../../store/slices/achievement';
import { formatCurrency }           from '../utils/formatters';
import Box        from '../elements/Box';
import classes    from './EarningSegmentation.module.css';
import Typography from '@mui/material/Typography';


const commonChart  = {
  chart      : {
    backgroundColor: '#fafafa',
    type           : 'column',
  },
  yAxis      : {
    // min        : 0,
    title      : {
      text: 'Lucro em R$'
    },
    stackLabels: {
      enabled  : true,
      style    : {
        fontWeight: 'bold',
        color     : 'gray'
      },
      formatter: function () {
        return `${formatCurrency(this.total)}`;
      }
    }
  },
  tooltip    : {
    formatter: function () {
      return `
      <b>${this.x} <br/></b>
      ${this.series.name}: <b>${formatCurrency(this.y)}</b><br/>
      Total: <b>${formatCurrency(this.point.stackTotal)}</b>
      `;
    }
  },
  plotOptions: {
    column: {
      stacking  : 'normal',
      dataLabels: {
        enabled  : true,
        formatter: function () {
          return `${formatCurrency(this.y)}`;
        }
      },
    }
  },
  credits    : {
    enabled: false
  },
};
const commonChart2 = {
  chart      : {
    backgroundColor: '#fafafa',
    type           : 'column',
  },
  yAxis      : {
    // min        : 0,
    title      : {
      text: 'Lucro em R$'
    },
    stackLabels: {
      enabled  : false,
      style    : {
        fontWeight: 'bold',
        color     : 'gray'
      },
      formatter: function () {
        return `${formatCurrency(this.total)}`;
      }
    }
  },
  tooltip    : {
    formatter: function () {
      return `
      <b>${this.x} <br/></b>
      ${this.series.name}: <b>${formatCurrency(this.y)}</b><br/>
      Total: <b>${formatCurrency(this.point.stackTotal)}</b>
      `;
    }
  },
  plotOptions: {
    column: {
      stacking  : 'normal',
      dataLabels: {
        enabled: false,
        // rotation: -90,
        // formatter: function () {
        //   return `${formatCurrency(this.y)}`;
        // }
      },
    }
  },
  credits    : {
    enabled: false
  },
};

const title1 = {text: 'Realização por tipo'};
const title2 = {text: 'Realização por tipo x ano'};

const AchievementSegmentation = () => {
  
  const [chart1Options, setChart1Options]         = useState(null);
  const [chart2Options, setChart2Options]         = useState(null);
  const dispatch                                  = useDispatch();
  const {compiledAchievements, yearsAchievements} = useSelector(state => state.achievement);
  
  useEffect(() => {
    
    setChart1Options({
      ...commonChart,
      xAxis      : {
        categories: compiledAchievements.categories
      },
      title : title1,
      series: [{
        name: 'Swing Trade',
        data: compiledAchievements.st
      }, {
        name: 'Day Trade',
        data: compiledAchievements.dt
      },
      ]
    });
    
    
  }, [compiledAchievements]);
  useEffect(() => {
    
    setChart2Options({
      ...commonChart2,
      title : title2,
      xAxis : {
        categories: yearsAchievements.years,
        labels    : {
          rotation: -45,
        }
      },
      series: yearsAchievements.series,
    });
    
    
  }, [yearsAchievements]);
  
  useEffect(() => {
    dispatch(getCompiledAchievements());
  }, []);
  
  // console.log("compiledAchievements.st", yearsAchievements.years)
  return (
    <>
     
      <Box>
        {!!yearsAchievements.years.length &&
        <Grid container>
          <Grid item xs={12} md={6}>
            <HighchartsReact highcharts={Highcharts} options={chart1Options} immutable = { true }/>
          </Grid>
          <Grid item xs={12} md={6}>
            <HighchartsReact highcharts={Highcharts} options={chart2Options} immutable = { true }/>
          </Grid>
        </Grid>
        }
        {!yearsAchievements.years.length &&
          <Grid item xs={12} className={classes.NegativeBox}>
            <Typography variant={'h6'} className={classes.NegativeText}>
              Você ainda não possui realizações
            </Typography>
          </Grid>
        }
      </Box>
     
    </>
  );
};

export default AchievementSegmentation;
