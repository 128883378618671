import React          from 'react';
import classes        from './ExcludePopover.module.css';
import ConfirmPopover from '../elements/ConfirmPopover';
import DeleteIcon     from '@mui/icons-material/Delete';

const ExcludePopover = (exclusionFunc, type) => {
  
  const artigo = type.slice(-1);
  
  return ({row}) => {
    
    
    return (
      <div className={classes.ActionsButtons}>
        <ConfirmPopover title="Confirmação de Ação" message={`Confirma exclusão d${artigo} ${type}?`} tooltip={`exclusão ${type}`}
                        iconButton={DeleteIcon}
                        onConfirm={() => exclusionFunc(row)}
        />
      </div>
    );
    
  };
}

export default ExcludePopover;
