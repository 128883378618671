import React, { useState } from 'react';

import { useForm }                  from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import classes                      from './IncludeIncorporation.module.css';
import Button                       from '../elements/Button';
import Dialog                       from '../elements/Dialog';

import { decimalFormat, }                   from '../utils/constants';
import { handleError, rules }               from '../utils/rules';
import Grid                                 from '@mui/material/Grid';
import { searchAdmPaper }                   from '../../store/slices/paper';
import FormDate                             from '../elements/FormDate';
import CakeIcon                             from '@mui/icons-material/Cake';
import FormAutoComplete                     from '../elements/FormAutoComplete';
import FormMask                             from '../elements/FormMask';
import HighlightOffTwoToneIcon              from '@mui/icons-material/HighlightOffTwoTone';
import { computeScission, includeScission } from '../../store/slices/scission';
import FormSwitch                           from '../elements/FormSwitch';

const defaultValues = {
  date        : '',
  originPaper : null,
  destinyPaper: null,
  multiplier  : '',
  schedule    : false,
};

const IncludeScission = () => {
  
  const dispatch = useDispatch();
  
  const [modalOn, setModalOn] = useState(false);
  
  const {control, handleSubmit, getValues, formState} = useForm({mode: 'all', defaultValues});
  
  const {dirtyFields, isValid, errors} = formState;
  
  let modal;
  
  const openModal = () => {
    setModalOn(true);
  };
  
  const closeModal = () => setModalOn(false);
  
  const onSubmit = (data) => {
    
    const {date, destinyPaper, originPaper, multiplier, schedule} = data;
    
    const cis = {
      dataCisao     : date,
      multiplicador : multiplier,
      papelIdOrigem : originPaper.cdPapel,
      papelIdDestino: destinyPaper.cdPapel
    };
    const func   = schedule ? includeScission : computeScission;
    
    dispatch(func(cis));
    
  };
  
  const disabled  = !isValid;
  const showPaper = (!!dirtyFields['date'] && !Boolean(errors['date']));
  
  modal = <Dialog id="scission-modal" show={modalOn} handleClose={closeModal} title={'Inclusão Cisão'}>
    
    <form id="scission" onSubmit={handleSubmit(onSubmit)} className={classes.Form}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} className={classes.Item}>
          <FormDate label="Data Cisão" name="date" Icon={CakeIcon} errorMessage={handleError(errors, 'date')}
                    control={control} rules={{required: true}}
          />
        </Grid>
        <Grid item xs={12} sm={6} className={classes.Item}>
          <FormMask label="Multiplicador" name="multiplier" Icon={HighlightOffTwoToneIcon}
                    errorMessage={handleError(errors, 'multiplier')} control={control}
                    rules={rules('value')} formatOptions={decimalFormat}
          />
        </Grid>
        <Grid item xs={12} sm={6} className={classes.Item}>
          {showPaper &&
          <FormAutoComplete label="Papel Origem" name="originPaper" placeholder="Pesquise o papel Origem" control={control}
                            rules={(rules('paper', null, 'Papel'))} dataFields={{slice: 'paper', data: 'papersSearched'}}
                            dataFunction={searchAdmPaper} searchField={'busca'} requiredData={{dataPesquisa: getValues('date')}}
                            displayField="sigla" errorMessage={handleError(errors, 'originPaper')}/>
          }
        </Grid>
        <Grid item xs={12} sm={6} className={classes.Item}>
          {showPaper &&
          <FormAutoComplete label="Papel Destino" name="destinyPaper" placeholder="Pesquise o papel Destino" control={control}
                            rules={(rules('paper', null, 'Papel'))} dataFields={{slice: 'paper', data: 'papersSearched'}}
                            dataFunction={searchAdmPaper} searchField={'busca'} requiredData={{dataPesquisa: getValues('date')}}
                            displayField="sigla" errorMessage={handleError(errors, 'destinyPaper')}/>
          }
        </Grid>
        <Grid item xs={12} sm={6} className={classes.Switch}>
          <FormSwitch label={'Agendar'} name={'schedule'} control={control} defaultValue={defaultValues['schedule']}/>
        </Grid>
        <Grid item xs={12} className={classes.Button}>
          <Button color="secondary" disabled={disabled} type="submit">
            Cadastrar
          </Button>
        </Grid>
      </Grid>
    </form>
  </Dialog>;
  
  return (
    <>
      <Button color="secondary" disabled={!!modalOn} onClick={openModal}>
        Incluir
      </Button>
      {modal}
    </>
  );
};

export default IncludeScission;
