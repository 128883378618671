import React      from 'react';
import MBox       from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid       from '@mui/material/Grid';

const defaultProps = {
  bgcolor    : 'background.paper',
  borderColor: 'text.primary',
  m          : 1,
  border     : 1,
  // style: { width: '5rem', height: '5rem' },
};

const Box = ({children, title, boxProps, titleProps, ...props}) => {
  return (
    <Grid item xs={12} className={boxProps}>
        <MBox borderRadius="borderRadius" {...defaultProps} {...props}>
          {!!title &&
          <Grid item xs={12} className={titleProps}>
            <Typography variant="h4"> {title} </Typography>
          </Grid>
          }
          {children}
        </MBox>
    </Grid>
  );
};
export default Box;
