import React                  from 'react';

import Tooltip from '@mui/material/Tooltip';
import Button  from '@mui/material/Button';
import classes from './CellTooltip.module.css'
const CellTooltip = (tooltip, field) => {
  
  return ({row}) => {
    
    
    return (
      <div>
        {!!row.original[field] &&
          <Tooltip title={tooltip}>
            <span><Button  disabled  classes={{ disabled: classes.Button }}  >{row.original[field]}</Button ></span>
          </Tooltip>
        }
      </div>
    );
    
  };
}

export default CellTooltip;
