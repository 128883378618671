import React        from 'react';
import env          from '../../environment';
import Typography   from '@mui/material/Typography';
import { ListItem } from '@material-ui/core';
import List         from '@mui/material/List';
import Grid         from '@mui/material/Grid';
import classes      from './UseTherms.module.css';

const useTherms = () => {
  
  
  return (
    <Grid item xs={12} style={{paddingRight: '5px'}}>
      <Typography variant="h5" gutterBottom>
        Termos de Uso
      </Typography>
      <Typography variant="body2" align="justify" gutterBottom>
        Leia estes <b>Termos de Uso</b> e as condições cuidadosamente. Ao marcar a caixa de aceite ao final, você concorda que leu, compreendeu e concorda com
        esses termos.
      </Typography>
      <Typography variant="body2" align="justify" gutterBottom>
        Este destina-se a regular as condições para utilizar os serviços oferecidos através de acesso ao site e portal {env.host} e
        seus subdomínios, daqui em diante denominado “Kiila”, acessado e utilizado por pessoas físicas ou jurídicas, aqui denominadas “usuários”, hospedado
        por serviços contratados em servidores de internet aqui denominados “Servidores”.
        <br/>
        Antes de prosseguir, é fundamental que você leia e compreenda todos os Termos que regulam a utilização da plataforma e de seus benefícios. O usuário
        que não concordar, nem aceitar, sem limitações ou modificações, estes Termos de Uso, deverá abster-se de utilizar a plataforma. Por isso, é importante
        que voce dedique alguns minutos do seu tempo para ler estes Termos com bastante atenção. Isso vai permitir que você aproveite com segurança tudo o que
        o Kiila tem para lhe oferecer.
        Caso tenha dúvidas sobre o conteúdo destes Termos de Uso, entre em contato pelos canais disponíveis.
      </Typography>
      <br/>
      <Typography variant="h6" gutterBottom>
        1. Sobre o Kiila
      </Typography>
      <br/>
      <Typography variant="body2" align="justify" gutterBottom>
        1.1 O Kiila é uma portal de propriedade da pessoa jurídica de direito privado, MT Alimentos Ltda., organizado sob a forma de sociedade limitada,
        regularmente constituída e registrada na Junta Comercial, com sede em Brasília - DF,
        inscrita no CNPJ sob o nº18.375.731/0001-87. O Kiila é acessado através da internet pelo site {env.host}, seus subdomínios, e também
        através de conexão direta, via network socket e/ou API (Interface de Programação de Aplicações) web própria, ao endereço {env.host}, onde são oferecidos
        os serviços adquiridos mediante contratação.
      </Typography>
      <br/>
      <Typography variant="body2" align="justify" gutterBottom>
        1.2 Fica determinado que toda a identidade visual disponibilizada na plataforma e outros materiais correspondentes ao Kiila constituem
        direitos autorais e/ou direitos de propriedade do CNPJ mencionado, assim como todos os softwares utilizados.
      </Typography>
      <br/>
      <Typography variant="h6" gutterBottom>
        2. Aspectos Gerais
      </Typography>
      <br/>
      <Typography variant="body2" align="justify" gutterBottom>
        2.1. Por meio dos presentes Termos afirmamos nosso compromisso com a privacidade e segurança no tratamento das informações coletadas dos usuários, bem
        como dos produtos e serviços financeiros ofertados pelo Kiila.
      </Typography>
      <br/>
      <Typography variant="body2" align="justify" gutterBottom>
        2.2 Os Termos aplicam-se a todos os usuários, bem como aos administradores, colaboradores, fornecedores, terceiros e demais envolvidos nas atividades do
        Kiila
      </Typography>
      <br/>
      <Typography variant="body2" align="justify" gutterBottom>
        2.3 Com base nas Leis no 9.279/1996 (“Lei da Propriedade Industrial”), no 9.609/1998 (“Lei de Software”) e no 9.610/1998 (“Lei de Direitos Autorais”), e
        sem prejuízo das demais normas relativas à proteção da propriedade intelectual, todos os direitos de propriedade intelectual do Kiila e seus serviços
        estão reservados, assim como todas as marcas, logotipos, nomes comerciais, layouts, gráficos e design de interface, imagens, ilustrações, fotografias,
        apresentações, vídeos, conteúdos de som e áudio, programas de computador, banco de dados, arquivos de transmissão e quaisquer outras informações ou
        obras protegidas pela legislação aplicável.
      </Typography>
      <br/>
      <Typography variant="body2" align="justify" gutterBottom>
        2.4 O Kiila concede ao usuário, por meio destes Termos e durante sua vigência, uma licença pessoal, não exclusiva, não transferível, não sublicenciável
        e
        limitada, para acessar os Serviços do Kiila para uso pessoal ou comercial.
      </Typography>
      <br/>
      <Typography variant="body2" align="justify" gutterBottom>
        2.5 A transmissão de informações pela internet pode ser objeto de atrasos arbitrários, que fogem ao controle do Kiila. Os usuários,
        através destes Termos, reconhecem que o Kiila e/ou seus parceiros não serão responsabilizados por prejuízos advindos desses atrasos.
      </Typography>
      <br/>
      <Typography variant="body2" align="justify" gutterBottom>
        2.6 A forma e as funcionalidades do site e portal poderão mudar ocasionalmente, sem aviso prévio. Da mesma forma, o modelo de exploração de cada
        funcionalidade do portal e site podem ser alterados.
      </Typography>
      <br/>
      <Typography variant="h6" gutterBottom>
        3. Aceite aos Termos
      </Typography>
      <br/>
      <Typography variant="body2" align="justify" gutterBottom>
        3.1. O usuário deverá ler, atenta e integralmente, o teor destes Termos e, caso concorde com as suas disposições, deverá manifestar seu consentimento
        livre, expresso, informado e inequívoco com relação ao seu conteúdo. Este aceite se dará ao marcar a caixa de aceite ao final do mesmo.
      </Typography>
      <br/>
      <Typography variant="body2" align="justify" gutterBottom>
        3.1.1. O usuário compreende que tal consentimento poderá ser revogado a qualquer momento, mediante solicitação ao Kiila, oportunidade em
        que os seus dados e informações coletados serão excluídos.
      </Typography>
      <br/>
      <Typography variant="body2" align="justify" gutterBottom>
        3.2. O usuário reconhece ser o único e exclusivo responsável por eventual cadastro realizado no Kiila e pela veracidade dos dados fornecidos. O usuário
        declara reconhecer que o Kiila não possui qualquer responsabilidade pela veracidade dos dados fornecidos, bem como por eventuais danos decorrentes da
        inexatidão e/ou desatualização de referidas informações.
      </Typography>
      <br/>
      <Typography variant="body2" align="justify" gutterBottom>
        3.3 Pelo presente instrumento, o usuário contrata o serviço que lhe é oferecido mediante acesso ao ambiente no site {env.host}, para o
        auxílio ao controle de aplicações em bolsa de valores, com o objetivo de auxiliar o usuário no controle e nas suas apurações de resultados
        para o pagamento do Imposto de Renda mensal e declaração anual, conforme a interpretação do Kiila à legislação brasileira tributária em vigor, e
        conforme às interpretações listadas no ANEXO I destes Termos.
      </Typography>
      <br/>
      <Typography variant="body2" align="justify" gutterBottom>
        3.4 O usuário declara ter conhecimento de que, na forma da legislação vigente, a obrigação do recolhimento do Imposto de Renda mensal é de sua
        responsabilidade e que o Imposto de Renda deve ser recolhido até o último dia do mês subsequente ao que se realizou o ganho, estando ciente, caso atrase
        esse pagamento, que recolherá esse imposto com a incidência das multas e juros.
      </Typography>
      <br/>
      <Typography variant="body2" align="justify" gutterBottom>
        3.5 O usuário declara estar ciente e concordar com a forma de apuração descrita no ANEXO I que faz parte integrante destes Termos.
      </Typography>
      <br/>
      <Typography variant="body2" align="justify" gutterBottom>
        3.6 O usuário, ao concordar com o presente Termo, declara que concorda ser identificado pelo Kiila e seus parceiros, no uso de
        cookies e/ou de outras tecnologias, e, ter com isso a disposição, serviços e informações personalizadas.
      </Typography>
      <br/>
      <Typography variant="h6" gutterBottom>
        4. Prazo e término
      </Typography>
      <br/>
      <Typography variant="body2" align="justify" gutterBottom>
        4.1 A adesão à este Termo poderá ser rescindida via canais de comunicação disponibilizados pelo Kiila a qualquer momento.
      </Typography>
      <br/>
      <Typography variant="body2" align="justify" gutterBottom>
        4.2 Nos casos de cancelamento de planos em que foi concedido um desconto devido ao tempo de comprometimento do usuário permanecer com o serviço,
        haverá uma retenção de 30% referente aos valores do prazo ainda não utilizado, a título de multa.
      </Typography>
      <br/>
      <Typography variant="body2" align="justify" gutterBottom>
        4.2.1 No caso das assinaturas mensais, caso o cancelamento seja feito dentro de 7 dias após o pedido, o valor será totalmente restituído. Caso o
        cancelamento seja feito após 7 dias, seu pagamento não será reembolsado e o serviço continuará até o fim do período de cobrança do mês vigente.
      </Typography>
      <br/>
      <Typography variant="body2" align="justify" gutterBottom>
        4.3 O Kiila poderá, suspender ou cancelar, temporária ou definitivamente, a conta de um usuário, a qualquer momento, além de
        tomar as medidas legais cabíveis se:
      </Typography>
      <List component="nav" aria-label="violações" className={classes.TextParams}>
        <ListItem>
          (i) o usuário não cumprir qualquer dispositivo destes Termos de Uso;
        </ListItem>
        <ListItem>
          (ii) o usuário praticar atos fraudulentos, dolosos ou com evidente má-fé, de modo a obter vantagem indevida;
        </ListItem>
        <ListItem>
          (iii) forem identificados atos do usuário realizados com a finalidade de induzir ao erro para obter vantagem manifestamente ilícita;
        </ListItem>
        <ListItem>
          (iv) não puder ser verificada a identidade do usuário ou qualquer informação fornecida por ele seja inverídica;
        </ListItem>
        <ListItem>
          (v) alguma atitude do usuário tiver o condão de causar danos a terceiros ou à própria Bússola do Investidor;
        </ListItem>
        <ListItem>
          (vi) o usuário divulgar ou postar conteúdo em desacordo com a legislação vigente, com os bons costumes, ou ofensivo ao direito de qualquer pessoa;
        </ListItem>
        <ListItem>
          (vii) o usuário adotar, no âmbito da relação com o Kiila, bem como seus representantes e funcionários, condutas desrespeitosas, ofensivas,
          difamatórias, obscenas, ilegais ou realizar ameaças; e
        </ListItem>
        <ListItem>
          (viii) praticar SPAM ou qualquer tipo de atitudes contrárias às boas práticas da internet e das redes sociais.
        </ListItem>
      </List>
      <br/>
      <Typography variant="h6" gutterBottom>
        5. Obrigações do Kiila
      </Typography>
      <br/>
      <Typography variant="body2" align="justify" gutterBottom>
        5.1 Nunca revelar os dados relativos aos nomes dos usuários, números de identidade, inscrição no CPF, à composição da carteira de ativos dos usuários, a
        parâmetros de consultas e análises, a movimentações da carteira dos usuários, impostos recolhidos ou qualquer outra informação do usuário ou por ele
        prestada.
      </Typography>
      <br/>
      <Typography variant="body2" align="justify" gutterBottom>
        5.2 Auxiliar o usuário em seus cálculos de Imposto de Renda para que estes sejam realizados de forma mais precisa e que estejam o mais consoante
        possível com a interpretação habitual da legislação pertinente a cada caso, conforme IN 25, IN 487 e a Lei n. 11.033/04 em vigor desde 01 de janeiro de
        2005.
      </Typography>
      <br/>
      <Typography variant="body2" align="justify" gutterBottom>
        5.3 Reparar com a maior brevidade possível qualquer falha/bug reportada pelo usuário.
      </Typography>
      <br/>
      <Typography variant="h6" gutterBottom>
        6. Obrigações do usuário
      </Typography>
      <br/>
      <Typography variant="body2" align="justify" gutterBottom>
        6.1 Conferir as informações e dados no portal Kiila e que compõem a base histórica para o cálculo do Imposto de Renda, verificando as operações que lhe
        são apresentadas. Reportando imediatamente caso encontre alguma irregularidade.
      </Typography>
      <br/>
      <Typography variant="body2" align="justify" gutterBottom>
        6.2 Verificar, ao final de cada mês, o saldo de quantidade das ações apresentada pelo portal Kiila, com o extrato mensal que recebe da
        Companhia Brasileira de Liquidação e Custódia, considerando que as operações efetivadas nos últimos 3 dias do mês não estarão contidas no extrato da
        Companhia Brasileira de Liquidação e Custódia.
      </Typography>
      <br/>
      <Typography variant="body2" align="justify" gutterBottom>
        6.3 Conferir todas as operações realizadas e informadas no portal.
      </Typography>
      <br/>
      <Typography variant="body2" align="justify" gutterBottom>
        6.4 Complementar manualmente, quando necessário, impostos retidos na fonte, subscrições participadas, entre outros.
      </Typography>
      <br/>
      <Typography variant="body2" align="justify" gutterBottom>
        6.5 Incluir as informações com a veracidade assim como fazer correção necessárias para o funcionamento e operacionalização dao portal Kiila.
      </Typography>
      <br/>
      <Typography variant="body2" align="justify" gutterBottom>
        6.6 Manter a senha de acesso em sigilo, devendo evitar sua perda ou utilização indevida, respondendo legalmente pelos danos que venham a ser causados em
        decorrência.
      </Typography>
      <br/>
      <Typography variant="body2" align="justify" gutterBottom>
        6.7 O Kiila não se responsabiliza pela incapacidade de acesso à conta devido à perda ou esquecimento da senha. Para recuperá-la, o
        usuário deverá utilizar a opção de “Esqueci minha senha” na página inicial do portal.
      </Typography>
      <br/>
      <Typography variant="h6" gutterBottom>
        7. Termos de contratação
      </Typography>
      <br/>
      <Typography variant="body1" gutterBottom>
        7.1 Planos Disponíveis
      </Typography>
      <br/>
      <Typography variant="body2" align="justify" gutterBottom>
        7.1.1 Para acessar os serviços disponíveis, o usuário deverá aderir a um dos planos vigentes, escolhendo o tempo de uso desejado. Além disso,
        alguns recursos adicionais podem ser contratados a parte, caso o usuário extrapole a quantidade estabelecida no plano.
      </Typography>
      <br/>
      <Typography variant="body2" align="justify" gutterBottom>
        7.1.2 Explicaremos quais opções estão disponíveis para o usuário ao criar uma conta na plataforma da Bússola do Investidor. Certas opções são
        gratuitamente fornecidas para o usuário.
      </Typography>
      <br/>
      <Typography variant="body2" align="justify" gutterBottom>
        7.1.3 O Kiila pode ofertar planos, assinaturas, ou serviços promocionais especiais, inclusive ofertas de serviços e produtos de terceiros
        em conjunto com ou através do serviço oferecido pelo portal Kiila.
      </Typography>
      <br/>
      <Typography variant="body2" align="justify" gutterBottom>
        7.1.4 O Kiila não se responsabiliza pelos produtos e serviços prestados por terceiros.
      </Typography>
      <br/>
      <Typography variant="body2" align="justify" gutterBottom>
        7.1.5 O Kiila se reserva o direito de incluir, modificar ou encerrar os planos de assinatura e ofertas promocionais, a qualquer momento, de acordo com
        estes Termos.
      </Typography>
      <br/>
      <Typography variant="body2" align="justify" gutterBottom>
        7.1.6 O usuário pode encontrar a descrição dos planos na página de planos.
      </Typography>
      <br/>
      <Typography variant="body1" gutterBottom>
        7.2 Avaliação
      </Typography>
      <br/>
      <Typography variant="body2" align="justify" gutterBottom>
        7.2.1 Durante o período de “Avaliação”, o usuário terá acesso temporário, atualmente de 30 dias, a todas as funcionalidades disponíveis aos usuários do
        Plano Premium.
      </Typography>
      <br/>
      <Typography variant="body2" align="justify" gutterBottom>
        7.2.2 Cada usuário terá direito ao período de Avaliação uma única vez. Serão utilizados mecanismos de verificação e cruzamento de dados para evitar
        fraudes. Caso seja constatado que um usuário burlou ou tentou burlar esta regra, este usuário será banido da plataforma.
      </Typography>
      <br/>
      <Typography variant="body2" align="justify" gutterBottom>
        7.2.3 O período de Avaliação será concedido pelo Kiila aos usuários a seu exclusivo critério, podendo o mesmo aumentar ou reduzir os
        tempos de acesso ao teste e as funcionalidades nele incluídas de forma discricionária e sem aviso prévio.
      </Typography>
      <br/>
      <Typography variant="body2" align="justify" gutterBottom>
        7.2.4 Após decorrido o período de Avaliação, caso o usuário não contrate um plano, a prestação de serviço e acesso aos dados será interrompida.
      </Typography>
      <br/>
      <Typography variant="h6" gutterBottom>
        8. Isenções de responsabilidade
      </Typography>
      <Typography variant="body2" align="justify" gutterBottom>
        8.1 O Kiila garante a disponibilidade do serviço, à exceção das seguintes hipóteses:
      </Typography>
      <List component="nav" aria-label="isenções" className={classes.TextParams}>
        <ListItem>
          (i) caso fortuito e força maior;
        </ListItem>
        <ListItem>
          (ii) ações de terceiros que impeçam a prestação do serviço;
        </ListItem>
        <ListItem>
          (iii)interrupção ou suspensão pelas concessionárias dos serviços de telefonia;
        </ListItem>
        <ListItem>
          (iv) manutenções técnicas e/ou operacionais que exijam o desligamento temporário do portal Kiila ou impossibilite o seu acesso;
        </ListItem>
        <ListItem>
          (v) quaisquer outros fatores que independem do controle do Kiila.
        </ListItem>
      </List>
      <Typography variant="body2" align="justify" gutterBottom>
        8.2 O Kiila utilizará de todos os seus recursos para que os cálculos de auxílio ao usuário sejam realizados da maneira mais precisa
        possível e de acordo com interpretação mais habitual da legislação tributária em vigor. No entanto, o Kiila não se responsabiliza pelos
        danos gerados por uso em desconforme do aqui estabelecido, em quaisquer circunstâncias, ou pelos dados informados pelo usuário à Receita Federal do
        Brasil, sendo de sua inteira responsabilidade certificar-se da exatidão dos cálculos e dados fornecidos e calculados pela plataforma do Kiila.
      </Typography>
      <br/>
      <Typography variant="h6" gutterBottom>
        9. Comunicação entre as partes
      </Typography>
      <br/>
      <Typography variant="body2" align="justify" gutterBottom>
        9.1 Em caso de dúvida ou falha no portal Kiila, ou questões relacionadas a conta e pagamento, o usuário deverá contatar o Kiila através de um dos seus
        canais de atendimento.
      </Typography>
      <br/>
      <Typography variant="h6" gutterBottom>
        10. Legislação e Foro
      </Typography>
      <br/>
      <Typography variant="body2" align="justify" gutterBottom>
        10.1 Caso surja alguma controvérsia ou disputa por força do disposto em qualquer questão no que tange a relação jurídica envolvendo o Kiila, fica eleito
        o foro da Comarca de Brasília, Distrito Federal, podendo o Kiila optar, a seu exclusivo critério, pelo foro de domicílio do usuário.
      </Typography>
      <br/>
      <Typography variant="h6" gutterBottom>
        11. Alterações nos Termos
      </Typography>
      <br/>
      <Typography variant="body2" align="justify" gutterBottom>
        11.1 Ocasionalmente, o Kiila pode fazer alterações nos presentes Termos por razões válidas como melhorar as funções ou recursos existentes, acrescentar
        novas funções ou recursos, implementar avanços científicos e tecnológicos, e ajustes técnicos, assegurando a operacionalidade ou a segurança da
        plataforma, bem como por razões legais ou regulamentares.
      </Typography>
      <br/>
      <Typography variant="body2" align="justify" gutterBottom>
        11.2 Uma vez que o Kiila se reserva o direito de mudar estes Termos de Uso, é de sua responsabilidade a leitura regular
        sempre que acessar e/ou utilizar este site. Ainda assim, no caso de alterações, enviaremos com antecedência via email as novas condições.
      </Typography>
      <br/>
      <Typography variant="body2" align="justify" gutterBottom>
        11.3 O conteúdo disponibilizado no Kiila é protegido pela legislação brasileira de direitos autorais. Não o reproduza em meios de comunicação
        sem autorização.
      </Typography>
      <br/>
      <Typography variant="h6" gutterBottom>
        ANEXO I
      </Typography>
      <br/>
      <Typography variant="body1" gutterBottom>
        1. Isenções
      </Typography>
      <br/>
      <Typography variant="body2" align="justify" gutterBottom>
        1.1 São isentos do imposto sobre a renda os ganhos líquidos auferidos por pessoa física em operações efetuadas com ações, no mercado à vista de bolsas
        de valores ou mercado de balcão, se o total das alienações desse ativo, realizadas no mês, não exceder a R$ 20.000, 00 (vinte mil reais);
      </Typography>
      <br/>
      <Typography variant="body2" align="justify" gutterBottom>
        1.2 A isenção não se aplica, entre outras, às operações de daytrade, às negociações de cotas de fundos imobiliários.
      </Typography>
      <br/>
      <Typography variant="body1" gutterBottom>
        2. Daytrade
      </Typography>
      <Typography variant="body2" align="justify" gutterBottom>
        2.1 Considera-se daytrade a operação ou a conjugação de operações iniciadas e encerradas em um mesmo dia, com o mesmo ativo, em uma mesma instituição
        intermediadora, em que a quantidade negociada tenha sido liquidada, total ou parcialmente.
      </Typography>
      <br/>
      <Typography variant="body2" align="justify" gutterBottom>
        2.2 Ganhos em operações de daytrade sempre serão tributados.
      </Typography>
      <br/>
      <Typography variant="body2" align="justify" gutterBottom>
        2.3 Operações de ações e fundos imobiliários serão consideradas como uma coisa só no assunto daytrade.
      </Typography>
      <br/>
      <Typography variant="body1" gutterBottom>
        3. Compensação de perdas anteriores
      </Typography>
      <br/>
      <Typography variant="body2" align="justify" gutterBottom>
        3.1 Toda perda realizada no mês corrente e em meses anteriores, é considerada para cálculo do imposto devido nas operações que não se enquadram nos
        critérios de isenção.
      </Typography>
      <br/>
      <Typography variant="body2" align="justify" gutterBottom>
        3.2 Caso existam perdas não utilizadas em anos anteriores, continuarão ser a consideradas nos anos seguintes.
      </Typography>
      <br/>
      <Typography variant="body1" gutterBottom>
        4. Taxas
      </Typography>
      <Typography variant="body2" align="justify" gutterBottom>
        Atualmente existem duas maneiras possíveis de se informar as operações.
      </Typography>
      <br/>
      <Typography variant="body2" align="justify" gutterBottom>
        4.1 Manual - O usuário é responsável por informar os custos. Nesse caso devem ser informados todos os valores operacionais debitados pela corretora,
        exceto imposto de renda IRRF.
      </Typography>
      <br/>
      <Typography variant="body2" align="justify" gutterBottom>
        4.2 Leitura de PDF - a plataforma irá ler o arquivo PDF enviado, e considerará todas os custos operacionais efetivamente descritos como debitados,
        exceto
        IRRF.
      </Typography>
      <br/>
      <Typography variant="body1" gutterBottom>
        5. Custo Médio
      </Typography>
      <br/>
      <Typography variant="body2" align="justify" gutterBottom>
        O Kiila calcula um custo médio para cada ação, mesmo quando o usuário opera em mais de uma corretora.
      </Typography>
      <br/>
      <Typography variant="body1" gutterBottom>
        6. IRRF Normal e Daytrade
      </Typography>
      <br/>
      <Typography variant="body2" align="justify" gutterBottom>
        O portal Kiila dispõe de mecanismos para o usuário impostar o IRRF, porém não consideramos ele nos cálculos, na hora de recolhimento o próprio usuário
        deve abater esse valor da guia
      </Typography>
      <br/>
      <Typography variant="body1" gutterBottom>
        7. Data de Corte das Operações
      </Typography>
      <br/>
      <Typography variant="body2" align="justify" gutterBottom>
        A data de execução das operações será considerada como referência para fins de verificação da isenção mensal será considerada devido à vendas não
        superiores às R$20.000,00 (vinte mil reais) e para cálculo de proventos a ser recebidos. No caso de subscrições e IPO's, será considerada a menor data
        entre data de disponibilização do papel e data de liquidação.
      </Typography>
      <br/>
      
      <Typography variant="body2" align="justify" gutterBottom>
        Última modificação: 12/01/2021
      </Typography>
    </Grid>
  );
};


export default useTherms;
