import React, { useEffect, useState } from 'react';

import { useForm }                  from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import classes                      from './IncludeUnderwriting.module.css';
import Button                       from '../elements/Button';
import Dialog                       from '../elements/Dialog';

import { handleError, rules } from '../utils/rules';
import Grid                   from '@mui/material/Grid';
import { searchAdmPaper }     from '../../store/slices/paper';
import { includeReceipt }     from '../../store/slices/receipt';
import FormDate               from '../elements/FormDate';
import CakeIcon               from '@mui/icons-material/Cake';
import FormAutoComplete       from '../elements/FormAutoComplete';


const defaultValues = {
  paper: null,
  date : '',
};

const IncludeReceipt = () => {
  
  const dispatch = useDispatch();
  
  const {includeSituation} = useSelector(state => state.receipt);
  
  const [modalOn, setModalOn] = useState(false);
  
  
  useEffect(() => {
    
    if (includeSituation === 'idle') reset(defaultValues);
    
  }, [includeSituation]);
  
  const {control, handleSubmit, reset, getValues, formState} = useForm({mode: 'all', defaultValues});
  
  const {dirtyFields, isValid, errors} = formState;
  
  let modal;
  
  const openModal = () => {
    setModalOn(true);
  };
  
  const closeModal = () => setModalOn(false);
  
  const onSubmit = (data) => {
    
    let {paper} = data;
    
    dispatch(includeReceipt({
      data           : {
        papelId: paper.cdPapel,
      }, internalData: {
        id     : paper.cdPapel,
        papelId: paper.cdPapel,
        sigla  : paper.sigla,
        recibos: [],
      }
    }));
    
  };
  
  const disabled  = (!isValid || includeSituation === 'pending');
  const showPaper = (!!dirtyFields['date'] && !Boolean(errors['date']));
  
  modal = <Dialog id="receipt-modal" show={modalOn} handleClose={closeModal} title={'Inclusão Recibo'}>
    
    <form id="receipt" onSubmit={handleSubmit(onSubmit)} className={classes.Form}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} className={classes.Item}>
          <FormDate label="Data Vigência" name="date" Icon={CakeIcon} errorMessage={handleError(errors, 'date')}
                    control={control} rules={{required: true}}
          />
        </Grid>
        <Grid item xs={12} sm={6} className={classes.Item}>
          {showPaper &&
          <FormAutoComplete label="Papel" name="paper" placeholder="Pesquise o papel" control={control}  errorMessage={handleError(errors, 'paper')}
                            dataFunction={searchAdmPaper} searchField={'busca'} requiredData={{dataPesquisa: getValues('date')}}
                            displayField="sigla" rules={(rules('paper'))} dataFields={{slice: 'paper', data: 'papersSearched'}} />
          }
        </Grid>
        <Grid item xs={12} className={classes.Button}>
          <Button color="secondary" disabled={disabled} type="submit">
            {includeSituation === 'pending' ? 'Cadastrando' : 'Cadastrar'}
          </Button>
        </Grid>
      </Grid>
    </form>
  </Dialog>;
  
  return (
    <>
      <Button color="secondary" disabled={!!modalOn} onClick={openModal}>
        Incluir
      </Button>
      {modal}
    </>
  );
};

export default IncludeReceipt;
