import React   from 'react';
import classes from './HistoryButton.module.css';

import HistoryIcon from '@mui/icons-material/History';
import IconButton  from '@mui/material/IconButton';

const HistoryButton = (onClick) => ({row}) => {
  
  return (
    <div className={classes.ActionsButtons}>
      <IconButton aria-label="history" tooltip="teste" color="primary" onClick={() => onClick(row)}>
        <HistoryIcon />
      </IconButton>
    </div>
  
  );
  
};


export default HistoryButton;
