import React, { useEffect, useState } from 'react';
import Grid                           from '@mui/material/Grid';
import HighchartsReact                from 'highcharts-react-official';
import Highcharts                     from 'highcharts';
import { useDispatch, useSelector }   from 'react-redux';
import { getCompiledWallet }          from '../../store/slices/wallet';
import { formatCurrency }             from '../utils/formatters';
import Box                            from '../elements/Box';

const commonChart = {
  chart        : {
    plotBackgroundColor: null,
    plotBorderWidth    : null,
    backgroundColor    : '#fafafa',
    plotShadow         : false,
    type               : 'pie',
  },
  tooltip      : {
    formatter: function () {
      return `
      ${this.point.name} <br/>
      ${this.series.name} <b>${formatCurrency(this.y)}</b>
      `;
    }
  },
  // tooltip      : {pointFormat: '{series.name}: <b>R${point.y}</b>'},
  plotOptions  : {
    pie: {
      allowPointSelect: true,
      cursor          : 'pointer',
      dataLabels      : {
        enabled: true,
        format : '<b>{point.name}</b>: {point.percentage:.1f} %'
      },
      showInLegend    : true
    }
  },
  credits      : {
    enabled: false
  },
  accessibility: {
    point: {
      valueSuffix: '%'
    }
  },
};

const title1 = {text: 'Carteira Adquirida'};
const title2 = {text: 'Carteira Valor Mercado'};


const WalletSegmentation = () => {
  
  const [chart1Options, setChart1Options] = useState(null);
  const [chart2Options, setChart2Options] = useState(null);
  const dispatch                          = useDispatch();
  const {compiledStocks}                  = useSelector(state => state.wallet);
  
  useEffect(() => {
    
    setChart1Options({
      ...commonChart,
      title : title1,
      series: [{
        name        : 'Valor Bruto',
        colorByPoint: true,
        data        : compiledStocks.inicial
      }]
    });
    setChart2Options({
      ...commonChart,
      title : title2,
      series: [{
        name        : 'Valor Bruto',
        colorByPoint: true,
        data        : compiledStocks.atual
      }]
    });
    
  }, [compiledStocks]);
  
  useEffect(() => {
    dispatch(getCompiledWallet());
  }, []);
  
  return (
    <>
      {!!compiledStocks.inicial.length &&
      <Box>
        <Grid container>
          <Grid item xs={12} md={6}>
            <HighchartsReact highcharts={Highcharts} options={chart1Options} immutable = { true }/>
          </Grid>
          <Grid item xs={12} md={6}>
            <HighchartsReact highcharts={Highcharts} options={chart2Options} immutable = { true }/>
          </Grid>
        </Grid>
      </Box>
      }
      </>
  );
};

export default WalletSegmentation;
