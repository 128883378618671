import React from 'react';
import useScrollTrigger    from '@mui/material/useScrollTrigger';
import classes             from './HeaderBar.module.css';
import AppBar              from '@mui/material/AppBar';
import Toolbar             from '@mui/material/Toolbar';

const ElevationScroll = ({...props }) => {
  const {children} = props;
  
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold        : 0,
  });
  
  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
};

const HeaderBar = ({children, ...props}) => {
  
  return (
    <ElevationScroll  {...props}>
      <AppBar className={classes.Color} position="sticky">
        <Toolbar>
          {children}
        </Toolbar>
      </AppBar>
    </ElevationScroll>
  );
};


export default HeaderBar;
