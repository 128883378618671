import React, { useEffect }         from 'react';
import { useNavigate, useParams }    from 'react-router-dom';
import Grid                         from '@mui/material/Grid';
import classes                      from './NewPassword.module.css';
import { changePassword }           from '../../store/slices/auth';
import { useDispatch, useSelector } from 'react-redux';
import { useForm }            from 'react-hook-form';
import FormPassword           from '../../components/elements/FormPassword';
import { handleError, rules } from '../../components/utils/rules';
import Button                       from '../../components/elements/Button';
import Typography                   from '@mui/material/Typography';


const defaultValues = {
  myPassword     : '',
  password       : '',
  passwordConfirm: '',
};

const NewPassword = () => {
  
  const dispatch = useDispatch();
  const {token}  = useParams();
  const navigate  = useNavigate();
  
  const {passwordSituation, user} = useSelector(state => state.auth);
  
  const {handleSubmit, reset, getValues, control, formState} = useForm({mode: 'all', defaultValues});
  const {isValid, errors } = formState;
  
  // console.log("user", user)
  if (!token && !user.id) navigate('/');
  
  useEffect(() => {
    
    if (passwordSituation === 'success') {
      reset(defaultValues);
      if (token) navigate('/');
    }
    
  }, [passwordSituation]);
  
  const onSubmit = (data) => {
    // console.log(data); // handle the data here
    const {myPassword, password} = data;
    dispatch(changePassword({data: {token, currentPassword: myPassword, newPassword: password, userId: user.id}, urlId:token}));
  };
  
  const disabled = (!isValid || passwordSituation === 'pending');
  
  return (
    <form id="password" onSubmit={handleSubmit(onSubmit)} className={classes.Form}>
      <Grid container spacing={2} direction={'column'} alignItems={'center'}>
        <Grid item xs={12} >
          <Typography variant="body1" className={classes.Title}>
            Alteração de senha
          </Typography>
        </Grid>
        {!!user.id &&
          <Grid item xs={12} sm={6}>
            <FormPassword label="Senha Atual" name="myPassword" errorMessage={handleError(errors, 'myPassword')}
                          control={control} rules={rules('password', )}
            />
          </Grid>
        }
        <Grid item xs={12} sm={6}>
          <FormPassword label="Nova Senha" name="password" errorMessage={handleError(errors, 'password')}
                        control={control} rules={rules('password', )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormPassword label="Confirme a Senha" name="passwordConfirm" errorMessage={handleError(errors, 'passwordConfirm')}
                        control={control} rules={rules('passwordConfirm', getValues('password'))}
          />
        </Grid>
        <Grid item xs={12}>
          <Button color="secondary" disabled={disabled} type="submit">
            {passwordSituation === 'pending' ? 'Alterando' : 'Alterar'}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
  
};
export default NewPassword;
