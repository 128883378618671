import React   from 'react';
import classes from './Button.module.css';
import MButton from '@mui/material/Button';

const Button = ({className, color, size, loading,  disabled, ...props}) => {
  
  let internalColor;
  
  if (color === 'primary')  internalColor = classes.Primary;
  if (color === 'secondary')  internalColor = classes.Dark;
  if (!color)  internalColor = classes.Transparent;
  
  const myClasses = [classes.Button, internalColor, className].join(' ');
  
  return (<MButton variant="contained" color={color} disabled={disabled} className={myClasses} {...props}/>    );
 
};


export default Button;
