import React from 'react';
import { Navigate, Route } from 'react-router-dom';
import { StyledEngineProvider } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import CssBaseline from '@mui/material/CssBaseline';
import Achievement from './containers/Achievement/Achievement';
import Amortization from './containers/Amortization/Amortization';
import Business from './containers/Business/Business';
import Company from './containers/Company/Company';
import Earning from './containers/Earning/Earning';
import EarningEvent from './containers/EarningEvent/EarningEvent';
import Home from './containers/Home/Home';
import Incorporation from './containers/Incorporation/Incorporation';
import Main from './containers/Main/Main';
import MarketValue from './containers/MarketValue/MarketValue';
import MonthTax from './containers/MonthTax/MonthTax';
import MyAmortization from './containers/MyAmortization/MyAmortization';
import MyEarning from './containers/MyEarning/MyEarning';
import MyIncorporation from './containers/MyIncorporation/MyIncorporation';
import MyRearrangement from './containers/MyRearrangement/MyRearrangement';
import MyScission from './containers/MyScission/MyScission';
import SpotPaper from './containers/Paper/SpotPaper';
import Rearrangement from './containers/Rearrangement/Rearrangement';
import Receipt from './containers/Receipt/Receipt';
import Statistic from './containers/Report/Statistic';
import Transference from './containers/Transference/Transference';
import Underwriting from './containers/Underwriting/Underwriting';
import BuyWallet from './containers/Wallet/BuyWallet';
import YearPosition from './containers/YearPosition/YearPosition';
import YearEarningPosition from './containers/YearEarningPosition/YearEarningPosition';
import SellWallet from './containers/Wallet/SellWallet';
import TemporalWallet from './containers/Wallet/TemporalWallet';
import FuturePaper from './containers/Paper/FuturePaper';
import Scission from './containers/Scission/Scission';
import Spinner from './components/layout/partials/Spinner';
import Message from './components/sections/Message';
import { checkRole } from './components/utils/auth';
import { profiles } from './components/utils/constants';
import Layout from './layouts/Layout';
import RoutesTransition from './components/layout/partials/RoutesTransition';
import RegisterValidation from './containers/Auth/RegisterValidation';
import NewPassword from './containers/Auth/NewPassword';
import AboutUs from './containers/AboutUs/AboutUs';
import Contact from './containers/Contact/Contact';
import Faq from './containers/Faq/Faq';
import UseTherms from './components/sections/UseTherms';
import Plans from './components/sections/Plans';
import MyPlan from './containers/MyPlan/MyPlan';
import NoPlan from './containers/NoPlan/NoPlan';
import AdminActions from './containers/AdminActions/AdminActions';
import AdmBusiness from './containers/AdmBusiness/AdmBusiness';
import YearEarningNext from './containers/YearEarningNext/YearEarningNext';
import Income from './containers/Report/Income';
import Repurchase from './containers/Repurchase/Repurchase';
import MyRepurchase from './containers/MyRepurchase/MyRepurchase';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import brLocale from 'date-fns/locale/pt-BR';

const mountRoutes = (isAuthenticated, user, havePlan) => {

  // console.log("havePlan", havePlan)

  const routes = [];
  if (!isAuthenticated) {
    routes.push(<Route path="/" key={'main'} element={<Main />} />);
    routes.push(<Route path="/validacao/:token" key={'validation'} element={<RegisterValidation />} />);
  } else {
    if (havePlan) {
      routes.push(<Route path="/" key={'home'} element={<Home />} />);
      checkRole(routes, <Route path="/administracao/amortizacao" key={'amortization'} element={<Amortization />} />,
        [profiles.ADMIN], user);
      checkRole(routes, <Route path="/administracao/cotacao" key={'market-value'} element={<MarketValue />} />,
        [profiles.ADMIN], user);
      checkRole(routes, <Route path="/administracao/empresa" key={'company'} element={<Company />} />,
        [profiles.ADMIN], user);
      checkRole(routes, <Route path="/administracao/incorporacao" key={'incorporation'} element={<Incorporation />} />,
        [profiles.ADMIN], user);
      checkRole(routes, <Route path="/administracao/cisao" key={'scission'} element={<Scission />} />,
        [profiles.ADMIN], user);
      checkRole(routes, <Route path="/administracao/papel/vista" key={'paper'} element={<SpotPaper />} />,
        [profiles.ADMIN], user);
      checkRole(routes, <Route path="/administracao/papel/futuro" key={'paper'} element={<FuturePaper />} />,
        [profiles.ADMIN], user);
      checkRole(routes, <Route path="/administracao/provento" key={'earning'} element={<Earning />} />,
        [profiles.ADMIN], user);
      checkRole(routes, <Route path="/administracao/evento/provento" key={'earning-event'} element={<EarningEvent />} />,
        [profiles.ADMIN], user);
      checkRole(routes, <Route path="/administracao/rearranjo" key={'rearrangement'} element={<Rearrangement />} />,
        [profiles.ADMIN], user);
      checkRole(routes, <Route path="/administracao/recompra" key={'repurchase'} element={<Repurchase />} />,
        [profiles.ADMIN], user);
      checkRole(routes, <Route path="/administracao/recibo" key={'receipt'} element={<Receipt />} />,
        [profiles.ADMIN], user);
      checkRole(routes, <Route path="/administracao/subscricao" key={'underwriting'} element={<Underwriting />} />,
        [profiles.ADMIN], user);
      checkRole(routes, <Route path="/administracao/acoes" key={'adm-actions'} element={<AdminActions />} />,
        [profiles.ADMIN], user);
      checkRole(routes, <Route path="/administracao/negocios" key={'adm-business'} element={<AdmBusiness />} />,
        [profiles.ADMIN], user);
      routes.push(<Route path="/amortizacao" key={'m-amortization'} element={<MyAmortization />} />);
      routes.push(<Route path="/carteira/comprada" key={'buy-wallet'} element={<BuyWallet />} />);
      routes.push(<Route path="/carteira/vendida" key={'sell-wallet'} element={<SellWallet />} />);
      routes.push(<Route path="/carteira/temporal" key={'temporal-wallet'} element={<TemporalWallet />} />);
      routes.push(<Route path="/imposto/movimentacao" key={'tax-movement'} element={<MonthTax />} />);
      routes.push(<Route path="/imposto/posicao" key={'tax-position'} element={<YearPosition />} />);
      routes.push(<Route path="/imposto/provento/posterior" key={'tax-earning-next'} element={<YearEarningNext />} />);
      routes.push(<Route path="/imposto/provento" key={'tax-earning'} element={<YearEarningPosition />} />);
      routes.push(<Route path="/incorporacao" key={'m-incorporation'} element={<MyIncorporation />} />);
      routes.push(<Route path="/cisao" key={'m-scission'} element={<MyScission />} />);
      routes.push(<Route path="/negocio" key={'business'} element={<Business />} />);
      routes.push(<Route path="/provento" key={'m-earning'} element={<MyEarning />} />);
      routes.push(<Route path="/realizacao" key={'achievement'} element={<Achievement />} />);
      routes.push(<Route path="/rearranjo" key={'m-rearrangement'} element={<MyRearrangement />} />);
      routes.push(<Route path="/recompra" key={'m-repurchase'} element={<MyRepurchase />} />);
      routes.push(<Route path="/estatistica" key={'statistic'} element={<Statistic />} />);
      routes.push(<Route path="/lucro" key={'income'} element={<Income />} />);
      routes.push(<Route path="/transferencia" key={'transfer'} element={<Transference />} />);
    } else {
      routes.push(<Route path="/" key={'home'} element={<NoPlan />} />);
    }

    routes.push(<Route path="/plano" key={'m-plan'} element={<MyPlan />} />);
  }

  routes.push(<Route path="/contato" key={'contact'} element={<Contact />} />);
  routes.push(<Route path="/faq" key={'faq'} element={<Faq />} />);
  routes.push(<Route path="/sobre" key={'about'} element={<AboutUs />} />);
  routes.push(<Route path="/senha/:token?" key={'password'} element={<NewPassword />} />);
  routes.push(<Route path="/planos" key={'plans'} element={<Plans />} />);
  routes.push(<Route path="/termos" key={'therms'} element={<UseTherms />} />);
  routes.push(<Route path={'/*'} key={'redirect'} element={<Navigate replace to="/" />} />);

  return (
    <RoutesTransition>
      {routes}
    </RoutesTransition>
  );
};

const App = () => {

  const { isAuthenticated, user } = useSelector(state => state.auth);
  const { havePlan } = useSelector(state => state.api);
  const { loading, messages } = useSelector(state => state.app);


  return (
    <StyledEngineProvider injectFirst>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={brLocale}>
        {/* <CssBaseline/> */}
        {!!loading && <Spinner />}
        <Layout authenticated={isAuthenticated}>
          {mountRoutes(isAuthenticated, user, havePlan)}
        </Layout>
        <Message messages={messages} />
      </LocalizationProvider>
    </StyledEngineProvider>
  );


};

export default App;
