import React, { useEffect } from 'react';
import InternalHeader       from '../components/layout/InternalHeader';
import Footer               from '../components/layout/Footer';
import classes              from './InternalLayout.module.css';
import Drawer               from '../components/layout/Drawer';
import Container            from '@mui/material/Container';
import MountTransition      from '../components/layout/partials/MountTransition';
import { AnimatePresence }  from 'framer-motion';

const InternalLayout = ({children}) => {
  
  const [open, setOpen] = React.useState(window.innerWidth > 479);
  
  const handleDrawerOpen  = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  
  const handleWindowResize = () => {
    
    if (window.innerWidth < 480) handleDrawerClose();
    // console.log('entrou aqui', hugeScreen)
    // const mobile         = window.innerWidth < 480;
    // console.log("oi", window.innerWidth < 480)
    
  };
  
  useEffect(() => {
    // initiate the event handler
    window.addEventListener('resize', handleWindowResize);
    
    // this will clean up the event every time the component is re-rendered
    return function cleanup() {
      window.removeEventListener('resize', handleWindowResize);
    };
  });
  
  // console.log('open', open);
  
  return (
    <AnimatePresence mode='wait'>
      <MountTransition>
        <div className={classes.Root}>
          <InternalHeader open={open} handleOpenMenu={handleDrawerOpen}/>
          <Drawer open={open} handleCloseMenu={handleDrawerClose}/>
          <main className={classes.SiteContent}>
            <div className={classes.HeaderSpacer}/>
            <Container maxWidth="false" className={classes.Container}>
              {children}
            </Container>
            <Footer/>
          </main>
        </div>
      </MountTransition>
    </AnimatePresence>
  );
};

export default InternalLayout;
