import React, { useMemo }                     from 'react';
import Grid                                   from '@mui/material/Grid';
import Table                                  from '../../components/elements/Table';
import { useSelector }                        from 'react-redux';
import IncludeScission                   from '../../components/containerActions/IncludeScission';
import classes                                from './Scission.module.css';
import { formatDate }                         from '../../components/utils/formatters';
import RedeemEarning                          from '../../components/containerActions/RedeemEarning';
import { getPaperScissions, processScission } from '../../store/slices/scission';
import ProcessJob                             from '../../components/partials/ProcessJob';
import ParameterHeader                        from '../../components/sections/ParameterHeader';

const Scission = () => {
  
  
  const {paperScissions} = useSelector(state => state.scission);
  
  const columns = useMemo(
    () =>
      [
        {
          accessor: 'papelDestino',
          Header  : 'Papel Destino',
        },
        {
          accessor: 'multiplicador',
          Header  : 'Multiplicador',
        },
        {
          accessor: 'dataCisao',
          Header  : 'Data Cisão',
          Cell    : ({value}) => formatDate(value),
        },
      ],
    []
  );
  
  return (
    <Grid container>
      <Grid item xs={12} className={classes.Header}>
        <ParameterHeader Redeem={RedeemEarning} Process={ProcessJob} slice={'scission'} procFunc={processScission}
                         getFunc={getPaperScissions}/>
      </Grid>
      <Grid item xs={12}>
        <Table columns={columns} rowsData={paperScissions} inclusion={<IncludeScission/>}/>
      </Grid>
    </Grid>
  );
};

export default Scission;
