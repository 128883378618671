import { createSlice }                                                    from '@reduxjs/toolkit';
import { thunkAPI }                                                       from '../../components/utils/api';
 
import { defineUnderwritingType } from '../../components/utils/constants';
 
import { formatDate }                                                     from '../../components/utils/formatters';


export const getPaperUnderwritings     = thunkAPI('paperUnderwriting/get', 'v1/subscricoes/papel/periodo', 'get',);
export const getPaperUnderwritingsType = thunkAPI('paperUnderwriting-type/get', 'v1/subscricoes/papel/tipo/periodo', 'get',);
export const getUnderwritings          = thunkAPI('underwriting/get', 'v1/subscricoes', 'get',);
export const includeUnderwriting       = thunkAPI('underwriting/include', 'v1/subscricoes', 'post',
  {successMessage: 'Subscrição incluída com sucesso'});

const convertBusinessSub = (value) => {
  
  switch (value) {
    case 7:
      return 1;
    case 3:
      return 2;
  }
  
};

const formatOpPaperUnderwritings = (actualData, underwritings) => {
  
  const myOp                   = {...actualData};
  const formattedUnderwritings = [];
  
  const dataRef = underwritings.date;
  const papelId = underwritings.papelId;
  
  for (const data of underwritings.response) {
    formattedUnderwritings.push({
      name : `${data.Recibo.sigla} - ${formatDate(data.dtDebito)}`,
      date : data.dtDebito,
      value: data.vlSubscricao
    });
  }
  
  if (!myOp[papelId]) myOp[papelId] = {};
  myOp[papelId][dataRef] = formattedUnderwritings;
  
  return myOp;
  
};
const formatPaperUnderwritings   = (underwritings) => {
  
  const formattedUnderwritings = [];
  
  // console.log("AAA", underwritings)
  for (const data of underwritings.response) {
    if (data.tipoSubscricao === convertBusinessSub(underwritings.businessId))
      formattedUnderwritings.push({
        name : `${data.Recibo.sigla} - ${formatDate(data.dtDebito)}`,
        date : data.dtDebito,
        value: data.vlSubscricao
      });
  }
  
  return formattedUnderwritings;
  
};
const formatUnderwritings        = (underwritings) => {
  
  const formattedUnderwritings = [];
  
  let i = 0;
  for (const data of underwritings) {
    
    formattedUnderwritings.push({
      id            : i,
      papel         : data.Papel.sigla,
      recibo        : data.Recibo.sigla,
      dataDebito    : data.dtDebito,
      dataConversao : data.dtConversao,
      vlSubscricao  : data.vlSubscricao,
      tipoSubscricao: defineUnderwritingType(data.tipoSubscricao),
    });
    i++;
  }
  
  return formattedUnderwritings;
  
};


const slice = createSlice({
  name         : 'underwriting',
  initialState : {
    underwritings      : [],
    paperUnderwritings : [],
    papersUnderwritings: {},
    includeSituation   : 'idle'
  },
  reducers     : {},
  extraReducers: {
    [getPaperUnderwritingsType.fulfilled]: (state, action) => {
      state.papersUnderwritings = formatOpPaperUnderwritings(state.papersUnderwritings, action.payload);
    },
    [getPaperUnderwritings.fulfilled]    : (state, action) => {
      state.paperUnderwritings = formatPaperUnderwritings(action.payload);
    },
    [getUnderwritings.fulfilled]         : (state, action) => {
      state.underwritings = formatUnderwritings(action.payload);
    },
    [includeUnderwriting.pending]        : (state) => {
      state.includeSituation = 'pending';
    },
    [includeUnderwriting.fulfilled]      : (state, action) => {
      let underwritings = [...state.underwritings];
      
      const {papel, recibo, dataDebito, dataConversao, tipoSubscricao, vlSubscricao} = action.payload;
      
      let id = underwritings.length;
      
      const newUnderwriting = {
        id,
        papel,
        recibo,
        dataDebito,
        dataConversao,
        tipoSubscricao,
        vlSubscricao
      };
      
      underwritings.unshift(newUnderwriting);
      
      state.underwritings    = underwritings;
      state.includeSituation = 'idle';
    },
    [includeUnderwriting.rejected]       : (state) => {
      state.includeSituation = 'rejected';
    },
  }
});

const {reducer} = slice;


export default reducer;
