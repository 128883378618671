import React, { useEffect, useMemo } from 'react';
import Table                         from '../../components/elements/Table';
import { useDispatch, useSelector }  from 'react-redux';
import { getMyIncorporations }                      from '../../store/slices/incorporation';
import { formatDate, formatDecimal, formatInteger } from '../../components/utils/formatters';
import Grid                                         from '@mui/material/Grid';
import Box                           from '../../components/elements/Box';
import classes                       from '../Wallet/Wallet.module.css';
import Typography                    from '@mui/material/Typography';


const MyIncorporation = () => {
  
  
  const dispatch           = useDispatch();
  const {myIncorporations} = useSelector(state => state.incorporation);
  
  useEffect(() => {
    dispatch(getMyIncorporations());
  }, []);
  
  const columns = useMemo(
    () =>
      [
        {
          accessor: 'papelOrigem',
          Header  : 'Papel Origem',
        },
        {
          accessor: 'papelDestino',
          Header  : 'Papel Destino',
        },
        {
          accessor: 'qtBase',
          Header  : 'Quantidade Original',
        },
        {
          accessor: 'multiplicador',
          Header  : 'Multiplicador',
          Cell    : ({value}) => formatDecimal(value)
        },
        {
          accessor: 'qtDestino',
          Header  : 'Quantidade Destino',
        },
        {
          accessor: 'dataIncorporacao',
          Header  : 'Data Incorporação',
          Cell    : ({value}) => formatDate(value)
        },
      ],
    []
  );
  
  return (
    <Grid container>
      {!!myIncorporations.length &&
      <Grid item xs={12}>
        <Table columns={columns} rowsData={myIncorporations}/>
      </Grid>
      }
      {!myIncorporations.length &&
      <Box>
        <Grid item xs={12} className={classes.NegativeBox}>
          <Typography variant={'h6'} className={classes.NegativeText}>
            Você não possui incorporações
          </Typography>
        </Grid>
      </Box>
      }
    </Grid>
  
  );
};


export default MyIncorporation;
