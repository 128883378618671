import { createSlice } from '@reduxjs/toolkit';
import { thunkAPI }    from '../../components/utils/api';

export const excludeBusiness    = thunkAPI('business/exclude', 'v1/negocios', 'delete', {successMessage: 'Negócio excluído com sucesso'});
export const getBusiness        = thunkAPI('business/get', 'v1/negocios', 'get',);
export const getBusinessTypes   = thunkAPI('business-type/get', 'v1/tiposNegocio/vigentes',
  {verb: 'get', getCache: ['business', 'historyBusinessTypes']},);
export const getYearsBusiness   = thunkAPI('business-years/get', 'v1/negocio/anos', 'get',);
export const includeAdmBusiness = thunkAPI('business-adm/include', 'v1/negocios/adm', 'post', {successMessage: 'Negócio processado com sucesso'});
export const includeBusiness    = thunkAPI('business/include', 'v1/negocios', 'post', {successMessage: 'Negócio incluído com sucesso'});
export const includeLotBusiness = thunkAPI('business-lot/include', 'v1/negocios/lote', 'post', {successMessage: 'Negócios incluídos com sucesso'});
export const readPDFBusiness    = thunkAPI('business-pdf/read', 'v1/negocios/pdf', 'post');

const formatBusiness      = (business) => {
  
  const formattedBusiness = [];
  // console.log('business', business)
  
  for (const data of business) {
    let exercicio;
    if (data.NegocioExercicio) exercicio = 'E';
    
    formattedBusiness.push({
      id          : data.seqlNegocio,
      papel       : data.Papel.sigla,
      quantidade  : data.qtPapel,
      valorPapel  : data.vlPapel,
      valorNegocio: data.qtPapel * data.vlPapel,
      corretora   : data.Corretora.abreviatura,
      tipoNegocio : data.TipoNegocio.descricao,
      custo       : data.custo,
      dataNegocio : data.dtNegocio,
      exercicio
    });
  }
  
  return formattedBusiness;
  
};
const formatBusinessTypes = (businessTypes) => {
  
  const formattedBusinessTypes = [];
  
  for (const data of businessTypes) {
    
    formattedBusinessTypes.push({
      id  : data.cdTipoNegocio,
      nome: data.descricao,
    });
  }
  
  return formattedBusinessTypes;
  
};
const formatPDFBusiness   = (data) => {
  
  const formattedPDFBusiness = [];
  
  // console.log('PDF', data);
  
  let id = 0;
  for (const reg of data.business) {
    
    formattedPDFBusiness.push({
      id,
      papel     : reg.paper,
      custoTotal: reg.tax,
      valor     : reg.singleValue,
      quantity  : reg.quantity,
      type      : reg.operation,
      exercise  : reg.exercise,
    });
    id++;
  }
  
  let exchangeId;
  if (data.exchange) exchangeId = data.exchange;
  // console.log('formattedPDFBusiness', formattedPDFBusiness);
  
  return {business: formattedPDFBusiness, date: data.invoiceDate, exchangeId};
  
};

const businessSlice = createSlice({
  name         : 'business',
  initialState : {
    business            : [],
    pdfBusiness         : {},
    businessTypes       : [],
    historyBusinessTypes: [],
    yearBusiness        : [],
    includeSituation    : 'idle'
  },
  reducers     : {
    resetPdfBusiness: (state) => {
      state.pdfBusiness = {};
    }
  },
  extraReducers: {
    [getBusinessTypes.fulfilled]  : (state, action) => {
      state.historyBusinessTypes = action.payload;
      state.businessTypes        = formatBusinessTypes(action.payload);
    },
    [getBusiness.fulfilled]       : (state, action) => {
      state.business = formatBusiness(action.payload);
    },
    [getYearsBusiness.fulfilled]  : (state, action) => {
      state.yearBusiness = action.payload;
    },
    [excludeBusiness.fulfilled]   : (state, action) => {
      let business = [...state.business];
      business.splice(action.payload.index, 1);
      state.business = business;
    },
    [includeBusiness.fulfilled]   : (state, action) => {
      let business = [...state.business];
      
      const {id, custo, dataNegocio, exercido, quantidade, papel, valorPapel, corretora, tipoNegocio} = action.payload;
      let exercicio;
      
      if (exercido) exercicio = 'E';
      
      const newBusiness = {
        id,
        papel,
        quantidade,
        valorPapel,
        valorNegocio: quantidade * valorPapel,
        corretora,
        tipoNegocio,
        custo,
        dataNegocio,
        exercicio
      };
      
      business.unshift(newBusiness);
      
      state.business         = business;
      state.includeSituation = 'idle';
    },
    [includeLotBusiness.fulfilled]: (state, action) => {
      state.pdfBusiness = {};
      let business      = [...state.business];
      
      const {ids, negociosSaida} = action.payload;
      
      
      for (let index = 0; index < negociosSaida.length; index++) {
        
        const negocio = negociosSaida[index];
        let exercicio;
  
        if (negocio.exercido) exercicio = 'E';
        
        const newBusiness = {
          ...negocio,
          id          : ids[index],
          exercicio,
          valorNegocio: negocio.quantidade * negocio.valorPapel,
        };
        
        business.unshift(newBusiness);
      }
      
      state.business         = business;
      state.includeSituation = 'idle';
    },
    [includeBusiness.pending]     : (state) => {
      state.includeSituation = 'pending';
    },
    [includeBusiness.rejected]    : (state) => {
      state.includeSituation = 'rejected';
    },
    [includeLotBusiness.pending]  : (state) => {
      state.includeSituation = 'pending';
    },
    [includeLotBusiness.rejected] : (state) => {
      state.includeSituation = 'rejected';
    },
    [readPDFBusiness.fulfilled]   : (state, action) => {
      state.pdfBusiness = formatPDFBusiness(action.payload);
    },
    [readPDFBusiness.pending]     : (state, action) => {
      state.pdfBusiness = {};
    },
  }
});

const {reducer, actions} = businessSlice;

export const {resetPdfBusiness} = actions;


export default reducer;
