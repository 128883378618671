import React from 'react';
import MIconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

const IconButton = ({icon, cor, size, tooltip, ...props}) => {
  
  const Icon = icon;
  
  return (
    <Tooltip title={tooltip}>
      <MIconButton {...props}>
        <Icon size={size} color={cor}/>
      </MIconButton>
    </Tooltip>
  )
  
};


export default IconButton;
