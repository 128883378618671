import React from 'react';

import { Controller } from 'react-hook-form';
import OutlinedInput  from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel     from '@mui/material/InputLabel';
import FormControl    from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';

import classes from './Input.module.css';

const FormText = ({label, name, Icon, rules, errorMessage, control, ...props}) => {
  
  return (
    <FormControl aria-label={name} variant="outlined" fullWidth className={classes.Field}>
      <InputLabel error={!!errorMessage} htmlFor={name}>{label}</InputLabel>
      <Controller
        render={({field}) =>
          <OutlinedInput {...field}
                         {...props}
                         id={name}
                         label={label}
                         type="text"
                         aria-invalid={errorMessage}
                         aria-describedby={label}
                         error={!!errorMessage}
                         endAdornment={
                           <InputAdornment position="end">
                             <Icon/>
                           </InputAdornment>
                         }
          />
        }
        name={name}
        control={control}
        rules={rules}
      />
      {!!errorMessage && <FormHelperText error margin="dense">{errorMessage}</FormHelperText>}
    
    </FormControl>
  
  
  );
};


export default FormText;
