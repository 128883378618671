import React, { useEffect, useMemo } from 'react';
import Dialog                        from '../elements/Dialog';
import Grid                          from '@mui/material/Grid';
import { useDispatch, useSelector }  from 'react-redux';
import { getCompanyHistory } from '../../store/slices/company';
import Table                 from '../elements/Table';
import { formatDate, }       from '../utils/formatters';


const CompanyHistory = ({data, modalOn, closeModal}) => {
  
  const dispatch         = useDispatch();
  const {companyHistory} = useSelector(state => state.company);
  
  
  useEffect(() => {
    
    if (modalOn) {
      const {id} = data;
      dispatch(getCompanyHistory({empresaId: id}));
    }
    
  }, [data]);
  
  const columns = useMemo(
    () =>
      [
        {
          accessor: 'nome',
          Header  : 'Nome',
        },
        {
          accessor: 'dataInicio',
          Header  : 'Data Inicio',
          Cell    : ({value}) => formatDate(value)
        },
        {
          accessor: 'dataFim',
          Header  : 'Data Fim',
          Cell    : ({value}) => formatDate(value)
        },
      ],
    []
  );
  
  return (
    <Dialog id="company-history-modal" show={modalOn} handleClose={closeModal} title={'Histórico da Empresa'}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Table columns={columns} rowsData={companyHistory} hideFilter/>
        </Grid>
      </Grid>
    </Dialog>
  );
  
};


export default CompanyHistory;
