import React            from 'react';
import { makeStyles }   from '@mui/material/styles';
import Accordion        from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography       from '@mui/material/Typography';
import ExpandMoreIcon   from '@mui/icons-material/ExpandMore';
import Grid           from '@mui/material/Grid';
import classes        from './MyPlanDetail.module.css';
import Box            from '../elements/Box';
import { formatDate } from '../utils/formatters';
import PlanChange     from '../sections/PlanChange';
import PlanAddon      from '../sections/PlanAddon';


const MyAddonDetail = ({userConsume}) => {
  
  
  return (
    <Grid container>
      <Grid item xs={8} className={classes.Plan}>
        <Typography variant="h5" className={classes.Text} gutterBottom>
          Consumo Adicional
        </Typography>
      </Grid>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon/>} aria-controls="panel1a-content" id="panel1a-header">
          <Typography variant="body1" className={classes.TotalTitle}>{userConsume.adcText}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <PlanAddon/>
        </AccordionDetails>
      </Accordion>
    
    
    </Grid>
  );
};


export default MyAddonDetail;
