import React             from 'react';
import Snackbar          from '@mui/material/Snackbar';
import IconButton        from '@mui/material/IconButton';
import CloseIcon         from '@mui/icons-material/Close';
import Alert             from '@mui/material/Alert';
import { useDispatch }   from 'react-redux';
import { removeMessage } from '../../store/slices/app';

let lastType = 'info';

const Message = ({messages}) => {
  
  const dispatch = useDispatch();
  
  const handleClose = () => {
    dispatch(removeMessage());
  };
  
  if (messages.length) {
    lastType = messages[0].type;
  }
  
  const message = messages.length ? messages[0].text : '';
  
  const type = lastType;
  
  return (
    
    <Snackbar
      anchorOrigin={{vertical: 'bottom', horizontal: 'left',}}
      open={!!(messages && messages.length)}
      autoHideDuration={4000}
      onClose={handleClose}
      ContentProps={{'aria-describedby': 'message-id',}}
      action={
        <IconButton key="close" aria-label="Close" color="inherit" onClick={handleClose}>
          <CloseIcon fontSize="small"/>
        </IconButton>
      }
    >
      <Alert variant="filled" onClose={handleClose} severity={type}>
        {message}
      </Alert>
    </Snackbar>
  
  );
};

export default Message;

