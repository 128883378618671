import React, { useEffect, useMemo }                 from 'react';
import { formatCurrency, formatDate, formatInteger } from '../../components/utils/formatters';
import excludePopover                                from '../../components/partials/ExcludePopover';
import Grid                                          from '@mui/material/Grid';
import Table                                         from '../../components/elements/Table';
import { useDispatch, useSelector }                  from 'react-redux';
import { getBusiness }                               from '../../store/slices/business';
import { excludeBusiness }                           from '../../store/slices/business';
import IncludeBusiness                               from '../../components/containerActions/IncludeBusiness';
import CellTooltip                                   from '../../components/elements/CellTooltip';


const Business = () => {
  
  const dispatch = useDispatch();
  
  const {business} = useSelector(state => state.business);
  
  const confirmExclusion = (rowData) => dispatch(excludeBusiness({data: {negocioId: rowData.original.id}, internalData: {index: rowData.index}}));
  
  useEffect(() => {
    
    dispatch(getBusiness());
  }, []);
  
  
  const columns = useMemo(
    () =>
      [
        {
          accessor: 'papel',
          Header  :'Papel',
        },
        {
          accessor: 'quantidade',
          Header  :  'Quantidade',
          Cell    : ({value}) => formatInteger(value)
        },
        {
          accessor: 'valorPapel',
          Header  :  'Valor Papel',
          Cell    : ({value}) => formatCurrency(value)
        },
        {
          accessor: 'custo',
          Header  :  'Custo',
          Cell    : ({value}) => formatCurrency(value)
        },
        {
          accessor: 'valorNegocio',
          Header  :  'Valor Negócio',
          Cell    : ({value}) => formatCurrency(value)
        },
        {
          accessor: 'corretora',
          Header  :'Corretora',
        },
        {
          accessor: 'tipoNegocio',
          Header  : 'Tipo de Negócio',
        },
        {
          Header  : 'Data Negócio',
          id      : 'dataNegocio',
          accessor: value => formatDate(value.dataNegocio) ,
          sortType: 'alphanumericOriginal',
        },
        {
          Header:  '',
          accessor: 'exercicio',
          Cell    : CellTooltip('Realizado via exercício', 'exercicio')
        },
        {
          Header:  '',
          id: 'exclusion',
          Cell    : excludePopover(confirmExclusion, 'negócio')
        },
        
      ],
    []
  );
  
  // console.log("business", business)
  
  return (
    <Grid container>
      <Grid item xs={12}>
        {!!columns.length && <Table columns={columns} rowsData={business} inclusion={<IncludeBusiness/>}/>}
      </Grid>
    </Grid>
  );
};

export default Business;
