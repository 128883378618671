import { createSlice }                                from '@reduxjs/toolkit';
import { thunkAPI }                                   from '../../components/utils/api';
import { daysBetweenDates, justToDate, subtractDays } from '../../components/utils/date';
import { defineOperationType, definePaperType }       from '../../components/utils/constants';

export const getAchievements         = thunkAPI('achievement/get', 'v1/realizacao/finalizadas/provento', 'get',);
export const getAchievementsReport   = thunkAPI('achievement-report/get', 'v1/realizacao/finalizadas', 'get',);
export const getAllAchievements      = thunkAPI('allAchievement/get', 'v1/realizacao/completo', 'get',);
export const getTemporalWallet      = thunkAPI('temporalWallet/get', 'v1/realizacao/posicao', 'get',);
export const getCompiledAchievements = thunkAPI('compiledAchievements/get', 'v1/realizacao/agrupado', 'get',);

const last12 = justToDate(subtractDays(new Date(), 365));

const formatAchievements         = (achievements, time) => {
  
  const formattedAchievements = [];
  let i                       = -1;
  const paperTypes            = {};
  const series                = [];
  const values                = {};
  const periods               = {};
  
  for (const data of achievements) {
    // console.log("data", data)
    const papel      = data.sigla;
    const tipoPapel  = data.tipoPapel;
    const periodo    = daysBetweenDates(data.dtCompra, data.dtVenda);
    const objetoBase = periodo > 0 ? 'Venda' : 'Compra';
    const dataBase   = data[`dt${objetoBase}`];
    const anoBase    = dataBase.substring(0, 4);
    const mesBase    = dataBase.substring(5, 7);
    let mensal       = periodo / 30;
    
    if (mensal < 0) mensal *= -1;
    
    const quantidade              = data.qtPapel;
    const vlObjBase               = data[`vl${objetoBase}`];
    const custoTotalCompra        = quantidade * data.custoCompra;
    const custoTotalVenda         = quantidade * data.custoVenda;
    const custoTotal              = custoTotalCompra + custoTotalVenda;
    const balancoUnitario         = (data.vlVenda - data.vlCompra - data.custoVenda - data.custoCompra);
    const balanco                 = balancoUnitario * quantidade;
    const debitoCompra            = Number(data.custoCompra) + Number(data.vlCompra);
    const debitoVenda             = Number(data.custoVenda) + Number(data.vlVenda);
    const debito                  = debitoCompra || debitoVenda;
    const valorizacao             = balancoUnitario / debito;
    const corretoraCompra         = data.corretoraCompra;
    const corretoraVenda          = data.corretoraVenda;
    const custoTotalOriginal      = (Number(data.custoCompraOriginal) + Number(data.custoVendaOriginal)) * quantidade;
    const balancoUnitarioOriginal = (data.vlVendaOriginal - data.vlCompraOriginal - data.custoVendaOriginal - data.custoCompraOriginal);
    const balancoOriginal         = balancoUnitarioOriginal * quantidade;
    const debitoCompraOriginal    = Number(data.custoCompraOriginal) + Number(data.vlCompraOriginal);
    const debitoVendaOriginal     = Number(data.custoVendaOriginal) + Number(data.vlVendaOriginal);
    const debitoOriginal          = debitoCompraOriginal || debitoVendaOriginal;
    const valorizacaoOriginal     = balancoUnitarioOriginal / debitoOriginal;
    const valorizacaoMensal       = valorizacaoOriginal / mensal;
    const valorProvento           = Number(data.valorProvento) || 0;
    const valorProventoUnitario   = valorProvento / quantidade;
    const valorizacaoProventos    = (balancoUnitario + valorProventoUnitario) / debito;
    const valorizacaoProvOriginal = (balancoUnitarioOriginal + valorProventoUnitario) / debitoOriginal;
    const valorizacaoProvMensal   = valorizacaoProvOriginal / mensal;
    const dayTrade                = data.dtCompra === data.dtVenda;
    
    const realizacao = {
      id              : `${i + 1}d${dataBase}v${data[`vl${objetoBase}`]}`,
      vlCompraOriginal: data.vlCompraOriginal,
      vlVendaOriginal : data.vlVendaOriginal,
      dataCompra      : data.dtCompra,
      dataVenda       : data.dtVenda,
      custoTotalOriginal,
      balancoOriginal,
      valorizacaoOriginal,
      valorizacaoProvOriginal,
      valorizacaoMensal,
      valorizacaoProvMensal,
      corretoraCompra,
      corretoraVenda,
      quantidade,
    };
    
    
    if (i > -1 && papel === formattedAchievements[i].papel && objetoBase === formattedAchievements[i].objetoBase &&
      dataBase === formattedAchievements[i].dataBase && dayTrade === formattedAchievements[i].dayTrade &&
      vlObjBase === formattedAchievements[i][`valor${objetoBase}`]) {
      formattedAchievements[i].quantidade += quantidade;
      formattedAchievements[i].balanco += balanco;
      formattedAchievements[i].custoTotalCompra += custoTotalCompra;
      formattedAchievements[i].custoTotalVenda += custoTotalVenda;
      formattedAchievements[i].custoTotal += custoTotal;
      formattedAchievements[i].valorProvento += valorProvento;
      formattedAchievements[i].realizacoes.push(realizacao);
    } else {
      i++;
      formattedAchievements.push({
        id         : i,
        anoBase,
        mesBase,
        papel,
        quantidade,
        valorCompra: data.vlCompra,
        valorVenda : data.vlVenda,
        custoTotalCompra,
        custoTotalVenda,
        custoTotal,
        balanco,
        valorizacao,
        valorizacaoProventos,
        valorProvento,
        valorizacaoMensal,
        valorizacaoProvMensal,
        objetoBase,
        dataBase,
        dayTrade,
        realizacoes: [realizacao],
      });
    }
    
    const mesNumero = Number(mesBase);
    
    if (!periods[anoBase]) periods[anoBase] = {};
    if (!periods[anoBase][mesNumero]) periods[anoBase][mesNumero] = true;
    
    if (paperTypes[`${tipoPapel}-${dayTrade}`] === undefined) {
      paperTypes[`${tipoPapel}-${dayTrade}`] = series.length;
      if (values[`${tipoPapel}-${dayTrade}`] === undefined) {
        values[`${tipoPapel}-${dayTrade}`] = {
          index: series.length,
          data : {}
        };
      }
      series.push({
        name : defineOperationType(tipoPapel, dayTrade),
        stack: definePaperType(tipoPapel),
        data : []
      });
    }
    
    if (values[`${tipoPapel}-${dayTrade}`].data[anoBase] === undefined) values[`${tipoPapel}-${dayTrade}`].data[anoBase] = {geral: 0, meses: {}};
    if (values[`${tipoPapel}-${dayTrade}`].data[anoBase].meses[mesNumero] === undefined)
      values[`${tipoPapel}-${dayTrade}`].data[anoBase].meses[mesNumero] = 0;
    values[`${tipoPapel}-${dayTrade}`].data[anoBase].geral += balanco;
    values[`${tipoPapel}-${dayTrade}`].data[anoBase].meses[mesNumero] += balanco;
  }
  
  const xAxis = [];
  for (const [year, months] of Object.entries(periods)) {
    if (time > 12) xAxis.push(year);
    else {
      for (const month of Object.keys(months)) {
        xAxis.push(`${month}/${year}`);
      }
    }
    
    for (const [type, index] of Object.entries(paperTypes)) {
      if (time > 12) {
        let value = (!!values[type].data[year] && values[type].data[year].geral) || 0;
        series[index].data.push(value);
      } else {
        for (const month of Object.keys(months)) {
          let value = (!!values[type].data[year] && values[type].data[year].meses && values[type].data[year].meses[month]) || 0;
          series[index].data.push(value);
        }
      }
    }
    
  }
  // console.log(formattedAchievements)
  // console.log(series)
  
  return [formattedAchievements, {periods: xAxis, series}];
  
};
const formatGroupedAchievements  = (achievements, earnings, time) => {
  
  const paperTypesData        = {};
  const series                = [];
  const periods               = {};
  
  
  for (const data of achievements) {
    
    const periodo         = daysBetweenDates(data.dtCompra, data.dtVenda);
    const objetoBase      = periodo > 0 ? 'Venda' : 'Compra';
    const dataBase        = data[`dt${objetoBase}`];
    const anoBase         = dataBase.substring(0, 4);
    const mesBase         = Number(dataBase.substring(5, 7));
    const dayTrade        = data.dtCompra === data.dtVenda;
    const tipoPapel       = defineOperationType(data.Papel.cdTipoPapel, dayTrade);
    const balancoUnitario = (data.vlVenda - data.vlCompra - data.custoVenda - data.custoCompra);
    const quantidade      = data.qtPapel;
    const balanco         = Number(balancoUnitario * quantidade);
    
    // console.log(data);
    
    if (!periods[anoBase]) periods[anoBase] = {};
    if (!periods[anoBase][mesBase]) periods[anoBase][mesBase] = true;
    
    if (paperTypesData[tipoPapel] === undefined) {
      paperTypesData[tipoPapel] = {
        index: series.length,
        data : {}
      };
      series.push({
        name: tipoPapel,
        // stack: definePaperType(tipoPapel),
        data: []
      });
    }
    
    
    if (paperTypesData[tipoPapel].data[anoBase] === undefined) paperTypesData[tipoPapel].data[anoBase] = {geral: 0, meses: {}};
    if (paperTypesData[tipoPapel].data[anoBase].meses[mesBase] === undefined) paperTypesData[tipoPapel].data[anoBase].meses[mesBase] = 0;
    paperTypesData[tipoPapel].data[anoBase].geral += balanco;
    paperTypesData[tipoPapel].data[anoBase].meses[mesBase] += balanco;
    
    // if (!periods[anoBase]) periods[anoBase] = {};
    // if (!periods[anoBase][mesBase]) periods[anoBase][mesBase] = {};
    // if (!periods[anoBase][mesBase][tipoPapel]) periods[anoBase][mesBase][tipoPapel] = 0;
    //
    // periods[anoBase][mesBase][tipoPapel] += balanco
    
    
  }
  
  for (const data of earnings) {
    
    const anoBase   = data.ano.substring(0, 4);
    const mesBase   = Number(data.mes.substring(5, 7));
    const tipoPapel = 'Proventos';
    
    if (!periods[anoBase]) periods[anoBase] = {};
    if (!periods[anoBase][mesBase]) periods[anoBase][mesBase] = true;
  
    if (paperTypesData[tipoPapel] === undefined) {
      paperTypesData[tipoPapel] = {
        index: series.length,
        data : {}
      };
      series.push({
        name: tipoPapel,
        // stack: definePaperType(tipoPapel),
        data: []
      });
    }
   
    const balanco = Number(data.valorProvento);
    
    if (paperTypesData[tipoPapel].data[anoBase] === undefined) paperTypesData[tipoPapel].data[anoBase] = {geral: 0, meses: {}};
    if (paperTypesData[tipoPapel].data[anoBase].meses[mesBase] === undefined) paperTypesData[tipoPapel].data[anoBase].meses[mesBase] = 0;
    paperTypesData[tipoPapel].data[anoBase].geral += balanco;
    paperTypesData[tipoPapel].data[anoBase].meses[mesBase] += balanco;
    
    // if (!periods[anoBase]) periods[anoBase] = {};
    // if (!periods[anoBase][mesBase]) periods[anoBase][mesBase] = {};
    // if (!periods[anoBase][mesBase][tipoPapel]) periods[anoBase][mesBase][tipoPapel] = 0;
    //
    // periods[anoBase][mesBase][tipoPapel] += data.valorProvento;
  }
  
  const xAxis = [];
  for (const [year, months] of Object.entries(periods)) {
    if (time > 12) xAxis.push(year);
    else {
      for (const month of Object.keys(months)) {
        xAxis.push(`${month}/${year}`);
      }
    }
    
    for (const {index, data} of Object.values(paperTypesData)) {
       
      if (time > 12) {
        let value = (!!data[year] && data[year].geral) || 0;
        series[index].data.push(value);
      } else {
        for (const month of Object.keys(months)) {
          let value = (!!data[year] && data[year].meses && data[year].meses[month]) || 0;
          series[index].data.push(value);
        }
      }
    }
    
  }
  
  // console.log(paperTypesData);
  // console.log(series);
  
  return {periods: xAxis, series};
  
};
const formatAchievementsReports  = (achievements) => {
  
  
  const formattedAchievements = {
    estatistica     : {
      absolutoMedio                : {
        title        : 'Lucro Negócios',
        subtitle     : 'Preço Médio',
        rightSubtitle: 'Completo',
        principal    : 'value',
        papel        : 'TODOS',
        valor        : 0,
        realizacao   : {}
      },
      absolutoMedioProventos       : {
        title        : 'Lucro Negócios c/ Proventos',
        subtitle     : 'Preço Médio',
        rightSubtitle: 'Completo',
        principal    : 'value',
        papel        : 'TODOS',
        valor        : 0,
        realizacao   : {}
      },
      absolutoMedioPeriodo         : {
        title        : 'Lucro Negócios',
        subtitle     : 'Preço Médio',
        rightSubtitle: '12 meses',
        principal    : 'value',
        papel        : 'TODOS',
        valor        : 0,
        realizacao   : {}
      },
      absolutoMedioPeriodoProventos: {
        title        : 'Lucro Negócios c/ Proventos',
        subtitle     : 'Preço Médio',
        rightSubtitle: '12 meses',
        principal    : 'value',
        papel        : 'TODOS',
        valor        : 0,
        realizacao   : {}
      },
    },
    geral           : {
      absolutoMedio     : {
        title    : 'Maior Realização Absoluta',
        subtitle : 'Preço Médio',
        principal: 'value',
        valor    : 0,
        periodo  : 0,
      },
      percentualMedio   : {
        title      : 'Maior Realização Percentual',
        subtitle   : 'Preço Médio',
        principal  : 'appreciation',
        valorizacao: 0,
        periodo    : 0,
      },
      absolutoOriginal  : {
        title    : 'Maior Realização Absoluta',
        subtitle : 'Preço Original',
        principal: 'value',
        valor    : 0,
        periodo  : 0,
      },
      percentualOriginal: {
        title      : 'Maior Realização Percentual',
        subtitle   : 'Preço Original',
        principal  : 'appreciation',
        valorizacao: 0,
        periodo    : 0,
      },
    },
    geralProventos  : {
      absolutoMedio     : {
        title     : 'Maior Realização Absoluta',
        subtitle  : 'Preço Médio',
        principal : 'value',
        valor     : 0,
        periodo   : 0,
        realizacao: {},
      },
      percentualMedio   : {
        title      : 'Maior Realização Percentual',
        subtitle   : 'Preço Médio',
        principal  : 'appreciation',
        valorizacao: 0,
        periodo    : 0,
        realizacao : {},
      },
      absolutoOriginal  : {
        title     : 'Maior Realização Percentual',
        subtitle  : 'Preço Original',
        principal : 'value',
        valor     : 0,
        periodo   : 0,
        realizacao: {},
      },
      percentualOriginal: {
        title      : 'Maior Realização Percentual',
        subtitle   : 'Preço Original',
        principal  : 'appreciation',
        valorizacao: 0,
        periodo    : 0,
        realizacao : {},
      },
    },
    periodo         : {
      absolutoMedio     : {
        title     : 'Maior Realização Absoluta',
        subtitle  : 'Preço Médio',
        principal : 'value',
        valor     : 0,
        periodo   : 0,
        realizacao: {},
      },
      percentualMedio   : {
        title      : 'Maior Realização Percentual',
        subtitle   : 'Preço Médio',
        principal  : 'appreciation',
        valorizacao: 0,
        periodo    : 0,
        realizacao : {},
      },
      absolutoOriginal  : {
        title     : 'Maior Realização Absoluta',
        subtitle  : 'Preço Original',
        principal : 'value',
        valor     : 0,
        periodo   : 0,
        realizacao: {},
      },
      percentualOriginal: {
        title      : 'Maior Realização Percentual',
        subtitle   : 'Preço Original',
        principal  : 'appreciation',
        valorizacao: 0,
        periodo    : 0,
        realizacao : {},
      },
    },
    periodoProventos: {
      absolutoMedio     : {
        title     : 'Maior Realização Absoluta',
        subtitle  : 'Preço Médio',
        principal : 'value',
        valor     : 0,
        periodo   : 0,
        realizacao: {},
      },
      percentualMedio   : {
        title      : 'Maior Realização Percentual',
        subtitle   : 'Preço Médio',
        principal  : 'appreciation',
        valorizacao: 0,
        periodo    : 0,
        realizacao : {},
      },
      absolutoOriginal  : {
        title     : 'Maior Realização Percentual',
        subtitle  : 'Preço Original',
        principal : 'value',
        valor     : 0,
        periodo   : 0,
        realizacao: {},
      },
      percentualOriginal: {
        title      : 'Maior Realização Percentual',
        subtitle   : 'Preço Original',
        principal  : 'appreciation',
        valorizacao: 0,
        periodo    : 0,
        realizacao : {},
      },
    },
  };
  
  let balancoGlobal          = 0;
  let debitoGlobal           = 0;
  let proventosGlobal        = 0;
  let debitoGlobalPeriodo    = 0;
  let balancoGlobalPeriodo   = 0;
  let proventosGlobalPeriodo = 0;
  let numeroNegocios         = 0;
  let numeroNegociosPeriodo  = 0;
  
  for (const data of achievements) {
    numeroNegocios++;
    const quantidade              = data.qtPapel;
    const periodo                 = daysBetweenDates(data.dtCompra, data.dtVenda);
    const objetoBase              = periodo > 0 ? 'Venda' : 'Compra';
    const dataBase                = data[`dt${objetoBase}`];
    const balancoUnitario         = (data.vlVenda - data.vlCompra - data.custoVenda - data.custoCompra);
    const balanco                 = balancoUnitario * quantidade;
    const balancoUnitarioOriginal = (data.vlVendaOriginal - data.vlCompraOriginal - data.custoVendaOriginal - data.custoCompraOriginal);
    const balancoOriginal         = balancoUnitarioOriginal * quantidade;
    const debitoCompra            = Number(data.custoCompra) + Number(data.vlCompra);
    const debitoVenda             = Number(data.custoVenda) + Number(data.vlVenda);
    const debito                  = debitoCompra || debitoVenda;
    const valorizacao             = balancoUnitario / debito;
    const debitoCompraOriginal    = Number(data.custoCompraOriginal) + Number(data.vlCompraOriginal);
    const debitoVendaOriginal     = Number(data.custoVendaOriginal) + Number(data.vlVendaOriginal);
    const debitoOriginal          = debitoCompraOriginal || debitoVendaOriginal;
    const valorizacaoOriginal     = balancoUnitarioOriginal / debitoOriginal;
    const valorProvento           = Number(data.valorProvento) || 0;
    const balancoProv             = balanco + valorProvento;
    const balancoOrigProv         = balancoOriginal + valorProvento;
    const valorProventoUnitario   = valorProvento / quantidade;
    const valorizacaoProventos    = (balancoUnitario + valorProventoUnitario) / debito;
    const valorizacaoProvOriginal = (balancoUnitarioOriginal + valorProventoUnitario) / debitoOriginal;
    balancoGlobal += balanco;
    proventosGlobal += valorProvento;
    debitoGlobal += debitoCompra * quantidade;
    
    if (formattedAchievements.geral.absolutoMedio.valor < balanco) {
      const periodo = daysBetweenDates(data.dtCompra, data.dtVenda);
      let mensal    = periodo / 30;
      if (mensal < 0) mensal *= -1;
      const valorizacaoMensal                                     = valorizacao / mensal;
      formattedAchievements.geral.absolutoMedio.valor             = balanco;
      formattedAchievements.geral.absolutoMedio.periodo           = periodo;
      formattedAchievements.geral.absolutoMedio.valorizacao       = valorizacao;
      formattedAchievements.geral.absolutoMedio.valorizacaoMensal = valorizacaoMensal;
      formattedAchievements.geral.absolutoMedio.papel             = data.sigla;
      formattedAchievements.geral.absolutoMedio.realizacao        = data;
    }
    if (formattedAchievements.geral.absolutoOriginal.valor < balancoOriginal) {
      const periodo = daysBetweenDates(data.dtCompra, data.dtVenda);
      let mensal    = periodo / 30;
      if (mensal < 0) mensal *= -1;
      const valorizacaoMensal = valorizacaoOriginal / mensal;
      
      formattedAchievements.geral.absolutoOriginal.valor             = balancoOriginal;
      formattedAchievements.geral.absolutoOriginal.valorizacao       = valorizacaoOriginal;
      formattedAchievements.geral.absolutoOriginal.valorizacaoMensal = valorizacaoMensal;
      formattedAchievements.geral.absolutoOriginal.periodo           = periodo;
      formattedAchievements.geral.absolutoOriginal.papel             = data.sigla;
      formattedAchievements.geral.absolutoOriginal.realizacao        = data;
    }
    if (formattedAchievements.geral.percentualMedio.valorizacao < valorizacao) {
      const periodo = daysBetweenDates(data.dtCompra, data.dtVenda);
      let mensal    = periodo / 30;
      if (mensal < 0) mensal *= -1;
      
      const valorizacaoMensal                                       = valorizacao / mensal;
      formattedAchievements.geral.percentualMedio.valor             = balanco;
      formattedAchievements.geral.percentualMedio.periodo           = periodo;
      formattedAchievements.geral.percentualMedio.valorizacao       = valorizacao;
      formattedAchievements.geral.percentualMedio.valorizacaoMensal = valorizacaoMensal;
      formattedAchievements.geral.percentualMedio.papel             = data.sigla;
      formattedAchievements.geral.percentualMedio.realizacao        = data;
    }
    if (!!debitoOriginal && formattedAchievements.geral.percentualOriginal.valorizacao < valorizacaoOriginal) {
      const periodo = daysBetweenDates(data.dtCompra, data.dtVenda);
      let mensal    = periodo / 30;
      if (mensal < 0) mensal *= -1;
      const valorizacaoMensal = valorizacaoOriginal / mensal;
      
      formattedAchievements.geral.percentualOriginal.valor             = balancoOriginal;
      formattedAchievements.geral.percentualOriginal.periodo           = periodo;
      formattedAchievements.geral.percentualOriginal.valorizacao       = valorizacaoOriginal;
      formattedAchievements.geral.percentualOriginal.valorizacaoMensal = valorizacaoMensal;
      formattedAchievements.geral.percentualOriginal.papel             = data.sigla;
      formattedAchievements.geral.percentualOriginal.realizacao        = data;
    }
    
    
    if (formattedAchievements.geralProventos.absolutoMedio.valor < balancoProv) {
      
      const periodo = daysBetweenDates(data.dtCompra, data.dtVenda);
      let mensal    = periodo / 30;
      if (mensal < 0) mensal *= -1;
      
      const valorizacaoProvMensal = valorizacaoProventos / mensal;
      
      formattedAchievements.geralProventos.absolutoMedio.valor             = balancoProv;
      formattedAchievements.geralProventos.absolutoMedio.periodo           = periodo;
      formattedAchievements.geralProventos.absolutoMedio.valorizacao       = valorizacaoProventos;
      formattedAchievements.geralProventos.absolutoMedio.valorizacaoMensal = valorizacaoProvMensal;
      formattedAchievements.geralProventos.absolutoMedio.papel             = data.sigla;
      formattedAchievements.geralProventos.absolutoMedio.realizacao        = data;
    }
    if (formattedAchievements.geralProventos.absolutoOriginal.valor < balancoOrigProv) {
      const periodo = daysBetweenDates(data.dtCompra, data.dtVenda);
      let mensal    = periodo / 30;
      if (mensal < 0) mensal *= -1;
      
      const valorizacaoProvMensal                                             = valorizacaoProvOriginal / mensal;
      formattedAchievements.geralProventos.absolutoOriginal.valor             = balancoOrigProv;
      formattedAchievements.geralProventos.absolutoOriginal.valorizacao       = valorizacaoProvOriginal;
      formattedAchievements.geralProventos.absolutoOriginal.valorizacaoMensal = valorizacaoProvMensal;
      formattedAchievements.geralProventos.absolutoOriginal.periodo           = periodo;
      formattedAchievements.geralProventos.absolutoOriginal.papel             = data.sigla;
      formattedAchievements.geralProventos.absolutoOriginal.realizacao        = data;
    }
    if (formattedAchievements.geralProventos.percentualMedio.valorizacao < valorizacaoProventos) {
      const periodo = daysBetweenDates(data.dtCompra, data.dtVenda);
      let mensal    = periodo / 30;
      if (mensal < 0) mensal *= -1;
      
      const valorizacaoMensal                                                = valorizacaoProventos / mensal;
      formattedAchievements.geralProventos.percentualMedio.valor             = balancoProv;
      formattedAchievements.geralProventos.percentualMedio.periodo           = periodo;
      formattedAchievements.geralProventos.percentualMedio.valorizacao       = valorizacaoProventos;
      formattedAchievements.geralProventos.percentualMedio.valorizacaoMensal = valorizacaoMensal;
      formattedAchievements.geralProventos.percentualMedio.papel             = data.sigla;
      formattedAchievements.geralProventos.percentualMedio.realizacao        = data;
    }
    if (!!debitoOriginal && formattedAchievements.geralProventos.percentualOriginal.valorizacao < valorizacaoProvOriginal) {
      const periodo = daysBetweenDates(data.dtCompra, data.dtVenda);
      let mensal    = periodo / 30;
      if (mensal < 0) mensal *= -1;
      const valorizacaoMensal = valorizacaoProvOriginal / mensal;
      
      formattedAchievements.geralProventos.percentualOriginal.valor             = balancoOrigProv;
      formattedAchievements.geralProventos.percentualOriginal.periodo           = periodo;
      formattedAchievements.geralProventos.percentualOriginal.valorizacao       = valorizacaoProvOriginal;
      formattedAchievements.geralProventos.percentualOriginal.valorizacaoMensal = valorizacaoMensal;
      formattedAchievements.geralProventos.percentualOriginal.papel             = data.sigla;
      formattedAchievements.geralProventos.percentualOriginal.realizacao        = data;
    }
    
    
    if (last12 <= dataBase) {
      numeroNegociosPeriodo++;
      balancoGlobalPeriodo += balanco;
      proventosGlobalPeriodo += valorProvento;
      debitoGlobalPeriodo += debitoCompra * quantidade;
      if (formattedAchievements.periodo.absolutoMedio.valor < balanco) {
        const periodo = daysBetweenDates(data.dtCompra, data.dtVenda);
        let mensal    = periodo / 30;
        if (mensal < 0) mensal *= -1;
        
        const valorizacaoMensal                                       = valorizacao / mensal;
        formattedAchievements.periodo.absolutoMedio.valor             = balanco;
        formattedAchievements.periodo.absolutoMedio.periodo           = periodo;
        formattedAchievements.periodo.absolutoMedio.valorizacao       = valorizacao;
        formattedAchievements.periodo.absolutoMedio.valorizacaoMensal = valorizacaoMensal;
        formattedAchievements.periodo.absolutoMedio.papel             = data.sigla;
        formattedAchievements.periodo.absolutoMedio.realizacao        = data;
      }
      if (formattedAchievements.periodo.absolutoOriginal.valor < balancoOriginal) {
        const periodo = daysBetweenDates(data.dtCompra, data.dtVenda);
        let mensal    = periodo / 30;
        if (mensal < 0) mensal *= -1;
        const valorizacaoMensal = valorizacaoOriginal / mensal;
        
        formattedAchievements.periodo.absolutoOriginal.valor             = balancoOriginal;
        formattedAchievements.periodo.absolutoOriginal.valorizacao       = valorizacaoOriginal;
        formattedAchievements.periodo.absolutoOriginal.valorizacaoMensal = valorizacaoMensal;
        formattedAchievements.periodo.absolutoOriginal.periodo           = periodo;
        formattedAchievements.periodo.absolutoOriginal.papel             = data.sigla;
        formattedAchievements.periodo.absolutoOriginal.realizacao        = data;
      }
      if (formattedAchievements.periodo.percentualMedio.valorizacao < valorizacao) {
        const periodo = daysBetweenDates(data.dtCompra, data.dtVenda);
        let mensal    = periodo / 30;
        if (mensal < 0) mensal *= -1;
        
        const valorizacaoMensal                                         = valorizacao / mensal;
        formattedAchievements.periodo.percentualMedio.valor             = balanco;
        formattedAchievements.periodo.percentualMedio.periodo           = periodo;
        formattedAchievements.periodo.percentualMedio.valorizacao       = valorizacao;
        formattedAchievements.periodo.percentualMedio.valorizacaoMensal = valorizacaoMensal;
        formattedAchievements.periodo.percentualMedio.papel             = data.sigla;
        formattedAchievements.periodo.percentualMedio.realizacao        = data;
      }
      if (!!debitoOriginal && formattedAchievements.periodo.percentualOriginal.valorizacao < valorizacaoOriginal) {
        const periodo = daysBetweenDates(data.dtCompra, data.dtVenda);
        let mensal    = periodo / 30;
        if (mensal < 0) mensal *= -1;
        const valorizacaoMensal = valorizacaoOriginal / mensal;
        
        formattedAchievements.periodo.percentualOriginal.valor             = balancoOriginal;
        formattedAchievements.periodo.percentualOriginal.periodo           = periodo;
        formattedAchievements.periodo.percentualOriginal.valorizacao       = valorizacaoOriginal;
        formattedAchievements.periodo.percentualOriginal.valorizacaoMensal = valorizacaoMensal;
        formattedAchievements.periodo.percentualOriginal.papel             = data.sigla;
        formattedAchievements.periodo.percentualOriginal.realizacao        = data;
      }
      
      if (formattedAchievements.periodoProventos.absolutoMedio.valor < balancoProv) {
        const periodo = daysBetweenDates(data.dtCompra, data.dtVenda);
        let mensal    = periodo / 30;
        if (mensal < 0) mensal *= -1;
        const valorizacaoProvMensal = valorizacaoProventos / mensal;
        
        formattedAchievements.periodoProventos.absolutoMedio.valor             = balancoProv;
        formattedAchievements.periodoProventos.absolutoMedio.periodo           = periodo;
        formattedAchievements.periodoProventos.absolutoMedio.valorizacao       = valorizacaoProventos;
        formattedAchievements.periodoProventos.absolutoMedio.valorizacaoMensal = valorizacaoProvMensal;
        formattedAchievements.periodoProventos.absolutoMedio.papel             = data.sigla;
        formattedAchievements.periodoProventos.absolutoMedio.realizacao        = data;
      }
      if (formattedAchievements.periodoProventos.absolutoOriginal.valor < balancoOrigProv) {
        const periodo = daysBetweenDates(data.dtCompra, data.dtVenda);
        let mensal    = periodo / 30;
        if (mensal < 0) mensal *= -1;
        const valorizacaoProvMensal                                               = valorizacaoProvOriginal / mensal;
        formattedAchievements.periodoProventos.absolutoOriginal.valor             = balancoOrigProv;
        formattedAchievements.periodoProventos.absolutoOriginal.valorizacao       = valorizacaoProvOriginal;
        formattedAchievements.periodoProventos.absolutoOriginal.valorizacaoMensal = valorizacaoProvMensal;
        formattedAchievements.periodoProventos.absolutoOriginal.periodo           = periodo;
        formattedAchievements.periodoProventos.absolutoOriginal.papel             = data.sigla;
        formattedAchievements.periodoProventos.absolutoOriginal.realizacao        = data;
      }
      
      if (formattedAchievements.periodoProventos.percentualMedio.valorizacao < valorizacaoProventos) {
        const periodo = daysBetweenDates(data.dtCompra, data.dtVenda);
        let mensal    = periodo / 30;
        if (mensal < 0) mensal *= -1;
        
        const valorizacaoMensal                                                  = valorizacaoProventos / mensal;
        formattedAchievements.periodoProventos.percentualMedio.valor             = balancoProv;
        formattedAchievements.periodoProventos.percentualMedio.periodo           = periodo;
        formattedAchievements.periodoProventos.percentualMedio.valorizacao       = valorizacaoProventos;
        formattedAchievements.periodoProventos.percentualMedio.valorizacaoMensal = valorizacaoMensal;
        formattedAchievements.periodoProventos.percentualMedio.papel             = data.sigla;
        formattedAchievements.periodoProventos.percentualMedio.realizacao        = data;
      }
      if (!!debitoOriginal && formattedAchievements.periodoProventos.percentualOriginal.valorizacao < valorizacaoProvOriginal) {
        const periodo = daysBetweenDates(data.dtCompra, data.dtVenda);
        let mensal    = periodo / 30;
        if (mensal < 0) mensal *= -1;
        const valorizacaoMensal = valorizacaoProvOriginal / mensal;
        
        formattedAchievements.periodoProventos.percentualOriginal.valor             = balancoOrigProv;
        formattedAchievements.periodoProventos.percentualOriginal.periodo           = periodo;
        formattedAchievements.periodoProventos.percentualOriginal.valorizacao       = valorizacaoProvOriginal;
        formattedAchievements.periodoProventos.percentualOriginal.valorizacaoMensal = valorizacaoMensal;
        formattedAchievements.periodoProventos.percentualOriginal.papel             = data.sigla;
        formattedAchievements.periodoProventos.percentualOriginal.realizacao        = data;
      }
    }
    
  }
  
  if (balancoGlobal) {
    const totalGeral                                                          = (balancoGlobal + proventosGlobal);
    formattedAchievements.estatistica.absolutoMedio.valor                     = balancoGlobal;
    formattedAchievements.estatistica.absolutoMedio.valorizacaoMedia          = balancoGlobal / debitoGlobal;
    formattedAchievements.estatistica.absolutoMedio.ticketMedio               = balancoGlobal / numeroNegocios;
    formattedAchievements.estatistica.absolutoMedioProventos.valor            = totalGeral;
    formattedAchievements.estatistica.absolutoMedioProventos.valorizacaoMedia = totalGeral / debitoGlobal;
    formattedAchievements.estatistica.absolutoMedioProventos.ticketMedio      = totalGeral / numeroNegocios;
    
    const totalGeralPeriodo                                                 = (balancoGlobalPeriodo + proventosGlobalPeriodo);
    formattedAchievements.estatistica.absolutoMedioPeriodo.valor            = balancoGlobalPeriodo;
    formattedAchievements.estatistica.absolutoMedioPeriodo.valorizacaoMedia = balancoGlobalPeriodo / debitoGlobalPeriodo;
    formattedAchievements.estatistica.absolutoMedioPeriodo.ticketMedio      = balancoGlobalPeriodo / numeroNegociosPeriodo;
    
    formattedAchievements.estatistica.absolutoMedioPeriodoProventos.valor            = totalGeralPeriodo;
    formattedAchievements.estatistica.absolutoMedioPeriodoProventos.valorizacaoMedia = totalGeralPeriodo / debitoGlobalPeriodo;
    formattedAchievements.estatistica.absolutoMedioPeriodoProventos.ticketMedio      = totalGeralPeriodo / numeroNegociosPeriodo;
  }
  
  // console.log('period', period);
  
  
  return formattedAchievements;
  
};
const formatCompiledAchievements = (achievements) => {
  
  const formattedCompiledAchievements = {
    categories: [],
    st        : [],
    dt        : []
  };
  const formattedYearsAchievements    = [];
  
  const supportObj = {};
  
  // console.log('achievements', achievements);
  
  const years = [];
  let anoAnterior;
  
  for (const data of achievements) {
    const saldo = Number(data.saldo);
    
    const {ano, daytrade, tipo_papel} = data;
    // console.log("tipo_papel", tipo_papel, daytrade, saldo, )
    
    if (supportObj[tipo_papel] === undefined) {
      formattedCompiledAchievements.categories.push(definePaperType(tipo_papel));
      const index                             = formattedCompiledAchievements.categories.length - 1;
      formattedCompiledAchievements.st[index] = 0;
      formattedCompiledAchievements.dt[index] = 0;
      supportObj[tipo_papel]                  = index;
    }
    
    if (anoAnterior !== ano && years.length < 10) {
      years.unshift(ano);
      for (const reg of formattedYearsAchievements) {
        reg.data.unshift(0);
      }
      
    }
    
    if (supportObj[`${tipo_papel}-${daytrade}`] === undefined) {
      formattedYearsAchievements.push({
        name : defineOperationType(tipo_papel, daytrade),
        stack: definePaperType(tipo_papel),
        data : []
      });
      const index                             = formattedYearsAchievements.length - 1;
      supportObj[`${tipo_papel}-${daytrade}`] = index;
      for (let i = 0; i < years.length; i++) {
        formattedYearsAchievements[index].data[i] = 0;
      }
    }
    
    const index  = supportObj[tipo_papel];
    const index2 = supportObj[`${tipo_papel}-${daytrade}`];
    
    formattedYearsAchievements[index2].data[0] = saldo;
    
    if (daytrade) formattedCompiledAchievements.dt[index] += saldo;
    else formattedCompiledAchievements.st[index] += saldo;
    anoAnterior = ano;
  }
  
  
  // console.log('formattedYearsAchievements', formattedYearsAchievements);
  // console.log('formattedCompiledAchievements', formattedCompiledAchievements);
  
  return [formattedCompiledAchievements, {years, series: formattedYearsAchievements}];
  
};
const formatDateWallet = (wallet) => {

  const formattedWallet = [];
  const groupedWallet   = [];

  let paperIndexes = {};
  let ultimoPapel;

  for (const ac of wallet ) {

    let quantidade = ac.qtPapel;
    let corretora;

    switch(true) {
      case !ac.dtVenda:
        corretora = ac.CorretoraCompra.abreviatura;
        break;
      case !ac.dtCompra:
        corretora = ac.CorretoraVenda.abreviatura;
        quantidade *= -1;
        break;
      case ac.dtCompra > ac.dtVenda:
        corretora = ac.CorretoraVenda.abreviatura;
        quantidade *= -1;
        break;
      case ac.dtVenda >= ac.dtCompra:
        corretora = ac.CorretoraCompra.abreviatura;
        break;
    }

    if (ultimoPapel !== ac.cdPapel)  {
      ultimoPapel = ac.cdPapel;
      paperIndexes = {};
      groupedWallet.push({
        papel: ac.Papel.sigla,
        quantidade,  
      })
    } else {
      groupedWallet[groupedWallet.length - 1].quantidade += quantidade;
    }
    
    if (paperIndexes[corretora] === undefined) {
      formattedWallet.push({
        papel: ac.Papel.sigla,
        quantidade,  
        corretora
      })
      paperIndexes[corretora] = formattedWallet.length - 1;
    } else {
      formattedWallet[paperIndexes[corretora]].quantidade += quantidade;
    }
        
  }
  
  // console.log("formattedWallet", formattedWallet);
  // console.log("groupedWallet", groupedWallet);

  return [formattedWallet, groupedWallet];
}

const achievementSlice = createSlice({
  name         : 'achievement',
  initialState : {
    achievements        : [],
    achievementsReport  : {},
    dateWallet          : [],
    groupedWallet       : [],
    groupedAchievements : {},
    compiledAchievements: {st: [], dt: []},
    yearsAchievements   : {series: [], years: [],},
    periodAchievements  : {series: [], periods: [],},
  },
  reducers     : {},
  extraReducers: {
    [getAchievements.fulfilled]        : (state, action) => {
      [state.achievements, state.periodAchievements] = formatAchievements(action.payload.response, action.payload.time);
    },
    [getAllAchievements.fulfilled]     : (state, action) => {
      state.achievementsReport = formatAchievementsReports(action.payload);
    },
    [getAchievementsReport.fulfilled]  : (state, action) => {
      state.groupedAchievements = formatGroupedAchievements(action.payload.realizacoes, action.payload.proventos, action.payload.time);
    },
    [getCompiledAchievements.fulfilled]: (state, action) => {
      [state.compiledAchievements, state.yearsAchievements] = formatCompiledAchievements(action.payload);
    },
    [getTemporalWallet.fulfilled]: (state, action) => {
      [state.dateWallet, state.groupedWallet] = formatDateWallet(action.payload);
    },
    
  }
});

const {reducer} = achievementSlice;


export default reducer;
