import React, { useEffect, useRef } from 'react';
import ClickAwayListener            from '@mui/material/ClickAwayListener';
import Grow                         from '@mui/material/Grow';
import Paper                        from '@mui/material/Paper';
import Popper                       from '@mui/material/Popper';
import MenuItem                     from '@mui/material/MenuItem';
import MenuList                     from '@mui/material/MenuList';
import ListItemIcon                 from '@mui/material/ListItemIcon';
import LockIcon                     from '@mui/icons-material/Lock';
import { useNavigate }               from 'react-router-dom';
import classes from './ProfileMenu.module.css'


const ProfileMenu = ({handleToggle, open, anchorRef}) => {
  
  const navigate  = useNavigate();
  
  const handleClick = (link) => {
    navigate(link);
    handleToggle();
  };
  
  
  const handleListKeyDown = (event) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      handleToggle();
    }
  };
  
  const prevOpen = useRef(open);
  
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    
    prevOpen.current = open;
  }, [open]);
  
  return (
    <div className={classes.Root}>
        <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
          {({TransitionProps, placement}) => (
            <Grow
              {...TransitionProps}
              style={{transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'}}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleToggle}>
                  <MenuList autoFocusItem={open} id="profile-options" onKeyDown={handleListKeyDown}>
                    <MenuItem onClick={() => handleClick('/senha')}>
                      <ListItemIcon>
                        <LockIcon fontSize="small" />
                      </ListItemIcon>
                      Senha
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
    </div>
  );
};


export default ProfileMenu;
