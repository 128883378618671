import jwtDecode     from 'jwt-decode';
import { setHeader } from './api';

export const consumeToken          = token => {
  
  setHeader(token)
  const decoded                                       = jwtDecode(token);
  let user                                            = {};
  // console.log("token", token)
  // console.log("decoded", decoded)
  if (decoded.iss === 'Kiila' && decoded.exp > Date.now() / 1000) {
    user = {
      ...decoded.sub,
      validity: decoded.exp * 1000,
    }
  }
  
  return user;
};
