import { createSlice } from '@reduxjs/toolkit';
import { thunkAPI }    from '../../components/utils/api';


export const resetAllUsersConsume      = thunkAPI('earning/include', '/v1/consumo/zerar', 'post',
  {successMessage: 'Provento Incluído com sucesso'});


const slice = createSlice({
  name         : 'others',
  initialState : {
 
  },
  reducers     : {},
  extraReducers: {  }
});

const {reducer} = slice;

export default reducer;
