import React, { useEffect, useMemo, useState } from 'react';
import Table                                   from '../../components/elements/Table';
import { useDispatch, useSelector }            from 'react-redux';
import { formatCpfCnpj, formatCurrency }       from '../../components/utils/formatters';
import Grid                                    from '@mui/material/Grid';
import YearBusinessSelect                      from '../../components/partials/YearBusinessSelect';
import { getYearEarnings }                     from '../../store/slices/earning';
import Box                                     from '../../components/elements/Box';
import classes                                 from '../Wallet/Wallet.module.css';
import Typography                              from '@mui/material/Typography';


const YearEarningPosition = () => {
  
  
  const dispatch                        = useDispatch();
  const {yearEarnings}                  = useSelector(state => state.earning);
  const {yearBusiness}                  = useSelector(state => state.business);
  const [yearSelected, setYearSelected] = useState('');
  
  const handleChange = (event) => {
    setYearSelected(event.target.value);
  };
  
  const initializeValue = (value) => {
    setYearSelected(value);
  };
  
  useEffect(() => {
    
    if (yearSelected) dispatch(getYearEarnings({year: yearSelected}));
  }, [yearSelected]);
  
  const columns = useMemo(
    () =>
      [
        {
          accessor: 'sigla',
          Header  : 'Papel',
          
        },
        {
          accessor: 'cnpj',
          Header  : 'CNPJ',
          Cell    : ({value}) => formatCpfCnpj(value)
          
        },
        {
          accessor: 'valorDividendo',
          Header  : 'Valor Dividendos',
          Cell    : ({value}) => formatCurrency(value)
          
        },
        {
          accessor: 'valorJcp',
          Header  : 'Valor Juros Sobre Capital',
          Cell    : ({value}) => formatCurrency(value)
          
        },
        {
          accessor: 'valorRendimento',
          Header  : 'Valor Rendimento',
          Cell    : ({value}) => formatCurrency(value)
          
        },
      
      ],
    []
  );
  
  
  return (
    <Grid container>
      <Grid item xs={12}>
        <Table columns={columns} rowsData={yearEarnings} inclusion={
          <YearBusinessSelect onChange={handleChange} value={yearSelected} onInit={initializeValue}/>}/>
      </Grid>
      {!yearBusiness.length &&
      <Box>
        <Grid item xs={12} className={classes.NegativeBox}>
          <Typography variant={'h6'} className={classes.NegativeText}>
            Você ainda não possui ativos
          </Typography>
        </Grid>
      </Box>
      }
    </Grid>
  
  );
};


export default YearEarningPosition;
