import React from 'react';


import OutlinedInput  from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel     from '@mui/material/InputLabel';
import FormControl    from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';

import classes        from './Input.module.css';
import { Controller } from 'react-hook-form';
import NumberFormat   from 'react-number-format';

const MaskedTextField = (props) => {
  
  let {onChange, inputRef, formatOptions, ...other} = props;
  
  // console.log("formatOptions", formatOptions)
  
  return <NumberFormat {...other} getInputRef={inputRef} onValueChange={({value}) => onChange(value)}
                       decimalSeparator={','} thousandSeparator={'.'} allowNegative={false}
                       decimalScale={formatOptions.scale} isAllowed={formatOptions.validator} format={formatOptions.formatter}
  />;
  
};


const FormMaskText = ({name, defaultValue, label, Icon, control, rules, disabled, formatOptions, errorMessage, ...props}) => {
  
  return (
    <FormControl aria-label={name} variant="outlined" fullWidth className={classes.Field}>
      <InputLabel error={!!errorMessage} htmlFor={name}>{label}</InputLabel>
      <Controller name={name} control={control} rules={rules} defaultValue={defaultValue}
                  render={({field}) => (
                    <OutlinedInput id={name} label={label} error={!!errorMessage} {...props}
                                   inputProps={{formatOptions, ...field}}
                                   inputComponent={MaskedTextField}
                    />
                  )}
      />
      {!!errorMessage && <FormHelperText error margin="dense">{errorMessage}</FormHelperText>}
    </FormControl>
  
  );
};


export default FormMaskText;
