import React, { useState } from 'react';

import { useForm }      from 'react-hook-form';
import { useDispatch, } from 'react-redux';
import classes          from './IncludeRearrangement.module.css';
import Button           from '../elements/Button';
import Dialog           from '../elements/Dialog';

import { decimalFormat, }                       from '../utils/constants';
import { handleError, rules }                   from '../utils/rules';
import Grid                                     from '@mui/material/Grid';
import { searchAdmPaper }                       from '../../store/slices/paper';
import FormDate                                 from '../elements/FormDate';
import CakeIcon                                 from '@mui/icons-material/Cake';
import FormAutoComplete                         from '../elements/FormAutoComplete';
import FormMask                                 from '../elements/FormMask';
import FormSwitch                               from '../elements/FormSwitch';
import AttachMoneyIcon                          from '@mui/icons-material/AttachMoney';
import { includeRepurchase, computeRepurchase } from '../../store/slices/repurchase';

const defaultValues = {
  creditDate    : '',
  repurchaseDate: '',
  paper         : null,
  value         : '',
  schedule      : false,
};

const IncludeRepurchase = () => {
  
  const dispatch = useDispatch();
  
  const [modalOn, setModalOn] = useState(false);
  
  const {control, handleSubmit, getValues, formState} = useForm({mode: 'all', defaultValues});
  
  const {dirtyFields, isValid, errors} = formState;
  
  let modal;
  
  const openModal = () => {
    setModalOn(true);
  };
  
  const closeModal = () => setModalOn(false);
  
  const onSubmit = (data) => {
    
    const {repurchaseDate, creditDate, paper, value, schedule} = data;
    const func                                                 = schedule ? includeRepurchase : computeRepurchase;
    
    dispatch(func({
      papelId    : paper.cdPapel,
      dtRecompra: repurchaseDate,
      dtCredito  : creditDate,
      vlRecompra : value
    }));
    
  };
  
  const disabled  = !isValid;
  const showPaper = (!!dirtyFields['repurchaseDate'] && !Boolean(errors['repurchaseDate']));
  
  modal = <Dialog id="repurchase-modal" show={modalOn} handleClose={closeModal} title={'Inclusão Recompra'}>
    
    <form id="repurchase" onSubmit={handleSubmit(onSubmit)} className={classes.Form}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} className={classes.Item}>
          <FormDate label="Data Recompra" name="repurchaseDate" Icon={CakeIcon} errorMessage={handleError(errors, 'repurchaseDate')}
                    control={control} rules={rules('businessDate')}
          />
        </Grid>
        <Grid item xs={12} sm={6} className={classes.Item}>
          <FormDate label="Data Crédito" name="creditDate" Icon={CakeIcon} errorMessage={handleError(errors, 'creditDate')}
                    control={control}
          />
        </Grid>
        <Grid item xs={12} sm={6} className={classes.Item}>
          <FormMask label="Valor Recompra" name="value" Icon={AttachMoneyIcon} defaultValue={defaultValues['value']}
                    errorMessage={handleError(errors, 'value')} control={control}
                    rules={rules('value')} formatOptions={decimalFormat}
          />
        </Grid>
        <Grid item xs={12} sm={6} className={classes.Item}>
          {showPaper &&
          <FormAutoComplete label="Papel" name="paper" placeholder="Pesquise o papel" control={control} dataFields={{slice: 'paper', data: 'papersSearched'}}
                            dataFunction={searchAdmPaper} searchField={'busca'} requiredData={{dataPesquisa: getValues('repurchaseDate')}}
                            displayField="sigla" rules={(rules('paper', null, 'Papel'))} errorMessage={handleError(errors, 'paper')}/>
          }
        </Grid>
        <Grid item xs={12} sm={6} className={classes.Switch}>
          <FormSwitch label={'Agendar'} name={'schedule'} control={control} defaultValue={defaultValues['schedule']}/>
        </Grid>
        <Grid item xs={12} className={classes.Button}>
          <Button color="secondary" disabled={disabled} type="submit">
            Cadastrar
          </Button>
        </Grid>
      </Grid>
    </form>
  </Dialog>;
  
  return (
    <>
      <Button color="secondary" disabled={!!modalOn} onClick={openModal}>
        Incluir
      </Button>
      {modal}
    </>
  );
};

export default IncludeRepurchase;
