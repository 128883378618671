import React, { useEffect, useState } from 'react';

import classes from './Login.module.css';

import FormText                     from '../../components/elements/FormText';
import DocIcon                      from '@mui/icons-material/PictureInPicture';
import CakeIcon                     from '@mui/icons-material/Cake';
import MailIcon                     from '@mui/icons-material/Email';
import PersonIcon                   from '@mui/icons-material/Person';
import Grid                         from '@mui/material/Grid';
import Button                       from '../../components/elements/Button';
import { useForm }                  from 'react-hook-form';
import FormPassword                 from '../../components/elements/FormPassword';
import { handleError, rules }       from '../../components/utils/rules';
import Dialog                       from '../../components/elements/Dialog';
import FormCheckbox                 from '../../components/elements/FormCheckbox';
import FormDate                     from '../../components/elements/FormDate';
import FormMask                     from '../../components/elements/FormMask';
import { docIdRule }                from '../../components/utils/constants';
import UseTherms                    from '../../components/sections/UseTherms';
import Paper                        from '@mui/material/Paper';
import { useDispatch, useSelector } from 'react-redux';
import { signup }                   from '../../store/slices/auth';
import RegisterMessage              from '../../components/partials/RegisterMessage';

const defaultValues = {
  name           : '',
  docID          : '',
  birthday       : null,
  email          : '',
  emailConfirm   : '',
  password       : '',
  passwordConfirm: '',
  therms         : false
};


const Signup = ({open, onClose,}) => {
  
  const dispatch           = useDispatch();
  const {includeSituation} = useSelector(state => state.auth);
  
  const [notSent, setNotSent] = useState(true);
  
  const {control, handleSubmit, reset, getValues, formState} = useForm({mode: 'all', defaultValues});
  
  const {isValid, isDirty, errors} = formState;
  
  useEffect(() => {
    
    if (includeSituation === 'idle' && isValid && isDirty) {
      // console.log('oi');
      setNotSent(false);
      reset(defaultValues);
    }
    
  }, [includeSituation]);
  
  
  const onSubmit = (data) => {
    // console.log(data); // handle the data here
    const {name, birthday, email, password, docID} = data;
    dispatch(signup({name, birthday, email, password, docID}));
  };
  
  
  const disabled = (!isValid || includeSituation === 'pending');
  
  return (
    <Dialog id="signup-dialog" show={open} handleClose={onClose} title={'Novo Cadastro'}>
      {!!notSent && <form id="signup" onSubmit={handleSubmit(onSubmit)} className={classes.Form}>
        <Grid container spacing={2}>
          <Grid item xs={12} className={classes.Item}>
            <FormText label="Nome Completo" name="name" Icon={PersonIcon} errorMessage={handleError(errors, 'name')}
                      control={control} rules={rules('name',)}
            />
          </Grid>
          <Grid item xs={12} sm={6} className={classes.Item}>
            <FormDate label="Data de Nascimento" name="birthday" Icon={CakeIcon} errorMessage={handleError(errors, 'birthday')}
                      control={control} rules={rules('birthday',)}
            />
          </Grid>
          <Grid item xs={12} sm={6} className={classes.Item}>
            <FormMask label="CPF/CNPJ" name="docID" Icon={DocIcon} defaultValue={defaultValues['docID']}
                      errorMessage={handleError(errors, 'docID')} control={control}
                      rules={rules('docID', null, 'CPF/CNPJ')} formatOptions={docIdRule}
            />
          </Grid>
          <Grid item xs={12} sm={6} className={classes.Item}>
            <FormText label="Email" name="email" Icon={MailIcon} errorMessage={handleError(errors, 'email')}
                      control={control} rules={rules('email',)}
            />
          </Grid>
          <Grid item xs={12} sm={6} className={classes.Item}>
            <FormText label="Confirme o Email" name="emailConfirm" Icon={MailIcon} errorMessage={handleError(errors, 'emailConfirm')}
                      control={control} rules={rules('emailConfirm', getValues('email'))}
            />
          </Grid>
          <Grid item xs={12} sm={6} className={classes.Item}>
            <FormPassword label="Senha" name="password" errorMessage={handleError(errors, 'password')}
                          control={control} rules={rules('password',)}
            />
          </Grid>
          <Grid item xs={12} sm={6} className={classes.Item}>
            <FormPassword label="Confirme a Senha" name="passwordConfirm" errorMessage={handleError(errors, 'passwordConfirm')}
                          control={control} rules={rules('passwordConfirm', getValues('password'))}
            />
          </Grid>
          <Grid item xs={12}>
            <Paper elevation={0} className={classes.Therms}>
              <UseTherms/>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <FormCheckbox label="Estou de acordo com os termos e condições" name="therms" control={control}
                          rules={rules('therms')}/>
          </Grid>
          <Grid item xs={12} className={classes.Button}>
            <Button color="secondary" disabled={disabled} type="submit">
              {includeSituation === 'pending' ? 'Cadastrando' : 'Cadastrar'}
            </Button>
          </Grid>
        </Grid>
      </form>}
      {!notSent &&
      <RegisterMessage handleBack={setNotSent}/>
      }
    </Dialog>
  );
};


export default Signup;
